/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  getDeliveryEstimate,
  selectDropoffs,
  selectPickups,
  setDefaultPickup,
  setDeliveryPickups
} from 'store/slices/new-delivery';
import { selectIsAuthenticated, selectUser } from 'store/slices/authentication';

import Button from 'components/objects/button';
import InfoBox from 'components/new-order/info-box';
import NewOrderPreview from 'components/new-order/preview';
import NewOrderShipmentOptions from 'components/new-order/shipment-options';
import Map from 'components/map';
import StaticMap from 'components/static-map';
import Banner from 'components/banner';

import previewIllustration from 'images/preview/illustration.svg';

import alertTriangle from 'images/icons/alert-triangle.svg';
import chevronLeftIcon from 'images/icons/chevron-left.svg';
import mapIcon from 'images/icons/map.svg';
import markerIcon from 'images/icons/marker-blue.svg';

import './styles.scss';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/login'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'header.sign_in'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/register'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'header.sign_up'
  }
];

const NewOrderDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const preview = useRef(null);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const [endLocation, setEndLocation] = useState('');
  const [deliveryError, setDeliveryError] = useState(null);
  const [dropoffAddress, setDropoffAddress] = useState(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [route, setRoute] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [startLocation, setStartLocation] = useState('');
  const [userLocation, setUserLocation] = useState(null);

  const dropoffs = useSelector(selectDropoffs);
  const pickups = useSelector(selectPickups);

  const intl = useIntl();

  // NOTE: Replaced with the above, I was not sure why we were using these in the first place
  // const dropoffAddress = route?.filter(address => address.type === 'dropoff' && !!address.location.streetAddress);
  // const pickUpAddress = route?.filter(address => address.type === 'pickup' && !!address.location.streetAddress);

  useEffect(() => {
    if (
      pickups.length &&
      dropoffs.length &&
      dropoffs[0]?.address &&
      pickups[0]?.address &&
      pickups[0]?.address?.streetAddress !== '' &&
      dropoffs[0]?.address?.streetAddress !== ''
    ) {
      setShowMap(true);
    }
  }, [pickups, dropoffs]);

  const handleStartLocationChange = (location) => {
    setStartLocation(location);
  };

  const handleEndLocationChange = (location) => {
    setEndLocation(location);
  };

  const handleDeliveryError = (error) => {
    setDeliveryError(error?.message);
  };

  const handleRouteRange = (orderRoute) => {
    const mappedPickups = orderRoute.pickups.map(pickup => ({
      location: pickup,
      type: 'pickup'
    }));

    const mappedStops = orderRoute.stops.map(stop => ({
      location: stop,
      type: 'stop'
    }));

    const mappedDropoffs = orderRoute.dropoffs.map(dropoff => ({
      location: dropoff,
      type: 'dropoff'
    }));

    const joinedRoute = mappedPickups.concat(mappedStops).concat(mappedDropoffs);

    if (joinedRoute.length && JSON.stringify(joinedRoute) !== JSON.stringify(route)) {
      setRoute(joinedRoute);
    }
  };

  const previewComponent = preview.current;

  const setOptionsWidth = useCallback(() => {
    if (!isAuthenticated && preview.current) {
      const bounds = preview.current.getBoundingClientRect();

      if (bounds) {
        const previewWidth = bounds.width;

        setPreviewWidth(previewWidth);
      }
    }
  }, [isAuthenticated]);

  const [previewWidth, setPreviewWidth] = useState(0);

  useEffect(() => {
    setOptionsWidth();
  }, [previewComponent, setOptionsWidth]);

  setTimeout(() => {
    setOptionsWidth();
  }, 4000);

  useEffect(() => {
    window.addEventListener('resize', setOptionsWidth);

    if (!isAuthenticated) {
      document.body.classList.add('is-new-order-logged-out-page');
    }


    return () => {
      window.removeEventListener('resize', setOptionsWidth);
      document.body.classList.remove('is-new-order-logged-out-page');
    };
  });

  const handleGeolocation = (position) => {
    if (position?.coords?.latitude && position?.coords?.longitude) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      setUserLocation(`${lat},${lng}`);
    }
  };

  navigator.geolocation.getCurrentPosition(handleGeolocation);

  useEffect(() => {
    if (isAuthenticated && user && !pickups[0]?.address?.streetAddress) {
      dispatch(setDefaultPickup());
    }
  }, [dispatch, isAuthenticated, user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthenticated) {
      if (pickups.length) {
        dispatch(setDeliveryPickups(pickups));
      }
    }
  }, [dispatch, pickups, isAuthenticated]);

  useEffect(() => {
    if (pickups[0] && dropoffs[0]) {
      setDropoffAddress(dropoffs[0].address);
      setPickupAddress(pickups[0].address);
    }
  }, [dropoffs, pickups]);

  useEffect(() => {
    if (
      dropoffAddress &&
      pickupAddress &&
      pickupAddress.streetAddress !== '' &&
      dropoffAddress.streetAddress !== ''
    ) {
      dispatch(getDeliveryEstimate({ dropoff: dropoffAddress, pickup: pickupAddress }))
        .then((response) => {
          if (response?.error) {
            if (response.payload) {
              handleDeliveryError(response.payload);
              throw new Error(response.payload.message || 'Something went wrong while requesting an estimate for your delivery.');
            } else {
              throw new Error(response.message || 'Something went wrong while requesting an estimate for your delivery.');
            }
          }

          return response;
        })
        .catch((error) => {
          // TODO: Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [dispatch, dropoffAddress, pickupAddress]);

  return (
    <div className="new-order-details">
      <div className="container">
        <Button
          classNames="new-order-details__previous"
          icon={chevronLeftIcon}
          noBackground
          noPadding
          text={intl.formatMessage({ id: 'header.back' })}
          onClick={() => history.goBack()}
        />
        <div className="row">
          <div className="col col-12 col-md-6">
            {!isAuthenticated && (
              <>
                <NewOrderPreview
                  ref={preview}
                />
                <div className="new-order-details__separator" />
              </>
            )}
            <NewOrderShipmentOptions
              width={previewWidth}
              onDeliveryError={handleDeliveryError}
              onEndLocationChange={handleEndLocationChange}
              onRouteChange={newRoute => handleRouteRange(newRoute)}
              onStartLocationChange={handleStartLocationChange}
            />
            {!isAuthenticated && (
              <div
                className="new-order-details__banner-wrapper"
                style={{ width: `${previewWidth}px` }}
              >
                <Banner
                  buttons={bannerButtons}
                  classNames="new-order-details__banner"
                  image={previewIllustration}
                  text="new-order.promo.text"
                  title="new-order.promo.title"
                />
              </div>
            )}
          </div>
          <div className="col col-12 offset-0 col-md-5 offset-lg-1">
            <div className="new-order-details__map-wrapper">
              {!isAuthenticated ? (
                <StaticMap
                  classNames="new-order-details__static-map"
                  instructions={intl.formatMessage({ id: 'map.location_permission' })}
                  location={userLocation}
                  markerIcon={markerIcon}
                />
              ) : (
                <>
                  {showMap ? (
                    <>
                      <Map
                        endLocation={endLocation}
                        id="newOrderMap"
                        route={route}
                        startLocation={startLocation}
                      />
                      {deliveryError && (
                        <div className="new-order-details__error">
                          <InfoBox
                            icon={alertTriangle}
                            title={deliveryError}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="new-order-details__map-placeholder">
                      <img
                        alt="Map Icon"
                        className="new-order-details__map-placeholder-icon"
                        src={mapIcon}
                      />
                      <FormattedMessage id="map.choose_addresses" />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderDetails;
