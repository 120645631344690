import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { fetchDashboard, fetchDashboardShops, selectDashboardShops, selectSections } from 'store/slices/dashboard';

import DashboardSection from 'components/sections/dashboard-section';
import Disclaimer from 'components/disclaimer';
import RadioToggle from '@youship/components/objects/radio-toggle';
import Search from 'components/search';
import ShopCard from 'components/shop-card';

// NOTE: MOCKED DATA
const MOCKED_SHOP_OPTIONS = [
  {
    id: 0,
    name: 'Delivery',
    isActive: true
  },
  {
    id: 1,
    name: 'Pickup',
    isActive: false
  }
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const sections = useSelector(selectSections);
  const shops = useSelector(selectDashboardShops);
  const intl = useIntl();

  const [searchValue, setSearchValue] = useState('');
  const [selectedShopOptionId, setSelectedShopOptionId] = useState(MOCKED_SHOP_OPTIONS[0].id);
  const [selectedShopOptionValue, setSelectedShopOptionValue] = useState(MOCKED_SHOP_OPTIONS[0].name.toLowerCase());

  const handleSearchChange = (event) => {
    setSearchValue(event?.target?.value ?? '');
  };

  const handleRadioToggleChange = (id, value) => {
    setSelectedShopOptionValue(value.toLowerCase());
    setSelectedShopOptionId(id);
  };

  useEffect(() => {
    dispatch(fetchDashboard(selectedShopOptionValue))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading the dashboard.');

        return response;
      })
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch, selectedShopOptionValue]);

  useEffect(() => {
    if (searchValue) {
      dispatch(fetchDashboardShops(searchValue))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading the dashboard.');

          return response;
        })
        .catch((error) => {
          // Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [dispatch, searchValue]);

  return (
    <div className="dashboard">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <Search
              placeholder={intl.formatMessage({ id: 'dashboard.search' })}
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-12 col-md-4">
            <RadioToggle
              options={MOCKED_SHOP_OPTIONS}
              selected={selectedShopOptionId}
              onChange={handleRadioToggleChange}
            />
          </div>
        </div>
      </div>
      {!searchValue && sections.map(section => (
        <DashboardSection
          key={section.id}
          {...section}
        />
      ))}
      {Array.isArray(shops) && searchValue && (
        <div className="dashboard-section">
          <div className="container">
            <div className="row">
              {shops.length ?
                shops.map(item => (
                  <div
                    key={item.id}
                    className="col col-12 col-sm-6 col-lg-4"
                  >
                    <ShopCard {...item} />
                  </div>
                )) : (
                  <div className="col col-12">
                    <Disclaimer />
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
