import React, { useState } from 'react';

import RegisterWizard from 'components/register/wizard';
import ImagePanel from '@youship/components/objects/image-panel';

import './styles.scss';

const RegisterArea = () => {
  const [currentStep, setCurrentStep] = useState(null);

  const getRegisterStep = (step) => {
    setCurrentStep(step);
  };

  return (
    <div className="register-area">
      {currentStep !== 3 && (
        <ImagePanel
          hiddenSm
          registerStep={currentStep}
        />
      )}
      <RegisterWizard
        onStepChange={getRegisterStep}
      />
    </div>
  );
};

export default RegisterArea;
