import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { selectIsUpdatingPassword, updatePassword } from 'store/slices/authentication';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';

import greenCheckIcon from 'images/icons/check-green.svg';

import './styles.scss';

const PasswordUpdateModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const isUpdatingPassword = useSelector(selectIsUpdatingPassword);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeated, setNewPasswordRepeated] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [oldPassword, setOldPassword] = useState('');

  const newPasswordRepeatedIsValid = !newPasswordRepeated || newPasswordRepeated === newPassword;
  const formIsValid = !!oldPassword && !!newPassword && newPasswordRepeated === newPassword;

  const intl = useIntl();

  const handleConfirmButtonClick = () => {
    dispatch(updatePassword({
      oldPassword,
      newPassword
    }))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while updating your password.');

        setSuccessMessage(response?.payload?.message || intl.formatMessage({ id: 'password.changed_success' }));

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        setErrorMessage(error.message);
      });
  };

  const handleNewPasswordInputChange = (event) => {
    setNewPassword(event?.target?.value ?? '');
  };

  const handleNewPasswordRepeatedInputChange = (event) => {
    setNewPasswordRepeated(event?.target?.value ?? '');
  };

  const handleOldPasswordInputChange = (event) => {
    setOldPassword(event?.target?.value ?? '');
  };

  const footer = successMessage ?
    null : (
      <div className="password-update-modal__footer">
        {!!errorMessage && (
          <p className="password-update-modal__error-message">
            {errorMessage}
          </p>
        )}
        <Button
          context="primary"
          disabled={!formIsValid || isUpdatingPassword}
          largeHorizontalPadding
          smallVerticalPadding
          onClick={handleConfirmButtonClick}
        >
          <FormattedMessage id="app.confirm" />
        </Button>
      </div>
    );

  return (
    <Modal
      classNames="password-update-modal"
      footer={footer}
      title={intl.formatMessage({ id: 'password.title' })}
      onClose={onClose}
    >
      <div className="password-update-modal__body">
        {successMessage ? (
          <div className="password-update-modal__feedback">
            <Notice
              description={successMessage}
              fixed
              icon={greenCheckIcon}
            />
            <Button
              context="primary"
              largeHorizontalPadding
              outline
              onClick={onClose}
            >
              <FormattedMessage id="app.finish" />
            </Button>
          </div>
        ) : (
          <form className="password-update-modal__form">
            <div className="row password-update-modal__row">
              <div className="col col-12 col-sm-6">
                <Input
                  block
                  inputId="password-update-modal-old-password-input"
                  label={intl.formatMessage({ id: 'password.old_password' })}
                  type="password"
                  value={oldPassword}
                  onChange={handleOldPasswordInputChange}
                />
              </div>
            </div>
            <div className="row password-update-modal__row">
              <div className="col col-12 col-sm-6">
                <Input
                  block
                  inputId="password-update-modal-new-password-input"
                  label={intl.formatMessage({ id: 'password.new_password' })}
                  type="password"
                  value={newPassword}
                  onChange={handleNewPasswordInputChange}
                />
              </div>
              <div className="col col-12 col-sm-6">
                <Input
                  block
                  inputId="password-update-modal-new-password-repeated-input"
                  label={intl.formatMessage({ id: 'password.repeat_password' })}
                  message={newPasswordRepeatedIsValid ? null : intl.formatMessage({ id: 'password.error_not_match' })}
                  status={newPasswordRepeatedIsValid ? null : 'error'}
                  type="password"
                  value={newPasswordRepeated}
                  onChange={handleNewPasswordRepeatedInputChange}
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

PasswordUpdateModal.propTypes = {
  onClose: PropTypes.func
};

PasswordUpdateModal.defaultProps = {
  onClose: () => {}
};

export default PasswordUpdateModal;
