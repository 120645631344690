/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Quantity = ({ block, label, max, min, value, onChange }) => {
  const preventAddition = typeof max === 'number' && value >= max;
  const preventSubtraction = typeof min === 'number' && value <= min;

  const handleMinusClick = () => {
    if (!preventSubtraction) onChange(value - 1);
  };

  const handlePlusClick = () => {
    if (!preventAddition) onChange(value + 1);
  };

  return (
    <div className={`quantity${block ? ' quantity--block' : ''}`}>
      {label && (
        <div className="quantity__label">
          {label}
        </div>
      )}
      <div className="quantity__input">
        <div
          className={`quantity__button quantity__button--minus${preventSubtraction ? ' quantity__button--disabled' : ''}`}
          onClick={handleMinusClick}
        />
        <div className="quantity__value">
          {value}
        </div>
        <div
          className={`quantity__button quantity__button--plus${preventAddition ? ' quantity__button--disabled' : ''}`}
          onClick={handlePlusClick}
        />
      </div>
    </div>
  );
};

Quantity.propTypes = {
  block: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func
};

Quantity.defaultProps = {
  block: false,
  label: '',
  max: null,
  min: 0,
  onChange: () => {}
};

export default Quantity;
