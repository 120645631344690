import packageExtraSmallIcon from 'images/icons/it-extra-small.svg';
import packageSmallIcon from 'images/icons/it-small.svg';
import packageMediumIcon from 'images/icons/it-medium.svg';
import packageBigIcon from 'images/icons/it-big.svg';
import packageLargeIcon from 'images/icons/it-large.svg';
import packageExtraLargeIcon from 'images/icons/it-extra-large.svg';

const getItemIcon = (icon) => {
  switch (icon) {
    case 'it_extra_small':
      return packageExtraSmallIcon;
    case 'it_small':
      return packageSmallIcon;
    case 'it_medium':
      return packageMediumIcon;
    case 'it_big':
      return packageBigIcon;
    case 'it_large':
      return packageLargeIcon;
    case 'it_extra_large':
      return packageExtraLargeIcon;
    default:
      return packageSmallIcon;
  }
};

export default getItemIcon;
