import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

import chevronLeftIcon from 'images/icons/chevron-left.svg';

const CategoryHeader = ({ category }) => (
  <div className="category-header">
    <Link to="/" className="category-header__previous">
      <img
        className="category-header__previous-icon"
        src={chevronLeftIcon}
        alt="Go to Categories Page"
      />
      Categories
    </Link>
    <h1 className="h2 category-header__title">
      {category}
    </h1>
  </div>
);

export default CategoryHeader;
