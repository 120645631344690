import BaseApi from '..';
import {
  DELIVERY_CREATE,
  DELIVERY_CREATE_MULTIPLE,
  DELIVERY_DETAILS,
  DELIVERY_ESTIMATE,
  DELIVERY_ITEM_TYPE,
  DELIVERY_ITEM_TYPES,
  DELIVERY_PAYMENT_CONFIRM,
  DELIVERY_PAYMENT_TYPES,
  DELIVERY_PROOF_OF_COLLECTION,
  DELIVERY_PROOF_OF_DELIVERY,
  DELIVERY_PROOF_OF_RETURN,
  DELIVERY_QUOTE,
  DELIVERY_QUOTE_MULTIPLE,
  DELIVERY_TRACKING
} from '../endpoints';


const DELIVERY_DEFAULT_TIME_INTERVAL = '00:00';
const DELIVERY_QUOTE_DISTANCE_ERROR_CODE = 11;

export {
  DELIVERY_DEFAULT_TIME_INTERVAL,
  DELIVERY_QUOTE_DISTANCE_ERROR_CODE
};

export default class DeliveryApi extends BaseApi {
  /* eslint-disable camelcase */

  static deliveryEstimate = ({
    distance, // optional
    duration, // optional
    pickup = {
      address: {
        lat: 0,
        lng: 0
      }
    },
    dropoff = {
      address: {
        lat: 0,
        lng: 0
      }
    }
  }) => this.post(DELIVERY_ESTIMATE, {
    distance,
    duration,
    pickup,
    dropoff
  });

  static deliveryQuote = ({
    service_code,
    distance, // optional
    duration, // optional
    draft_ref, // optional
    ship_type, // optional
    voucher, // optional
    get_resume, // optional
    pickup = {
      address: {
        lat: 0,
        lng: 0
      },
      schedule: { // optional
        ready: '' // optional
      }
    },
    dropoff = {
      address: {
        lat: 0,
        lng: 0
      },
      schedule: { // optional
        ready: '' // optional
      }
    },
    items = [
      {
        code: '',
        quantity: 0,
        height: 0,
        length: 0,
        width: 0,
        weight: 0
      }
    ],
    shipment = {
      reference: '',
      comments: '',
      fragile: false,
      return: false,
      handdelivery: false,
      marketing: false,
      chargevalue: 0,
      chargeinfo: '',
      statedvalue: 0,
      insuredvalue: 0
    }
  }) => this.post(DELIVERY_QUOTE, {
    service_code,
    distance,
    duration,
    draft_ref,
    ship_type,
    voucher,
    get_resume,
    pickup,
    dropoff,
    items,
    shipment
  });

  static multiDeliveryQuote = ({
    orders = [{
      service_code: '',
      distance: 0, // optional
      duration: 0, // optional
      voucher: '', // optional
      get_resume: false, // optional
      pickup: {
        address: {
          lat: 0,
          lng: 0
        },
        schedule: { // optional
          ready: '' // optional
        }
      },
      dropoff: {
        address: {
          lat: 0,
          lng: 0
        },
        schedule: { // optional
          ready: '' // optional
        }
      },
      items: [
        {
          code: '',
          quantity: 0,
          height: 0,
          length: 0,
          width: 0,
          weight: 0
        }
      ],
      shipment: {
        reference: '',
        comments: '',
        fragile: false,
        return: false,
        handdelivery: false,
        marketing: false,
        chargevalue: 0,
        chargeinfo: '',
        statedvalue: 0,
        insuredvalue: 0
      }
    }]
  }) => this.post(DELIVERY_QUOTE_MULTIPLE, {
    orders
  });

  static deliveryCreate = ({
    service_code,
    distance, // optional
    duration, // optional
    voucher, // optional
    payment_code,
    pickup = {
      address: {
        street_address: '',
        number_address: '', // optional
        apartment_address: '', // optional
        state_address: '', // optional
        postalcode: '',
        city: '',
        countrycode: '',
        notes: '', // optional
        lat: 0,
        lng: 0,
        formatted_address: ''
      },
      contact: {
        name: '',
        phonecode: '',
        phone: '',
        phonelist: '', // optional
        company: '', // optional
        email: '' // optional
      },
      schedule: {
        ready: '',
        deadline: '' // optional
      },
      notes: '' // optional
    },
    dropoff = {
      address: {
        street_address: '',
        number_address: '', // optional
        apartment_address: '', // optional
        state_address: '', // optional
        postalcode: '',
        city: '',
        countrycode: '',
        notes: '', // optional
        lat: 0,
        lng: 0,
        formatted_address: ''
      },
      contact: {
        name: '',
        phonecode: '',
        phone: '',
        phonelist: '', // optional
        company: '', // optional
        email: '' // optional
      },
      schedule: {
        ready: '', // optional
        deadline: '' // optional
      },
      notes: '' // optional
    },
    items = [
      {
        code: '',
        quantity: 0,
        height: 0,
        length: 0,
        width: 0,
        weight: 0,
        description: ''
      }
    ],
    shipment = {
      shipment_code: '',
      reference: '',
      comments: ''
    },
    invoicing = { // optional
      name: '',
      address: '',
      postalcode: '',
      city: '',
      countrycode: '',
      vat: '',
      email: ''
    }
  }) => this.post(DELIVERY_CREATE, {
    service_code,
    distance,
    duration,
    voucher,
    payment_code,
    pickup,
    dropoff,
    items,
    shipment,
    invoicing
  });

  static multipleDeliveryCreate = ({
    orders = [{
      service_code: '',
      distance: 0, // optional
      duration: 0, // optional
      voucher: '', // optional
      payment_code: '',
      pickup: {
        address: {
          street_address: '',
          number_address: '', // optional
          apartment_address: '', // optional
          state_address: '', // optional
          postalcode: '',
          city: '',
          countrycode: '',
          notes: '', // optional
          lat: 0,
          lng: 0,
          formatted_address: ''
        },
        contact: {
          name: '',
          phonecode: '',
          phone: '',
          phonelist: '', // optional
          company: '', // optional
          email: '' // optional
        },
        schedule: {
          ready: '',
          deadline: '' // optional
        },
        notes: '' // optional
      },
      dropoff: {
        address: {
          street_address: '',
          number_address: '', // optional
          apartment_address: '', // optional
          state_address: '', // optional
          postal: '',
          countrycode: '',
          notes: '', // optional
          lat: 0,
          lng: 0,
          formatted_address: ''
        },
        contact: {
          name: '',
          phonecode: '',
          phone: '',
          phonelist: '', // optional
          company: '', // optional
          email: '' // optional
        },
        schedule: {
          ready: '', // optional
          deadline: '' // optional
        },
        notes: '' // optional
      },
      items: [
        {
          code: '',
          quantity: 0,
          height: 0,
          length: 0,
          width: 0,
          weight: 0,
          description: ''
        }
      ],
      shipment: {
        shipment_code: '',
        reference: '',
        comments: ''
      },
      invoicing: { // optional
        name: '',
        address: '',
        postalcode: '',
        city: '',
        countrycode: '',
        vat: '',
        email: ''
      }
    }]
  }) => this.post(DELIVERY_CREATE_MULTIPLE, {
    orders
  });

  static confirmPayment = ({
    ys_token
  }) => this.post(DELIVERY_PAYMENT_CONFIRM, {
    ys_token
  });

  static deliveryDetails = ({
    order_code
  }) => this.post(DELIVERY_DETAILS, {
    order_code
  });

  static listItemTypes = ({
    service_code
  }) => this.post(DELIVERY_ITEM_TYPES, {
    service_code
  });

  static findItemType = ({
    codenumber
  }) => this.post(DELIVERY_ITEM_TYPE, {
    codenumber
  });

  static paymentMethods = ({
    service_code
  }) => this.post(DELIVERY_PAYMENT_TYPES, {
    service_code
  });

  static proofOfCollection = ({
    order_code
  }) => this.post(DELIVERY_PROOF_OF_COLLECTION, {
    order_code
  });

  static proofOfDelivery = ({
    order_code
  }) => this.post(DELIVERY_PROOF_OF_DELIVERY, {
    order_code
  });

  static proofOfReturn = ({
    order_code
  }) => this.post(DELIVERY_PROOF_OF_RETURN, {
    order_code
  });

  static deliveryTracking = ({
    order_code
  }) => this.post(DELIVERY_TRACKING, {
    order_code
  });

  /* eslint-enable camelcase */
}
