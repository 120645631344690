import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import getItemIcon from '@youship/utils/shipment-items-icons';
import formatPrice from '@youship/utils/format-price';
import paymentIcon from '@youship/utils/payment-icons';

import Button from '@youship/components/objects/button';

import closeIcon from 'images/icons/close.svg';
import eyeIcon from 'images/icons/eye.svg';
import fileIcon from 'images/icons/file.svg';
import orderImagePlaceholder from 'images/order-placeholder.png';
import shipImagePlaceholder from 'images/ship-placeholder.png';

import './styles.scss';
import clockIcon from 'images/icons/clock.svg';

const OrderCard = ({
  actions,
  display,
  dropOff,
  full,
  hasCloseIcon,
  id,
  image,
  items,
  onClose,
  onSeeDetails,
  payment,
  pickUp,
  resume,
  status,
  badges,
  totalPrice,
  type
}) => {
  const cardContent = useRef();
  const location = useLocation();
  const dropOffAddress = dropOff?.address?.streetAddress;
  const dropOffName = dropOff?.contact?.name;
  const dropOffTime = dropOff?.time;
  const paymentMethodCode = payment?.method?.code;
  const paymentMethodName = payment?.method?.name;
  const pickUpAddress = pickUp?.address?.streetAddress;
  const pickUpName = pickUp?.contact?.name;
  const pickUpTime = pickUp?.time;
  const { priceDetails } = resume;

  const isOrder = type === 'order';

  const defaultBackgroundPlaceholder = isOrder ? orderImagePlaceholder : shipImagePlaceholder;

  const closeIconElement = full && hasCloseIcon ? (
    <img
      alt="Close Icon"
      className="order-card__close-icon"
      src={closeIcon}
      onClick={onClose}
    />
  ) : null;

  const imageElement = (
    <img
      alt="Order"
      className="order-card__image"
      src={image ?? defaultBackgroundPlaceholder}
    />
  );

  const intl = useIntl();

  return (
    <div className={`order-card${full ? ' order-card--full' : ''}`}>
      {closeIconElement}
      {!full && imageElement}
      <div className="order-card__content">
        <div className="order-card__header">
          <div className="order-card__title">
            {display?.title}
          </div>
          <div className="order-card__price-date">
            {display?.subtitle}
          </div>
          {badges?.schedule && (
            <div
              className="order-card__schedule"
              style={badges?.schedule?.color ? { border: `1px solid ${badges?.schedule?.color}`, color: badges?.schedule?.color } : {}}
            >
              {badges?.schedule?.text}
            </div>
          )}
          {badges?.status && (
            <div
              className="order-card__status"
              style={badges?.status?.color ? { border: `1px solid ${badges?.status?.color}`, color: badges?.status?.color } : {}}
            >
              {badges?.status?.text}
            </div>
          )}
        </div>
        {full && imageElement}
        {isOrder ? (
          <ul
            ref={cardContent}
            className="order-card-order-list"
          >
            {full && (
              <div className="order-card-order-list__title">
                <FormattedMessage id="order.items" />
              </div>
            )}
            {items.map((item, index) => (
              <li
                key={index}
                className="order-card-order-list__item"
              >
                <span className="order-card-order-list__item-quantity">
                  {item?.quantity}
                </span>
                <span className="order-card-order-list__item-name">
                  {item?.description}
                </span>
                {full && !!item?.price?.value && (
                  <span className="order-card-order-list__item-price">
                    {formatPrice(item.price.value)}
                  </span>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div className={`order-card-ship-list__container${full ? ' order-card-ship-list__container--full' : ''}`}>
            <ul
              ref={cardContent}
              className="order-card-ship-list"
            >
              {(!!pickUpAddress || !!pickUpName) && (
                <li
                  className="order-card-ship-list__item order-card-ship-list__item--circle order-card-ship-list__item--start"
                >
                  {!!pickUpName && (
                    pickUpName
                  )}
                  {!!pickUpAddress && (
                    <div className="order-card-ship-list__item-description">
                      {pickUpAddress}
                    </div>
                  )}
                  {!!pickUpTime && full && (
                    <div className="order-card-ship-list__item-description">
                      <div className="order-card__icon-wrapper">
                        <img
                          alt="Request Icon"
                          src={clockIcon}
                        />
                        <span className="order-card__time">
                          {pickUpTime}
                        </span>
                      </div>
                    </div>
                  )}
                </li>
              )}
              <li
                className="order-card-ship-list__item order-card-ship-list__item--middle"
              >
                <div className="order-card-ship-list__item-description order-card-ship-list__item-description--black">
                  {resume?.name}
                  {display?.shipment}
                </div>
              </li>
              <li className="order-card-ship-list__item order-card-ship-list__item--circle order-card-ship-list__item--end">
                {!!dropOffName && dropOffName}
                {!!dropOffAddress && !full && (
                  <div className="order-card-ship-list__item-description">
                    {dropOffAddress}
                  </div>
                )}
                {full && (
                  <>
                    <div className="order-card-ship-list__item-description">
                      {dropOffAddress}
                    </div>
                    <div className="order-card-ship-list__item-description">
                      <div className="order-card__icon-wrapper">
                        <img
                          alt="Request Icon"
                          src={clockIcon}
                        />
                        <span className="order-card__time">
                          {dropOffTime}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
      {full && (
        <div>
          {type === 'ship' && !!items.length && (
            <div className="order-card__shipment">
              <div className="order-card__title">
                <FormattedMessage id="order.shipment" />
              </div>
              {type === 'ship' && items.map((item, index) => (
                <div
                  key={index}
                  className="order-card__item-list"
                >
                  <img
                    alt="Shipment"
                    className="order-card__item-icon"
                    src={getItemIcon(item?.icon)}
                  />
                  <ul className="order-card-order-list order-card__ul-padding">
                    <li>
                      {item?.description}
                      <div className="order-card-ship-list__item-description">
                        {item?.subtitle}
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          )}
          {!!payment && !!totalPrice && (
            <div className="order-card__payment">
              <div className="order-card__title">
                <FormattedMessage id="order.payment" />
              </div>
              <div className="order-card__payment-card-container">
                {paymentMethodCode ? (
                  <img
                    alt="Card Icon"
                    className="order-card_payment-card-icon"
                    src={paymentIcon(paymentMethodCode)}
                  />
                ) : paymentMethodName}
                <div className="order-card__payment-card-value">
                  {formatPrice(totalPrice)}
                </div>
              </div>
            </div>
          )}
          {Array.isArray(priceDetails) && !!priceDetails.length && (
            <div className="order-card__footer">
              <ul className="order-card__footer-list">
                {priceDetails.map((entry, index) => (
                  <li
                    key={index}
                    className="order-card__footer-list-item"
                  >
                    <div className="order-card__footer-list-item-name">
                      {entry?.text}
                    </div>
                    <div className="order-card__footer-list-item-value">
                      {formatPrice(entry?.value)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* Actions are not going to be shown for now */}
          {/* {!!actions && (
            <ul className="order-card__options">
              {actions.map((item, index) => (
                <li
                  key={index}
                  className="order-card__options-item"
                >
                  {item.text}
                </li>
              ))}
            </ul>
          )} */}
        </div>
      )}
      {!full && (
        <div className="order-card__footer">
          <div className="order-card__footer-content">
            <Link
              className="order-card__details-link"
              to={{
                pathname: `/order/${id}`,
                state: {
                  orderListSearch: location.search
                }
              }}
            >
              <Button
                block
                icon={eyeIcon}
                text={intl.formatMessage({ id: 'orders.details' })}
              />
            </Link>
            <div className="order-card__button-wrapper">
              <Button
                icon={fileIcon}
                light
                onClick={onSeeDetails}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

OrderCard.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      action: PropTypes.string,
      actionUrl: PropTypes.string,
      text: PropTypes.string
    })),
    PropTypes.shape({
      main: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.string,
        actionUrl: PropTypes.string,
        text: PropTypes.string
      })),
      options: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.string,
        actionUrl: PropTypes.string,
        text: PropTypes.string
      }))
    })
  ]),
  dropOff: PropTypes.shape({
    address: PropTypes.shape({
      streetAddress: PropTypes.string
    }),
    dropOffName: PropTypes.string,
    time: PropTypes.string
  }),
  full: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
  image: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    iconUrl: PropTypes.string,
    value: PropTypes.number,
    quantity: PropTypes.number,
    subtitle: PropTypes.string
  })),
  onClose: PropTypes.func,
  onSeeDetails: PropTypes.func,
  payment: PropTypes.shape({
    paymentMethodCode: PropTypes.string,
    paymentMethodName: PropTypes.string
  }),
  pickUp: PropTypes.shape({
    address: PropTypes.shape({
      streetAddress: PropTypes.string
    }),
    pickUpName: PropTypes.string,
    time: PropTypes.string
  }),
  resume: PropTypes.shape({
    distance: PropTypes.string,
    priceDetails: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    })),
    quantity: PropTypes.number,
    time: PropTypes.string
  }),
  totalPrice: PropTypes.string,
  type: PropTypes.string.isRequired
};

OrderCard.defaultProps = {
  actions: null,
  dropOff: null,
  full: false,
  hasCloseIcon: false,
  items: null,
  onClose: () => {},
  onSeeDetails: () => {},
  payment: null,
  pickUp: null,
  resume: null,
  totalPrice: null
};

export default OrderCard;
