import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import deliveryManIcon from 'images/icons/delivery-man.svg';

import './styles.scss';

const OrderProgress = ({ color, info, percentage, status }) => (
  <div className="order-progress">
    <div className="order-progress__header">
      <img
        alt="Delivery Man"
        className="order-progress__icon"
        src={deliveryManIcon}
      />
      <div className="order-progress__header-details">
        <div className="order-progress__title">
          <FormattedMessage id="order.track.progress" />
        </div>
        {!!info && (
          <div className="order-progress__info">
            {info}
          </div>
        )}
      </div>
    </div>
    <div className="order-progress__bar">
      <span className="order-progress__bar-divider order-progress__bar-divider--1" />
      <span className="order-progress__bar-divider order-progress__bar-divider--2" />
      <span className="order-progress__bar-divider order-progress__bar-divider--3" />
      <span className="order-progress__bar-divider order-progress__bar-divider--4" />
      <span
        className="order-progress__bar-inner"
        style={{
          backgroundColor: color,
          width: `${percentage}%`
        }}
      />
    </div>
    {!!status && (
      <div className="order-progress__status">
        {status}
      </div>
    )}
  </div>
);

OrderProgress.propTypes = {
  color: PropTypes.string,
  info: PropTypes.string,
  percentage: PropTypes.number,
  status: PropTypes.string
};

OrderProgress.defaultProps = {
  color: null,
  info: null,
  percentage: 25,
  status: null
};

export default OrderProgress;
