import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const RadioToggle = ({ onChange, options, selected }) => (
  <div className="radio-toggle">
    <div className="radio-toggle__content">
      {options.map(item => (
        <div
          key={item.id}
          className="radio-toggle__content-option"
        >
          <input
            className="radio-toggle__content-input"
            id={item.id}
            name="radio"
            type="radio"
            value={item.name}
          />
          <label
            className={`radio-toggle__content-input-label${selected === item.id ? ' radio-toggle__content-input-label--active' : ''}`}
            htmlFor={item.id}
            onClick={() => onChange(item.id, item.name)}
          >
            {item.name}
          </label>
        </div>
      ))}
    </div>
  </div>
);

RadioToggle.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  }))
};

RadioToggle.defaultProps = {
  onChange: () => {},
  options: []
};

export default RadioToggle;
