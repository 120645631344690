import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';

import {
  getDeliveryDetails,
  getDeliveryTracking,
  getPublicDeliveryDetails,
  getPublicDeliveryTracking,
  rateCustomer,
  rateShop,
  rateTransporter,
  rateUser,
  selectDeliveryById,
  selectHasLiveTracking,
  selectIsLoadingDelivery
} from 'store/slices/deliveries';
import { selectInitialized, selectIsAuthenticated, selectUser } from 'store/slices/authentication';

import formatPrice from '@youship/utils/format-price';

import Map from 'components/map';
import Button from '@youship/components/objects/button';
import OrderActions from 'components/order/actions';
import OrderDropOff from 'components/order/drop-off';
import OrderReview from 'components/order/review';
import OrderSummary from 'components/order/summary';
import OrderShipment from 'components/order/shipment';
import OrderPayment from 'components/order/payment';
import OrderMessages from 'components/order/messages';
import OrderNotes from 'components/order/notes';
import OrderProgress from 'components/order/progress';
import OrderProposals from 'components/order/proposals';
import ReviewModal from 'components/order/review-modal';
import Loader from '@youship/components/objects/loader';

import chevronLeftIcon from 'images/icons/chevron-left.svg';

import './styles.scss';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { orderId } = useParams();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthenticationInitialized = useSelector(selectInitialized);
  const isLoadingDelivery = useSelector(selectIsLoadingDelivery);
  const hasLiveTracking = useSelector(selectHasLiveTracking);
  const order = useSelector(state => selectDeliveryById(state, orderId));
  const user = useSelector(selectUser);
  const userType = user?.type;

  // NOTE: Leonel’s changes
  // eslint-disable-next-line no-unused-vars
  const bids = order?.bids;
  const dropoffSchedule = order?.dropOff?.schedule?.deadline;
  const goBackUrl = `${userType === 'transporter' ? '/' : '/orders'}${location?.state?.orderListSearch || ''}`;
  const orderType = order?.type;
  const paymentUrl = order?.payment?.paymentUrl;
  const pickupSchedule = order?.pickUp?.schedule?.ready;
  const rateTransporterActionExists = order?.rateTransporterActionExists;
  const rateShopActionExists = order?.rateShopActionExists;
  const rateUserActionExists = order?.rateUserActionExists;
  const showBids = order?.showBids;

  const [deliveryRoute, setDeliveryRoute] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rateModalSuccessMessage, setRateModalSuccessMessage] = useState(null);
  const [showPaymentButton, setShowPaymentButton] = useState(true);
  const [showProgress, setShowProgress] = useState(true);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showShopReviewModal, setShowShopReviewModal] = useState(false);
  const [modalRatingNumber, setModalRatingNumber] = useState(0);
  const [showInteractiveMap, setShowInteractiveMap] = useState(false);

  const handleMapLoaded = () => {
    setShowProgress(true);
  };

  const handleRatingChange = (value) => {
    setModalRatingNumber(value);
  };

  const handleOrderReviewClick = () => {
    setRateModalSuccessMessage(null);
    setShowReviewModal(true);
  };

  const handleOrderShopReviewClick = () => {
    setShowShopReviewModal(true);
  };

  const intl = useIntl();

  const handleReviewModalConfirmationClick = (event, context) => {
    event.preventDefault();

    let action = null;

    if (context === 'Customer') action = dispatch(rateCustomer({ orderId, userType, rating: modalRatingNumber }));
    if (context === 'Shop') action = dispatch(rateShop({ orderId, userType, rating: modalRatingNumber }));
    if (context === 'Transporter') action = dispatch(rateTransporter({ orderId, userType, rating: modalRatingNumber }));
    if (context === 'User') action = dispatch(rateUser({ orderId, userType, rating: modalRatingNumber }));

    if (action) {
      action
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

          return response;
        })
        .then((response) => {
          dispatch(getDeliveryDetails(orderId));

          setRateModalSuccessMessage('Thank you for your review.');

          return response;
        })
        .catch((error) => {
          // NOTE: Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  const handleShowInteractiveMap = () => {
    if (deliveryRoute) {
      setShowInteractiveMap(true);
    }
  };

  const closeReviewModal = () => {
    setShowReviewModal(false);
  };

  const closeShopReviewModal = () => {
    setShowShopReviewModal(false);
  };

  const handlePaymentButtonClick = () => {
    const paymentUrlWithReturn = `${paymentUrl}&ys_return_url=${origin}/order/${orderId}`;

    window.location.replace(paymentUrlWithReturn);
  };

  useEffect(() => {
    if (isAuthenticationInitialized && orderId) {
      const action = isAuthenticated ? dispatch(getDeliveryDetails(orderId)) : dispatch(getPublicDeliveryDetails(orderId));

      action
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading delivery data.');

          return response;
        })
        .catch((error) => {
          setErrorMessage(error?.message);
        });
    }
  }, [dispatch, isAuthenticated, isAuthenticationInitialized, orderId]);

  useEffect(() => {
    if (order && !deliveryRoute) {
      const route = [];

      if (typeof order.pickUp?.address?.lat === 'number' && typeof order.pickUp?.address?.lng === 'number') {
        route.push({
          location: {
            lat: order.pickUp.address.lat,
            lng: order.pickUp.address.lng
          },
          type: 'pickup'
        });
      }

      if (typeof order.dropOff?.address?.lat === 'number' && typeof order.dropOff?.address?.lng === 'number') {
        route.push({
          location: {
            lat: order.dropOff.address.lat,
            lng: order.dropOff.address.lng
          },
          type: 'dropoff'
        });
      }

      if (route.length) setDeliveryRoute(route);
    }
  }, [deliveryRoute, order]);

  useEffect(() => {
    if (isAuthenticated && orderId) {
      dispatch(getDeliveryTracking(orderId))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while tracking this delivery.');

          return response;
        })
        .catch((error) => {
          setErrorMessage(error?.message);
        });
    } else {
      dispatch(getPublicDeliveryTracking(orderId))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while tracking this delivery.');

          return response;
        })
        .catch((error) => {
          setErrorMessage(error?.message);
        });
    }
  }, [dispatch, isAuthenticated, orderId]);

  useEffect(() => {
    let liveTrackingInterval = null;

    if (hasLiveTracking) {
      liveTrackingInterval = setInterval(() => {
        dispatch(getDeliveryDetails(orderId))
          .then((response) => {
            if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading delivery data.');

            return response;
          })
          .catch((error) => {
            setErrorMessage(error?.message);
          });
      }, 60000);
    }

    return () => clearInterval(liveTrackingInterval);
  }, [dispatch, hasLiveTracking, orderId]);

  const header = (
    <>
      <Link
        className="order-details__previous"
        to={goBackUrl}
      >
        <img
          alt="Go to Orders page"
          className="order-details__previous-icon"
          src={chevronLeftIcon}
        />
        <FormattedMessage id="orders.title" />
      </Link>
      <h1 className="order-details__title">
        <FormattedMessage id="order.title" />
      </h1>
    </>
  );

  if (!order) {
    return (
      <div className="order-details">
        <div className="container">
          {header}
          {isLoadingDelivery ? (
            <div className="order-details__loader-wrapper">
              <Loader />
            </div>
          ) : (
            <p>
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    );
  }

  const orderProgressStatus = order.tracking?.status?.name ? order.tracking.status.name : null;
  const orderProgressInfo = order.tracking?.status?.description ? order.tracking.status.description : null;
  const orderStatusColor = order?.status?.status_color || null;
  let orderProgress = 0;

  if (typeof order?.tracking?.status?.pos === 'number' && typeof order?.tracking?.status?.steps && order.tracking.status.pos > 0) {
    orderProgress = (order.tracking.status.pos * 100) / order.tracking.status.steps;
  } else if (Array.isArray(order.tracking?.history)) {
    const trackingStates = order.tracking.history;
    const trackingStatesConcludedCount = order.tracking.history.filter(entry => entry.isDone === true || entry.isCurrent === true);

    orderProgress = (trackingStatesConcludedCount.length * 100) / trackingStates.length;
  }

  const handleOrderPayment = (paymentCode) => {
    if (paymentCode === 'mb') {
      setShowPaymentButton(false);
    }
  };


  // Filter pay action if selected payment method is Multibanco
  // NOTE: prone to errors so it should be refactored later
  const orderActionsList = Array.isArray(order?.actionsList) && order?.actionsList.filter(actionItem => !(order.payment?.method?.code === 'mb' && actionItem.action === 'pay'));

  return (
    <div className={`order-details${!order.isPaid ? ' order-details--unpaid' : ''}`}>
      <div className="container">
        {header}
        <div className="row">
          <div className="col col-12 col-md-6">
            <div className="order-details__order-summary">
              <OrderSummary {...order} />
            </div>
            {orderType === 'order' && order.dropOff && (
              <OrderDropOff {...order.dropOff} />
            )}
            {order.items && (
              <OrderShipment
                items={order.items}
                type={order.type}
              />
            )}
            {!!order.notes && (
              <OrderNotes
                notes={order.notes}
              />
            )}
            {!!order.user && !!order.user?.name && (
              <OrderReview
                {...order.user}
                showReviewButton={!!rateShopActionExists || !!rateTransporterActionExists || !!rateUserActionExists}
                onReviewClick={handleOrderReviewClick}
              />
            )}
            {orderType === 'order' && !!order.shop && !!order.shop?.name && userType === 'user' && (
              <OrderReview
                {...order.shop}
                showReviewButton={!!rateShopActionExists}
                onReviewClick={handleOrderShopReviewClick}
              />
            )}
            {showBids && (
              <OrderProposals
                {...order}
              />
            )}
            <OrderPayment
              {...order}
              onOrderPayment={handleOrderPayment}
            />
            {Array.isArray(orderActionsList) && orderActionsList.length > 0 && (
              <OrderActions
                actions={orderActionsList}
                dropoffSchedule={dropoffSchedule}
                goBackUrl={goBackUrl}
                id={orderId}
                pickupSchedule={pickupSchedule}
              />
            )}
            <OrderMessages
              messages={order.messages || []}
              orderId={orderId}
              publicDeliveryMessage={order.publicDelivery}
            />
            {!order.isPaid && paymentUrl && showPaymentButton && (
              <div className="order-details__footer">
                <div className="order-details__footer-price">
                  {typeof order.payment?.value === 'number' ? formatPrice(order.payment.value) : order.payment?.value}
                </div>
                <Button
                  context="primary"
                  largeHorizontalPadding
                  text={intl.formatMessage({ id: 'order.action.paynow' })}
                  onClick={handlePaymentButtonClick}
                />
              </div>
            )}
          </div>
          <div className="col col-12 offset-0 col-md-5 offset-lg-1">
            <div
              className={`order-details__map-wrapper${deliveryRoute ? ' order-details__map-wrapper--overlay-cursor' : ''}`}
              onClick={handleShowInteractiveMap}
            >
              {!showInteractiveMap && order.image && (
                <>
                  {deliveryRoute && (
                    <div className="order-details__map-overlay">
                      <FormattedMessage id="order.action.interactive_map" />
                    </div>
                  )}
                  <img
                    alt="Map"
                    className="order-details__map-image"
                    src={order.imageUrl}
                  />
                </>
              )}
              {showInteractiveMap && (
                <Map
                  id="orderTrackerMap"
                  route={deliveryRoute}
                  onMapLoaded={handleMapLoaded}
                />
              )}
              {showProgress && (
                <div className="order-details__progress">
                  <OrderProgress
                    color={orderStatusColor}
                    info={orderProgressInfo}
                    percentage={orderProgress}
                    status={orderProgressStatus}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showReviewModal && (
        <ReviewModal
          {...order?.user}
          finalMessage={rateModalSuccessMessage}
          rating={modalRatingNumber}
          onClose={closeReviewModal}
          onConfirm={event => handleReviewModalConfirmationClick(event, order?.user?.title)}
          onRate={handleRatingChange}
        />
      )}
      {showShopReviewModal && (userType === 'user' || userType === 'transporter' || userType === 'driver') && (
        <ReviewModal
          {...order?.shop}
          finalMessage={rateModalSuccessMessage}
          rating={modalRatingNumber}
          onClose={closeShopReviewModal}
          onConfirm={event => handleReviewModalConfirmationClick(event, order?.shop?.title)}
          onRate={handleRatingChange}
        />
      )}
    </div>
  );
};

export default OrderDetails;
