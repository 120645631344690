import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import bagIcon from 'images/icons/bag.svg';
import clockIcon from 'images/icons/clock.svg';
import locationIcon from 'images/icons/location.svg';
import mapIcon from 'images/icons/map.svg';
import orderIcon from 'images/icons/order.svg';
import truckIcon from 'images/icons/truck.svg';

import './styles.scss';

const OrderSummary = ({ dropOff, name, pickUp, resume, type }) => {
  const distance = resume?.deliveryDistance;
  const dropOffPhone = dropOff?.contact?.phone;
  const dropOffName = dropOff?.contact?.lineContact || '-';
  const dropOffTime = dropOff?.schedule?.timeEstimate || '-';
  const orderNumber = name || '';
  const pickUpAddress = pickUp?.address?.lineAddress || '-';
  const pickUpName = pickUp?.contact?.lineContact || '-';
  const pickUpPhone = pickUp?.contact?.phone;
  const pickUpTime = pickUp?.schedule?.timeEstimate || '-';

  let shipmentPickUpAddress = pickUp?.address?.streetAddress ? JSON.stringify(pickUp.address.streetAddress) : null;
  let shipmentDropoffAddress = dropOff?.address?.streetAddress ? JSON.stringify(dropOff.address.streetAddress) : null;

  shipmentPickUpAddress = shipmentPickUpAddress.slice(1, shipmentPickUpAddress.length - 1).replace(/\\n/g, '<br />');
  shipmentDropoffAddress = shipmentDropoffAddress.slice(1, shipmentDropoffAddress.length - 1).replace(/\\n/g, '<br />');

  const { service } = resume;

  return (
    <div className="order-summary">
      <h3 className="order-summary__title">
        <FormattedMessage id="order.summary" />
      </h3>
      {type === 'order' ? (
        <div className="order-summary__details">
          {!!orderNumber && (
            <div className="order-summary__item">
              <img
                alt="Order Icon"
                className="order-summary__item-icon"
                src={orderIcon}
              />
              {orderNumber}
            </div>
          )}
          <div className="order-summary__item order-summary__item--flex order-summary__item--center">
            <div className="order-summary__item">
              <img
                alt="Bag Icon"
                className="order-summary__item-icon"
                src={bagIcon}
              />
              <FormattedMessage id="order.from" />
              :
              <span className="order-summary__item-highlight">
                {pickUpName}
              </span>
            </div>
            {!!pickUpPhone && (
              <a
                href={`tel:${pickUpPhone}`}
              >
                <div className="order-summary__phone-badge">
                  <span className="order-summary__phone-number">
                    {pickUpPhone}
                  </span>
                </div>
              </a>
            )}
          </div>
          <div className="order-summary__item">
            <img
              alt="Location Icon"
              className="order-summary__item-icon"
              src={locationIcon}
            />
            {pickUpAddress}
          </div>
          <div className="order-summary__item">
            <img
              alt="Clock Icon"
              className="order-summary__item-icon"
              src={clockIcon}
            />
            {pickUpTime}
          </div>
        </div>
      ) : (
        <div className="order-summary__details">
          <div className="order-summary__items">
            {!!distance && (
              <div className="order-summary__item order-summary__item--inline">
                <img
                  alt="Bag Icon"
                  className="order-summary__item-icon"
                  src={mapIcon}
                />
                {distance}
              </div>
            )}
            {!!service && (
              <div className="order-summary__item order-summary__item--inline">
                <img
                  alt="Truck Icon"
                  className="order-summary__item-icon"
                  src={truckIcon}
                />
                {service}
              </div>
            )}
            {!!orderNumber && (
              <div className="order-summary__item order-summary__item--inline">
                <img
                  alt="Order Icon"
                  className="order-summary__item-icon"
                  src={orderIcon}
                />
                {orderNumber}
              </div>
            )}
          </div>
          <div className="order-summary-ship-list__container">
            <ul className="order-summary-ship-list">
              <li
                className="order-summary-ship-list__item order-summary-ship-list__item--circle order-summary-ship-list__item--start"
              >
                <div className="order-summary-ship-list__item-name-wrapper">
                  <span className="order-summary-ship-list__item-name">
                    {pickUpName}
                  </span>
                  {!!pickUpPhone && (
                    <a
                      href={`tel:${pickUpPhone}`}
                    >
                      <div className="order-summary__phone-badge">
                        <span className="order-summary__phone-number">
                          {pickUpPhone}
                        </span>
                      </div>
                    </a>
                  )}
                </div>
                <div
                  className="order-summary-ship-list__item-description"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: shipmentPickUpAddress }}
                />
                <div className="order-summary__item--name order-summary__item--inline">
                  <img
                    alt="Clock Icon"
                    className="order-summary__item-icon"
                    src={clockIcon}
                  />
                  {pickUpTime}
                </div>
              </li>
              <li className="order-summary-ship-list__item order-summary-ship-list__item--circle order-summary-ship-list__item--end">
                <div className="order-summary-ship-list__item-name-wrapper">
                  <span className="order-summary-ship-list__item-name">
                    {dropOffName}
                  </span>
                  {!!dropOffPhone && (
                    <a
                      href={`tel:${dropOffPhone}`}
                    >
                      <div className="order-summary__phone-badge">
                        <span className="order-summary__phone-number">
                          {dropOffPhone}
                        </span>
                      </div>
                    </a>
                  )}
                </div>
                <div
                  className="order-summary-ship-list__item-description"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: shipmentDropoffAddress }}
                />
                <div className="order-summary__item--name order-summary__item--inline">
                  <img
                    alt="Clock Icon"
                    className="order-summary__item-icon"
                    src={clockIcon}
                  />
                  {dropOffTime}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

OrderSummary.propTypes = {
  dropOff: PropTypes.shape({
    dropOffPhone: PropTypes.string,
    dropOffName: PropTypes.string,
    dropOffTime: PropTypes.string,
    shipmentDropoffAddress: PropTypes.string
  }),
  pickUp: PropTypes.shape({
    pickUpAddress: PropTypes.string,
    pickUpPhone: PropTypes.string,
    pickUpName: PropTypes.string,
    pickUpTime: PropTypes.string,
    shipmentPickUpAddress: PropTypes.string
  }),
  resume: PropTypes.shape({
    distance: PropTypes.string,
    service: PropTypes.string
  }),
  type: PropTypes.string
};

OrderSummary.defaultProps = {
  dropOff: null,
  pickUp: null,
  resume: null,
  type: null
};

export default OrderSummary;
