import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectLocale } from 'store/slices/locales';

import calendarIcon from 'images/icons/calendar.svg';

import './styles.scss';

const CALENDAR_OPEN_DIRECTION_DOWN = 'down';
const CALENDAR_OPEN_DIRECTION_UP = 'up';

const DateSelect = ({ allowedDates, disabled, endDate: endDateFromProps, foreground, id, left, onChange, onReset, range, rightIcon, startDate: startDateFromProps }) => {
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [focusedInput, setFocusedInput] = useState(null);
  const [singleDateFocused, setSingleDateFocused] = useState(false);
  const [isScreenMobile, setIsScreenMobile] = useState(window.innerWidth < 768);
  const [openDirection, setOpenDirection] = useState(CALENDAR_OPEN_DIRECTION_DOWN);
  const calendar = useRef(null);
  const intl = useIntl();

  const locale = useSelector(selectLocale);

  moment().locale(locale);

  useEffect(() => {
    if (startDateFromProps) setStartDate(moment(startDateFromProps));
    else if (startDateFromProps === null) setStartDate(null);
  }, [startDateFromProps]);

  useEffect(() => {
    if (endDateFromProps) setEndDate(moment(endDateFromProps));
    else if (endDateFromProps === null) setEndDate(null);
  }, [endDateFromProps]);

  useEffect(() => {
    setFocusedInput(null);
  }, [isScreenMobile]);

  const handleDatesChange = (dates) => {
    if (dates.startDate) {
      setStartDate(moment(dates.startDate));
    }

    if (dates.endDate) {
      setEndDate(moment(dates.endDate));
    }

    onChange(dates.startDate, dates.endDate);
  };

  const handleCalendarPosition = () => {
    const viewportHeight = window.innerHeight;

    if (calendar && calendar.current) {
      const calendarVerticalPosition = calendar.current.getBoundingClientRect().top;

      if (calendarVerticalPosition > viewportHeight - 350) {
        setOpenDirection(CALENDAR_OPEN_DIRECTION_UP);
      } else {
        setOpenDirection(CALENDAR_OPEN_DIRECTION_DOWN);
      }
    }
  };

  const handleDateRangePickerFocusChange = (newFocusedInput) => {
    setFocusedInput(newFocusedInput);
  };

  const handleDateRangePickerClose = () => {
    if (isScreenMobile) {
      setFocusedInput(null);
    }
  };

  const handleDateResetButtonClick = () => {
    onReset();
  };

  const handleDateSelectClick = () => {
    if (range) {
      setFocusedInput('startDate');
    } else {
      setSingleDateFocused(true);
    }
  };

  const handleResize = () => {
    setIsScreenMobile(window.innerWidth < 768);
  };

  const handleSingleDateChange = (date) => {
    if (startDateFromProps) setStartDate(moment(startDateFromProps));
    else if (startDateFromProps === null) setStartDate(null);
    else setStartDate(moment(date));

    onChange(date);
  };

  const checkIfDayIsBlocked = (date) => {
    if (Array.isArray(allowedDates) && allowedDates.length) {
      if (allowedDates.includes(date.format('YYYY-MM-DD'))) return false;

      return true;
    }

    return false;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleCalendarPosition);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleCalendarPosition);
    };
  });

  return (
    <div
      ref={calendar}
      className={`date-select${
        disabled ? ' date-select--disabled' : ''}${
        rightIcon ? ' date-select--right-padding' : ''}${
        foreground ? ' date-select--foreground' : ''}
      `}
      onClick={!focusedInput && !disabled ? handleDateSelectClick : () => {}}
    >
      <div className="date-select__icon-wrapper">
        <img
          alt="Calendar Icon"
          className="date-select__icon"
          src={calendarIcon}
        />
      </div>
      <div className={`date-select__inputs${left ? ' date-select__inputs--left' : ''}`}>
        {range ? (
          <DateRangePicker
            displayFormat="DD/MM/YYYY"
            endDate={endDate}
            endDateId="date-select-end-date"
            endDatePlaceholderText={intl.formatMessage({ id: 'app.end_date' })}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            isOutsideRange={day => moment().diff(day) < 0}
            noBorder
            openDirection={openDirection}
            orientation={isScreenMobile ? 'vertical' : 'horizontal'}
            readOnly
            small
            startDate={startDate}
            startDateId="date-select-start-date"
            startDatePlaceholderText={intl.formatMessage({ id: 'app.start_date' })}
            withFullScreenPortal={isScreenMobile}
            onClose={handleDateRangePickerClose}
            onDatesChange={disabled ? () => {} : (newStartDate, newEndDate) => handleDatesChange(newStartDate, newEndDate)}
            onFocusChange={disabled ? () => {} : newFocusedInput => handleDateRangePickerFocusChange(newFocusedInput)}
          />
        ) : (
          <SingleDatePicker
            date={startDate}
            displayFormat="DD/MM/YYYY"
            focused={singleDateFocused}
            hideKeyboardShortcutsPanel
            id={`date-select-single-${id}`}
            isDayBlocked={checkIfDayIsBlocked}
            noBorder
            numberOfMonths={1}
            openDirection={openDirection}
            readOnly
            small
            withFullScreenPortal={isScreenMobile}
            onDateChange={disabled ? () => {} : date => handleSingleDateChange(date)}
            onFocusChange={disabled ? () => {} : ({ focused }) => setSingleDateFocused(focused)}
          />
        )}
        {rightIcon && (
          <img
            alt="Clear dates"
            className="date-select__right-icon"
            src={rightIcon}
            onClick={handleDateResetButtonClick}
          />
        )}
      </div>
    </div>
  );
};

DateSelect.propTypes = {
  allowedDates: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  foreground: PropTypes.bool,
  id: PropTypes.string,
  left: PropTypes.bool,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  range: PropTypes.bool,
  rightIcon: PropTypes.string,
  startDate: PropTypes.string
};

DateSelect.defaultProps = {
  allowedDates: [],
  disabled: false,
  foreground: false,
  id: 'select-date',
  left: false,
  onChange: () => {},
  onReset: () => {},
  range: false,
  rightIcon: null,
  startDate: '' // null is used to remove selection
};

export default DateSelect;
