import BaseApi from '..';
import {
  TRANSPORTER_CANCEL,
  TRANSPORTER_DELIVERIES_ACTIVE,
  TRANSPORTER_DELIVERIES_CURRENT,
  TRANSPORTER_DELIVERIES_HISTORY,
  TRANSPORTER_DELIVERIES_SEARCH,
  TRANSPORTER_DRIVERS_LIST,
  TRANSPORTER_SIGN_UP
} from '../endpoints';


export default class TransporterApi extends BaseApi {
  /* eslint-disable camelcase */

  static signUpAsTransporter = ({
    company_name,
    companycode,
    worktype,
    insurancetype,
    charter, // optional
    insurance, // optional
    policy, // optional
    coverage, // optional
    address,
    postalcode,
    city,
    vat,
    drivinglicense,
    vehicletype,
    vehiclecapacity,
    maincity,
    availability,
    about,
    bank_name, // optional
    bank_account // optional
  }) => {
    const data = {
      worktype,
      insurancetype,
      address,
      postalcode,
      city,
      vat,
      drivinglicense,
      vehicletype,
      vehiclecapacity,
      maincity,
      availability,
      about
    };

    if (bank_name) data.bank_name = bank_name;
    if (bank_account) data.bank_account = bank_account;
    if (company_name) data.company_name = company_name;
    if (companycode) data.companycode = companycode;
    if (charter) data.charter = charter;
    if (coverage) data.coverage = coverage;
    if (insurance) data.insurance = insurance;
    if (policy) data.policy = policy;

    return this.post(TRANSPORTER_SIGN_UP, data);
  };

  static cancelSignUpRequest = () => this.post(TRANSPORTER_CANCEL);

  static currentDeliveries = () => this.post(TRANSPORTER_DELIVERIES_CURRENT);

  static activeDeliveries = ({
    pages = {
      current: 0,
      resultsbypage: 0
    },
    user_code
  }) => {
    const data = {
      pages
    };

    if (user_code) data.user_code = user_code;

    return this.post(TRANSPORTER_DELIVERIES_ACTIVE, data);
  };

  static historyDeliveries = ({
    pages = {
      current: 0,
      resultsbypage: 0
    },
    user_code
  }) => {
    const data = {
      pages
    };

    if (user_code) data.user_code = user_code;

    return this.post(TRANSPORTER_DELIVERIES_HISTORY, data);
  };

  static searchDeliveries = ({
    pages = {
      current: 0,
      resultsbypage: 0
    },
    user_code
  }) => {
    const data = {
      pages
    };

    if (user_code) data.user_code = user_code;

    return this.post(TRANSPORTER_DELIVERIES_SEARCH, data);
  };

  static getDrivers = () => this.post(TRANSPORTER_DRIVERS_LIST);

  /* eslint-enable camelcase */
}
