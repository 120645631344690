import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';

const CoverImage = ({ backLink, imageUrl, message, onBackLinkClick }) => (
  <div
    className="cover-image"
    style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : null}
  >
    {!!message && (
      <div className="cover-image__overlay">
        {message}
      </div>
    )}
    {!!backLink?.path && (
      <div className="cover-image__button-container container">
        <Link to={backLink.path}>
          <div
            className="cover-image__button"
            onClick={onBackLinkClick}
          >
            {backLink.label}
          </div>
        </Link>
      </div>
    )}
  </div>
);

CoverImage.propTypes = {
  backLink: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string
  }),
  imageUrl: PropTypes.string,
  message: PropTypes.string,
  onBackLinkClick: PropTypes.func
};

CoverImage.defaultProps = {
  backLink: null,
  imageUrl: null,
  message: null,
  onBackLinkClick: () => {}
};

export default CoverImage;
