import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { submitReply } from 'store/slices/messages';
import { getDeliveryDetails } from 'store/slices/deliveries';

import Button from '@youship/components/objects/button';

import avatarImage from 'images/avatar.jpg';
import helpIcon from 'images/icons/help.svg';

import './styles.scss';

const OrderMessage = ({ message, orderId, userPhotoUrl }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [userReply, setUserReply] = useState('');

  const handleReplyInputChange = (value) => {
    setUserReply(value);
  };

  const handleReplyFormSubmit = (event, messageCode) => {
    event.preventDefault();
    dispatch(submitReply({
      messageCode,
      reply: userReply
    }))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while submitting your reply.');

        return response;
      })
      .then((response) => {
        dispatch(getDeliveryDetails(orderId));
        setUserReply('');

        return response;
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const isReplyValid = !!userReply;

  const sortedAnswers = Array.isArray(message?.answers) ?
    JSON.parse(JSON.stringify(message.answers)) :
    null;

  // Sort in chronological order (most recent is sorted last)
  if (sortedAnswers) {
    // It's necessary to reverse because the API dates don't have seconds and the API sends messages in reversed chronological order
    sortedAnswers.reverse();
    sortedAnswers.sort((a, b) => {
      if (moment(a.dateSent).isBefore(b.dateSent)) return -1;
      if (moment(b.dateSent).isBefore(a.dateSent)) return 1;

      return 0;
    });
  }

  const intl = useIntl();

  return (
    <div className="order-message">
      <div className="order-message__history">
        {!!message?.owner && (
          <div
            className={`order-message__message-wrapper${message.owner.title === 'User' ? ' order-message__message-wrapper--user' : ''}`}
          >
            <img
              alt="Avatar"
              className="order-message__avatar"
              src={message.userPhotoUrl || avatarImage}
            />
            <div className="order-message__text">
              <div className="order-message__author">
                {message.owner.name}
                {message.owner.title === 'admin' && (
                  <img
                    alt="Help Icon"
                    className="order-message__help-icon"
                    src={helpIcon}
                  />
                )}
              </div>
              <div className="order-message__message">
                {message.msg}
              </div>
            </div>
            <div className="order-message__date">
              {message.date_send}
            </div>
          </div>
        )}
        {!!sortedAnswers && sortedAnswers.map((answer, index) => (
          <div
            key={index}
            className={`order-message__message-wrapper${answer.owner.title === 'User' ? ' order-message__message-wrapper--user' : ''} order-message__message-wrapper--reply`}
          >
            <img
              alt="Avatar"
              className="order-message__avatar"
              src={answer.userPhotoUrl || avatarImage}
            />
            <div className="order-message__text">
              <div className="order-message__author">
                {answer.owner.name}
                {answer.owner.title === 'admin' && (
                  <img
                    alt="Help Icon"
                    className="order-message__help-icon"
                    src={helpIcon}
                  />
                )}
              </div>
              <div className="order-message__message">
                {answer.msg}
              </div>
            </div>
            <div className="order-message__date">
              {answer.dateSent}
            </div>
          </div>
        ))}
        {!!errorMessage && (
          <div className="order-message__errors">
            <p>
              {errorMessage}
            </p>
          </div>
        )}
        <form
          className="order-message__input-wrapper"
          onSubmit={event => handleReplyFormSubmit(event, message.messageCode)}
        >
          <img
            alt="Avatar"
            className="order-message__avatar"
            src={userPhotoUrl ?? avatarImage}
          />
          <input
            className="order-message__input"
            placeholder={intl.formatMessage({ id: 'order.message.reply_placeholder' })}
            value={userReply}
            onChange={event => handleReplyInputChange(event?.target?.value ?? '')}
          />
          <Button
            className="order-message__button"
            disabled={!isReplyValid}
            type="submit"
          />
        </form>
      </div>
    </div>
  );
};

OrderMessage.prototypes = {
  orderId: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.shape({
    avatar: PropTypes.string,
    dateSent: PropTypes.string,
    owner: PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string
    }),
    msg: PropTypes.string
  })),
  userPhotoUrl: PropTypes.string
};

OrderMessage.defaultProps = {
  messages: null,
  orderId: null,
  userPhotoUrl: null
};

export default OrderMessage;
