import React, { useEffect } from 'react';

import AccountHeader from 'components/sections/account-header';
import AccountDetails from 'components/sections/account-details';

const Account = () => {
  useEffect(() => {
    document.body.classList.add('has-cover-image');

    return () => {
      document.body.classList.remove('has-cover-image');
    };
  });

  return (
    <>
      <AccountHeader />
      <AccountDetails />
    </>
  );
};

export default Account;
