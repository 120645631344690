/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const NOTE_CONTEXT_DANGER = 'danger';
const NOTE_CONTEXT_DEFAULT = 'default';
const NOTE_CONTEXT_SUCCESS = 'success';

const NOTE_CONTEXTS = [
  NOTE_CONTEXT_DANGER,
  NOTE_CONTEXT_DEFAULT,
  NOTE_CONTEXT_SUCCESS
];

const Radio = ({ alignTop, description, disabled, icon, iconRight, light, note, noteContext, onChange, selected, label, value }) => (
  <div
    className={`radio${alignTop ? ' radio--align-top' : ''}${disabled ? ' radio--disabled' : ''}${light ? ' radio--light' : ''}`}
    onClick={disabled ? () => {} : () => onChange(value)}
  >
    <div className={`radio__outer-circle${value === selected ? ' radio__outer-circle--selected' : ''}`}>
      <div className="radio__inner-circle" />
    </div>
    {!!icon && !iconRight && (
      <img
        alt={label}
        className="radio__icon"
        src={icon}
      />
    )}
    <div className="radio__content">
      <div className="radio__content-header">
        <div className="radio__label">
          {label}
        </div>
        {!!icon && !!iconRight && (
          <img
            alt={label}
            className="radio__icon radio__icon__right"
            src={icon}
          />
        )}
      </div>
      {!!description && (
        <div className="radio__description">
          {description}
        </div>
      )}
    </div>
    {!!note && (
      <div className={`radio__note${NOTE_CONTEXTS.includes(noteContext) && noteContext !== NOTE_CONTEXT_DEFAULT ? ` radio__note--${noteContext}` : ''}`}>
        {note}
      </div>
    )}
  </div>
);

Radio.propTypes = {
  alignTop: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  label: PropTypes.node,
  light: PropTypes.bool,
  note: PropTypes.string,
  noteContext: PropTypes.oneOf(NOTE_CONTEXTS),
  onChange: PropTypes.func,
  selected: PropTypes.string,
  value: PropTypes.string.isRequired
};

Radio.defaultProps = {
  alignTop: false,
  description: null,
  disabled: false,
  icon: null,
  iconRight: false,
  label: null,
  light: false,
  note: null,
  noteContext: NOTE_CONTEXT_DEFAULT,
  onChange: () => {},
  selected: null
};

export default Radio;
