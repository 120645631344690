import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Dropdown from '@youship/components/objects/dropdown';
import LocaleSelect from 'components/locale-select';

import avatarImage from '@youship/assets/images/avatar.jpg';

import './styles.scss';

const AccountDropdown = ({
  accountLinkPath,
  currentLanguage,
  hideLanguageOptionsList,
  isLanguageMenuVisible,
  linkComponent,
  onClose,
  onLogoutClick,
  setCurrentLanguage,
  show,
  showLanguageOptionsList,
  user
}) => {
  const name = user?.name || [user?.firstname, user?.lastname].join(' ');
  const imageUrl = user?.photo_url || avatarImage;
  const email = user?.email || '';

  const Link = linkComponent;
  const accountLinkText = <FormattedMessage id="header.edit_profile" />;
  const accountLink = linkComponent ?
    (
      <Link
        className="account-dropdown__link"
        to={accountLinkPath}
        onClick={onClose}
      >
        {accountLinkText}
      </Link>
    ) :
    (
      <a
        className="account-dropdown__link"
        href={accountLinkPath}
        onClick={onClose}
      >
        {accountLinkText}
      </a>
    );

  return (
    <Dropdown
      hasBackgroundOverlay
      horizontalPosition="right"
      show={show}
      onClose={onClose}
    >
      <div className="account-dropdown">
        {(!!imageUrl || !!name || !!email) && (
          <div className="account-dropdown__header">
            {!!imageUrl && (
              <div className="account-dropdown__avatar">
                <img
                  alt="Avatar"
                  className="account-dropdown__avatar-image"
                  src={imageUrl}
                />
              </div>
            )}
            {(!!name || !!email) && (
              <div className="account-dropdown__header-info">
                {!!name && (
                  <div className="account-dropdown__name">
                    {name}
                  </div>
                )}
                {!!email && (
                  <div className="account-dropdown__email">
                    {email}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <ul className="account-dropdown__list">
          {isLanguageMenuVisible ? (
            <>
              <li
                className="account-dropdown__list-item account-dropdown__list-item--has-back-chevron"
                onClick={hideLanguageOptionsList}
              >
                <FormattedMessage id="header.back" />
              </li>
              <LocaleSelect
                desktopSelector
                setSelectedLanguage={setCurrentLanguage}
                showAsList
              />
            </>
          ) : (
            <>
              <li
                className="account-dropdown__list-item account-dropdown__list-item--has-chevron"
                onClick={showLanguageOptionsList}
              >
                <img
                  alt="Language Icon"
                  className="account-dropdown__list-item-language-icon"
                  src={currentLanguage?.icon}
                />
                <span>
                  {currentLanguage?.text}
                </span>
              </li>
              <li className="account-dropdown__list-item account-dropdown__list-item--has-chevron">
                {accountLink}
              </li>
              <li
                className="account-dropdown__list-item"
                onClick={onLogoutClick}
              >
                <FormattedMessage id="header.sign_out" />
              </li>
            </>
          )}
        </ul>
      </div>
    </Dropdown>
  );
};

AccountDropdown.propTypes = {
  accountLinkPath: PropTypes.string,
  currentLanguage: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string
  }),
  hideLanguageOptionsList: PropTypes.func,
  isLanguageMenuVisible: PropTypes.bool,
  linkComponent: PropTypes.shape({
    render: PropTypes.func
  }),
  onClose: PropTypes.func,
  onLogoutClick: PropTypes.func,
  setCurrentLanguage: PropTypes.func,
  show: PropTypes.bool,
  showLanguageOptionsList: PropTypes.func,
  user: PropTypes.shape()
};

AccountDropdown.defaultProps = {
  accountLinkPath: '/account',
  currentLanguage: null,
  hideLanguageOptionsList: () => {},
  isLanguageMenuVisible: false,
  linkComponent: null,
  onClose: () => {},
  onLogoutClick: () => {},
  setCurrentLanguage: () => {},
  show: false,
  showLanguageOptionsList: () => {},
  user: null
};

export default AccountDropdown;
