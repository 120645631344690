/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';

import editIcon from 'images/icons/edit.svg';
import trashIcon from 'images/icons/trash.svg';
import trashRedIcon from 'images/icons/trash--red.svg';

import './styles.scss';

const ShipmentOptionsOption = ({
  deletable,
  description,
  edit,
  hasError,
  icon,
  noBackground,
  noBorder,
  noPadding,
  onDelete,
  onEdit,
  title
}) => (
  <div className={`shipment-options-option${
    hasError ? ' shipment-options-option--has-error' : ''}${
    noBackground ? ' shipment-options-option--no-background' : ''}${
    noBorder ? ' shipment-options-option--no-border' : ''}${
    noPadding ? ' shipment-options-option--no-padding' : ''}
  `}
  >
    {icon && (
      <img
        alt="Icon"
        className="shipment-options-option__icon"
        src={icon}
      />
    )}
    <div className="shipment-options-option__content">
      <div className="shipment-options-option__header">
        <div className="shipment-options-option_title">
          {title}
        </div>
        {(deletable || edit) && (
          <div className="shipment-options-option__actions">
            {deletable && (
              <img
                alt="Delete Icon"
                className="shipment-options-option__action-icon"
                src={trashIcon}
                onClick={onDelete}
                onMouseOut={
                  e => (e.currentTarget.src = trashIcon)
                }
                onMouseOver={
                  e => (e.currentTarget.src = trashRedIcon)
                }
              />
            )}
            {edit && (
              <img
                alt="Edit Icon"
                className="shipment-options-option__action-icon"
                src={editIcon}
                onClick={onEdit}
              />
            )}
          </div>
        )}
      </div>
      <div className="shipment-options-option__description">
        {description}
      </div>
    </div>
  </div>
);

ShipmentOptionsOption.propTypes = {
  deletable: PropTypes.bool,
  description: PropTypes.string,
  edit: PropTypes.bool,
  icon: PropTypes.string,
  noBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  title: PropTypes.string
};

ShipmentOptionsOption.defaultProps = {
  deletable: false,
  description: '',
  edit: true,
  icon: '',
  noBackground: false,
  noBorder: false,
  noPadding: false,
  onDelete: () => {},
  onEdit: () => {},
  title: ''
};


export default ShipmentOptionsOption;
