import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { login, selectIsAuthenticated, selectIsLoggingIn, setRedirectionUrl } from 'store/slices/authentication';
import { fetchNotifications } from 'store/slices/notifications';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Loader from '@youship/components/objects/loader';
import { FormattedMessage, useIntl } from 'react-intl';

import './styles.scss';

const LoginForm = () => {
  const { control, errors, handleSubmit, register, reset, trigger, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      username: '',
      password: ''
    }
  });

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoggingIn = useSelector(selectIsLoggingIn);
  const [failed, setFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Sorry, we were unable to process your request at this time. Please try again later.');

  const urlParameters = new URLSearchParams(window.location.search);
  const browserRedirectionUrl = urlParameters.get('redirectionurl');

  const getInputMessage = (name) => {
    if (errors[name]) {
      return errors[name].message;
    }

    return '';
  };

  const getInputStatus = (name) => {
    if (errors[name]) {
      return 'error';
    }

    return 'default';
  };

  const handleInputChange = (value, props) => {
    const { name } = props;

    props.onChange(value);

    if (errors[name]) {
      trigger(name);
    }
  };

  const isFormValid = () => {
    const inputs = ['username', 'password'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const handleFormSubmit = (data) => {
    if (isFormValid) {
      const { username, password } = data;
      const userData = {
        email: username,
        password
      };

      setIsSubmitting(true);

      dispatch(login(userData))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while trying to login.');

          return response;
        })
        .then(async (response) => {
          setIsSubmitting(false);
          reset();

          if (response) {
            await dispatch(fetchNotifications());

            if (browserRedirectionUrl) {
              dispatch(setRedirectionUrl(browserRedirectionUrl));
            }
          }

          return response;
        })
        .catch((error) => {
          setFailed(true);
          setIsSubmitting(false);
          setErrorMessage(error.message);
        });
    }
  };

  const isFormEditDisabled = isAuthenticated;
  const isFormSubmitDisabled = !isFormValid() || isLoggingIn || isAuthenticated;
  const isForgotPasswordLinkDisabled = isAuthenticated;

  const intl = useIntl();

  useEffect(() => {
    register({ name: 'username' }, {
      required: intl.formatMessage({ id: 'login.email.required' })
    });

    register({ name: 'password' }, {
      required: intl.formatMessage({ id: 'login.password.required' })
    });
  });


  return (
    <form
      className="login-form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {isSubmitting && (
        <div className="login-form__loader-wrapper">
          <Loader />
        </div>
      )}
      <div className="login-form__input-group">
        <Controller
          control={control}
          name="username"
          render={props => (
            <Input
              block
              disabled={isFormEditDisabled}
              inputId="email"
              label={intl.formatMessage({ id: 'login.email.label' })}
              message={getInputMessage(props.name)}
              placeholder={intl.formatMessage({ id: 'login.email.placeholder' })}
              status={getInputStatus(props.name)}
              onBlur={props.onBlur}
              onChange={e => handleInputChange(e.target.value, props)}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={props => (
            <Input
              block
              disabled={isFormEditDisabled}
              inputId="password"
              label={intl.formatMessage({ id: 'login.password.label' })}
              message={getInputMessage(props.name)}
              placeholder={intl.formatMessage({ id: 'login.password.placeholder' })}
              status={getInputStatus(props.name)}
              type="password"
              onBlur={props.onBlur}
              onChange={e => handleInputChange(e.target.value, props)}
            />
          )}
        />
      </div>
      <div className="login-form__footer">
        <Link
          className={`login-form__link${isForgotPasswordLinkDisabled ? ' login-form__link--disabled' : ''}`}
          to="/forgot-password"
        >
          <FormattedMessage id="login.forgot_password.link" />
        </Link>
        <Button
          context="primary"
          disabled={isFormSubmitDisabled}
          largeHorizontalPadding
          smallVerticalPadding
          text={intl.formatMessage({ id: 'login.login' })}
          type="submit"
        />
      </div>
      {failed && (
        <div className="login-form__error">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export default LoginForm;
