import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';


import Button from 'components/objects/button';

import moto from 'images/moto.svg';

import './styles.scss';

const MARKETING_WEBSITE_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL;

const NotFoundShops = ({ buttonLink, buttonText, text, title }) => {
  const intl = useIntl();

  return (
    <div className="not-found-shops">
      <div className="not-found-shops__content">
        <img
          alt="Become a Partner"
          src={moto}
        />
        <h1 className="not-found-shops__title">
          <FormattedMessage id={title} />
        </h1>
        <p className="not-found-shops__text">
          <FormattedMessage id={text} />
        </p>
        <Button
          context="primary"
          external
          linkComponent={Link}
          linkProps={{ to: `${MARKETING_WEBSITE_URL}${buttonLink}` }}
          noArrow
          text={intl.formatMessage({ id: `${buttonText}` })}
        />
      </div>
    </div>
  );
};

NotFoundShops.propTypes = {
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

NotFoundShops.defaultProps = {
  buttonLink: null,
  buttonText: null,
  text: null,
  title: null
};

export default NotFoundShops;
