import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Checkbox from '@youship/components/objects/checkbox';
import Input from '@youship/components/objects/input';
import Select from '@youship/components/objects/select';

import './styles.scss';

const ShipmentOptionsShipmentDetails = ({ details, onChange, values }) => {
  const {
    hasCharge,
    hasFragile,
    hasHandDelivery,
    hasReference,
    hasShipper
  } = details;

  const [showCharge, setShowCharge] = useState(false);
  const [chargeValue, setChargeValue] = useState('no');

  const intl = useIntl();

  const showChargeOptions = [
    {
      text: intl.formatMessage({ id: 'new-order.shipment.options.yes' }),
      value: 'yes'
    },
    {
      text: intl.formatMessage({ id: 'new-order.shipment.options.no' }),
      value: 'no'
    }
  ];

  useEffect(() => {
    if (values.chargeValue || values.chargeInfo) {
      setShowCharge(true);
      setChargeValue('yes');
    }
  }, [values.chargeInfo, values.chargeValue]);

  const handleChargeOptionsChange = (value) => {
    setChargeValue(value);

    if (value === 'yes') {
      setShowCharge(true);
    } else {
      setShowCharge(false);
      onChange({ ...values, chargeValue: 0 });
      onChange({ ...values, chargeInfo: '' });
    }
  };

  const hasShipmentOptions = hasReference || hasShipper || hasFragile || hasCharge || hasHandDelivery;

  if (!hasShipmentOptions) return false;

  return (
    <div className="shipment-details">
      {hasReference && (
        <div className="shipment-details__input-group">
          <Input
            block
            label={intl.formatMessage({ id: 'new-order.shipment.options.reference.label' })}
            placeholder={intl.formatMessage({ id: 'new-order.shipment.options.reference.placeholder' })}
            type="text"
            value={values?.reference || ''}
            onChange={event => onChange({ ...values, reference: event?.target?.value ?? '' })}
          />
        </div>
      )}
      {hasShipper && (
        <div className="shipment-details__input-group">
          <Input
            block
            label={intl.formatMessage({ id: 'new-order.shipment.options.shipper.label' })}
            placeholder={intl.formatMessage({ id: 'new-order.shipment.options.shipper.placeholder' })}
            value={values?.shipper || ''}
            onChange={event => onChange({ ...values, shipper: event?.target?.value ?? '' })}
          />
        </div>
      )}
      {hasCharge && (
        <div className="shipment-details__input-group">
          <Select
            label={intl.formatMessage({ id: 'new-order.shipment.options.charge.title' })}
            options={showChargeOptions}
            value={chargeValue}
            onChange={value => handleChargeOptionsChange(value)}
          />
        </div>
      )}
      {showCharge && (
        <div className="shipment-details__billing-details">
          <div className="row d-flex align-items-end">
            <div className="col-12 col-lg-6 shipment-details__input-group">
              <Input
                block
                label={intl.formatMessage({ id: 'new-order.shipment.options.charge.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.shipment.options.charge.placeholder' })}
                type="number"
                value={values?.chargeValue}
                onChange={event => onChange({ ...values, chargeValue: event?.target?.value ?? 0 })}
              />
            </div>
            <div className="col-12 col-lg-6 shipment-details__input-group">
              <Input
                block
                placeholder={intl.formatMessage({ id: 'new-order.shipment.options.charge.method' })}
                value={values?.chargeInfo || ''}
                onChange={event => onChange({ ...values, chargeInfo: event?.target?.value ?? '' })}
              />
            </div>
          </div>
        </div>
      )}
      {/* NOTE: DISABLED FOR NOW PER CLIENT REQUEST */}
      {/* <div className="shipment-details__input-group">
        <Input
          block
          label="Comments"
          placeholder="Insert any comments or notes"
          value={values?.comments || ''}
          onChange={event => onChange({ ...values, comments: event?.target?.value ?? '' })}
        />
      </div> */}
      {(hasFragile || hasHandDelivery) && (
        <div className="shipment-details__checkbox-group">
          {hasFragile && (
            <Checkbox
              checked={values?.fragile || false}
              label={intl.formatMessage({ id: 'new-order.shipment.options.fragile.label' })}
              noBorder
              selected={values?.fragile || false}
              onChange={(selected) => {
                onChange({ ...values, fragile: selected });
              }}
            />
          )}
          {hasHandDelivery && (
            <Checkbox
              checked={values?.handDelivery || false}
              label={intl.formatMessage({ id: 'new-order.shipment.options.handdelivery.label' })}
              noBorder
              selected={values?.handDelivery || false}
              onChange={(selected) => {
                onChange({ ...values, handDelivery: selected });
              }}
            />
          )}
          {/* NOTE: DISABLED FOR NOW PER CLIENT REQUEST */}
          {/* <Checkbox
            checked={values?.marketing || false}
            label="Marketing"
            noBorder
            selected={values?.marketing || false}
            onChange={(selected) => {
              onChange({ ...values, marketing: selected });
            }}
          /> */}
        </div>
      )}
    </div>
  );
};

ShipmentOptionsShipmentDetails.propTypes = {
  details: PropTypes.shape({}),
  onChange: PropTypes.func
};

ShipmentOptionsShipmentDetails.defaultProps = {
  details: null,
  onChange: () => {}
};


export default ShipmentOptionsShipmentDetails;
