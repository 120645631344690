import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Modal from '@youship/components/objects/modal';

import './styles.scss';

const ContactModal = ({ companyName, contactName, contactPhoneNumber, countryCallingCode, onClose, onSave, taxId, title }) => {
  const [modalContact, setModalContact] = useState({
    countryCode: countryCallingCode,
    company: companyName,
    name: contactName,
    phone: contactPhoneNumber,
    tax: taxId
  });

  const handleEditContactModalSaveButton = () => {
    if (isContactValid) {
      onSave(modalContact);
    }
  };

  const isContactValid = !!modalContact?.name && !!modalContact?.countryCode && !!modalContact?.phone;

  const handleModalClose = () => {
    onClose();
  };

  return (
    <Modal
      footer={
        <Button
          context="primary"
          disabled={!isContactValid}
          largeHorizontalPadding
          text="Save"
          onClick={handleEditContactModalSaveButton}
        />
      }
      footerAlignRight
      subtitle="Add your contact details"
      title={title ?? 'Contact'}
      onClose={handleModalClose}
      onEnter={handleEditContactModalSaveButton}
    >
      <div className="contact-modal">
        <div className="row contact-modal__inputs-wrapper">
          <div className="col col-6">
            <Input
              block
              inputId="input-name"
              label="Name"
              placeholder="Type your name"
              value={modalContact?.name}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, name: value }));
              }}
            />
          </div>
          <div className="col col-6">
            <Input
              block
              inputId="input-tax-id"
              label="Tax Id Number"
              placeholder="Type your Tax ID"
              type="number"
              value={modalContact?.tax}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, tax: value }));
              }}
            />
          </div>
        </div>
        <div className="row contact-modal__inputs-wrapper">
          <div className="col col-12">
            <Input
              block
              inputId="input-company"
              label="Company (optional)"
              placeholder="Enter Company Name"
              value={modalContact?.company}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, company: value }));
              }}
            />
          </div>
        </div>
        <div className="contact-modal__inputs-wrapper">
          <div className="contact-modal__input-group">
            <Input
              block
              countryCallingCode={modalContact?.countryCode}
              inputId="input-phone"
              label="Phone"
              placeholder="987 654 321"
              type="phone"
              value={modalContact?.phone}
              onChange={(event) => {
                const value = event?.target?.value ?? '';

                setModalContact(prevState => ({ ...prevState, phone: value }));
              }}
              onCountryCallingCodeChange={(code) => {
                setModalContact(prevState => ({ ...prevState, countryCode: code }));
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ContactModal.propTypes = {
  companyName: PropTypes.string,
  contactName: PropTypes.string,
  contactPhoneNumber: PropTypes.string,
  countryCallingCode: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  taxId: PropTypes.string,
  title: PropTypes.string
};

ContactModal.defaultProps = {
  companyName: null,
  contactName: null,
  contactPhoneNumber: null,
  countryCallingCode: null,
  onClose: () => {},
  onSave: () => {},
  taxId: null,
  title: null
};

export default ContactModal;
