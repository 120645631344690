import React from 'react';

import NotificationsList from 'components/sections/notifications-list';

const Notifications = () => (
  <>
    <NotificationsList />
  </>
);

export default Notifications;
