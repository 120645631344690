import React, { useEffect } from 'react';

import NotFoundContent from 'components/sections/not-found-content';

const NotFound = () => {
  useEffect(() => {
    document.body.classList.add('is-not-found-page');

    return () => {
      document.body.classList.remove('is-not-found-page');
    };
  });

  return (
    <>
      <NotFoundContent />
    </>
  );
};

export default NotFound;
