/* eslint-disable no-undefined */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addVoucherCodeToCheckoutQuote,
  removeVoucherCodeFromCheckoutQuote,
  requestCheckoutQuote,
  resetVoucherCode,
  selectCheckoutQuoteVoucherCode,
  selectVoucherCode,
  setVoucherCode
} from 'store/slices/shopping-cart';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Modal from '@youship/components/objects/modal';

import couponIcon from 'images/icons/coupon.svg';

import './styles.scss';

const ShoppingCartVoucherModal = () => {
  const dispatch = useDispatch();
  const checkoutQuoteVoucherCode = useSelector(selectCheckoutQuoteVoucherCode);
  const voucherCode = useSelector(selectVoucherCode);

  const handleClose = () => {
    dispatch(resetVoucherCode());
  };

  const handleInputChange = (value) => {
    dispatch(setVoucherCode(value));
  };

  const handleSave = () => {
    if (voucherCode) dispatch(addVoucherCodeToCheckoutQuote());
    else dispatch(removeVoucherCodeFromCheckoutQuote());

    dispatch(requestCheckoutQuote())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while requesting a quote for your order.');

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  if (voucherCode === null) return null;

  return (
    <Modal
      title="Discount code"
      classNames="shopping-cart-voucher-modal"
      footerAlignRight
      footer={
        <Button
          disabled={!checkoutQuoteVoucherCode && !voucherCode}
          text={checkoutQuoteVoucherCode && !voucherCode ? 'Remove' : 'Finish'}
          context="primary"
          onClick={handleSave}
        />
      }
      onClose={handleClose}
    >
      <div className="shopping-cart-voucher-modal__body">
        <Input
          inputId="voucher-code"
          value={voucherCode}
          onChange={event => handleInputChange(event?.target?.value ?? '')}
          block
          backgroundImage={couponIcon}
          placeholder="Enter your discount code"
        />
      </div>
    </Modal>
  );
};

export default ShoppingCartVoucherModal;
