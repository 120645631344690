import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import image1 from 'images/authentication/image-1.jpg';
import image2 from 'images/authentication/image-2.jpg';
import image3 from 'images/authentication/image-3.jpg';
import image4 from 'images/authentication/image-4.jpg';
import image5 from 'images/authentication/image-5.jpg';
import image6 from 'images/authentication/image-6.jpg';
import image7 from 'images/authentication/image-7.jpg';


const ImagePanel = ({ hiddenSm }) => {
  const images = [image1, image2, image3, image4, image5, image6, image7];
  const randomNumber = Math.floor(Math.random() * images.length);
  const image = images[randomNumber];

  return (
    <>
      <div
        className={`image-panel${hiddenSm ? ' d-sm-none d-md-block' : ''}`}
        style={{ backgroundImage: `url(${image})` }}
      >
      </div>
      <div className={`container image-panel__container${hiddenSm ? ' d-sm-none d-md-block' : ''}`}>
        <div className="row image-panel__row">
          <div className="col col-sm-2 offset-sm-4 col-lg-1 offset-lg-5 image-panel__columns-filler" />
        </div>
      </div>
    </>
  );
};


ImagePanel.propTypes = {
  hiddenSm: PropTypes.bool
};

ImagePanel.defaultProps = {
  image: '',
  hiddenSm: false
};


export default ImagePanel;
