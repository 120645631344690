import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Modal from '@youship/components/objects/modal';

import './styles.scss';

const ShipmentOptionsInformationModal = ({ information, onClose, onSave }) => {
  const [modalInformation, setModalInformation] = useState(information);

  const intl = useIntl();

  return (
    <Modal
      footer={
        <Button
          context="primary"
          largeHorizontalPadding
          text={intl.formatMessage({ id: 'new-order.notes.save' })}
          onClick={() => onSave(modalInformation)}
        />
      }
      footerAlignRight
      title={intl.formatMessage({ id: 'new-order.notes.title' })}
      onClose={onClose}
    >
      <div className="information-modal">
        <Input
          block
          label={intl.formatMessage({ id: 'new-order.notes.label' })}
          value={modalInformation}
          onChange={event => setModalInformation(event?.target?.value ?? '')}
        />
      </div>
    </Modal>
  );
};

ShipmentOptionsInformationModal.propTypes = {
  information: PropTypes.string,
  onSave: PropTypes.func
};

ShipmentOptionsInformationModal.defaultProps = {
  information: '',
  onSave: () => {}
};


export default ShipmentOptionsInformationModal;
