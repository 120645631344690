import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import ShopCard from 'components/shop-card';
import Disclaimer from 'components/disclaimer';

import './styles.scss';

const ShopsSlider = ({ items }) => (
  Array.isArray(items) ?
    (
      <Swiper
        breakpoints={{
          576: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 3
          }
        }}
        className="shops-slider"
        slidesPerView="1"
        spaceBetween={32}
      >
        {items.length ?
          items.map((item, index) => (
            <SwiperSlide
              key={index}
              className="shops-slider__slide"
            >
              <ShopCard {...item} />
            </SwiperSlide>
          )) : (
            <div className="col col-12">
              <Disclaimer />
            </div>
          )}
      </Swiper>
    ) :
    null
);

ShopsSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape())
};

ShopsSlider.defaultProps = {
  items: null
};

export default ShopsSlider;
