/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectOrder, selectPaymentIsInProcess, stopPaymentProcess } from 'store/slices/shopping-cart';

import Modal from '@youship/components/objects/modal';

import './styles.scss';

const CheckoutPaymentModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const order = useSelector(selectOrder);
  const paymentIsInProcess = useSelector(selectPaymentIsInProcess);

  const handleClose = () => {
    dispatch(stopPaymentProcess());
    onClose();
  };

  if (!paymentIsInProcess) return null;

  return (
    <Modal
      classNames="checkout-payment-modal"
      noHeader
      noScroll
      onClose={handleClose}
    >
      <iframe
        className="checkout-payment-modal__iframe"
        src={order.paymentUrl}
        title="Payment"
      />
    </Modal>
  );
};

CheckoutPaymentModal.propTypes = {
  onClose: PropTypes.func
};

CheckoutPaymentModal.defaultProps = {
  onClose: () => {}
};

export default CheckoutPaymentModal;
