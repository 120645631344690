import { createSlice } from '@reduxjs/toolkit';

import locales from '@youship/i18n/locales';

import SessionStorageManager from '@youship/utils/SessionStorageManager';

const sessionStorageLocale = SessionStorageManager.getLocale();
const localeKeys = Object.keys(locales);
const defaultLocaleKey = localeKeys.find(key => locales[key].default);
const initialLocale = sessionStorageLocale || defaultLocaleKey;


// Slice:

const shoppingCartSlice = createSlice({
  name: 'locales',

  initialState: {
    locale: initialLocale
  },

  reducers: {
    setLocale (state, action) {
      const locale = action.payload;

      if (localeKeys.includes(locale)) {
        state.locale = locale;
        SessionStorageManager.setLocale(locale);
      }
    }
  }
});

export default shoppingCartSlice.reducer;


// Actions

export const { setLocale } = shoppingCartSlice.actions;


// Selectors:

export const selectLocale = state => state.locales.locale;
