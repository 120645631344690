import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { addAddress, editAddress, selectUserAddresses } from 'store/slices/user-addresses';
import { selectUser } from 'store/slices/authentication';

import AddressForm from '@youship/components/address-form';
import MapPoint from '@youship/components/map-point';
import Button from '@youship/components/objects/button';
import Modal from '@youship/components/objects/modal';

import bookIcon from '@youship/assets/images/icons/book.svg';
import mapIcon from '@youship/assets/images/icons/map.svg';
import markerPickupIcon from '@youship/assets/images/icons/marker-blue.svg';

import './styles.scss';

const AddressModal = ({ currentAddress, onClose, onSave, title, type }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userDefaultAddress = user?.defaultAddress;
  const userAddresses = useSelector(selectUserAddresses);

  const addressesBook = userAddresses.map(item => item.address);

  const [address, setAddress] = useState(currentAddress);
  const [addressChangeIsLoading, setAddressChangeIsLoading] = useState(false);
  const [addressContact, setAddressContact] = useState(null);
  const [isAddressInAddressBook, setIsAddressInAddressBook] = useState(false);
  const [mapIsLoading, setMapIsLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [searchAddress, setSearchAddress] = useState(currentAddress.streetAddress);

  const handleEditAddressModalSaveButton = () => {
    if (addressIsValid) {
      switch (type) {
        case 'user':
          if (isAddressInAddressBook) {
            dispatch(editAddress({
              address: {
                apartmentAddress: address.apartmentAddress,
                city: address.city,
                countryCode: address.countryCode,
                formattedAddress: address.formattedAddress,
                lat: address.lat,
                lng: address.lng,
                numberAddress: address.numberAddress,
                postalCode: address.postalCode,
                streetAddress: address.streetAddress
              },
              addressCode: address.addressCode,
              contact: addressContact,
              isDefault: true
            }))
              .then((response) => {
                onSave();

                return response;
              })
              .catch((error) => {
                // Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
              });
          } else {
            dispatch(addAddress({
              address: {
                apartmentAddress: address.apartmentAddress,
                city: address.city,
                countryCode: address.countryCode,
                formattedAddress: address.formattedAddress,
                lat: address.lat,
                lng: address.lng,
                numberAddress: address.numberAddress,
                postalCode: address.postalCode,
                streetAddress: address.streetAddress
              },
              contact: addressContact,
              isDefault: true
            }))
              .then((response) => {
                onSave();

                return response;
              })
              .catch((error) => {
                // Add proper error handling
                // eslint-disable-next-line no-console
                console.log(error);
              });
          }
          break;
        default:
          onSave(address, addressContact);
      }
    }
  };

  const handleFormChange = (formAddress, selectedAddressContact) => {
    setIsAddressInAddressBook(addressesBook.some(item => item.apartmentAddress === formAddress.apartmentAddress &&
      item.city === formAddress.city &&
      item.countryCode === formAddress.countryCode &&
      item.numberAddress === formAddress.numberAddress &&
      item.postalCode === formAddress.postalCode &&
      item.streetAddress === formAddress.streetAddress));

    setAddress(prevState => ({
      ...prevState,
      ...formAddress
    }));

    if (formAddress?.streetAddress) setSearchAddress(formAddress.streetAddress);
    setAddressContact(selectedAddressContact);
  };

  const handleFormSearchChange = (formSearchAddress) => {
    setSearchAddress(formSearchAddress);
  };

  useEffect(() => {
    setShowMap(address && address.streetAddress !== '');

    return () => {
      setShowMap(false);
    };
  }, [address]);

  const handleModalClose = () => {
    if (!mapIsLoading || !addressChangeIsLoading) onClose();
  };

  const addressIsValid = !!address && !!address.streetAddress && !!address.postalCode;

  const intl = useIntl();

  let subtitleTranslationKey = '';
  let titleTranslateKey = '';

  if (type === 'pickup') {
    subtitleTranslationKey = 'map.pickup.subtitle';
    titleTranslateKey = 'map.pickup.title';
  } else if (type === 'delivery') {
    subtitleTranslationKey = 'map.delivery.subtitle';
    titleTranslateKey = 'map.delivery.title';
  } else if (type === 'dropoff') {
    subtitleTranslationKey = 'map.dropoff.subtitle';
    titleTranslateKey = 'map.dropoff.title';
  } else if (type === 'stop') {
    subtitleTranslationKey = 'map.stop.subtitle';
    titleTranslateKey = 'map.stop.title';
  } else if (type === 'user') {
    subtitleTranslationKey = 'map.user.subtitle';
    titleTranslateKey = 'map.user.title';
  }

  const finalSubtitle = intl.formatMessage({ id: subtitleTranslationKey }) ?? null;
  const finalTitle = title ?? intl.formatMessage({ id: titleTranslateKey });

  return (
    <Modal
      footer={
        <Button
          context="primary"
          disabled={!addressIsValid || mapIsLoading || addressChangeIsLoading}
          largeHorizontalPadding
          text={type === 'user' ? intl.formatMessage({ id: 'map.save' }) : intl.formatMessage({ id: 'map.select' })}
          onClick={handleEditAddressModalSaveButton}
        />
      }
      footerAlignRight
      subtitle={finalSubtitle}
      title={finalTitle}
      onClose={handleModalClose}
      onEnter={handleEditAddressModalSaveButton}
    >
      <div className="address-modal">
        {/* Map */}
        {showMap ? (
          <MapPoint
            address={address?.formattedAddress}
            coordinates={{ lat: address.lat, lng: address.lng }}
            id="modalMap"
            location={address?.formattedAddress}
            markerIcon={markerPickupIcon}
            onAddressChange={handleFormChange}
            onLoadingAddressChange={(value) => {
              setAddressChangeIsLoading(value);
            }}
            onLoadingStateChange={(value) => {
              setMapIsLoading(value);
            }}
          />
        ) : (
          <div className="address-modal__map-placeholder">
            <img
              alt="Map Icon"
              className="address-modal__map-placeholder-icon"
              src={mapIcon}
            />
            <div>
              <FormattedMessage id="map.choose_location" />
              <img
                alt="User addresses"
                className="address-modal__user-addresses-msg-icon"
                src={bookIcon}
              />
            </div>
          </div>
        )}
        <AddressForm
          address={address}
          addressIsChanging={addressChangeIsLoading}
          compact
          isInModal
          mapIsLoading={mapIsLoading}
          searchAddress={searchAddress}
          type={type}
          userAddress={userDefaultAddress}
          onChange={handleFormChange}
          onSearchInputChange={handleFormSearchChange}
        />
      </div>
    </Modal>
  );
};

AddressModal.propTypes = {
  currentAddress: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string
};

AddressModal.defaultProps = {
  currentAddress: null,
  onClose: () => {},
  onSave: () => {},
  title: null,
  type: 'pickup'
};

export default AddressModal;
