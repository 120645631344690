import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUser, removePhoto, selectUser, updatePhoto } from 'store/slices/authentication';

import accountCoverImage from 'images/account-cover.jpg';
import avatarImage from 'images/avatar.jpg';
import cameraIcon from 'images/icons/camera.svg';
import uploadIcon from 'images/icons/plus.svg';
import removeIcon from 'images/icons/trash--red.svg';

import Dropdown from '@youship/components/objects/dropdown';

import './styles.scss';

const AccountHeader = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const changePhotoInputRef = useRef();

  const userImageUrl = user?.photo_url || avatarImage;
  const removeAvatarDisabled = !user?.photo_url;

  const [showUpdatePhotoDropdown, setShowUpdatePhotoDropdown] = useState(false);

  const readImageFile = file => new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

  const handleChangePhotoInputChange = async (event) => {
    const files = event?.target?.files;
    const image = files[0] || null;

    if (image) {
      try {
        const convertedImage = await readImageFile(image);
        const imageBase64Data = convertedImage.slice(convertedImage.indexOf('base64,') + 7);

        dispatch(updatePhoto({
          image: imageBase64Data
        }))
          .then((response) => {
            dispatch(getUser());
            setShowUpdatePhotoDropdown(false);

            return response;
          })
          .catch((error) => {
            throw error;
          });
      } catch (error) {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const handleRemovePhotoButtonClick = () => {
    if (changePhotoInputRef.current) changePhotoInputRef.current.value = '';

    if (!removeAvatarDisabled) {
      dispatch(removePhoto())
        .then((response) => {
          dispatch(getUser());
          setShowUpdatePhotoDropdown(false);

          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const handleUpdatePhotoButtonClick = () => {
    setShowUpdatePhotoDropdown(true);
  };

  const handleUpdatePhotoDropdownClose = () => {
    setShowUpdatePhotoDropdown(false);
  };

  return (
    <div className="account-header">
      <div className="account-header__image-wrapper">
        <img
          alt="Account Cover"
          className="account-header__image"
          src={accountCoverImage}
        />
      </div>
      <div className="account-header__avatar-container">
        <div className="container">
          <div className="account-header__avatar-wrapper">
            <div className="account-header__avatar">
              <img
                alt="Avatar"
                className="account-header__avatar-image"
                src={userImageUrl}
              />
            </div>
            <div className="account-header__edit-avatar">
              <button
                className="account-header__edit-avatar-button"
                type="button"
                onClick={handleUpdatePhotoButtonClick}
              >
                <img
                  alt="Camera Icon"
                  className="account-header__edit-avatar-icon"
                  src={cameraIcon}
                />
              </button>
              <Dropdown
                autoWidth
                className="account-header__upload-avatar-menu-dropdown"
                horizontalPosition="left"
                show={showUpdatePhotoDropdown}
                verticalPosition="bottom"
                onClose={handleUpdatePhotoDropdownClose}
              >
                <div className="account-header__upload-avatar">
                  <label
                    className="account-header__upload-avatar-label"
                    htmlFor="avatar-icon"
                  >
                    <img
                      alt="Upload Icon"
                      className="account-header__upload-avatar-icon"
                      src={uploadIcon}
                    />
                    <p className="account-header__upload-avatar-text">
                      Upload Photo
                    </p>
                  </label>
                  <input
                    ref={changePhotoInputRef}
                    className="account-header__upload-avatar-input"
                    id="avatar-icon"
                    type="file"
                    onChange={handleChangePhotoInputChange}
                  />
                </div>
                <button
                  className={`account-header__remove-avatar${removeAvatarDisabled ? ' account-header__remove-avatar--disabled' : ''}`}
                  disabled={removeAvatarDisabled}
                  type="button"
                  onClick={handleRemovePhotoButtonClick}
                >
                  <img
                    alt="Remove Icon"
                    className="account-header__remove-avatar-icon"
                    src={removeIcon}
                  />
                  <p className="account-header__remove-avatar-text">
                    Remove Photo
                  </p>
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountHeader;
