/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import countryTelephoneData from 'country-telephone-data';

import Dropdown from '../dropdown';
import aqCountryFlagIcon from '../../../assets/images/icons/countries/aq.svg';
import uploadIcon from '../../../assets/images/icons/circle-chevron-up.svg';

import './styles.scss';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDSq8lMU2FUvhWFPNPPCyn_viuDE5t8FPI';

const STATUS_DEFAULT = 'default';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

const Input = ({
  backgroundImage,
  block,
  disabledDropdown,
  countryCallingCode,
  inputId,
  label,
  message,
  readOnly,
  smallBackground,
  status,
  onBlur,
  onChange,
  onCountryCallingCodeChange,
  onAddressChange,
  fileName,
  ...inputProps
}) => {
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);
  const [selectedCountryCallingCode, setSelectedCountryCallingCode] = useState(countryCallingCode || '351');
  const [selectedCountryFlag, setSelectedCountryFlag] = useState(null);
  const phoneDropdown = useRef(null);
  const addressInput = useRef(null);
  const [autocompleteInitialized, setAutocompleteInitialized] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState('');

  const onPhoneDropdownClose = () => {
    setShowPhoneDropdown(false);
  };

  const countries = countryTelephoneData.allCountries;

  const [filteredCountries, setFilteredCountries] = useState(countries);

  useEffect(() => {
    setSelectedCountryFlag(getCountryFlagIcon(countries
      .find(country => country.dialCode === selectedCountryCallingCode).iso2));
  }, [selectedCountryCallingCode, countries]);

  useEffect(() => {
    if (countrySearchTerm !== '') {
      const countriesList = [...countries]
        .filter(country => country.name.toLowerCase().includes(countrySearchTerm.toLowerCase()));

      setFilteredCountries(countriesList);
    } else {
      setFilteredCountries([...countries]);
    }
  }, [countrySearchTerm, countries]);


  const getCountryFlagIcon = (countryCode) => {
    if (countryCode === 'aq') {
      return aqCountryFlagIcon;
    }

    return `https://flag.pk/flags/4x3/${countryCode}.svg`;
  };

  const handlePhoneInputClick = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 768) {
      setShowPhoneDropdown(true);
    }
  };

  const handlePhoneInputKeyDown = (event) => {
    if (event.keyCode === 13) {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 768) {
        setShowPhoneDropdown(true);
      }
    }
  };

  const handleSelectedCountryCallingCodeClick = (callingCode) => {
    setSelectedCountryCallingCode(callingCode);
    onCountryCallingCodeChange(callingCode);
    setShowPhoneDropdown(false);
  };

  const handleSelectedCountryCallingCodeKeyDown = (event, callingCode) => {
    if (event.keyCode === 13) {
      setSelectedCountryCallingCode(callingCode);
      onCountryCallingCodeChange(callingCode);
      setShowPhoneDropdown(false);
    }
  };

  let autocomplete = null;

  const initAutocomplete = () => {
    if (inputProps?.type === 'address' && !autocomplete && window.google?.maps?.places) {
      autocomplete = new window.google.maps.places.Autocomplete(addressInput.current);
      autocomplete.setComponentRestrictions({
        country: ['pt', 'es']
      });
      autocomplete.addListener('place_changed', fillInAddress);
      setAutocompleteInitialized(true);
    }
  };

  const fillInAddress = async () => {
    const addressObject = autocomplete.getPlace();
    const query = addressObject.formatted_address;

    onChange({
      target: {
        value: query
      }
    });

    onAddressChange(addressObject);
  };

  useEffect(() => {
    if (!document.getElementById('google-maps-react')) {
      const googleMapsScript = document.createElement('script');

      googleMapsScript.setAttribute('id', 'google-maps-react');

      googleMapsScript.type = 'text/javascript';
      googleMapsScript.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;

      const googleMapsScriptTag = document.getElementsByTagName('script')[0];

      googleMapsScriptTag.parentNode.insertBefore(googleMapsScript, googleMapsScriptTag);
    }
  });

  useEffect(() => {
    if (!window.google) {
      document.getElementById('google-maps-react')
        .addEventListener('load', () => {
          initAutocomplete();
        });
    } else if (!autocompleteInitialized) {
      initAutocomplete();
    }
  });

  return (
    <div className={`input__wrapper${inputProps.type === 'phone' ? ' input__wrapper--phone' : ''}`}>
      {label && (
        <label
          className="input__label"
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <div className="input__container">
        {inputProps.type === 'phone' && (
          <>
            <div
              className={`input__phone${disabledDropdown ? ' input__phone--disabled-dropdown' : ''}`}
              role="button"
              tabIndex={0}
              onClick={handlePhoneInputClick}
              onKeyDown={handlePhoneInputKeyDown}
            >
              <img
                alt="Country Flag"
                className="input__phone-flag"
                src={selectedCountryFlag}
              />
              +
              {selectedCountryCallingCode}
              <select
                ref={phoneDropdown}
                className="input__phone-default-select"
                disabled={disabledDropdown}
                id="filter-select"
                value={selectedCountryCallingCode}
                onChange={event => setSelectedCountryCallingCode(event?.target?.value ?? '')}
              >
                {countries.map(country => (
                  <option
                    key={country.iso2}
                    value={country.dialCode}
                  >
                    {country.name}
                    {' '}
                    (+
                    {country.dialCode}
                    )
                  </option>
                ))}
              </select>
            </div>
            <Dropdown
              autoWidth
              className="input-phone-dropdown__wrapper"
              scrollable
              show={showPhoneDropdown}
              onClose={onPhoneDropdownClose}
            >
              <>
                <input
                  className="input input--search input--block"
                  value={countrySearchTerm}
                  onChange={event => setCountrySearchTerm(event?.target?.value ?? '')}
                />
                <div className="input-phone-dropdown">
                  {filteredCountries.map(country => (
                    <div
                      key={country.iso2}
                      className={`input-phone-dropdown__item${selectedCountryCallingCode === country.dialCode ? ' input-phone-dropdown__item--selected' : ''}`}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSelectedCountryCallingCodeClick(country.dialCode)}
                      onKeyDown={event => handleSelectedCountryCallingCodeKeyDown(event, country.dialCode)}
                    >
                      <img
                        alt={`${country.name} Flag Icon`}
                        className="input-phone-dropdown__flag"
                        src={getCountryFlagIcon(country.iso2)}
                      />
                      <div className="input-phone-dropdown__country-name">
                        {country.name}
                        <div className="input-phone-dropdown__country-calling-code">
                          (+
                          {country.dialCode}
                          )
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            </Dropdown>
          </>
        )}
        {inputProps.type !== 'textarea' && inputProps.type !== 'file' && (<input
          {...inputProps}
          ref={addressInput}
          autoComplete={inputProps.type === 'address' ? 'new-password' : 'true'}
          className={`input${block ? ' input--block' : ''}${status === STATUS_ERROR ? ' input--error' : ''}${backgroundImage ? ' input--has-background-image' : ''}${smallBackground && backgroundImage ? ' input--small-background' : ''}${inputProps.type === 'file' ? ' input--upload' : ''}`}
          id={inputId}
          readOnly={readOnly}
          style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}}
          type={inputProps.type === 'address' ? 'search' : inputProps.type}
          onBlur={onBlur}
          onChange={onChange}
        />)}
        {inputProps.type === 'textarea' && (<textarea
          {...inputProps}
          ref={addressInput}
          className={`input${block ? ' input--block' : ''}${status === STATUS_ERROR ? ' input--error' : ''}${backgroundImage ? ' input--has-background-image' : ''}${smallBackground && backgroundImage ? ' input--small-background' : ''}`}
          id={inputId}
          rows="5"
          onBlur={onBlur}
          onChange={onChange}
        />)}
        {inputProps.type === 'file' && (
          <>
            <input
              {...inputProps}
              className={`input${inputProps.type === 'file' ? ' input--upload' : ''}`}
              id={inputId}
              readOnly={readOnly}
              type={inputProps.type === 'address' ? 'search' : inputProps.type}
              onBlur={onBlur}
              onChange={onChange}
            />
            <label
              className={`input__upload-label${status === STATUS_ERROR ? ' input__upload-label-error' : ''}`}
              htmlFor={inputId}
              readOnly={readOnly}
            >
              <img
                alt="Upload file"
                className="input__upload-label-icon"
                src={uploadIcon}
              />
              Upload -&nbsp;
              <span className="input__upload-label-file-name">
                {fileName}
              </span>
            </label>
          </>
        )}
      </div>
      {!!message && (
        <div className={`input__message${status === STATUS_ERROR ? ' input__message--error' : ''}${status === STATUS_SUCCESS ? ' input__message--success' : ''}`}>
          {message}
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  backgroundImage: PropTypes.string,
  block: PropTypes.bool,
  countryCallingCode: PropTypes.string,
  disabledDropdown: PropTypes.bool,
  fileName: PropTypes.string,
  inputId: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  onAddressChange: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onCountryCallingCodeChange: PropTypes.func,
  readOnly: PropTypes.bool,
  smallBackground: PropTypes.bool,
  status: PropTypes.oneOf([STATUS_ERROR, STATUS_DEFAULT, STATUS_SUCCESS])
};

Input.defaultProps = {
  backgroundImage: '',
  block: false,
  countryCallingCode: null,
  disabledDropdown: null,
  fileName: null,
  inputId: 'input-id',
  label: null,
  message: null,
  onAddressChange: () => {},
  onBlur: () => {},
  onChange: () => {},
  onCountryCallingCodeChange: () => {},
  readOnly: false,
  smallBackground: false,
  status: STATUS_DEFAULT
};

export default Input;
