import React, { useEffect } from 'react';
import { Route as ReactRoute, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { resetRedirectionUrl, selectInitialized, selectIsAuthenticated, selectRedirectionUrl, selectRequirePhoneActivation, setRedirectionUrl } from 'store/slices/authentication';

const ROUTE_PATHNAME_LOGIN = '/login';
const ROUTE_PATHNAME_REGISTER = '/register';

const Route = ({ children, component: Component, requiresAuthentication, render, ...routeProps }) => {
  const currentPathname = useLocation().pathname;

  const dispatch = useDispatch();

  const authenticationInitialized = useSelector(selectInitialized);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const redirectionUrl = useSelector(selectRedirectionUrl);
  const requirePhoneActivation = useSelector(selectRequirePhoneActivation);

  useEffect(() => {
    if (currentPathname) {
      // Conditions to return Redirect (see render method of <ReactRoute> below)
      if (
        (requiresAuthentication && authenticationInitialized && !isAuthenticated && currentPathname !== ROUTE_PATHNAME_LOGIN) ||
        (requirePhoneActivation && currentPathname !== ROUTE_PATHNAME_REGISTER)
      ) {
        dispatch(setRedirectionUrl(currentPathname));
      } else if (currentPathname !== ROUTE_PATHNAME_LOGIN && currentPathname !== ROUTE_PATHNAME_REGISTER) {
        dispatch(resetRedirectionUrl());
      }
    }
  }, [
    authenticationInitialized,
    dispatch,
    currentPathname,
    isAuthenticated,
    requirePhoneActivation,
    requiresAuthentication
  ]);

  return (
    <ReactRoute
      {...routeProps}
      render={(props) => {
        // Redirect to login when trying to access a guarded page if not authenticated
        if (requiresAuthentication && currentPathname !== ROUTE_PATHNAME_LOGIN) {
          // NOTE: redirect to login should only be returned if isAuthenticated can be verified
          if (!authenticationInitialized) return null;
          if (!isAuthenticated) return <Redirect to={ROUTE_PATHNAME_LOGIN} />;
        }

        // Redirect to register when trying to access any page if registration is incomplete
        // NOTE: will land on code activation step of register wizard automatically
        if (requirePhoneActivation && currentPathname !== ROUTE_PATHNAME_REGISTER) return <Redirect to={ROUTE_PATHNAME_REGISTER} />;

        // Redirect when trying to access login view if already authenticated
        if (authenticationInitialized && isAuthenticated && currentPathname === ROUTE_PATHNAME_LOGIN) {
          if (redirectionUrl) {
            dispatch(resetRedirectionUrl());

            return <Redirect to={redirectionUrl} />;
          }

          return <Redirect to="/" />;
        }

        if (render) return render(props);
        if (Component) return <Component {...props} />;

        return children ?? null;
      }}
    />
  );
};

export default Route;
