import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUser, selectIsSigningUpAsShop, signUpAsShop } from 'store/slices/authentication';

import {
  NUMBERS_ONLY_PATTERN,
  POSTAL_CODE_PATTERN,
  VAT_MINIMUM_LENGTH
} from '@youship/utils/form-validation';

import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import Loader from '@youship/components/objects/loader';
import Checkbox from '@youship/components/objects/checkbox';
import Notice from '@youship/components/objects/notice';

import greenCheckIcon from 'images/icons/check-green.svg';

import './styles.scss';

const MARKETING_WEBSITE_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL;

const ShopForm = () => {
  const { control, errors, handleSubmit, register, trigger, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      about: '',
      address: '',
      bankAccountIban: null,
      bankAccountName: '',
      city: '',
      companyName: '',
      companyCode: null,
      schedule: '',
      shopName: '',
      shopDescription: '',
      postalCode: null,
      vat: null
    }
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const isSigningUpAsShop = useSelector(selectIsSigningUpAsShop);

  // NOTE: This variables are always false because the client wants to always show the form
  const userIsAwaitingShopApproval = false;
  const userIsShop = false;

  const [errorMessage, setErrorMessage] = useState('Sorry, we were unable to process your request at this time. Please try again later.');
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const isFormValid = termsAgreed && [
    'about',
    'address',
    'city',
    'companyName',
    'schedule',
    'postalCode',
    'shopName',
    'shopDescription',
    'vat'
  ].every(input => (!!watch(input) || typeof watch(input) === 'number') && !errors[input]);

  const isFormDisabled = isSigningUpAsShop || userIsAwaitingShopApproval || userIsShop;
  const isFormSubmitDisabled = isFormDisabled || !isFormValid;

  const intl = useIntl();

  useEffect(() => {
    register({ name: 'about' }, {
      required: intl.formatMessage({ id: 'r_partner.form.about.required' }),
      minLength: {
        value: 5,
        message: intl.formatMessage({ id: 'r_partner.form.about.pattern' })
      }
    });

    register({ name: 'address' }, {
      required: intl.formatMessage({ id: 'r_partner.form.address.required' })
    });

    register({ name: 'bankAccountIban' });

    register({ name: 'bankAccountName' });

    register({ name: 'city' }, {
      required: intl.formatMessage({ id: 'r_partner.form.city.required' })
    });

    register({ name: 'companyName' }, {
      required: intl.formatMessage({ id: 'r_partner.form.company.required' })
    });

    register({ name: 'companyCode' }, {
      required: intl.formatMessage({ id: 'r_partner.form.company_code.required' })
    });

    register({ name: 'postalCode' }, {
      pattern: {
        value: POSTAL_CODE_PATTERN,
        message: intl.formatMessage({ id: 'r_partner.form.postalcode.pattern' })
      },
      required: intl.formatMessage({ id: 'r_partner.form.postalcode.required' })
    });

    register({ name: 'schedule' }, {
      required: intl.formatMessage({ id: 'r_partner.form.availability.required' })
    });

    register({ name: 'shopName' }, {
      required: intl.formatMessage({ id: 'r_partner.form.business_name.required' })
    });

    register({ name: 'shopDescription' }, {
      required: intl.formatMessage({ id: 'r_partner.form.description.required' }),
      minLength: {
        value: 5,
        message: intl.formatMessage({ id: 'r_partner.form.description.pattern' })
      }
    });

    register({ name: 'vat' }, {
      pattern: {
        value: NUMBERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'r_partner.form.vat.pattern' })
      },
      minLength: {
        value: VAT_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'r_partner.form.vat.minlength' },
          { VAT_MINIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'r_partner.form.vat.required' })
    });
  });

  const getInputMessage = (name) => {
    if (errors[name]) {
      return errors[name].message;
    }

    return '';
  };

  const getInputStatus = (name) => {
    if (errors[name]) {
      return 'error';
    }

    return 'default';
  };

  const handleInputChange = (value, props) => {
    const { name } = props;

    props.onChange(value);

    if (errors[name]) {
      trigger(name);
    }
  };

  const handleFormSubmit = (data) => {
    dispatch(signUpAsShop(data))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while submitting the form.');

        return response;
      })
      .then((response) => {
        setSuccessMessage(intl.formatMessage({ id: 'r_partner.success' }));
        dispatch(getUser());

        setTimeout(() => {
          history.push('/account');
        }, 6000);

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        setFailed(true);
        setErrorMessage(error.message);
      });
  };

  return (
    <>
      {userIsShop && (
        <div className="shop-form__notice">
          <FormattedMessage id="r_partner.already_signup" />
        </div>
      )}
      {!userIsShop && !successMessage && userIsAwaitingShopApproval && (
        <div className="shop-form__notice">
          <FormattedMessage id="r_partner.already_awaiting" />
        </div>
      )}
      {!successMessage ? (
        <form
          className="shop-form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          {isSigningUpAsShop && (
            <div className="shop-form__loader-wrapper">
              <Loader />
            </div>
          )}
          <div className="shop-form__input-group">
            <Controller
              control={control}
              name="companyName"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.company.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.company.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Controller
              control={control}
              name="companyCode"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.companycode.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.companycode.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Controller
              control={control}
              name="shopName"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.business_name.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.business_name.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Controller
              control={control}
              name="shopDescription"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.description.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.description.placeholder' })}
                  status={getInputStatus(props.name)}
                  type="textarea"
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Controller
              control={control}
              name="address"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.address.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.address.placeholder' })}
                  status={getInputStatus(props.name)}
                  type="textarea"
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <div className="row">
              <div className="col-12 col-md-4">
                <Controller
                  control={control}
                  name="postalCode"
                  render={props => (
                    <Input
                      block
                      disabled={isFormDisabled}
                      label={intl.formatMessage({ id: 'r_partner.form.postalcode.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_partner.form.postalcode.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
              </div>
              <div className="col-12 col-md-8">
                <Controller
                  control={control}
                  name="city"
                  render={props => (
                    <Input
                      block
                      disabled={isFormDisabled}
                      label={intl.formatMessage({ id: 'r_partner.form.city.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_partner.form.city.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
              </div>
            </div>
            <Controller
              control={control}
              name="vat"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.vat.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.vat.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Controller
              control={control}
              name="schedule"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.availability.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.availability.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Controller
              control={control}
              name="about"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.about.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.about.placeholder' })}
                  status={getInputStatus(props.name)}
                  type="textarea"
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <hr className="transporter-form__separator" />
            <p className="transporter-form__subtitle">
              <FormattedMessage id="r_partner.form.bank.title" />
            </p>
            <Controller
              control={control}
              name="bankAccountName"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.bank_name.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.bank_name.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Controller
              control={control}
              name="bankAccountIban"
              render={props => (
                <Input
                  block
                  disabled={isFormDisabled}
                  label={intl.formatMessage({ id: 'r_partner.form.bank_account.label' })}
                  message={getInputMessage(props.name)}
                  placeholder={intl.formatMessage({ id: 'r_partner.form.bank_account.placeholder' })}
                  status={getInputStatus(props.name)}
                  onBlur={props.onBlur}
                  onChange={e => handleInputChange(e.target.value, props)}
                />
              )}
            />
            <Checkbox
              alignTop
              checked={termsAgreed}
              disabled={isFormDisabled}
              label={(
                <div className="shop-form__info">
                  <FormattedMessage id="register.form.terms_agree" />
                  {' '}
                  <a
                    className="shop-form__link"
                    href={`${MARKETING_WEBSITE_URL}/terms-and-conditions`}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={event => event.stopPropagation()}
                  >
                    <FormattedMessage id="register.form.terms_link" />
                  </a>
                  {' '}
                  <FormattedMessage id="register.form.terms_text" />
                  {' '}
                  <a
                    className="shop-form__link"
                    href={`${MARKETING_WEBSITE_URL}/privacy`}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={event => event.stopPropagation()}
                  >
                    <FormattedMessage id="register.form.privacy_link" />
                  </a>
                </div>
              )}
              noBorder
              smallMargin
              onChange={value => setTermsAgreed(value)}
            />
          </div>
          <div className="shop-form__footer">
            <Button
              context="primary"
              disabled={isFormSubmitDisabled}
              largeHorizontalPadding
              smallVerticalPadding
              text={intl.formatMessage({ id: 'r_partner.submit' })}
              type="submit"
            />
          </div>
          {failed && (
            <div className="shop-form__error">
              {errorMessage}
            </div>
          )}
        </form>
      ) : (
        <div className="shop-form__feedback">
          <Notice
            description={successMessage}
            fixed
            icon={greenCheckIcon}
          />
        </div>
      )}
    </>
  );
};

export default ShopForm;
