import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import UserAddressesApi from '@youship/api/user-addresses';

const userAddressesAdapter = createEntityAdapter();

const initialState = userAddressesAdapter.getInitialState({
  isAddingUserAddress: false,
  isLoadingUserAddresses: false
});

// Thunks:

export const getAddresses = createAsyncThunk(
  'userAddresses/getAddresses',
  payload => UserAddressesApi.addressList(payload)
    .then((response) => {
      const addresses = response.rows;

      if (Array.isArray(addresses)) {
        return addresses.map((address, index) => ({
          ...address,
          id: index,
          address: {
            ...address.address,
            apartmentAddress: address.address.apartment_address ?? '',
            countryCode: address.address.countrycode ?? '',
            formattedAddress: address.address.formatted_address ?? '',
            numberAddress: address.address.number_address ?? '',
            postalCode: address.address.postalcode ?? '',
            streetAddress: address.address.street_address ?? ''
          },
          addressCode: address.address_code,
          isDefault: address.isdefault,
          lineAddress: address.line_address,
          strAddress: address.str_address
        }));
      }

      return null;
    })
);

/* eslint-disable camelcase */
export const addAddress = createAsyncThunk(
  'userAddresses/addAddress',
  ({
    address: {
      apartmentAddress,
      city,
      countryCode,
      countryId,
      formattedAddress,
      lat,
      lng,
      numberAddress,
      postalCode,
      stateAddress,
      streetAddress
    },
    contact,
    isDefault
  }) => {
    const addressData = {
      address: {
        apartment_address: apartmentAddress,
        city,
        formatted_address: formattedAddress,
        number_address: numberAddress,
        postalcode: postalCode,
        street_address: streetAddress,
        countrycode: countryCode
      },
      isdefault: isDefault
    };

    if (apartmentAddress) addressData.address.apartment_address = apartmentAddress;
    if (countryCode) addressData.address.countrycode = countryCode;
    if (countryId) addressData.address.idcountry = countryId;
    if (lat) addressData.address.lat = lat;
    if (lng) addressData.address.lng = lng;
    if (numberAddress) addressData.address.number_address = numberAddress;
    if (stateAddress) addressData.address.state_address = stateAddress;

    if (contact) {
      const {
        company,
        countryCallingCode,
        email,
        name,
        notes,
        phone,
        phoneCodeId,
        phoneList
      } = contact;

      const contactData = {
        name,
        phone
      };

      if (company) contactData.company = company;
      if (countryCallingCode) contactData.phonecode = countryCallingCode;
      if (email) contactData.email = email;
      if (notes) contactData.notes = notes;
      if (phoneCodeId) contactData.idphonecode = phoneCodeId;
      if (phoneList) contactData.phonelist = phoneList;

      addressData.contact = contactData;
    }

    return UserAddressesApi.newAddress(addressData);
  }
);

export const editAddress = createAsyncThunk(
  'userAddresses/editAddress',
  ({
    address: {
      apartmentAddress,
      city,
      countryCode,
      countryId,
      formattedAddress,
      lat,
      lng,
      numberAddress,
      postalCode,
      stateAddress,
      streetAddress
    },
    contact,
    addressCode,
    isDefault
  }) => {
    const addressData = {
      address: {
        apartment_address: apartmentAddress,
        city,
        formatted_address: formattedAddress,
        number_address: numberAddress,
        postalcode: postalCode,
        street_address: streetAddress,
        countrycode: countryCode
      },
      address_code: addressCode,
      isdefault: isDefault
    };

    if (apartmentAddress) addressData.address.apartment_address = apartmentAddress;
    if (countryCode) addressData.address.countrycode = countryCode;
    if (countryId) addressData.address.idcountry = countryId;
    if (lat) addressData.address.lat = lat;
    if (lng) addressData.address.lng = lng;
    if (numberAddress) addressData.address.number_address = numberAddress;
    if (stateAddress) addressData.address.state_address = stateAddress;

    if (contact) {
      const {
        company,
        countryCallingCode,
        email,
        name,
        notes,
        phone,
        phoneCodeId,
        phoneList
      } = contact;

      const contactData = {
        name,
        phone
      };

      if (company) contactData.company = company;
      if (countryCallingCode) contactData.phonecode = countryCallingCode;
      if (email) contactData.email = email;
      if (notes) contactData.notes = notes;
      if (phoneCodeId) contactData.idphonecode = phoneCodeId;
      if (phoneList) contactData.phonelist = phoneList;

      addressData.contact = contactData;
    }

    return UserAddressesApi.editAddress(addressData);
  }
);
/* eslint-enable camelcase */

// Slice:

const userAddressesSlice = createSlice({
  name: 'userAddresses',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(addAddress.pending, (state) => {
        state.isAddingUserAddress = true;
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        state.isAddingUserAddress = false;

        return action.response;
      })
      .addCase(addAddress.rejected, (state) => {
        state.isAddingUserAddress = false;
      })
      .addCase(editAddress.pending, (state) => {
        state.isAddingUserAddress = true;
      })
      .addCase(editAddress.fulfilled, (state, action) => {
        state.isAddingUserAddress = false;

        return action.response;
      })
      .addCase(editAddress.rejected, (state) => {
        state.isAddingUserAddress = false;
      })
      .addCase(getAddresses.pending, (state) => {
        state.isLoadingUserAddresses = true;
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        const addresses = action.payload;

        if (Array.isArray(addresses)) {
          userAddressesAdapter.setAll(state, addresses);
        }

        state.isLoadingUserAddresses = false;
      })
      .addCase(getAddresses.rejected, (state) => {
        state.isLoadingUserAddresses = false;
      });
  }
});

export default userAddressesSlice.reducer;

// Selectors:

export const {
  selectAll: selectUserAddresses
} = userAddressesAdapter.getSelectors(state => state.userAddresses);

export const selectIsAddingUserAddress = state => state.userAddresses.isAddingUserAddress;

export const selectIsLoadingUserAddresses = state => state.userAddresses.isLoadingUserAddresses;
