import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

import newOrderIllustration from 'images/new-order-illustration.svg';

const NewOrderCard = () => (
  <Link to="/new-order">
    <div
      className="new-order-card"
      style={{ backgroundImage: `url(${newOrderIllustration})` }}
    >
      <div className="new-order-card__content">
        <div className="new-order-card__title">
          <FormattedMessage id="dashboard.new_order_card.title" />
        </div>
        <div className="new-order-card__description">
          <FormattedMessage id="dashboard.new_order_card.description" />
        </div>
      </div>
    </div>
  </Link>
);

export default NewOrderCard;
