import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Moment from 'moment';

import { markNotificationAsSeen, selectIsLoading, selectNotifications } from 'store/slices/notifications';

import NotificationsGroup from 'components/notifications-group';

import './styles.scss';


const NotificationsList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const notifications = useSelector(selectNotifications);

  const handleNotificationClick = (id) => {
    // To use in combination with the onClick event handler in Notification’s wrapper of PushNotifications, which has some padding
    dispatch(markNotificationAsSeen(id));
  };

  const intl = useIntl();

  const today = {
    title: intl.formatMessage({ id: 'notifications.today' }),
    notifications: [],
    showTimeDifference: true
  };

  const yesterday = {
    title: intl.formatMessage({ id: 'notifications.yesterday' }),
    notifications: [],
    showTimeDifference: false
  };

  const twoDays = {
    title: intl.formatMessage({ id: 'notifications.two_days' }),
    notifications: [],
    showTimeDifference: false
  };

  const twoWeeks = {
    title: intl.formatMessage({ id: 'notifications.two_weeks' }),
    notifications: [],
    showTimeDifference: false
  };

  const moreThanAMonth = {
    title: intl.formatMessage({ id: 'notifications.more_than_a_month' }),
    notifications: [],
    showTimeDifference: false
  };

  if (notifications) {
    notifications.forEach((notification) => {
      const differenceToday = 0;
      const differenceYesterday = 1;
      const differenceTwoDays = 2;
      const differenceTwoWeeks = 14;
      const differenceMoreThanAMonth = 30;

      const now = Moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const notificationDate = Moment(notification.date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const difference = now.diff(notificationDate, 'days');

      if (difference <= differenceToday) {
        today.notifications.push(notification);
      } else if (difference >= differenceYesterday && difference < differenceTwoDays) {
        yesterday.notifications.push(notification);
      } else if (difference >= differenceTwoDays && difference < differenceTwoWeeks) {
        twoDays.notifications.push(notification);
      } else if (difference >= differenceTwoWeeks && difference < differenceMoreThanAMonth) {
        twoWeeks.notifications.push(notification);
      } else {
        moreThanAMonth.notifications.push(notification);
      }
    });
  }

  const notificationGroups = [
    today,
    yesterday,
    twoDays,
    twoWeeks,
    moreThanAMonth
  ].filter(notificationGroup => notificationGroup.notifications.length);

  return (
    <div className="notifications-list">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col col-12 col-md-8">
            <h1 className="notifications-list__title">
              <FormattedMessage id="notifications.title" />
            </h1>
            {!!notificationGroups && notificationGroups.length ? (
              <>
                {notificationGroups.map((notificationGroup, index) => (
                  <NotificationsGroup
                    key={index}
                    {...notificationGroup}
                    showTimeDifference
                    onNotificationClick={handleNotificationClick}
                  />
                ))}
              </>
            ) : !isLoading && (
              <div className="notifications-list__empty">
                <FormattedMessage id="notifications.empty" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsList;
