import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import DeliveryApi, { DELIVERY_DEFAULT_TIME_INTERVAL } from '@youship/api/delivery';
import { FormatAddressToApi, FormatShipmentToApi } from 'utils/new-order';
import SessionStorageManager from '@youship/utils/SessionStorageManager';

const newDeliveryAdapter = createEntityAdapter();

const initialState = newDeliveryAdapter.getInitialState({
  autoAddShipmentItems: null,

  delivery: null,

  deliveryDistance: null,

  deliveryDuration: null,

  deliveryError: null,

  deliveryIsLoading: null,

  deliveryQuote: null,

  deliveryQuoteVoucherCode: null,

  deliveryQuoteError: null,

  deliveryQuoteIsLoading: null,

  dropoffs: [],

  destinationSchedule: {
    dropoffDate: null,
    dropoffTimeSlotValue: DELIVERY_DEFAULT_TIME_INTERVAL
  },

  hasReturn: false,

  paymentTypeId: null,

  pickupNotes: null,

  pickups: [],

  originSchedule: {
    pickupDate: null,
    pickupTimeSlotValue: DELIVERY_DEFAULT_TIME_INTERVAL
  },

  returnShipment: null,

  serviceCode: '',

  shipmentOptions: {},

  shipments: [[]],

  rates: null,

  shipType: null,

  stops: []
});

// Thunks:

export const createDelivery = createAsyncThunk(
  'newDelivery/createDelivery',
  (argument, { getState }) => {
    const state = getState().newDelivery;

    if (!state) return Promise.reject(new Error('Unable to access new delivery data.'));

    const {
      dropoffs,
      destinationSchedule,
      deliveryQuote,
      deliveryQuoteVoucherCode,
      hasReturn,
      paymentTypeId,
      pickups,
      originSchedule,
      serviceCode,
      shipments
    } = state;

    if (!destinationSchedule) return Promise.reject(new Error('A dropoff schedule must be selected before creating a delivery.'));
    if (!deliveryQuote) return Promise.reject(new Error('A new delivery quote must be requested before creating a delivery.'));
    if (!paymentTypeId) return Promise.reject(new Error('A payment method must be selected before creating a delivery.'));
    if (!originSchedule) return Promise.reject(new Error('A pickup schedule must be selected before creating a delivery.'));

    const { dropoffAddress: deliveryDropoffAddress, deliveryItems, pickupAddress: deliveryPickupAddress } = deliveryQuote;
    const { dropoffDate, dropoffTimeSlotValue } = destinationSchedule;
    const { pickupDate, pickupTimeSlotValue } = originSchedule;

    if (!deliveryDropoffAddress) return Promise.reject(new Error('The stored new delivery quote does not include a dropoff address.'));
    if (!deliveryPickupAddress) return Promise.reject(new Error('The stored new delivery quote does not include a pickup address.'));
    if (!Array.isArray(deliveryItems) || !deliveryItems.length) return Promise.reject(new Error('The stored new delivery quote does not include an item list.'));

    // Note: This is commented since shipment data appears to be optional
    // This can be removed once we make sure that is the case
    // if (!shipment) return Promise.reject(new Error('A shipment must be selected before creating a delivery.'));
    if (!serviceCode) return Promise.reject(new Error('The stored new delivery quote does not include a service code.'));

    if (!dropoffDate) return Promise.reject(new Error('A dropoff schedule date must be selected before creating a delivery.'));
    if (!dropoffTimeSlotValue) return Promise.reject(new Error('A dropoff schedule time slot must be selected before creating a delivery.'));
    if (!pickupDate) return Promise.reject(new Error('A pickup schedule date must be selected before creating a delivery.'));
    if (!pickupTimeSlotValue) return Promise.reject(new Error('A dropoff schedule time slot must be selected before creating a delivery.'));

    const destinationScheduleDate = `${dropoffDate} ${dropoffTimeSlotValue}`;
    const originScheduleDate = `${pickupDate} ${pickupTimeSlotValue}`;

    let data = null;

    const dropoff = dropoffs[0];
    const pickup = pickups[0];

    const completeDropoff = FormatAddressToApi(dropoff);
    const completePickup = FormatAddressToApi(pickup);

    const deliveryData = populateDeliveryData({
      destination: completeDropoff,
      origin: completePickup,
      destinationSchedule,
      originSchedule,
      serviceCode,
      shipment: dropoff.details,
      items: Array.isArray(shipments[0]) ?
        shipments[0].map(item => ({
          ...item,
          specifications: {
            code: item.specifications?.code,
            description: item.specifications?.description,
            quantity: item.specifications?.quantity,
            height: item.specifications?.height,
            length: item.specifications?.length,
            width: item.specifications?.width,
            weight: item.specifications?.weight
          }
        })) :
        []
    });

    if (hasReturn) {
      data = [];

      data.push(deliveryData.data);

      const returnDeliveryData = populateDeliveryData({
        destination: completePickup,
        origin: completeDropoff,
        destinationSchedule,
        originSchedule,
        serviceCode,
        shipment: pickup.details,
        items: Array.isArray(shipments[shipments.length - 1]) ?
          shipments[shipments.length - 1].map(item => ({
            ...item,
            specifications: {
              code: item.specifications?.code,
              description: item.specifications?.description,
              quantity: item.specifications?.quantity,
              height: item.specifications?.height,
              length: item.specifications?.length,
              width: item.specifications?.width,
              weight: item.specifications?.weight
            }
          })) :
          []
      });

      data.push(returnDeliveryData.data);
    } else {
      data = deliveryData.data;
      data.pickup.notes = pickup.notes;
    }

    // NOTE: This might be removed in the future
    // It depends on the future refactor for multiple destinations

    /* eslint-disable camelcase */
    /* const data = {
      service_code: serviceCode,
      payment_code: paymentTypeId,
      pickup: {
        address: {
          ...pickupAddress,
          // Hardcoded as it's mandatory in the API and the addresss handling is not sending a countrycode
          countrycode: 'PT',
          postalcode: pickupAddress.postalCode,
          street_address: pickupAddress.streetAddress
        },
        contact: {
          name: pickupContactName,
          phonecode: pickupContactPhoneCountryCode,
          phone: pickupContactPhoneNumber
        },
        schedule: {
          ready: originScheduleDate
        },
        notes: pickupNotes
      },
      dropoff: {
        address: {
          ...dropoffAddress,
          // Hardcoded as it's mandatory in the API and the addresss handling is not sending a countrycode
          countrycode: 'PT',
          postalcode: dropoffAddress.postalCode,
          street_address: dropoffAddress.streetAddress
        },
        contact: {
          company: dropoffContactCompany,
          name: dropoffContactName,
          phonecode: dropoffContactPhoneCountryCode,
          phone: dropoffContactPhoneNumber
        },
        schedule: {
          deadline: destinationScheduleDate
        },
        notes: dropoffNotes
      },
      items: deliveryItems,
      shipment
    }; */

    if (deliveryQuoteVoucherCode) data.voucher = deliveryQuoteVoucherCode;
    /* eslint-enable camelcase */

    if (Array.isArray(data)) {
      /* eslint-disable camelcase */
      data = data.map((dataEntry) => {
        const pickupDataEntry = { ...dataEntry.pickup };
        const dropoffDataEntry = { ...dataEntry.dropoff };

        pickupDataEntry.schedule = {
          ready: originScheduleDate
        };

        dropoffDataEntry.schedule = {
          deadline: destinationScheduleDate
        };

        return {
          ...dataEntry,
          payment_code: paymentTypeId,
          dropoff: dropoffDataEntry,
          pickup: pickupDataEntry
        };
      });
    } else {
      data.payment_code = paymentTypeId;
      /* eslint-enable camelcase */
    }

    const deliveryCreate = Array.isArray(data) ? DeliveryApi.multipleDeliveryCreate({ orders: data }) : DeliveryApi.deliveryCreate(data);

    return deliveryCreate
      .then(response => ({
        ...response,
        orderCode: response.order_code,
        paymentUrl: response.payment_url,
        ysToken: response.ys_token
      }))
      .catch(error => error);
  }
);

const populateDeliveryData = (payload) => {
  const {
    origin,
    destination,
    destinationSchedule,
    originSchedule,
    serviceCode,
    shipment,
    items
  } = payload;

  const pickupAddress = origin.address;
  const pickupContact = origin.contact;
  const pickupNotes = origin.notes;

  const dropoffAddress = destination.address;
  const dropoffContact = destination.contact;
  const dropoffNotes = destination.notes;

  const { lat: dropoffLat, lng: dropoffLng } = dropoffAddress;
  const { lat: pickupLat, lng: pickupLng } = pickupAddress;

  const deliveryQuoteDropoffAddress = {};
  const deliveryQuotePickupAddress = {};

  if (typeof dropoffLat === 'number' && typeof dropoffLng === 'number') {
    deliveryQuoteDropoffAddress.lat = dropoffLat;
    deliveryQuoteDropoffAddress.lng = dropoffLng;
  }

  if (typeof pickupLat === 'number' && typeof pickupLng === 'number') {
    deliveryQuotePickupAddress.lat = pickupLat;
    deliveryQuotePickupAddress.lng = pickupLng;
  }

  const requestDropoffAddress = {
    ...dropoffAddress,
    ...deliveryQuoteDropoffAddress
  };

  const requestPickupAddress = {
    ...pickupAddress,
    ...deliveryQuotePickupAddress
  };

  const dropoff = {
    address: {
      ...requestDropoffAddress
    },
    ...(dropoffContact && { contact: {
      ...dropoffContact,
      phonecode: dropoffContact.phonecode || dropoffContact.countryCallingCode
    } }),
    ...(dropoffNotes && { notes: dropoffNotes })
  };

  const pickup = {
    address: {
      ...requestPickupAddress
    },
    ...(pickupContact && {
      contact: {
        ...pickupContact,
        phonecode: pickupContact.phonecode || pickupContact.countryCallingCode
      }
    }),
    ...(pickupNotes && { notes: pickupNotes })
  };

  let requestDestinationSchedule = null;

  // Add dropoff schedule only if it has been changed from the default value
  if (destinationSchedule) {
    const { dropoffDate, dropoffTimeSlotValue } = destinationSchedule;

    const schedule = `${moment(dropoffDate).format('YYYY-MM-DD')} ${dropoffTimeSlotValue}`;

    requestDestinationSchedule = destinationSchedule;
    dropoff.schedule = { deadline: schedule };
  }

  let requestOriginSchedule = null;

  // Add pickup schedule only if it has been changed from the default value
  if (originSchedule) {
    const { pickupDate, pickupTimeSlotValue } = originSchedule;

    const schedule = `${moment(pickupDate).format('YYYY-MM-DD')} ${pickupTimeSlotValue}`;

    requestOriginSchedule = originSchedule;
    pickup.schedule = { ready: schedule };
  }

  const deliveryItems = [];

  const itemsSpecifications = items.map(item => item.specifications);

  itemsSpecifications.forEach((item) => {
    const {
      code,
      description,
      height,
      length,
      quantity,
      width,
      weight
    } = item;

    if (item) {
      const deliveryItem = {
        code,
        description,
        height,
        length,
        quantity,
        width,
        weight
      };

      deliveryItems.push(deliveryItem);
    }
  });

  /* eslint-disable camelcase */
  const data = {
    data: {
      service_code: serviceCode,
      dropoff,
      pickup,
      shipment: FormatShipmentToApi(shipment),
      items: itemsSpecifications
    },
    request: {
      requestDestinationSchedule,
      requestOriginSchedule,
      requestDropoffAddress,
      requestPickupAddress
    },
    deliveryItems
  };

  return data;
};

export const getDeliveryQuote = createAsyncThunk(
  'newDelivery/getDeliveryQuote',
  (argument, { getState, rejectWithValue }) => {
    const state = getState().newDelivery;

    if (!state) return Promise.reject(new Error('Unable to access new delivery data.'));

    const {
      dropoffs,
      destinationSchedule,
      deliveryQuoteVoucherCode,
      hasReturn,
      pickups,
      originSchedule,
      serviceCode,
      shipments,
      shipType
    } = state;

    const items = Array.isArray(shipments) && shipments.length ? shipments[0] : [];

    if (!Array.isArray(pickups) || !pickups.length || !pickups[0].address) return Promise.reject(new Error('Dropoff address is invalid.'));
    if (!Array.isArray(dropoffs) || !dropoffs.length || !dropoffs[0].address) return Promise.reject(new Error('Dropoff address is invalid.'));
    if (!serviceCode) return Promise.reject(new Error('Invalid service code.'));

    const dropoff = dropoffs[0];
    const pickup = pickups[0];

    const completeDropoff = FormatAddressToApi(dropoff);
    const completePickup = FormatAddressToApi(pickup);

    let data = null;
    let request = null;
    let deliveryItems = null;

    const deliveryData = populateDeliveryData({
      destination: completeDropoff,
      destinationSchedule,
      origin: completePickup,
      originSchedule,
      serviceCode,
      shipment: dropoff.details,
      items: Array.isArray(items) ?
        items.map(item => ({
          ...item,
          specifications: {
            ...items.specifications,
            code: item.specifications?.code,
            quantity: item.specifications?.quantity,
            height: item.specifications?.height,
            length: item.specifications?.length,
            width: item.specifications?.width,
            weight: item.specifications?.weight
          }
        })) :
        []
    });

    if (hasReturn) {
      data = [];

      data.push(deliveryData.data);

      const returnDeliveryData = populateDeliveryData({
        destination: completePickup,
        destinationSchedule,
        origin: completeDropoff,
        originSchedule,
        serviceCode,
        shipment: pickup.details,
        items: Array.isArray(shipments) ?
          shipments[shipments.length - 1].map(item => ({
            ...item,
            specifications: {
              ...items.specifications,
              code: item.specifications?.code,
              quantity: item.specifications?.quantity,
              height: item.specifications?.height,
              length: item.specifications?.length,
              width: item.specifications?.width,
              weight: item.specifications?.weight
            }
          })) :
          []
      });

      data.push(returnDeliveryData.data);

      request = returnDeliveryData.request;
      // This part will need to be changed to account for multiple shipments with different items
      deliveryItems = items;
    } else {
      data = deliveryData.data;
      request = deliveryData.request;
      deliveryItems = deliveryData.deliveryItems;
    }

    if (shipType) data.ship_type = shipType;
    if (deliveryQuoteVoucherCode) data.voucher = deliveryQuoteVoucherCode;
    /* eslint-enable camelcase */

    const deliveryQuote = hasReturn ? DeliveryApi.multiDeliveryQuote({ orders: data }) : DeliveryApi.deliveryQuote(data);

    return deliveryQuote
      .then((response) => {
        if (!response?.quote) throw new Error('Unexpected response while requesting a quote for your delivery.');

        const { quote } = response;
        const {
          payment_message: paymentMessage,
          payment_types: paymentTypesFromQuote,
          price_details: priceDetailsFromQuote,
          schedule_intervals: scheduleIntervalsFromQuote,
          schedule_intervals2: scheduleIntervalsForDeliveryFromQuote,
          total
        } = quote;

        // Make sure that there are schedule intervals, since a valid schedule is required to create a delivery
        if (!Array.isArray(scheduleIntervalsFromQuote) || !scheduleIntervalsFromQuote.length) {
          throw new Error('The provided quote did not include schedule intervals.');
        }

        // Make sure that there are schedule intervals after selecting a pickup schedule, since a valid schedule is required to create a delivery
        if (!Array.isArray(scheduleIntervalsForDeliveryFromQuote) || !scheduleIntervalsForDeliveryFromQuote.length) {
          throw new Error('The provided quote did not include schedule intervals.');
        }

        // Make sure that there are payment options
        if (!Array.isArray(paymentTypesFromQuote) || !paymentTypesFromQuote.length) {
          throw new Error('The provided quote did not include payment options.');
        }

        const priceDetails = Array.isArray(priceDetailsFromQuote) ? priceDetailsFromQuote : [];

        const scheduleDropoffIntervals = scheduleIntervalsForDeliveryFromQuote
          .map(day => ({
            dropoffDate: day?.date?.datestring,
            dropoffTimeSlots: Array.isArray(day?.timeinterval) ?
              day.timeinterval.map(({ hour, minute, timestring }) => ({
                label: timestring,
                value: `${hour < 10 ? 0 : ''}${hour}:${minute < 10 ? 0 : ''}${minute}`
              })) :
              []
          }))
          .filter(({ dropoffDate, dropoffTimeSlots }) => dropoffDate && dropoffTimeSlots.length);

        const schedulePickupIntervals = scheduleIntervalsFromQuote
          .map(day => ({
            pickupDate: day?.date?.datestring,
            pickupTimeSlots: Array.isArray(day?.timeinterval) ?
              day.timeinterval.map(({ hour, minute, timestring }) => ({
                label: timestring,
                value: `${hour < 10 ? 0 : ''}${hour}:${minute < 10 ? 0 : ''}${minute}`
              })) :
              []
          }))
          .filter(({ pickupDate, pickupTimeSlots }) => pickupDate && pickupTimeSlots.length);

        const paymentTypes = paymentTypesFromQuote
          .map(paymentType => ({
            ...paymentType,
            id: paymentType.code,
            imageUrl: paymentType.icon_url
          }))
          .filter(({ id, name }) => (id || typeof id === 'number') && name);

        let quoteDistance = 0;
        let quoteDuration = 0;

        if (response?.orders) {
          response.orders.forEach((order) => {
            if (order.distance) {
              quoteDistance += order.distance;
            }
            if (order.duration) {
              quoteDuration += order.duration;
            }
          });
        } else {
          quoteDistance = response?.order?.distance;
          quoteDuration = response?.order?.duration;
        }

        const payload = {
          ...quote,
          distance: quoteDistance,
          duration: quoteDuration,
          dropoffAddress: request.requestDropoffAddress,
          paymentMessage,
          paymentTypes,
          pickupAddress: request.requestPickupAddress,
          priceDetails,
          deliveryItems,
          shipment: data.shipment,
          scheduleDropoffIntervals,
          schedulePickupIntervals,
          serviceCode,
          total
        };

        if (request.requestDestinationSchedule) payload.destinationSchedule = request.requestDestinationSchedule;
        if (request.requestOriginSchedule) payload.originSchedule = request.requestOriginSchedule;

        return payload;
      })
      // rejectWithValue is necessary to send a custom error on the payload
      .catch(error => rejectWithValue(error));
  }
);

export const getDeliveryEstimate = createAsyncThunk(
  'newDelivery/getDeliveryEstimate',
  ({ dropoff, pickup }) => {
    if (!(dropoff?.lat && dropoff.lng && pickup?.lat && pickup.lng)) {
      throw new Error('There was an error getting your pickup address and dropoff address.');
    }

    const dropoffAddress = {
      address: {
        lat: dropoff.lat,
        lng: dropoff.lng
      }
    };

    const pickupAddress = {
      address: {
        lat: pickup.lat,
        lng: pickup.lng
      }
    };

    return DeliveryApi.deliveryEstimate({ dropoff: dropoffAddress, pickup: pickupAddress })
      .then((response) => {
        const details = response;

        return {
          ...details,
          rates: Array.isArray(details.rates) ?
            details.rates.map(rate => ({
              ...rate,
              items: Array.isArray(rate.items) ?
                rate.items.map(item => ({
                  ...item,
                  autoAdd: item.auto_add,
                  iconUrl: item.icon_url,
                  id: `${rate.service?.code}-${item.name}`,
                  code: item.code,
                  formattedLength: {
                    value: item.length,
                    text: `${item.length} cm`
                  },
                  formattedLengths: Array.isArray(item.lengths) ?
                    item.lengths.map(option => ({
                      value: option,
                      text: `${option} cm`
                    })) :
                    [],
                  formattedHeight: {
                    value: item.height,
                    text: `${item.height} cm`
                  },
                  formattedHeights: Array.isArray(item.heights) ?
                    item.heights.map(option => ({
                      value: option,
                      text: `${option} cm`
                    })) :
                    [],
                  formattedWeight: {
                    value: item.weight,
                    text: `${item.weight} kg`
                  },
                  formattedWeights: Array.isArray(item.weights) ?
                    item.weights.map(option => ({
                      value: option,
                      text: `${option} kg`
                    })) :
                    [],
                  formattedWidth: {
                    value: item.width,
                    text: `${item.width} cm`
                  },
                  formattedWidths: Array.isArray(item.widths) ?
                    item.widths.map(option => ({
                      value: option,
                      text: `${option} cm`
                    })) :
                    [],
                  serviceCode: rate.service?.code
                })) :
                [],
              service: {
                ...rate.service,
                options: rate.service?.options ?
                  ({
                    ...rate.service.options,
                    hasCharge: rate.service.options.has_charge,
                    hasFragile: rate.service.options.has_fragile,
                    hasHandDelivery: rate.service.options.has_handdelivery,
                    hasInvoicing: rate.service.options.has_invoicing,
                    hasReference: rate.service.options.has_reference,
                    hasShipper: rate.service.options.has_shipper
                  }) :
                  {}
              }
            })) :
            []
        };
      })
      .then(details => ({
        ...details,
        autoAddShipments: Array.isArray(details.rates) ?
          details.rates.flatMap(rate => rate.items.filter(item => item.auto_add === true)) :
          []
      }))
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }
);

/* eslint-disable camelcase */
export const setDefaultPickup = createAsyncThunk(
  'newDelivery/setDefaultPickup',
  (argument, { getState }) => {
    const { authentication: authenticationState } = getState();

    if (!authenticationState?.user) return Promise.reject(new Error('Unable to access user data.'));

    const { user } = authenticationState;
    const { defaultAddress, firstName, lastName, name, phone } = user;
    const company = defaultAddress?.contact?.company;
    const { code, number } = phone;

    const countryCallingCode = typeof code === 'string' ? code.replace(/^\+/, '') : code;

    if (!defaultAddress) return Promise.reject(new Error('User data does not include a default address.'));
    if (!name && !firstName && !lastName) return Promise.reject(new Error('User data does not include a name.'));
    if (!code) return Promise.reject(new Error('User data does not include a phone country code'));
    if (!number) return Promise.reject(new Error('User data does not include a phone number.'));

    const {
      apartmentAddress,
      city,
      countryCode,
      countryId,
      formattedAddress,
      lat,
      lng,
      numberAddress,
      postalCode,
      stateAddress,
      streetAddress
    } = defaultAddress;

    const addressData = {
      address: {
        apartment_address: apartmentAddress,
        city,
        formatted_address: formattedAddress,
        number_address: numberAddress,
        postalcode: postalCode,
        street_address: streetAddress
      }
    };

    if (apartmentAddress) addressData.address.apartment_address = apartmentAddress;
    if (countryCode) addressData.address.countrycode = countryCode;
    if (countryId) addressData.address.idcountry = countryId;
    if (lat) addressData.address.lat = lat;
    if (lng) addressData.address.lng = lng;
    if (numberAddress) addressData.address.number_address = numberAddress;
    if (stateAddress) addressData.address.state_address = stateAddress;

    const payload = {
      address: {
        ...defaultAddress, // keep the camelCase frontend formatted fields
        ...addressData
      },
      contact: {
        name: name ? name : [firstName, lastName].join(' '),
        company,
        countryCallingCode,
        country_calling_code: countryCallingCode,
        phone: number
      },
      details: {},
      notes: ''
    };

    return Promise.resolve(payload);
  }
);
/* eslint-enable camelcase */

// Slice:

const newDeliverySlice = createSlice({
  name: 'newDelivery',

  initialState,

  reducers: {
    addVoucherCodeToDeliveryQuote (state) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { voucherCode } = state;

      if (voucherCode && typeof voucherCode === 'string') {
        state.deliveryQuoteVoucherCode = voucherCode;
        state.voucherCode = null;

        SessionStorageManager.setDeliveryVoucherCode(voucherCode);
      }
    },

    initialize (state) {
      const deliveryQuoteVoucherCode = SessionStorageManager.getDeliveryVoucherCode();
      const dropoffs = SessionStorageManager.getDeliveryDropoffs();
      const destinationSchedule = SessionStorageManager.getDeliveryDestinationSchedule();
      const hasReturn = SessionStorageManager.getDeliveryHasReturn();
      const paymentTypeId = SessionStorageManager.getDeliveryPaymentTypeId();
      const pickups = SessionStorageManager.getDeliveryPickups();
      const originSchedule = SessionStorageManager.getDeliveryOriginSchedule();
      const serviceCode = SessionStorageManager.getDeliveryServiceCode();
      const shipments = SessionStorageManager.getDeliveryShipments();
      const shipType = SessionStorageManager.getDeliveryShipType();
      const stops = SessionStorageManager.getDeliveryStops();

      if (deliveryQuoteVoucherCode) state.deliveryQuoteVoucherCode = deliveryQuoteVoucherCode;
      if (dropoffs) state.dropoffs = dropoffs;
      if (destinationSchedule?.dropoffDate) state.destinationSchedule.dropoffDate = destinationSchedule.dropoffDate;
      if (destinationSchedule?.dropoffTimeSlotValue) state.destinationSchedule.dropoffTimeSlotValue = destinationSchedule.dropoffTimeSlotValue;
      if (paymentTypeId) state.paymentTypeId = paymentTypeId;
      if (pickups) state.pickups = pickups;
      if (originSchedule?.pickupDate) state.originSchedule.pickupDate = originSchedule.pickupDate;
      if (originSchedule?.pickupTimeSlotValue) state.originSchedule.pickupTimeSlotValue = originSchedule.pickupTimeSlotValue;
      if (serviceCode) state.serviceCode = serviceCode;
      if (shipments) state.shipments = shipments;
      if (shipType) state.shipType = shipType;
      if (stops) state.stops = stops;

      state.hasReturn = hasReturn;
    },

    resetDelivery () {
      SessionStorageManager.removeDeliveryVoucherCode();
      SessionStorageManager.removeDeliveryDropoffs();
      SessionStorageManager.removeDeliveryDestinationSchedule();
      SessionStorageManager.removeDeliveryHasReturn();
      SessionStorageManager.removeDeliveryPaymentTypeId();
      SessionStorageManager.removeDeliveryPickups();
      SessionStorageManager.removeDeliveryOriginSchedule();
      SessionStorageManager.removeDeliveryServiceCode();
      SessionStorageManager.removeDeliveryShipments();
      SessionStorageManager.removeDeliveryShipType();
      SessionStorageManager.removeDeliveryStops();

      return initialState;
    },

    addDeliveryDropoff (state, action) {
      const newDropoff = action.payload;

      if (newDropoff) {
        state.dropoffs.push(newDropoff);
      }
    },

    addDeliveryPickup (state, action) {
      const newPickup = action.payload;

      if (newPickup) {
        state.pickups.push(newPickup);
      }
    },

    addDeliveryShipmentItems (state, action) {
      const { shipmentIndexSelected, selectedShipmentOption, selectedShipmentSpecifications } = action.payload;

      if (typeof shipmentIndexSelected === 'number' && selectedShipmentOption && selectedShipmentSpecifications) {
        const shipmentItems = state.shipments[shipmentIndexSelected];

        shipmentItems.push({
          info: selectedShipmentOption,
          id: `${selectedShipmentOption.serviceCode}-${selectedShipmentOption.name}`,
          specifications: { ...selectedShipmentSpecifications }
        });

        const rates = state.rates.find(rate => rate.service.code === selectedShipmentOption.serviceCode);

        state.shipmentOptions = rates?.service?.options;

        SessionStorageManager.setDeliveryShipments(state.shipments);
      }
    },

    addDeliveryStop (state, action) {
      const newStop = action.payload;

      if (newStop) {
        state.stops.push(newStop);
      }
    },

    addDeliveryStopShipment (state) {
      state.shipments.splice(state.shipments.length - 1, 0, []);
    },

    addDeliveryShipment (state) {
      state.shipments.push([]);
    },

    removeDeliveryDropoff (state, action) {
      const { dropoffIndexSelected } = action.payload;

      if (typeof dropoffIndexSelected === 'number') {
        state.dropoffs.splice(dropoffIndexSelected, 1);
      }
    },

    removeDeliveryPickup (state, action) {
      const { pickupIndexSelected } = action.payload;

      if (typeof pickupIndexSelected === 'number') {
        state.pickups.splice(pickupIndexSelected, 1);
      }
    },

    removeDeliveryShipment (state, action) {
      const { index } = action.payload;

      if (typeof index === 'number') {
        state.shipments.splice(index, 1);
      }
    },

    removeDeliveryShipmentItems (state, action) {
      if (action?.payload) {
        const { index, shipmentIndex } = action.payload;

        if (typeof index === 'number' && typeof shipmentIndex === 'number') {
          state.shipments[index].splice(shipmentIndex, 1);

          SessionStorageManager.setDeliveryShipments(state.shipments);
        }
      } else {
        state.shipments = [[]];
        SessionStorageManager.removeDeliveryShipments();
      }
    },

    removeDeliveryStop (state, action) {
      const { stopIndexSelected } = action.payload;

      if (typeof stopIndexSelected === 'number') {
        state.stops.splice(stopIndexSelected, 1);
      }
    },

    setAddressForPickup (state, action) {
      const { address, pickupIndexSelected } = action.payload;

      if (address && typeof pickupIndexSelected === 'number') {
        state.pickups[pickupIndexSelected].address = address;
        state.pickups[pickupIndexSelected].details = {};
        state.dropoffs.forEach((item) => {
          item.details = {};
        });
        state.hasReturn = false;
      }
    },

    setContactForPickup (state, action) {
      const { contact, modalPickupContact, pickupIndexSelected } = action.payload;
      const pickupContact = modalPickupContact || contact;

      if (pickupContact.name && pickupContact.countryCallingCode && pickupContact.phone && typeof pickupIndexSelected === 'number') {
        state.pickups[pickupIndexSelected].contact = pickupContact;
      }
    },

    setNotesForPickup (state, action) {
      const { notes, pickupIndexSelected } = action.payload;

      if (notes && typeof pickupIndexSelected === 'number') {
        state.pickups[pickupIndexSelected].notes = notes;
      }
    },

    setAddressForDropoff (state, action) {
      const { address, dropoffIndexSelected } = action.payload;

      if (address && typeof dropoffIndexSelected === 'number') {
        state.dropoffs[dropoffIndexSelected].address = address;
        state.dropoffs[dropoffIndexSelected].details = {};
        state.pickups.forEach((item) => {
          item.details = {};
        });
        state.hasReturn = false;
      }
    },

    setContactForDropoff (state, action) {
      const { contact, modalDropoffContact, dropoffIndexSelected } = action.payload;
      const dropoffContact = modalDropoffContact || contact;

      if (dropoffContact.name && dropoffContact.countryCallingCode && dropoffContact.phone && typeof dropoffIndexSelected === 'number') {
        state.dropoffs[dropoffIndexSelected].contact = dropoffContact;
      }
    },

    setNotesForDropoff (state, action) {
      const { notes, dropoffIndexSelected } = action.payload;

      if (notes && typeof dropoffIndexSelected === 'number') {
        state.dropoffs[dropoffIndexSelected].notes = notes;
      }
    },

    setAddressForStop (state, action) {
      const { address, stopIndexSelected } = action.payload;

      if (address && typeof stopIndexSelected === 'number') {
        state.stops[stopIndexSelected].address = address;
      }
    },

    setContactForStop (state, action) {
      const { contact, modalStopContact, stopIndexSelected } = action.payload;
      const stopContact = modalStopContact || contact;

      if (stopContact.name && stopContact.countryCallingCode && stopContact.phone && typeof stopIndexSelected === 'number') {
        state.dropoffs[stopIndexSelected].contact = stopContact;
      }
    },

    setNotesForStop (state, action) {
      const { notes, stopIndexSelected } = action.payload;

      if (notes && typeof stopIndexSelected === 'number') {
        state.stops[stopIndexSelected].notes = notes;
      }
    },

    setDeliveryDropoffs (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const dropoffs = action.payload;

      if (dropoffs) {
        state.dropoffs = dropoffs;
        SessionStorageManager.setDeliveryDropoffs(dropoffs);
      }
    },

    setDeliveryHasReturn (state, action) {
      const { hasReturn, shipmentsItems } = action.payload;

      if (shipmentsItems[0]) {
        state.shipments.push(shipmentsItems[0]);
        state.pickups[state.pickups.length - 1].details = state.dropoffs[0].details;
      } else {
        state.shipments.push([]);
      }

      if (hasReturn === false && hasReturn !== state.hasReturn) {
        state.shipments.splice(0, state.shipments.length - 1);
      }

      state.hasReturn = hasReturn;

      SessionStorageManager.setDeliveryHasReturn(hasReturn);
      SessionStorageManager.setDeliveryShipments(state.shipments);
    },

    setDeliveryPickups (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const pickups = action.payload;

      if (pickups) {
        state.pickups = pickups;
        SessionStorageManager.setDeliveryPickups(pickups);
      }
    },

    updateDeliveryShipmentItem (state, action) {
      const {
        shipmentEditIndex: shipmentItemIndex,
        shipmentIndexSelected: shipmentIndex,
        selectedShipmentOption: shipmentItemInfo,
        selectedShipmentSpecifications: shipmentItemSpecifications
      } = action.payload;

      if (typeof shipmentIndex === 'number' && typeof shipmentItemIndex === 'number' && shipmentItemInfo && shipmentItemSpecifications) {
        const shipmentItems = state.shipments[shipmentIndex];

        if (shipmentItems) {
          shipmentItems[shipmentItemIndex].info = shipmentItemInfo;
          shipmentItems[shipmentItemIndex].id = `${shipmentItemInfo.serviceCode}-${shipmentItemInfo.name}`;
          shipmentItems[shipmentItemIndex].specifications = shipmentItemSpecifications;

          SessionStorageManager.setDeliveryShipments(state.shipments);
        }
      }
    },

    setDeliveryStops (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const stops = action.payload;

      if (stops) {
        state.stops = stops;
        SessionStorageManager.setDeliveryStops(stops);
      }
    },

    setDestinationScheduleDate (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, destinationSchedule } = state;
      const dropoffDate = action.payload;

      if (dropoffDate) {
        if (deliveryQuote) {
          const { scheduleDropoffIntervals } = deliveryQuote;
          const scheduleInterval = scheduleDropoffIntervals.find(entry => entry.dropoffDate === dropoffDate);

          if (scheduleInterval) {
            const { dropoffTimeSlots } = scheduleInterval;

            // Try to keep the dropoffTimeSlotValue and fallback to the value of the first available timeSlot
            const dropoffTimeSlotValue = destinationSchedule && dropoffTimeSlots.map(({ value }) => value).includes(destinationSchedule.dropoffTimeSlotValue) ?
              destinationSchedule.dropoffTimeSlotValue :
              dropoffTimeSlots[0]?.value || null;

            const newDestinationSchedule = {
              dropoffDate,
              dropoffTimeSlotValue
            };

            state.destinationSchedule = newDestinationSchedule;

            SessionStorageManager.setDeliveryDestinationSchedule(newDestinationSchedule);
          } else if (destinationSchedule) {
            state.destinationSchedule.dropoffDate = null;

            SessionStorageManager.setDeliveryDestinationSchedule({
              dropoffDate: null,
              dropoffTimeSlotValue: destinationSchedule.dropoffTimeSlotValue
            });
          }
        }

        // Reset delivery when the schedule date is changed
        state.delivery = null;
        state.deliveryError = null;
      }
    },

    setDestinationScheduleTimeSlotValue (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, destinationSchedule } = state;
      const dropoffTimeSlotValue = action.payload;

      if (dropoffTimeSlotValue && destinationSchedule?.dropoffDate) {
        const { scheduleDropoffIntervals } = deliveryQuote;
        const { dropoffDate } = destinationSchedule;
        const scheduleInterval = scheduleDropoffIntervals.find(entry => entry.dropoffDate === dropoffDate);

        if (scheduleInterval) {
          const { dropoffTimeSlots } = scheduleInterval;

          if (dropoffTimeSlots.map(({ value }) => value).includes(dropoffTimeSlotValue)) {
            state.destinationSchedule.dropoffTimeSlotValue = dropoffTimeSlotValue;

            SessionStorageManager.setDeliveryDestinationSchedule({
              dropoffDate: destinationSchedule.dropoffDate,
              dropoffTimeSlotValue
            });

            // Reset delivery when the schedule time slot is changed
            state.delivery = null;
            state.deliveryError = null;
          }
        }
      }
    },

    setOriginScheduleDate (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, originSchedule } = state;
      const pickupDate = action.payload;

      if (pickupDate) {
        if (deliveryQuote) {
          const { schedulePickupIntervals } = deliveryQuote;
          const scheduleInterval = schedulePickupIntervals.find(entry => entry.pickupDate === pickupDate);

          if (scheduleInterval) {
            const { pickupTimeSlots } = scheduleInterval;

            // Try to keep the pickupTimeSlotValue and fallback to the value of the first available timeSlot
            const pickupTimeSlotValue = originSchedule && pickupTimeSlots.map(({ value }) => value).includes(originSchedule.pickupTimeSlotValue) ?
              originSchedule.pickupTimeSlotValue :
              pickupTimeSlots[0]?.value || null;

            const newOriginSchedule = {
              pickupDate,
              pickupTimeSlotValue
            };

            state.originSchedule = newOriginSchedule;

            SessionStorageManager.setDeliveryOriginSchedule(newOriginSchedule);
          }
        } else {
          state.originSchedule.pickupDate = pickupDate;

          SessionStorageManager.setDeliveryOriginSchedule({
            pickupDate,
            pickupTimeSlotValue: originSchedule.pickupTimeSlotValue
          });
        }

        // Reset delivery when the schedule date is changed
        state.delivery = null;
        state.deliveryError = null;
      }
    },

    setOriginScheduleTimeSlotValue (state, action) {
      // A getDeliveryQuote() should be dispatched when dispatching this reducer

      const { deliveryQuote, originSchedule } = state;
      const pickupTimeSlotValue = action.payload;

      if (pickupTimeSlotValue && originSchedule?.pickupDate) {
        const { schedulePickupIntervals } = deliveryQuote;
        const { pickupDate } = originSchedule;
        const scheduleInterval = schedulePickupIntervals.find(entry => entry.pickupDate === pickupDate);

        if (scheduleInterval) {
          const { pickupTimeSlots } = scheduleInterval;

          if (pickupTimeSlots.map(({ value }) => value).includes(pickupTimeSlotValue)) {
            state.originSchedule.pickupTimeSlotValue = pickupTimeSlotValue;

            SessionStorageManager.setDeliveryOriginSchedule({
              pickupDate: originSchedule.pickupDate,
              pickupTimeSlotValue
            });

            // Reset delivery when the schedule time slot is changed
            state.delivery = null;
            state.deliveryError = null;
          }
        }
      }
    },

    setPaymentTypeId (state, action) {
      const { deliveryQuote, paymentTypeId: paymentTypeIdFromState } = state;
      const paymentTypeId = action.payload;

      if (paymentTypeId && deliveryQuote && paymentTypeId !== paymentTypeIdFromState) {
        const { paymentTypes } = deliveryQuote;
        const paymentTypeIdIsValid = paymentTypes.findIndex(paymentType => paymentType.id === paymentTypeId) !== -1;

        if (paymentTypeIdIsValid) {
          // Reset delivery when the payment type is changed
          state.delivery = null;
          state.deliveryError = null;

          state.paymentTypeId = paymentTypeId;

          SessionStorageManager.setDeliveryPaymentTypeId(paymentTypeId);
        }
      }
    },

    setServiceCode (state, action) {
      const serviceCode = action.payload;

      if (serviceCode && serviceCode !== state.serviceCode) {
        state.serviceCode = serviceCode;

        SessionStorageManager.removeDeliveryShipments();
        if (serviceCode) SessionStorageManager.setDeliveryServiceCode(serviceCode);
        else SessionStorageManager.removeDeliveryServiceCode(serviceCode);
      }
    },

    setShipmentOptions (state, action) {
      const options = action.payload;

      if (options) {
        state.shipmentOptions = options;
      }
    },

    setShipmentForDropoff (state, action) {
      const { values, index } = action.payload;

      if (values && typeof index === 'number') {
        state.dropoffs[index].details = values;
      }
    },

    setShipmentForPickup (state, action) {
      const { values, index } = action.payload;

      if (values && typeof index === 'number') {
        state.pickups[index].details = values;
      }
    },

    setShipmentForStop (state, action) {
      const { values, index } = action.payload;

      if (values && typeof index === 'number') {
        state.stops[index].details = values;
      }
    },

    setShipType (state, action) {
      const type = action.payload;

      if (type) {
        state.shipType = type;

        SessionStorageManager.setDeliveryShipType(type);
      }
    },

    setVoucherCode (state, action) {
      const voucherCode = action.payload;

      if (typeof voucherCode === 'string') state.voucherCode = voucherCode;
    },

    startPaymentProcess (state) {
      state.paymentIsInProcess = true;
    },

    stopPaymentProcess (state) {
      state.paymentIsInProcess = false;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(createDelivery.pending, (state) => {
        state.deliveryError = null;
        state.deliveryIsLoading = true;
      })
      .addCase(createDelivery.fulfilled, (state, action) => {
        const delivery = action.payload;

        if (delivery) state.delivery = delivery;

        state.deliveryIsLoading = false;
      })
      .addCase(createDelivery.rejected, (state) => {
        state.deliveryIsLoading = false;
      })
      .addCase(getDeliveryQuote.pending, (state) => {
        state.deliveryQuoteError = null;
        state.deliveryQuoteIsLoading = true;

        // Reset delivery when a new delivery quote is requested
        state.delivery = null;
        state.deliveryError = null;
      })
      .addCase(getDeliveryQuote.fulfilled, (state, action) => {
        const { destinationSchedule: currentDestinationSchedule } = state;
        const { originSchedule: currentOriginSchedule } = state;
        const quote = action.payload;

        if (quote) {
          const { destinationSchedule: quoteDestinationSchedule, scheduleDropoffIntervals } = quote;
          const { originSchedule: quoteOriginSchedule, schedulePickupIntervals } = quote;

          const destinationSchedule = quoteDestinationSchedule || currentDestinationSchedule;
          const originSchedule = quoteOriginSchedule || currentOriginSchedule;

          // Use the current destinationSchedule if possible and fallback to the first values present as fallback
          const scheduleDropoffInterval = scheduleDropoffIntervals.find(({ dropoffDate }) => dropoffDate === destinationSchedule.dropoffDate) ||
          scheduleDropoffIntervals[0];
          const { dropoffTimeSlots } = scheduleDropoffInterval;
          const dropoffTimeSlot = dropoffTimeSlots.find(({ value }) => value === destinationSchedule.dropoffTimeSlotValue) || dropoffTimeSlots[0];

          const newDestinationSchedule = {
            dropoffDate: scheduleDropoffInterval.dropoffDate,
            dropoffTimeSlotValue: dropoffTimeSlot.value
          };

          // Use the current originSchedule if possible and fallback to the first values present as fallback
          const schedulePickupInterval = schedulePickupIntervals.find(({ pickupDate }) => pickupDate === originSchedule.pickupDate) ||
          schedulePickupIntervals[0];
          const { pickupTimeSlots } = schedulePickupInterval;
          const pickupTimeSlot = pickupTimeSlots.find(({ value }) => value === originSchedule.pickupTimeSlotValue) || pickupTimeSlots[0];

          const newOriginSchedule = {
            pickupDate: schedulePickupInterval.pickupDate,
            pickupTimeSlotValue: pickupTimeSlot.value
          };

          state.deliveryQuote = quote;
          state.destinationSchedule = newDestinationSchedule;
          state.originSchedule = newOriginSchedule;

          SessionStorageManager.setDeliveryDestinationSchedule(newDestinationSchedule);
          SessionStorageManager.setDeliveryOriginSchedule(newOriginSchedule);
        }

        state.deliveryQuoteIsLoading = false;
      })
      .addCase(getDeliveryQuote.rejected, (state, action) => {
        state.deliveryQuote = null;
        state.deliveryQuoteError = action?.payload || 'Something went wrong while requesting a quote for your delivery.';
        state.deliveryQuoteIsLoading = false;
      })
      .addCase(getDeliveryEstimate.pending, (state) => {
        state.deliveryQuoteError = null;
        state.deliveryQuoteIsLoading = true;

        // Reset delivery when a new delivery quote is requested
        state.delivery = null;
        state.deliveryError = null;
      })
      .addCase(getDeliveryEstimate.fulfilled, (state, action) => {
        const { distance } = action.payload?.resume;
        const { duration } = action.payload?.resume;
        const { rates } = action.payload;

        state.deliveryDistance = distance;
        state.deliveryDuration = duration;
        state.autoAddShipmentItems = action.payload?.autoAddShipments;
        state.rates = rates;

        state.deliveryQuoteIsLoading = false;
      })
      .addCase(getDeliveryEstimate.rejected, (state, action) => {
        state.deliveryQuote = null;
        state.deliveryQuoteError = action?.payload || 'Something went wrong while requesting an estimate for your delivery.';
        state.deliveryQuoteIsLoading = false;
      })
      .addCase(setDefaultPickup.fulfilled, (state, action) => {
        const pickup = action.payload;

        if (pickup) {
          state.pickups[0] = pickup;
        }
      });
  }
});

export default newDeliverySlice.reducer;

// Actions

export const {
  addDeliveryDropoff,
  addDeliveryPickup,
  addDeliveryShipment,
  addDeliveryShipmentItems,
  addDeliveryStop,
  addDeliveryStopShipment,
  addVoucherCodeToDeliveryQuote,
  initialize,
  removeDeliveryDropoff,
  removeDeliveryPickup,
  removeDeliveryShipment,
  removeDeliveryShipmentItems,
  removeDeliveryStop,
  resetDelivery,
  setAddressForDropoff,
  setAddressForPickup,
  setAddressForStop,
  setContactForDropoff,
  setContactForPickup,
  setContactForStop,
  setDeliveryDropoffs,
  setDeliveryHasReturn,
  setDeliveryPickups,
  setDeliveryShipments,
  setDeliveryShipType,
  setDeliveryStops,
  setDestinationScheduleDate,
  setDestinationScheduleTimeSlotValue,
  setNotesForDropoff,
  setNotesForPickup,
  setNotesForStop,
  setOriginScheduleDate,
  setOriginScheduleTimeSlotValue,
  setPaymentTypeId,
  setPickupNotes,
  setReturnShipmentDetails,
  setServiceCode,
  setShipmentForDropoff,
  setShipmentForPickup,
  setShipmentForStop,
  setShipmentOptions,
  setShipType,
  setVoucherCode,
  startPaymentProcess,
  stopPaymentProcess,
  updateDeliveryShipmentItem
} = newDeliverySlice.actions;

// Selectors:

export const {
  selectAll: selectDeliveryItems,
  selectById: selectDeliveryItemsById
} = newDeliveryAdapter.getSelectors(state => state.newDelivery);

export const selectAutoAddShipmentItems = state => state.newDelivery.autoAddShipmentItems;

export const selectDeliveryDistance = state => state.newDelivery.deliveryDistance;

export const selectDeliveryDuration = state => state.newDelivery.deliveryDuration;

export const selectDeliveryQuote = state => state.newDelivery.deliveryQuote;

export const selectDeliveryQuoteError = state => state.newDelivery.deliveryQuoteError;

export const selectDeliveryQuoteIsLoading = state => state.newDelivery.deliveryQuoteIsLoading;

export const selectDeliveryQuoteVoucherCode = state => state.newDelivery.deliveryQuoteVoucherCode;

export const selectDestinationSchedule = state => state.newDelivery.destinationSchedule;

export const selectDelivery = state => state.newDelivery.Delivery;

export const selectDeliveryError = state => state.newDelivery.deliveryError;

export const selectDeliveryIsLoading = state => state.newDelivery.deliveryIsLoading;

export const selectDropoffs = state => state.newDelivery.dropoffs;

export const selectHasReturn = state => state.newDelivery.hasReturn;

export const selectOriginSchedule = state => state.newDelivery.originSchedule;

export const selectPaymentIsInProcess = state => state.newDelivery.paymentIsInProcess;

export const selectPaymentTypeId = state => state.newDelivery.paymentTypeId;

export const selectPickupNotes = state => state.newDelivery.pickupNotes;

export const selectPickups = state => state.newDelivery.pickups;

export const selectReturnShipment = state => state.newDelivery.returnShipment;

export const selectServiceCode = state => state.newDelivery.serviceCode;

export const selectShipmentOptions = state => state.newDelivery.shipmentOptions;

export const selectShipments = state => state.newDelivery.shipments;

export const selectRates = state => state.newDelivery.rates;

export const selectShipType = state => state.newDelivery.shipType;

export const selectStops = state => state.newDelivery.stops;

export const selectVoucherCode = state => state.newDelivery.voucherCode;

export const selectVoucherIsValid = state => state.newDelivery.voucherCode && typeof state.newDelivery.voucherCode === 'string';
