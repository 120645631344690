import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import ConfirmationCode from '@youship/components/objects/confirmation-code';

import { activatePhone, getUser } from 'store/slices/authentication';
import { fetchNotifications } from 'store/slices/notifications';

const CONFIRMATION_CODE_LENGTH = 4;

const ConfirmationCodeStep = forwardRef((props, ref) => {
  const { classNames, formClassName, onError, onSubmitting, onSuccess, onValidation } = props;
  const form = useRef();

  useImperativeHandle(ref, () => ({
    submitForm () {
      handleSubmit(handleFormSubmit)();
    }
  }));

  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [failed, setFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Sorry, we were unable to process your request at this time. Please try again later.');

  const handleFormSubmit = (data) => {
    setIsSubmitting(true);
    onSubmitting();

    const activatePhoneData = {
      activationCode: confirmationCode
    };

    dispatch(activatePhone(activatePhoneData))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while creating your account.');

        return response;
      })
      .then(async (response) => {
        await Promise.all([dispatch(getUser()), dispatch(fetchNotifications())]);

        onSuccess();
        setIsSubmitting(false);

        return response;
      })
      .catch((error) => {
        setFailed(true);
        setIsSubmitting(false);
        onError(error);
        setErrorMessage(error.message);
      });
  };

  const handleConfirmationCodeChange = (codeCharacters) => {
    const code = codeCharacters.filter(character => !!character);

    setConfirmationCode(code.join(''));
  };

  const isCodeValid = confirmationCode && confirmationCode.length === CONFIRMATION_CODE_LENGTH;
  const isButtonDisabled = !isCodeValid || isSubmitting;

  useEffect(() => {
    onValidation(!isButtonDisabled);
  }, [isButtonDisabled, onValidation]);

  return (
    <form
      ref={form}
      className={`${formClassName}__step${classNames ? ` ${classNames}` : ''}`}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <ConfirmationCode
        label="register.form.confirmation_code.label"
        length={CONFIRMATION_CODE_LENGTH}
        onChange={handleConfirmationCodeChange}
      />
      {failed && (
        <div className={`${formClassName}__error`}>
          {errorMessage}
        </div>
      )}
    </form>
  );
});

ConfirmationCodeStep.propTypes = {
  classNames: PropTypes.string,
  formClassName: PropTypes.string,
  onError: PropTypes.func,
  onSubmitting: PropTypes.func,
  onSuccess: PropTypes.func,
  onValidation: PropTypes.func
};

ConfirmationCodeStep.defaultProps = {
  classNames: null,
  formClassName: 'register-wizard',
  onError: () => {},
  onSubmitting: () => {},
  onSuccess: () => {},
  onValidation: () => {}
};

export default ConfirmationCodeStep;
