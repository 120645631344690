import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  DASHBOARD_SECTION_TYPE_CATEGORIES,
  DASHBOARD_SECTION_TYPE_HIGHLIGHTS,
  DASHBOARD_SECTION_TYPE_SHOPS
} from '@youship/api/shopping';

import Button from 'components/objects/button';
import HighlightsSlider from 'components/highlights-slider';
import OrderTypesSlider from 'components/sections/order-types-slider';
import ShopsSlider from 'components/sections/shops-slider';

import './styles.scss';

const DashboardSection = ({
  [DASHBOARD_SECTION_TYPE_CATEGORIES]: categories,
  [DASHBOARD_SECTION_TYPE_HIGHLIGHTS]: highlights,
  [DASHBOARD_SECTION_TYPE_SHOPS]: shops,
  searchTerm,
  title
}) => {
  if ((!Array.isArray(categories) || !categories.length) && (!Array.isArray(highlights) || !highlights.length) && (!Array.isArray(shops) || !shops.length)) {
    return null;
  }

  const heading = title ? (
    <div className="dashboard-section__title">
      {title}
    </div>
  ) : null;

  let header = heading;

  const lists = [];

  if (Array.isArray(highlights) && highlights.length) {
    const filteredHighlights = searchTerm ?
      highlights.filter(highlight => (
        highlight.name && highlight.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      (
        highlight.description && highlight.description.toLowerCase().includes(searchTerm.toLowerCase())
      )) :
      highlights;

    lists.push((
      <HighlightsSlider
        key={DASHBOARD_SECTION_TYPE_HIGHLIGHTS}
        items={filteredHighlights}
      />
    ));
  }

  if (Array.isArray(categories) && categories.length) {
    if (title) {
      header = (
        <div className="row">
          <div className="col col-12 col-md-6 col-xl-4">
            {heading}
          </div>
          <div className="col col-12 col-md-6 col-xl-8 d-flex justify-content-md-end align-items-md-end">
            <Button
              context="primary"
              noArrow
              noBackground
              noPadding
              noShadow
              small
              to="/categories"
            >
              <FormattedMessage id="dashboard.all_categories" />
            </Button>
          </div>
        </div>
      );
    }

    const filteredCategories = searchTerm ?
      categories.filter(category => (
        category.name && category.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      (
        category.description && category.description.toLowerCase().includes(searchTerm.toLowerCase())
      )) :
      categories;

    lists.push((
      <OrderTypesSlider
        key={DASHBOARD_SECTION_TYPE_CATEGORIES}
        items={filteredCategories}
      />
    ));
  }

  if (Array.isArray(shops) && shops.length) {
    const filteredShops = searchTerm ?
      shops.filter(shop => (
        shop.name && shop.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      (
        shop.subtitle && shop.subtitle.toLowerCase().includes(searchTerm.toLowerCase())
      )) :
      shops;

    lists.push((
      <ShopsSlider
        key={DASHBOARD_SECTION_TYPE_SHOPS}
        items={filteredShops}
      />
    ));
  }

  return (
    <div className="dashboard-section">
      <div className="container">
        {header}
        {lists}
      </div>
    </div>
  );
};

DashboardSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  searchTerm: PropTypes.string,
  title: PropTypes.string
};

DashboardSection.defaultProps = {
  items: null,
  searchTerm: null,
  title: null
};

export default DashboardSection;
