import BaseApi from '..';
import {
  MESSAGE_ADD,
  MESSAGE_DETAILS,
  MESSAGE_LIST,
  MESSAGE_REPLY
} from '../endpoints';

export default class MessageApi extends BaseApi {
  /* eslint-disable camelcase */

  static newMessage = ({
    order_code,
    msg
  }) => this.post(MESSAGE_ADD, {
    order_code,
    msg
  });

  static reply = ({
    message_code,
    msg
  }) => this.post(MESSAGE_REPLY, {
    message_code,
    msg
  });

  static messageDetails = ({
    message_code
  }) => this.post(MESSAGE_DETAILS, {
    message_code
  });

  static listMessages = ({
    order_code,
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(MESSAGE_LIST, {
    order_code,
    pages
  });

  /* eslint-enable camelcase */
}
