import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@youship/components/objects/button';

import closeIcon from '../../../assets/images/icons/close.svg';

import './styles.scss';

const Notice = ({ classNames, description, fixed, icon, links, linkText, linkUrl, noTopMargin, noBottomMargin, title, onButtonClick }) => {
  const [show, setShow] = useState(true);

  const handleCloseIconClick = () => {
    setTimeout(() => {
      setShow(false);
    }, 0);
  };

  if (!show) return null;

  return (
    <div className={`notice${noTopMargin ? ' notice--no-top-margin' : ''}${noBottomMargin ? ' notice--no-bottom-margin' : ''} ${classNames ? classNames : ''}`}>
      <div className="notice__content">
        <img
          alt="Notice Icon"
          className="notice__icon"
          src={icon}
        />
        <div className="notice__text">
          {!!title && (
            <div className="notice__title">
              {title}
            </div>
          )}
          <div className="notice__description">
            {description}
          </div>
          {!!linkText && !!linkUrl && (
            <Link
              className="notice__link"
              to={linkUrl}
            >
              {linkText}
            </Link>
          )}
          <div className="d-flex">
            {Array.isArray(links) && links.map((link, index) => (
              <>
                {link.isButton && (
                  <Button
                    classNames="notice__link"
                    context="primary"
                    noBackground
                    noPadding
                    noShadow
                    text={link.text}
                    onClick={onButtonClick}
                  />
                )}
                {link.url && (
                  <a
                    key={index}
                    className="notice__link"
                    href={link.url}
                  >
                    {link.text}
                  </a>
                )}
              </>
            ))}
          </div>
        </div>
        {!fixed && (
          <img
            alt="Close Icon"
            className="notice__close-icon"
            src={closeIcon}
            onClick={handleCloseIconClick}
          />
        )}
      </div>
    </div>
  );
};

Notice.propTypes = {
  description: PropTypes.string,
  fixed: PropTypes.bool,
  icon: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  })),
  noBottomMargin: PropTypes.bool,
  noTopMargin: PropTypes.bool,
  onButtonClick: PropTypes.func,
  title: PropTypes.string
};

Notice.defaultProps = {
  description: '',
  fixed: false,
  icon: '',
  linkText: null,
  linkUrl: null,
  links: null,
  noBottomMargin: false,
  noTopMargin: false,
  onButtonClick: () => {},
  title: null
};

export default Notice;
