import React from 'react';

import LoginArea from 'components/sections/login-area';

const Login = () => (
  <>
    <LoginArea />
  </>
);

export default Login;
