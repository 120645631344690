import React from 'react';
import PropTypes from 'prop-types';

import Button from '../objects/button';
import Modal from '../objects/modal';

import infoIcon from '../../assets/images/icons/info.svg';

import './styles.scss';

const ConfirmationModal = ({ description, onClose, onConfirm, title }) => (
  <Modal
    classNames="confirmation-modal"
    footer={
      <div className="confirmation-modal__footer">
        <div className="confirmation-modal__footer-button-wrapper">
          <Button
            context="primary"
            text="Cancel"
            largeHorizontalPadding
            outline
            onClick={onClose}
          />
        </div>
        <div className="confirmation-modal__footer-button-wrapper">
          <Button
            context="primary"
            text="Continue"
            largeHorizontalPadding
            onClick={onConfirm}
          />
        </div>
      </div>
    }
    onClose={onClose}
  >
    <div className="confirmation-modal__body">
      <img
        className="confirmation-modal__icon"
        src={infoIcon}
        alt="Information Icon"
      />
      <div className="confirmation-modal__text">
        <div className="confirmation-modal__title">
          {title}
        </div>
        <div className="confirmation-modal__description">
          {description}
        </div>
      </div>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  description: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string
};

ConfirmationModal.defaultProps = {
  description: 'Do you want to proceed?',
  onClose: () => {},
  onConfirm: () => {},
  title: 'Warning'
};

export default ConfirmationModal;
