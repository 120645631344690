import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

import AuthenticationApi from '@youship/api/authentication';
import ShopApi from '@youship/api/shop';
import TransporterApi from '@youship/api/transporter';
import UserApi, { USER_TYPE_SHOP, USER_TYPE_TRANSPORTER } from '@youship/api/user';
import UploadApi from '@youship/api/upload';

import SessionStorageManager from '@youship/utils/SessionStorageManager';

const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;

const cookies = new Cookies();

const initialState = {
  authenticationToken: null,
  isLoadingUser: false,
  isLoggingIn: false,
  isSigningUp: false,
  isSigningUpAsTransporter: false,
  isSigningUpAsShop: false,
  isUpdatingUser: false,
  initialized: false,
  redirectionUrl: null,
  requirePhoneActivation: null,
  user: null
};

const formatUserResume = (user) => {
  if (!user) return null;

  const userData = {
    ...user,
    accountUsers: user.account_users?.map(item => ({
      ...item,
      id: item.user_code
    })),
    code: user.user_code,
    country: user.country ?
      {
        ...user.country,
        id: user.country.idcountry
      } :
      {},
    firstName: user.firstname,
    lastName: user.lastname,
    memberSince: user.membersince,
    name: user.name,
    options: user.options ?
      {
        ...user.options,
        menu: user.options.menu ?
          {
            ...user.options.menu,
            homeMap: user.options.menu.home_map,
            homePage: user.options.menu.home_page
          } :
          {}
      } :
      {},
    phone: user.phone ?
      {
        ...user.phone,
        code: user.phone.phonecode,
        countryId: user.phone.idcountry,
        number: user.phone.phone
      } :
      {},
    phoneNumber: user.phonenumber,
    photoUrl: user.photo_url,
    type: user.user_type
  };

  const defaultAddress = user.default_address?.address;
  const defaultAddressCode = user.default_address?.address_code;

  if (defaultAddress) {
    const {
      apartment_address: apartmentAddress,
      countrycode: countryCode,
      formatted_address: formattedAddress,
      number_address: numberAddress,
      postalcode: postalCode,
      street_address: streetAddress
    } = defaultAddress;

    if (apartmentAddress) defaultAddress.apartmentAddress = apartmentAddress;
    else defaultAddress.apartmentAddress = '';
    if (countryCode) defaultAddress.countryCode = countryCode;
    else defaultAddress.countryCode = '';
    if (formattedAddress) defaultAddress.formattedAddress = formattedAddress;
    else defaultAddress.formattedAddress = '';
    if (numberAddress) defaultAddress.numberAddress = numberAddress;
    else defaultAddress.numberAddress = '';
    if (postalCode) defaultAddress.postalCode = postalCode;
    else defaultAddress.postalCode = '';
    if (streetAddress) defaultAddress.streetAddress = streetAddress;
    else defaultAddress.streetAddress = '';
    if (defaultAddressCode) defaultAddress.addressCode = defaultAddressCode;
    else defaultAddress.addressCode = '';

    userData.defaultAddress = {
      ...defaultAddress,
      contact: {
        ...user.default_address.contact,
        company: user.default_address.contact.company
      }
    };
  }

  return userData;
};

// Thunks:

export const activatePhone = createAsyncThunk(
  'authentication/activatePhone',
  ({ activationCode }) => AuthenticationApi.activatePhone({ activationcode: activationCode })
    .then((response) => {
      let payload = null;

      if (response) {
        const { appkey, user } = response;

        if (appkey || user) {
          payload = {};

          if (appkey) payload.authenticationToken = appkey;

          if (user) {
            payload.user = {
              ...user,
              code: user.user_code,
              country: user.country ?
                {
                  ...user.country,
                  id: user.country.idcountry
                } :
                {},
              firstName: user.firstname,
              lastName: user.lastname,
              memberSince: user.memberSince,
              phone: user.phone ?
                {
                  ...user.phone,
                  code: user.phone.phonecode,
                  countryId: user.phone.idcountry,
                  number: user.phone.phone
                } :
                {},
              phoneNumber: user.phonenumber,
              type: user.user_type
            };
          }
        }
      }

      return payload;
    })
);

export const cancelTransporterRegistration = createAsyncThunk(
  'authentication/cancelTransporterRegistration',
  () => TransporterApi.cancelSignUpRequest()
);

export const cancelShopRegistration = createAsyncThunk(
  'authentication/cancelShopRegistration',
  () => ShopApi.cancelSignUpRequest()
);

export const getUser = createAsyncThunk(
  'authentication/getUser',
  () => UserApi.resume()
    .then((response) => {
      if (response) {
        const { user } = response;

        if (user) return formatUserResume(user);
      }

      return response;
    })
);

export const login = createAsyncThunk(
  'authentication/login',
  ({ email, password }) => AuthenticationApi.login({ email, password })
    .then((response) => {
      let payload = null;

      if (response) {
        const { activatephone, appkey, user } = response;

        payload = {
          requirePhoneActivation: activatephone ?? initialState.requirePhoneActivation
        };

        if (appkey) payload.authenticationToken = appkey;
        if (user) payload.user = formatUserResume(user);
      }

      return payload;
    })
);

export const loginFromSession = createAsyncThunk(
  'authentication/loginFromSession',
  token => AuthenticationApi.loginFromSession(token)
    .then((response) => {
      let payload = null;

      if (response) {
        const { activatephone, appkey, user } = response;

        payload = {
          requirePhoneActivation: activatephone ?? initialState.requirePhoneActivation
        };

        if (appkey) payload.authenticationToken = appkey;
        if (user) payload.user = formatUserResume(user);
      }

      return payload;
    })
);

// logout as an async action so that .then() can be used on it
export const logout = createAsyncThunk('authentication/logout', async () => true);

export const removePhoto = createAsyncThunk('authentication/removePhoto', () => UserApi.removePhoto());

export const requestRecoverToken = createAsyncThunk(
  'authentication/requestRecoverToken',
  ({ email }) => AuthenticationApi.requestRecoverToken({ email })
);

export const signUp = createAsyncThunk(
  'authentication/signUp',
  ({
    email,
    firstName,
    lastName,
    password,
    company,
    phone,
    phoneCode,
    countryCode
  }) => AuthenticationApi.signUp({
    email,
    firstname: firstName,
    lastname: lastName,
    password,
    company,
    phonenumber: phone,
    phonecode: phoneCode,
    countrycode: countryCode
  })
    .then((response) => {
      if (response) {
        const { activatephone, appkey, user } = response;

        return {
          authenticationToken: appkey ?? null,
          requirePhoneActivation: activatephone ?? null,
          user: formatUserResume(user) ?? null
        };
      }

      return null;
    })
);

export const signUpAsTransporter = createAsyncThunk(
  'authentication/signUpAsTransporter',
  ({
    about,
    address,
    bankAccountIban,
    bankAccountName,
    cargoSpace,
    city,
    companyCode,
    companyName,
    driverLicenseNumber,
    insuranceCoverage,
    insuranceName,
    insuranceNumber,
    insuranceType,
    postalCode,
    professionalStatus,
    schedule,
    transportLocation,
    transportPermit,
    vat,
    vehicleType
  }) => {
    /* eslint-disable camelcase */
    const data = {
      about,
      address,
      availability: schedule,
      bank_account: bankAccountIban,
      bank_name: bankAccountName,
      charter: transportPermit,
      city,
      company_name: companyName,
      companycode: companyCode,
      coverage: insuranceCoverage,
      drivinglicense: driverLicenseNumber,
      insurance: insuranceName,
      insurancetype: insuranceType,
      maincity: transportLocation,
      policy: insuranceNumber,
      postalcode: postalCode,
      vat,
      vehiclecapacity: cargoSpace,
      vehicletype: vehicleType,
      worktype: professionalStatus
    };
    /* eslint-enable camelcase */

    return TransporterApi.signUpAsTransporter(data);
  }
);

export const signUpAsShop = createAsyncThunk(
  'authentication/signUpAsShop',
  ({
    about,
    address,
    bankAccountIban,
    bankAccountName,
    city,
    companyName,
    companyCode,
    schedule,
    shopName,
    shopDescription,
    postalCode,
    vat
  }) => ShopApi.signUpAsShop({
    /* eslint-disable camelcase */
    about,
    address,
    bank_account: bankAccountIban,
    bank_name: bankAccountName,
    business_name: companyName,
    description: shopDescription,
    city,
    company_name: shopName,
    companycode: companyCode,
    postalcode: postalCode,
    schedule,
    vat
    /* eslint-enable camelcase */
  })
);

export const updateUser = createAsyncThunk(
  'authentication/updateUser',
  ({
    company,
    countryCode,
    firstName,
    lastName,
    phoneCode,
    phoneNumber
  }) => {
    const data = {
      countrycode: countryCode,
      firstname: firstName,
      lastname: lastName,
      phonecode: phoneCode,
      phonenumber: phoneNumber
    };

    if (company) data.company = company;

    return UserApi.editProfile(data);
  }
);

export const updatePassword = createAsyncThunk(
  'authentication/updatePassword',
  ({
    oldPassword,
    newPassword
  }) => UserApi.changePassword({
    curpassword: oldPassword,
    password: newPassword
  })
);

export const updatePhoto = createAsyncThunk(
  'authentication/updatePhoto',
  ({
    image
  }) => UserApi.addPhoto({
    image
  })
);

export const uploadTransporterDocuments = createAsyncThunk(
  'authentication/uploadTransporterDocuments',
  fileForm => UploadApi.uploadTransporterDocuments(fileForm)
);

// Slice:

const authenticationSlice = createSlice({
  name: 'authentication',

  initialState,

  reducers: {
    initialize (state) {
      const isLoggedOut = false;

      if (isLoggedOut) {
        // TODO: Clear storage if logout was done in Marketing Website
      } else {
        const authenticationToken = SessionStorageManager.getAuthenticationToken() || cookies.get('AUTH_TOKEN');
        const requirePhoneActivation = SessionStorageManager.getAuthenticationRequirePhoneActivation() || cookies.get('USER_REQUIRES_PHONE_ACTIVATION');
        const user = SessionStorageManager.getUserProfile();

        if (authenticationToken) state.authenticationToken = authenticationToken;
        if (typeof requirePhoneActivation === 'boolean') state.requirePhoneActivation = requirePhoneActivation;
        if (user) state.user = user;
      }

      state.initialized = true;
    },

    resetRedirectionUrl (state) {
      state.redirectionUrl = initialState.redirectionUrl;
    },

    setRedirectionUrl (state, action) {
      if (action && action.payload) state.redirectionUrl = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(activatePhone.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(activatePhone.fulfilled, (state, action) => {
        if (action.payload) {
          const { authenticationToken, user } = action.payload;

          state.requirePhoneActivation = false;
          SessionStorageManager.setAuthenticationRequirePhoneActivation(false);
          cookies.set('USER_REQUIRES_PHONE_ACTIVATION', 'false', { path: '/', domain: COOKIES_DOMAIN });

          if (authenticationToken) {
            state.authenticationToken = authenticationToken;
            SessionStorageManager.setAuthenticationToken(authenticationToken);
          }

          if (user) {
            state.user = user;
            SessionStorageManager.setUserProfile(user);
          }
        }

        state.isLoggingIn = false;
      })
      .addCase(activatePhone.rejected, (state) => {
        state.isLoggingIn = false;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        if (action.payload) {
          const user = action.payload;

          state.user = user;
          SessionStorageManager.setUserProfile(user);
        }

        state.isLoadingUser = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoadingUser = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload) {
          const { authenticationToken, requirePhoneActivation, user } = action.payload;

          state.requirePhoneActivation = requirePhoneActivation ?? initialState.requirePhoneActivation;
          SessionStorageManager.setAuthenticationRequirePhoneActivation(requirePhoneActivation);

          const expirationDate = new Date();

          expirationDate.setDate(expirationDate.getDate() + 7);

          if (authenticationToken) {
            state.authenticationToken = authenticationToken;
            SessionStorageManager.setAuthenticationToken(authenticationToken);
            cookies.set('AUTH_TOKEN', authenticationToken, { path: '/', domain: COOKIES_DOMAIN });

            if (user) {
              const { email, firstName, photoUrl } = user;

              cookies.set('USER', { email, firstName, photoUrl }, { path: '/', domain: COOKIES_DOMAIN });
            }
          }

          cookies.set('USER_REQUIRES_PHONE_ACTIVATION', requirePhoneActivation ? 'true' : 'false', { path: '/', domain: COOKIES_DOMAIN });

          if (user) {
            state.user = user;
            SessionStorageManager.setUserProfile(user);
          }
        }

        state.isLoggingIn = false;
      })
      .addCase(login.rejected, (state) => {
        state.isLoggingIn = false;
      })
      .addCase(loginFromSession.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(loginFromSession.fulfilled, (state, action) => {
        if (action.payload) {
          const { authenticationToken, requirePhoneActivation, user } = action.payload;
          const { email, firstName, photoUrl } = user;

          state.requirePhoneActivation = requirePhoneActivation ?? initialState.requirePhoneActivation;
          SessionStorageManager.setAuthenticationRequirePhoneActivation(requirePhoneActivation);

          const expirationDate = new Date();

          expirationDate.setDate(expirationDate.getDate() + 7);

          if (authenticationToken) {
            state.authenticationToken = authenticationToken;
            SessionStorageManager.setAuthenticationToken(authenticationToken);
            cookies.set('AUTH_TOKEN', authenticationToken, { path: '/', domain: COOKIES_DOMAIN });
            cookies.set('USER', { email, firstName, photoUrl }, { path: '/', domain: COOKIES_DOMAIN });
          }

          cookies.set('USER_REQUIRES_PHONE_ACTIVATION', requirePhoneActivation ? 'true' : 'false', { path: '/', domain: COOKIES_DOMAIN });

          if (user) {
            state.user = user;
            SessionStorageManager.setUserProfile(user);
          }
        }

        state.isLoggingIn = false;
      })
      .addCase(loginFromSession.rejected, (state) => {
        state.isLoggingIn = false;
      })
      .addCase(logout.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.authenticationToken = initialState.authenticationToken;
        state.requirePhoneActivation = initialState.requirePhoneActivation;
        state.user = initialState.user;

        cookies.remove('AUTH_TOKEN', { path: '/', domain: COOKIES_DOMAIN });
        cookies.remove('USER', { path: '/', domain: COOKIES_DOMAIN });
        cookies.remove('USER_REQUIRES_PHONE_ACTIVATION', { path: '/', domain: COOKIES_DOMAIN });

        SessionStorageManager.removeAuthenticationToken();
        SessionStorageManager.removeAuthenticationRequirePhoneActivation();
        SessionStorageManager.removeUserProfile();

        state.isLoggingIn = false;
      })
      .addCase(logout.rejected, (state) => {
        state.isLoggingIn = false;
      })
      .addCase(requestRecoverToken.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(requestRecoverToken.fulfilled, (state) => {
        state.isLoggingIn = false;
      })
      .addCase(requestRecoverToken.rejected, (state) => {
        state.isLoggingIn = false;
      })
      .addCase(signUp.pending, (state) => {
        state.isSigningUp = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        if (action.payload) {
          const { authenticationToken, requirePhoneActivation, user } = action.payload;

          state.requirePhoneActivation = requirePhoneActivation ?? initialState.requirePhoneActivation;
          state.user = user ?? initialState.user;

          SessionStorageManager.setAuthenticationRequirePhoneActivation(requirePhoneActivation);

          if (authenticationToken) {
            state.authenticationToken = authenticationToken;
            SessionStorageManager.setAuthenticationToken(authenticationToken);
            cookies.set('AUTH_TOKEN', authenticationToken, { path: '/', domain: COOKIES_DOMAIN });
          }

          cookies.set('USER_REQUIRES_PHONE_ACTIVATION', requirePhoneActivation ? 'true' : 'false', { path: '/', domain: COOKIES_DOMAIN });

          if (user) {
            state.user = user;
            SessionStorageManager.setUserProfile(user);
          }
        }

        state.isSigningUp = false;
      })
      .addCase(signUp.rejected, (state) => {
        state.isSigningUp = false;
      })
      .addCase(signUpAsTransporter.pending, (state) => {
        state.isSigningUpAsTransporter = true;
      })
      .addCase(signUpAsTransporter.fulfilled, (state, action) => {
        state.isSigningUpAsTransporter = false;
      })
      .addCase(signUpAsTransporter.rejected, (state) => {
        state.isSigningUpAsTransporter = false;
      })
      .addCase(signUpAsShop.pending, (state) => {
        state.isSigningUpAsShop = true;
      })
      .addCase(signUpAsShop.fulfilled, (state, action) => {
        state.isSigningUpAsShop = false;
      })
      .addCase(signUpAsShop.rejected, (state) => {
        state.isSigningUpAsShop = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isUpdatingUser = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.isUpdatingUser = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isUpdatingUser = false;
      })
      .addCase(updatePassword.pending, (state) => {
        state.isUpdatingPassword = true;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.isUpdatingPassword = false;
      })
      .addCase(updatePassword.rejected, (state) => {
        state.isUpdatingPassword = false;
      })
      .addCase(uploadTransporterDocuments.pending, (state) => {
        state.isUploadingTransporterDocuments = true;
      })
      .addCase(uploadTransporterDocuments.fulfilled, (state) => {
        state.isUploadingTransporterDocuments = false;
      })
      .addCase(uploadTransporterDocuments.rejected, (state) => {
        state.isUploadingTransporterDocuments = false;
      });
  }
});

export default authenticationSlice.reducer;

// Actions

export const { initialize, resetRedirectionUrl, setRedirectionUrl } = authenticationSlice.actions;

// Selectors:

export const selectIsAuthenticated = state => !!state.authentication.authenticationToken;

export const selectIsLoggingIn = state => state.authentication.isLoggingIn;

export const selectIsSigningUp = state => state.authentication.isSigningUp;

export const selectIsSigningUpAsTransporter = state => state.authentication.isSigningUpAsTransporter;

export const selectIsSigningUpAsShop = state => state.authentication.isSigningUpAsShop;

export const selectIsLoadingUser = state => state.authentication.isLoadingUser;

export const selectIsUpdatingUser = state => state.authentication.isUpdatingUser;

export const selectIsUpdatingPassword = state => state.authentication.isUpdatingPassword;

export const selectIsUploadingTransporterDocuments = state => state.authentication.isUploadingTransporterDocuments;

export const selectInitialized = state => state.authentication.initialized;

export const selectRedirectionUrl = state => state.authentication.redirectionUrl;

export const selectRequirePhoneActivation = state => state.authentication.requirePhoneActivation;

export const selectUser = state => state.authentication.user;

export const selectUserHomePage = state => state.authentication.user?.options?.menu?.homePage;

export const selectUserIsAwaitingTransporterApproval = state => state.authentication.user?.options?.transporter_waiting_approval === true;

export const selectUserIsTransporter = state => state.authentication.user?.type === USER_TYPE_TRANSPORTER;

export const selectUserProfileImageUrl = state => state.authentication.user?.photoUrl;

export const selectUserIsAwaitingShopApproval = state => state.authentication.user?.options?.shop_waiting_approval === true;

export const selectUserIsShop = state => state.authentication.user?.type === USER_TYPE_SHOP;
