import React from 'react';
import PropTypes from 'prop-types';

import formatPrice from '@youship/utils/format-price';

import avatarImage from 'images/avatar.jpg';
import calendarIcon from 'images/icons/calendar.svg';

import Button from 'components/objects/button';
import Modal from '@youship/components/objects/modal';
import Rating from '@youship/components/objects/rating';

import { FormattedMessage, useIntl } from 'react-intl';


import './styles.scss';

const BidModal = ({
  actions,
  isLoading,
  onActionButtonClick,
  onClose,
  priceDetails,
  schedule,
  transporter
}) => {
  const handleBidActionButtonClick = (event, action) => {
    event.preventDefault();

    if (!isLoading) {
      onActionButtonClick(action);
    }
  };

  const handleBidModalClose = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (!isLoading) {
      onClose();
    }
  };

  const intl = useIntl();

  return (
    <Modal
      classNames="bid-modal"
      footer={Array.isArray(actions) && !!actions.length ? (
        <div className="bid-modal__footer">
          <div className="bid-modal__footer-button-wrapper">
            {actions.map((action, index) => (
              <Button
                key={index}
                context="primary"
                disabled={isLoading}
                largeHorizontalPadding
                outline={action.action === 'refuse'}
                text={action.text}
                onClick={event => handleBidActionButtonClick(event, action.action)}
              />
            ))}
          </div>
        </div>
      ) : null}
      title={intl.formatMessage({ id: 'order.bid.transporter' })}
      onClose={handleBidModalClose}
    >
      <div className="bid-modal__proposal">
        <div className="bid-modal__transporter-details">
          <img
            alt="Transporter"
            className="bid-modal__transporter-image"
            src={transporter?.photoUrl || avatarImage}
          />
          <div>
            <div className="bid-modal__transporter-name">
              {transporter?.name}
            </div>
            <Rating
              value={transporter?.rating}
            />
          </div>
        </div>
        <div className="bid-modal__proposal-details">
          <h3 className="bid-modal__proposal-title">
            <FormattedMessage id="order.bid.title" />
          </h3>
          <div className="bid-modal__proposal-pickup">
            <FormattedMessage id="order.bid.pickup" />
            <div className="bid-modal__proposal-date">
              <img
                alt="Calendar Icon"
                className="bid-modal__proposal-icon"
                src={calendarIcon}
              />
              <span>
                {schedule?.ready}
              </span>
            </div>
          </div>
          <div className="bid-modal__proposal-dropoff">
            <FormattedMessage id="order.bid.dropoff" />
            <div className="bid-modal__proposal-date">
              <img
                alt="Calendar Icon"
                className="bid-modal__proposal-icon"
                src={calendarIcon}
              />
              <span>
                {schedule?.deadline}
              </span>
            </div>
          </div>
          <div className="bid-modal__proposal-value">
            <div className="bid-modal__proposal-price-title">
              <FormattedMessage id="order.bid.value" />
            </div>
            {Array.isArray(priceDetails) && !!priceDetails.length && (
              <ul>
                {priceDetails.map((entry, index) => (
                  <li
                    key={index}
                    className="bid-modal__payment-details-item"
                  >
                    <div className="bid-modal__payment-details-title">
                      {entry.text}
                    </div>
                    <div className="bid-modal__payment-details-value">
                      {formatPrice(entry.value)}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

BidModal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.string,
    text: PropTypes.string
  })),
  isLoading: PropTypes.bool,
  onActionButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  priceDetails: PropTypes.arrayOf(PropTypes.shape({})),
  schedule: PropTypes.shape({
    deadline: PropTypes.string,
    ready: PropTypes.string
  }),
  transporter: PropTypes.shape({
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    rating: PropTypes.number
  })
};

BidModal.defaultProps = {
  actions: null,
  isLoading: false,
  onActionButtonClick: () => {},
  onClose: () => {},
  priceDetails: null,
  schedule: null,
  transporter: null
};

export default BidModal;
