import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  requestCheckoutQuote,
  selectCheckoutQuote,
  selectCheckoutQuoteIsLoading,
  selectContactName,
  selectContactPhoneCountryCode,
  selectContactPhoneNumber,
  selectDropoffAddress,
  selectDropoffSchedule,
  selectOrderIsLoading,
  selectPaymentTypeId,
  setContactName,
  setContactPhoneCountryCode,
  setContactPhoneNumber,
  setDropoffAddress,
  setDropoffScheduleDate,
  setDropoffScheduleTimeSlotValue,
  setPaymentTypeId
} from 'store/slices/shopping-cart';

import Button from 'components/objects/button';
import ContactModal from '@youship/components/contact-modal';
import HourSelect from '@youship/components/objects/hour-select';
import Notice from '@youship/components/objects/notice';
import Radio from '@youship/components/objects/radio';

import AddressModal from '@youship/components/address-modal';
import DateSelect from 'components/date-select';
import ShipmentOptionsOption from 'components/new-order/shipment-options/option';

import paymentIcon from '@youship/utils/payment-icons';
import locationIcon from 'images/icons/location.svg';
import noticeIcon from 'images/icons/notice.svg';
import userIcon from 'images/icons/user.svg';

// NOTE: Payment Options related imports remain commented in case they are used in the future
// import walletIcon from 'images/icons/payment/wallet.svg';
// import cardIcon from 'images/icons/payment/card.svg';
// import mbIcon from 'images/icons/payment/mb.svg';
// import paypalIcon from 'images/icons/payment/paypal.svg';

import './styles.scss';

const CheckoutOptions = () => {
  const dispatch = useDispatch();

  const checkoutQuote = useSelector(selectCheckoutQuote);
  const checkoutQuoteIsLoading = useSelector(selectCheckoutQuoteIsLoading);
  const contactName = useSelector(selectContactName);
  const contactPhoneCountryCode = useSelector(selectContactPhoneCountryCode);
  const contactPhoneNumber = useSelector(selectContactPhoneNumber);
  const defaultDropoffAddress = useSelector(selectDropoffAddress);
  const dropoffschedule = useSelector(selectDropoffSchedule);
  const orderIsLoading = useSelector(selectOrderIsLoading);
  const paymentTypeId = useSelector(selectPaymentTypeId);

  const countryCallingCode = typeof contactPhoneCountryCode === 'string' ?
    contactPhoneCountryCode.replace(/^\+/, '') : // remove + char from the start
    contactPhoneCountryCode;
  const dropoffScheduleDate = dropoffschedule?.date || null;
  const dropoffScheduleTimeSlotValue = dropoffScheduleDate ? dropoffschedule?.timeSlotValue || null : null;
  const paymentMessage = checkoutQuote?.paymentMessage || null;
  const paymentTypes = checkoutQuote?.paymentTypes || null;
  const scheduleIntervals = checkoutQuote?.scheduleIntervals || null;

  const [address, setAddress] = useState({
    apartmentAddress: '',
    city: '',
    formattedAddress: '',
    numberAddress: '',
    postalCode: '',
    streetAddress: ''
  });
  const [companyName, setCompanyName] = useState('');
  const [hasAddress, setHasAddress] = useState(false);
  const [hasContact, setHasContact] = useState(false);
  const [hourSelectInstructions, setHourSelectInstructions] = useState('');
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [taxId, setTaxId] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);

  const disabled = !checkoutQuote || checkoutQuoteIsLoading || orderIsLoading;

  const formatAddressDescription = (selectedAddress) => {
    const addressOptionsArray = [
      selectedAddress.apartmentAddress || null,
      selectedAddress.city || null,
      selectedAddress.numberAddress || null,
      selectedAddress.postalCode || null,
      selectedAddress.streetAddress || null
    ];

    const formattedAddress = addressOptionsArray.filter(Boolean).join(' • ');

    return formattedAddress;
  };

  useEffect(() => {
    if (scheduleIntervals) {
      if (dropoffScheduleDate) {
        const scheduleInterval = scheduleIntervals.find(interval => interval.date === dropoffScheduleDate);

        if (scheduleInterval) {
          setTimeSlots(scheduleInterval.timeSlots);
          setHourSelectInstructions('Select one');
        } else {
          setTimeSlots([]);
          setHourSelectInstructions('No time slots for the chosen date');
        }
      } else {
        setTimeSlots([]);
        setHourSelectInstructions('Choose a date');
      }
    }
  }, [dropoffScheduleDate, scheduleIntervals]);

  const dispatchRequestCheckoutQuote = () => {
    dispatch(requestCheckoutQuote())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while requesting a quote for your order.');

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const handleDateSelectChange = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');

    dispatch(setDropoffScheduleDate(formattedDate));
    dispatchRequestCheckoutQuote();
  };

  const handleHourSelectChange = (value) => {
    dispatch(setDropoffScheduleTimeSlotValue(value));
    dispatchRequestCheckoutQuote();
  };

  const handlePaymentTypeRadioChange = (value) => {
    dispatch(setPaymentTypeId(value));
  };

  const handleAddAddressButtonClick = () => {
    setShowAddAddressModal(true);
  };

  const handleCloseAddAddressModal = () => {
    setShowAddAddressModal(false);
  };

  const handleCloseAddContactModal = () => {
    setShowAddContactModal(false);
  };

  const handleAddAddressModalSaveButton = (newAddress) => {
    setAddress(newAddress);
    setHasAddress(true);
    dispatch(setDropoffAddress(newAddress));
    dispatchRequestCheckoutQuote();
    handleCloseAddAddressModal();
  };

  const handleAddContactButtonClick = () => {
    setShowAddContactModal(true);
  };

  const handleAddContactModalSaveButton = (newContact) => {
    dispatch(setContactName(newContact.name));
    dispatch(setContactPhoneNumber(newContact.phone));
    dispatch(setContactPhoneCountryCode(`+${newContact.countryCode}`));
    setCompanyName(newContact.company);
    setTaxId(newContact.tax);
    setHasContact(true);
    handleCloseAddContactModal();
  };

  useEffect(() => {
    if (defaultDropoffAddress) {
      setHasAddress(true);
    }
  }, [defaultDropoffAddress]);

  useEffect(() => {
    if (contactName && contactPhoneNumber) {
      setHasContact(true);
    }
  }, [contactName, contactPhoneNumber]);

  return (
    <div className="checkout-options">
      <div className="checkout-options__header">
        <h1 className="checkout-options__header-title">
          Delivery details
        </h1>
      </div>
      <div className="checkout-options__delivery-info">
        <h3 className="checkout-options__title">
          Delivery address
        </h3>
        <div className="checkout-options__description">
          Add address for this shipment.
        </div>
        <div className="checkout-options__action">
          {!hasAddress ? (
            <Button
              block
              icon={locationIcon}
              light
              lightBorder
              outline
              text="Add Address"
              onClick={handleAddAddressButtonClick}
            />
          ) : (
            <div>
              <ShipmentOptionsOption
                description={formatAddressDescription(defaultDropoffAddress ?? address)}
                icon={locationIcon}
                title={defaultDropoffAddress ? defaultDropoffAddress.streetAddress : address.streetAddress}
                onEdit={handleAddAddressButtonClick}
              />
            </div>
          )}
        </div>
        <div className="checkout-options__action">
          {!hasContact ? (
            <Button
              block
              icon={userIcon}
              light
              lightBorder
              outline
              text="Add Contact"
              onClick={handleAddContactButtonClick}
            />
          ) : (
            <div>
              <ShipmentOptionsOption
                description={contactPhoneNumber}
                icon={userIcon}
                title={contactName}
                onEdit={handleAddContactButtonClick}
              />
            </div>
          )}
        </div>
      </div>
      <div className="checkout-options__divider" />
      <h3 className="checkout-options__title">
        Delivery Date
      </h3>
      <div className="checkout-options__description">
        Add a delivery date.
      </div>
      <div className="checkout-options__inputs-wrapper">
        <div className="row">
          <div className="col col-12 col-lg-6">
            <div className="checkout-options__input-group checkout-options__input-group--margin-md">
              <DateSelect
                disabled={disabled}
                id="initial"
                left
                startDate={dropoffScheduleDate}
                onChange={handleDateSelectChange}
              />
            </div>
          </div>
          <div className="col col-12 col-lg-6">
            <HourSelect
              disabled={disabled}
              instructions={hourSelectInstructions}
              options={timeSlots}
              selected={dropoffScheduleTimeSlotValue}
              onChange={handleHourSelectChange}
            />
          </div>
        </div>
      </div>
      {Array.isArray(paymentTypes) && !!paymentTypes.length && (
        <>
          <div className="checkout-options__divider" />
          <h3 className="checkout-options__title">
            Payment method
          </h3>
          <div className="checkout-options__description">
            Select the method you want.
          </div>
          {!!paymentMessage && (
            <Notice
              description={paymentMessage}
              icon={noticeIcon}
              // linkText="Learn More" // NOTE: disabled until it is known which link to use
              // linkUrl="/" // NOTE: disabled until it is known which link to use
            />
          )}
          <div className="checkout-options__input-group">
            {paymentTypes.map(paymentType => (
              <Radio
                key={paymentType.id}
                alignTop
                description={paymentType.description}
                disabled={disabled}
                icon={paymentIcon(paymentType.id)}
                iconRight
                label={paymentType.name}
                light
                selected={paymentTypeId}
                value={paymentType.id}
                onChange={() => handlePaymentTypeRadioChange(paymentType.id)}
              />
            ))}
          </div>
        </>
      )}
      {showAddAddressModal && (
        <AddressModal
          currentAddress={defaultDropoffAddress}
          title="Your addresses"
          type="delivery"
          onClose={handleCloseAddAddressModal}
          onSave={handleAddAddressModalSaveButton}
        />
      )}
      {showAddContactModal && (
        <ContactModal
          companyName={companyName}
          contactName={contactName}
          contactPhoneNumber={contactPhoneNumber}
          countryCallingCode={countryCallingCode}
          taxId={taxId}
          onClose={handleCloseAddContactModal}
          onSave={handleAddContactModalSaveButton}
        />
      )}
    </div>
  );
};

export default CheckoutOptions;
