import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import DeliveryApi from '@youship/api/delivery';
import ShopApi from '@youship/api/shop';
import BidApi from '@youship/api/bid';
import DeliveryOperationsApi from '@youship/api/delivery-operations';
import TrackApi from '@youship/api/track';
import TransporterApi from '@youship/api/transporter';
import UserApi from '@youship/api/user';

import noOrderImage from 'images/order_default.svg';

const deliveriesAdapter = createEntityAdapter();

const initialState = deliveriesAdapter.getInitialState({
  drivers: null,
  deliveryProof: null,
  hasLiveTracking: false,
  isExportingDeliveries: false,
  isLoadingDrivers: false,
  isLoadingDeliveries: false,
  isLoadingDeliveryProof: false,
  isPerformingAction: false,
  isSubmittingReview: false,
  pages: {
    current: 0,
    lastPage: 0
  }
});

const populateDeliveryData = ((delivery) => {
  let imageUrl = noOrderImage;

  if (delivery.type === 'ship' && delivery.map_url) {
    imageUrl = delivery.map_url;
  } else if (delivery.type === 'order' && delivery.photo_url) {
    imageUrl = delivery.photo_url;
  }

  return ({
    ...delivery,
    actions: Array.isArray(delivery.actions) ?
      delivery.actions.map(action => ({
        ...action,
        actionUrl: action.action_url ?? null
      })) :
      [],
    date: delivery.status.schedule_name,
    dropOff: {
      ...delivery.dropoff,
      address: {
        streetAddress: delivery.dropoff.address
      },
      contact: {
        name: delivery.dropoff.contact
      },
      time: delivery.dropoff.schedule_line2
    },
    id: delivery.order_code,
    image: imageUrl,
    imageUrl: delivery.map_url,
    isPaid: delivery.payment?.isdone,
    items: delivery.items.map(item => ({
      ...item,
      iconUrl: item.icon_url
    })),
    messages: Array.isArray(delivery.messages) ?
      delivery.messages.map(message => ({
        ...message,
        answers: Array.isArray(message.answers) ?
          message.answers.map(answer => ({
            ...answer,
            dateSent: answer.date_send,
            userPhotoUrl: message.owner.photo_url
          })) :
          [],
        messageCode: message.message_code,
        userPhotoUrl: message.owner.photo_url
      })) :
      [],
    notes: delivery.order?.notes,
    payment: delivery.payment ?
      {
        ...delivery.payment,
        paymentUrl: delivery.payment.payment_url,
        ysToken: delivery.payment.ys_token,
        method: {
          ...delivery.payment.method,
          iconUrl: delivery.payment.method.icon_url
        }
      } :
      null,
    pickUp: {
      ...delivery.pickup,
      address: {
        streetAddress: delivery.pickup.address
      },
      contact: {
        name: delivery.pickup.contact
      },
      time: delivery.pickup.schedule_line2
    },
    resume: {
      ...delivery.resume,
      deliveryDuration: delivery.resume.duration,
      priceDetails: delivery.resume.price_details,
      quantity: delivery.resume.numitens
    },
    shop: {
      ...delivery.shop,
      photoUrl: delivery.shop?.photo_url
    },
    status: {
      ...delivery.status,
      name: delivery.status.status_name,
      statusColor: delivery.status.status_color
    },
    totalPrice: delivery.totalvalue
  });
});

// Thunks:

export const acceptBid = createAsyncThunk(
  'deliveries/acceptBid',
  // eslint-disable-next-line camelcase
  bidCode => BidApi.acceptBid({ bid_code: bidCode })
);

export const exportDeliveries = createAsyncThunk(
  'deliveries/exportDeliveries',
  ({ str, reference, datefrom, dateto, usercode }) => UserApi.deliveriesExport({ str, reference, datefrom, dateto, usercode })
    .then(response => ({
      data: response,
      requestParams: { str, reference, datefrom, dateto, usercode }
    }))
);

export const getDeliveryDetails = createAsyncThunk(
  'deliveries/getDeliveryDetails',
  // eslint-disable-next-line camelcase
  orderID => DeliveryApi.deliveryDetails({ order_code: orderID })
    .then((response) => {
      if (response?.d_order) {
        const { d_order: delivery } = response;

        let imageUrl = noOrderImage;

        if (delivery.type === 'ship' && delivery.map_url) {
          imageUrl = delivery.map_url;
        } else if (delivery.type === 'order' && delivery.photo_url) {
          imageUrl = delivery.photo_url;
        }

        const allActions = [
          ...(delivery?.actions?.main || []),
          ...(delivery?.actions?.options || [])
        ];

        const actionsList = [];

        let rateCustomerActionExists = false;
        let rateTransporterActionExists = false;
        let rateShopActionExists = false;
        let rateUserActionExists = false;

        if (Array.isArray(allActions) && allActions.length) {
          // rateActions are not pushed inside forEach because they may come duplicated from the API
          allActions.forEach((action) => {
            if (action.action === 'rate_customer') rateCustomerActionExists = true;
            else if (action.action === 'rate_transporter') rateTransporterActionExists = true;
            else if (action.action === 'rate_shop') rateShopActionExists = true;
            else if (action.action === 'rate_user') rateUserActionExists = true;
            else actionsList.push({ ...action, actionUrl: action.action_url ?? null, photoUrl: action.photo_url ?? null });
          });
        }

        return {
          ...delivery,
          actionsList,
          bids: Array.isArray(delivery.bids) ?
            delivery.bids.map(bid => ({
              ...bid,
              bidCode: bid.bid_code,
              bidDate: bid.date_bid,
              payment: {
                ...bid.payment,
                paymentUrl: bid.payment?.payment_url,
                ysToken: bid.payment?.ys_token
              },
              priceDetails: Array.isArray(bid.price_details) ?
                bid.price_details.map(item => ({
                  ...item,
                  currency: item.currencycode
                })) :
                [],
              schedule: {
                ...bid?.schedule,
                strSchedule: bid.schedule?.str_schedule
              },
              status: {
                ...bid?.status,
                scheduleColor: bid.status?.schedule_color,
                scheduleName: bid.status?.schedule_name,
                statusColor: bid.status?.status_color,
                statusName: bid.status?.status_name
              },
              transporter: {
                ...bid?.transporter,
                photoUrl: bid.transporter?.photo_url,
                userCode: bid.transporter?.user_code
              }
            })) :
            [],
          date: delivery.status?.schedule_name,
          dropOff: {
            ...delivery.order?.dropoff,
            address: {
              ...delivery.order?.dropoff?.address,
              formattedAddress: delivery.order?.dropoff?.address?.formatted_address,
              streetAddress: delivery.order?.dropoff?.address?.str_address
            },
            contact: {
              ...delivery.order?.dropoff?.contact,
              lineContact: delivery.order?.dropoff?.contact?.line_contact
            },
            schedule: {
              ...delivery.order?.dropoff?.schedule,
              timeEstimate: delivery.order?.dropoff?.schedule?.str_schedule
            }
          },
          id: delivery.order_code,
          image: imageUrl,
          imageUrl: delivery.map_url,
          isPaid: delivery.payment?.isdone,
          items: delivery.order?.items,
          owner: delivery.owner ?
            {
              ...delivery.owner,
              userCode: delivery.owner?.user_code,
              photoUrl: delivery.owner?.photo_url
            } :
            null,
          messages: Array.isArray(delivery.messages) ?
            delivery.messages.map(message => ({
              ...message,
              answers: Array.isArray(message.answers) ?
                message.answers.map(answer => ({
                  ...answer,
                  dateSent: answer.date_send,
                  userPhotoUrl: answer.owner?.photo_url
                })) :
                [],
              messageCode: message.message_code,
              userPhotoUrl: message.owner?.photo_url
            })) :
            [],
          notes: delivery.order?.notes,
          payment: delivery.payment ?
            {
              ...delivery.payment,
              paymentUrl: delivery.payment.payment_url,
              ysToken: delivery.payment.ys_token,
              method: {
                ...delivery.payment.method,
                iconUrl: delivery.payment.method?.icon_url
              }
            } :
            null,
          pickUp: {
            ...delivery.order?.pickup,
            address: {
              ...delivery.order?.pickup.address,
              lineAddress: delivery.order?.pickup?.address?.line_address,
              streetAddress: delivery.order?.pickup?.address?.str_address
            },
            schedule: {
              ...delivery.order?.pickup?.schedule,
              timeEstimate: delivery.order?.pickup?.schedule?.str_schedule
            },
            contact: {
              ...delivery.order?.pickup?.contact,
              lineContact: delivery.order?.pickup?.contact?.line_contact
            }
          },
          rateCustomerActionExists,
          rateShopActionExists,
          rateTransporterActionExists,
          rateUserActionExists,
          resume: {
            ...delivery.resume,
            deliveryDistance: delivery.resume?.distance,
            deliveryDuration: delivery.resume?.duration,
            priceDetails: delivery.resume?.price_details
          },
          shop: {
            ...delivery.shop,
            photoUrl: delivery.shop?.photo_url
          },
          showBids: delivery.show_bids,
          tracking: {
            ...delivery.tracking,
            history: Array.isArray(delivery.tracking.history) ?
              delivery.tracking.history?.map(historyItem => ({
                ...historyItem,
                isDone: historyItem.isdone,
                isCurrent: historyItem.iscurrent
              })) :
              []
          },
          user: delivery.user ?
            {
              ...delivery.user,
              userCode: delivery.user?.user_code,
              photoUrl: delivery.user?.photo_url
            } :
            null,
          waypoints: delivery.order.waypoints ?
            {
              ...delivery.order.waypoints
            } :
            []
        };
      }

      return null;
    })
);

export const getDeliveries = createAsyncThunk(
  'deliveries/getDeliveries',
  (payload) => {
    const { deliveriesType, search, userType } = payload;

    let getSelectedDeliveries = null;

    if (userType === 'transporter') {
      switch (deliveriesType) {
        case 'history':
          getSelectedDeliveries = TransporterApi.historyDeliveries;
          break;
        case 'search':
          getSelectedDeliveries = TransporterApi.searchDeliveries;
          break;
        case 'active':
        default:
          getSelectedDeliveries = TransporterApi.activeDeliveries;
      }
    } else if (userType === 'shop') {
      switch (deliveriesType) {
        case 'history':
          getSelectedDeliveries = ShopApi.historyDeliveries;
          break;
        case 'active':
        default:
          getSelectedDeliveries = ShopApi.activeDeliveries;
      }
    } else {
      switch (deliveriesType) {
        case 'active':
          getSelectedDeliveries = UserApi.activeDeliveries;
          break;
        case 'history':
          getSelectedDeliveries = UserApi.historyDeliveries;
          break;
        case 'draft':
          getSelectedDeliveries = UserApi.draftDeliveries;
          break;
        case 'all':
        default:
          getSelectedDeliveries = UserApi.allDeliveries;
      }
    }

    return getSelectedDeliveries(search)
      .then((response) => {
        if (response) {
          let deliveries = [];

          if (Array.isArray(response.rows)) {
            if (userType === 'transporter' || userType === 'shop') {
              deliveries = response.rows.flatMap(status => status.rows.map(delivery => populateDeliveryData(delivery)));
            } else {
              deliveries = response.rows.map(delivery => populateDeliveryData(delivery));
            }
          }

          const data = {
            deliveries,
            pages: {
              current: response.pages?.current,
              lastPage: response.pages?.lastpage
            }
          };

          if (userType === 'transporter') data.driverId = search.user_code;

          return data;
        }

        return response;
      });
  }
);

export const getDeliveryProof = createAsyncThunk(
  'deliveries/getDeliveryProof',
  ({ orderId, proofType }) => {
    let proofAction = null;

    switch (proofType) {
      case 'proof_collection':
        proofAction = DeliveryApi.proofOfCollection;
        break;
      case 'proof_return':
        proofAction = DeliveryApi.proofOfReturn;
        break;
      case 'proof_delivery':
      default:
        proofAction = DeliveryApi.proofOfDelivery;
    }

    // eslint-disable-next-line camelcase
    return proofAction({ order_code: orderId })
      .then((response) => {
        const { proof } = response;

        if (proof) {
          return {
            ...proof,
            signature: proof.document_url,
            resultTitle: proof.result_title
          };
        }

        return null;
      });
  }
);

export const getDrivers = createAsyncThunk('deliveries/getDrivers', () => TransporterApi.getDrivers()
  .then((response) => {
    if (!Array.isArray(response?.rows)) throw new Error('The response did not include any drivers.');

    return response.rows.map(driver => ({
      ...driver,
      id: driver.user_code
    }));
  }));

export const getDeliveryTracking = createAsyncThunk(
  'deliveries/getDeliveryTracking',
  // eslint-disable-next-line camelcase
  orderID => DeliveryApi.deliveryTracking({ order_code: orderID })
    .then((response) => {
      if (response?.tracking) {
        const { tracking } = response;

        return {
          ...tracking,
          liveTracking: tracking.livetracking
        };
      }

      return null;
    })
);

export const getPublicDeliveryDetails = createAsyncThunk(
  'deliveries/getPublicDeliveryDetails',
  orderID => TrackApi.orderTracking({ code: orderID })
    .then((response) => {
      if (response?.tracking) {
        const { tracking: delivery } = response;

        return {
          ...delivery,
          actions: Array.isArray(delivery.actions) ?
            delivery.actions?.map(action => ({
              ...action,
              photoUrl: action.photo_url
            })) :
            [],
          dropOff: {
            ...delivery.dropoff,
            address: {
              ...delivery.dropoff?.address,
              streetAddress: delivery.dropoff?.address?.line_address
            },
            contact: {
              ...delivery.dropoff?.contact,
              lineContact: delivery.dropoff?.contact?.line_contact
            },
            schedule: {
              ...delivery.dropoff?.schedule,
              timeEstimate: delivery.dropoff?.schedule?.str_schedule
            }
          },
          id: orderID,
          name: delivery.resume?.number,
          owner: {
            ...delivery.owner,
            userCode: delivery.owner?.user_code,
            photoUrl: delivery.owner?.photo_url
          },
          pickUp: {
            ...delivery.pickup,
            address: {
              ...delivery.pickup.address,
              streetAddress: delivery.pickup?.address?.line_address
            },
            contact: {
              ...delivery.pickup?.contact,
              lineContact: delivery.pickup?.contact?.line_contact
            },
            schedule: {
              ...delivery.pickup?.schedule,
              timeEstimate: delivery.pickup?.schedule?.str_schedule
            }
          },
          publicDelivery: true,
          resume: {
            ...delivery.resume,
            deliveryDistance: delivery.resume?.distance,
            deliveryDuration: delivery.resume?.duration,
            numItems: delivery.resume?.numitens
          },
          tracking: {
            history: Array.isArray(delivery.history) ?
              delivery.history?.map(historyItem => ({
                ...historyItem,
                isDone: historyItem.isdone,
                isCurrent: historyItem.iscurrent
              })) :
              [],
            status: {
              ...delivery.status,
              position: delivery.status?.pos
            }
          },
          type: 'ship', // Tracking currently only works for shipments
          user: {
            ...delivery.user,
            userCode: delivery.user?.user_code,
            photoUrl: delivery.user?.photo_url
          }
        };
      }

      return null;
    })
);

export const getPublicDeliveryTracking = createAsyncThunk(
  'deliveries/getPublicDeliveryTracking',
  // eslint-disable-next-line camelcase
  orderID => TrackApi.orderLiveTracking({ code: orderID })
);

export const sendPublicDeliveryMessage = createAsyncThunk(
  'deliveries/sendPublicDeliveryMessage',
  ({ orderId, publicMessage }) => TrackApi.sendMessage({
    code: orderId,
    msg: publicMessage
  })
);

export const performDeliveryAction = createAsyncThunk(
  'deliveryOperations/performDeliveryAction',
  (payload) => {
    const orderId = payload?.orderId;
    const actionType = payload?.actionType;

    // eslint-disable-next-line camelcase
    const data = { order_code: orderId };

    switch (actionType) {
      case 'accept':
        return DeliveryOperationsApi.acceptDelivery(data);
      case 'at_collection_location':
        return DeliveryOperationsApi.courierArrivedAtCollectionLocation(data);
      case 'at_delivery_location':
        return DeliveryOperationsApi.courierArrivedAtDeliveryLocation(data);
      case 'at_return_location':
        return DeliveryOperationsApi.courierArrivedAtReturnLocation(data);
      case 'cancel':
        return DeliveryOperationsApi.cancelDelivery(data);
      case 'collect':
        return DeliveryOperationsApi.collectDelivery(data);
      case 'collection_intransit':
        return DeliveryOperationsApi.collectionInTransit(data);
      case 'delivery_intransit':
        return DeliveryOperationsApi.deliveryInTransit(data);
      case 'refuse':
        return DeliveryOperationsApi.refuseDelivery(data);
      case 'remove':
        return DeliveryOperationsApi.removeDelivery(data);
      case 'reschedule':
        return DeliveryOperationsApi.rescheduleDelivery(data);
      case 'return_intransit':
        return DeliveryOperationsApi.returnInTransit(data);
      case 'shop_accept':
        return DeliveryOperationsApi.shopAcceptRequest(data);
      case 'shop_finish_preparation':
        return DeliveryOperationsApi.shopFinishesPreparationOfRequest(data);
      case 'shop_prepare':
        return DeliveryOperationsApi.shopStartsPreparationOfRequest(data);
      case 'shop_refuse':
        return DeliveryOperationsApi.shopRefusesRequest(data);
      default:
        return Promise.reject(new Error('Unrecognized delivery action.'));
    }
  }
);

export const rateCustomer = createAsyncThunk(
  'deliveryOperations/rateCustomer',
  (payload) => {
    const orderId = payload?.orderId;
    const { rating } = payload;

    // eslint-disable-next-line camelcase
    const data = { order_code: orderId, rating };

    return DeliveryOperationsApi.rateCustomer(data);
  }
);

export const rateShop = createAsyncThunk(
  'deliveryOperations/rateShop',
  (payload) => {
    const orderId = payload?.orderId;
    const { rating } = payload;

    // eslint-disable-next-line camelcase
    const data = { order_code: orderId, rating };

    return DeliveryOperationsApi.rateShop(data);
  }
);

export const rateTransporter = createAsyncThunk(
  'deliveryOperations/rateTransporter',
  (payload) => {
    const orderId = payload?.orderId;
    const { rating } = payload;

    // eslint-disable-next-line camelcase
    const data = { order_code: orderId, rating };

    return DeliveryOperationsApi.rateTransporter(data);
  }
);

export const rateUser = createAsyncThunk(
  'deliveryOperations/rateUser',
  (payload) => {
    const orderId = payload?.orderId;
    const { rating } = payload;

    // eslint-disable-next-line camelcase
    const data = { order_code: orderId, rating };

    return DeliveryOperationsApi.rateUser(data);
  }
);

export const refuseBid = createAsyncThunk(
  'deliveries/refuseBid',
  // eslint-disable-next-line camelcase
  bidCode => BidApi.refuseBid({ bid_code: bidCode })
);

export const rescheduleDelivery = createAsyncThunk(
  'deliveryOperations/rescheduleDelivery',
  ({ orderId, dropoffDate, pickupDate }) => {
    const data = {
      // eslint-disable-next-line camelcase
      order_code: orderId,
      order: {
        pickup: {
          schedule: {
            ready: pickupDate
          }
        },
        dropoff: {
          schedule: {
            deadline: dropoffDate
          }
        }
      }
    };

    return DeliveryOperationsApi.rescheduleDelivery(data);
  }
);

// Slice:

const deliveriesSlice = createSlice({
  name: 'deliveries',

  initialState,

  reducers: {
    resetDeliveryProof (state) {
      state.deliveryProof = null;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(acceptBid.pending, (state) => {
        state.isPerformingAction = true;
      })
      .addCase(acceptBid.fulfilled, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(acceptBid.rejected, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(exportDeliveries.pending, (state) => {
        state.isExportingDeliveries = true;
      })
      .addCase(exportDeliveries.fulfilled, (state, action) => {
        const { data, requestParams } = action.payload;

        if (data) {
          let fileName = 'YouShip Deliveries';

          if (requestParams) {
            Object.values(requestParams).forEach((requestParam) => {
              if (requestParam) fileName = `${fileName}${fileName === 'YouShip Deliveries' ? ' -' : ''} ${requestParam}`;
            });
          }

          const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;

          const encodedUri = csvContent;
          const link = document.createElement('a');

          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `${fileName}.csv`);
          document.body.appendChild(link); // Required for FF

          link.click();
        }

        state.isExportingDeliveries = false;
      })
      .addCase(exportDeliveries.rejected, (state) => {
        state.isExportingDeliveries = false;
      })
      .addCase(getDeliveries.pending, (state) => {
        state.isLoadingDeliveries = true;
      })
      .addCase(getDeliveries.fulfilled, (state, action) => {
        const response = action.payload;

        if (Array.isArray(response.deliveries)) {
          deliveriesAdapter.setAll(state, response.deliveries);
          state.pages = response.pages;
        }
        state.isLoadingDeliveries = false;
      })
      .addCase(getDeliveries.rejected, (state) => {
        state.isLoadingDeliveries = false;
      })
      .addCase(getDeliveryDetails.pending, (state) => {
        state.isLoadingDelivery = true;
      })
      .addCase(getDeliveryDetails.fulfilled, (state, action) => {
        const order = action.payload;

        if (order) deliveriesAdapter.upsertOne(state, order);
        state.isLoadingDelivery = false;
      })
      .addCase(getDeliveryDetails.rejected, (state) => {
        state.isLoadingDelivery = false;
      })
      .addCase(getDrivers.pending, (state) => {
        state.isLoadingDrivers = true;
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.drivers = action.payload;
        }

        state.isLoadingDrivers = false;
      })
      .addCase(getDrivers.rejected, (state) => {
        state.isLoadingDrivers = false;
      })
      .addCase(getDeliveryProof.pending, (state) => {
        state.isLoadingDeliveryProof = true;
      })
      .addCase(getDeliveryProof.fulfilled, (state, action) => {
        const proof = action?.payload;

        if (proof) {
          state.deliveryProof = action.payload;
        }

        state.isLoadingDeliveryProof = false;
      })
      .addCase(getDeliveryProof.rejected, (state) => {
        state.isLoadingDeliveryProof = false;
      })
      .addCase(getDeliveryTracking.pending, (state) => {
        state.isLoadingDelivery = true;
      })
      .addCase(getDeliveryTracking.fulfilled, (state, action) => {
        const { liveTracking } = action?.payload;

        state.hasLiveTracking = liveTracking;

        state.isLoadingDelivery = false;
      })
      .addCase(getDeliveryTracking.rejected, (state) => {
        state.isLoadingDelivery = false;
      })
      .addCase(getPublicDeliveryDetails.pending, (state) => {
        state.isLoadingDelivery = true;
      })
      .addCase(getPublicDeliveryDetails.fulfilled, (state, action) => {
        const order = action.payload;

        if (order) deliveriesAdapter.upsertOne(state, order);
        state.isLoadingDelivery = false;
      })
      .addCase(getPublicDeliveryDetails.rejected, (state) => {
        state.isLoadingDelivery = false;
      })
      .addCase(getPublicDeliveryTracking.pending, (state) => {
        state.isLoadingDelivery = true;
      })
      .addCase(getPublicDeliveryTracking.fulfilled, (state) => {
        state.isLoadingDelivery = false;
      })
      .addCase(getPublicDeliveryTracking.rejected, (state) => {
        state.isLoadingDelivery = false;
      })
      .addCase(sendPublicDeliveryMessage.pending, (state) => {
        state.isLoadingDelivery = true;
      })
      .addCase(sendPublicDeliveryMessage.fulfilled, (state) => {
        state.isLoadingDelivery = false;
      })
      .addCase(sendPublicDeliveryMessage.rejected, (state) => {
        state.isLoadingDelivery = false;
      })
      .addCase(performDeliveryAction.pending, (state) => {
        state.isPerformingAction = true;
      })
      .addCase(performDeliveryAction.fulfilled, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(performDeliveryAction.rejected, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(rateCustomer.pending, (state) => {
        state.isSubmittingReview = true;
      })
      .addCase(rateCustomer.fulfilled, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(rateCustomer.rejected, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(rateShop.pending, (state) => {
        state.isSubmittingReview = true;
      })
      .addCase(rateShop.fulfilled, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(rateShop.rejected, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(rateTransporter.pending, (state) => {
        state.isSubmittingReview = true;
      })
      .addCase(rateTransporter.fulfilled, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(rateTransporter.rejected, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(rateUser.pending, (state) => {
        state.isSubmittingReview = true;
      })
      .addCase(rateUser.fulfilled, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(rateUser.rejected, (state) => {
        state.isSubmittingReview = false;
      })
      .addCase(refuseBid.pending, (state) => {
        state.isPerformingAction = true;
      })
      .addCase(refuseBid.fulfilled, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(refuseBid.rejected, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(rescheduleDelivery.pending, (state) => {
        state.isPerformingAction = true;
      })
      .addCase(rescheduleDelivery.fulfilled, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(rescheduleDelivery.rejected, (state) => {
        state.isPerformingAction = false;
      });
  }
});

export default deliveriesSlice.reducer;

// Actions

export const {
  initialize,
  resetDeliveryProof
} = deliveriesSlice.actions;

// Selectors:

export const {
  selectAll: selectDeliveries,
  selectById: selectDeliveryById
} = deliveriesAdapter.getSelectors(state => state.deliveries);

export const selectDrivers = state => state.deliveries.drivers;

export const selectDeliveryProof = state => state.deliveries.deliveryProof;

export const selectHasLiveTracking = state => state.deliveries.hasLiveTracking;

export const selectIsExportingDeliveries = state => state.deliveries.isExportingDeliveries;

export const selectIsLoadingDelivery = state => state.deliveries.isLoadingDelivery;

export const selectIsLoadingDeliveryProof = state => state.deliveries.isLoadingDeliveryProof;

export const selectIsLoadingDeliveryList = state => state.deliveries.isLoadingDeliveries;

export const selectIsLoadingDrivers = state => state.deliveries.isLoadingDrivers;

export const selectPages = state => state.deliveries.pages;

export const selectIsPerformingAction = state => state.deliveries.isPerformingAction;

export const selectIsSubmittingReview = state => state.deliveries.isSubmittingReview;
