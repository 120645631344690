import BaseApi from '..';
import {
  SHOP_CANCEL,
  SHOP_DELIVERIES_ACTIVE,
  SHOP_DELIVERIES_HISTORY,
  SHOP_PRODUCT_STOCK,
  SHOP_SIGNUP
} from '../endpoints';


export default class ShopApi extends BaseApi {
  /* eslint-disable camelcase */

  static cancelSignUpRequest = () => this.post(SHOP_CANCEL);

  static signUpAsShop = ({
    company_name,
    companycode, // optional
    business_name,
    description,
    address,
    postalcode,
    city,
    vat,
    about,
    schedule,
    bank_name, // optional
    bank_account // optional
  }) => {
    const data = {
      address,
      company_name,
      description,
      postalcode,
      city,
      vat,
      about,
      schedule
    };

    if (bank_account) data.bank_account = bank_account;
    if (bank_name) data.bank_name = bank_name;
    if (business_name) data.business_name = business_name;
    if (companycode) data.companycode = companycode;

    return this.post(SHOP_SIGNUP, data);
  };

  static activeDeliveries = ({
    tab, // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(SHOP_DELIVERIES_ACTIVE, {
    tab,
    pages
  });

  static historyDeliveries = ({
    str, // optional
    reference, // optional
    datefrom, // optional
    dateto, // optional
    user_code, // optional
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(SHOP_DELIVERIES_HISTORY, {
    str,
    reference,
    datefrom,
    dateto,
    user_code,
    pages
  });

  static setProductAvailable = ({
    product_code,
    available
  }) => this.post(SHOP_PRODUCT_STOCK, {
    product_code,
    available
  });

  /* eslint-enable camelcase */
}
