import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getUser, logout, selectIsAuthenticated, selectUser } from 'store/slices/authentication';
import { markNotificationAsSeen, resetNotifications, selectNotifications, selectUnseenNotifications } from 'store/slices/notifications';
import { resetDelivery, setDefaultPickup } from 'store/slices/new-delivery';
import { resetCheckoutData } from 'store/slices/shopping-cart';
import { selectLocale } from 'store/slices/locales';

import Dropdown from '@youship/components/objects/dropdown';
import OffScreenSlider from '@youship/components/objects/off-screen-slider';

import AccountDropdown from '@youship/components/account-dropdown';
import AddressModal from '@youship/components/address-modal';
import AvatarButton from '@youship/components/objects/avatar-button';
import Button from '@youship/components/objects/button';
import NotificationsDropdown from '@youship/components/notifications-dropdown';
import LocaleSelect from 'components/locale-select';

import locales from '@youship/i18n/locales';

import avatarImage from '@youship/assets/images/avatar.jpg';
import addressIcon from '@youship/assets/images/icons/address.svg';
import logo from '@youship/assets/images/logo.svg';
import menuIcon from '@youship/assets/images/icons/menu.svg';
import notificationsReadIcon from '@youship/assets/images/icons/notifications.svg';
import notificationsUnreadIcon from '@youship/assets/images/icons/notifications-unread.svg';
import orderIcon from '../../images/icons/order.svg';

import './styles.scss';

const MARKETING_WEBSITE_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL;

const DROPDOWN_HORIZONTAL_POSITION_LEFT = 'left';
const DROPDOWN_HORIZONTAL_POSITION_RIGHT = 'right';
const MOBILE_BREAKPOINT = 576;

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Account
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const userAddress = {
    apartmentAddress: user?.default_address?.address.apartmentAddress || '',
    city: user?.default_address?.address.city || '',
    formattedAddress: user?.default_address?.address.formattedAddress || '',
    numberAddress: user?.default_address?.address.numberAddress || '',
    postalCode: user?.default_address?.address.postalCode || '',
    streetAddress: user?.default_address?.address.streetAddress || ''
  };

  const userImageUrl = user?.photo_url;
  const userName = user?.firstname;
  const userEmail = user?.email;

  const address = user?.defaultAddress;

  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false);

  const currentPathName = useLocation().pathname;

  const isCurrentPageLogin = currentPathName === '/login';
  const isCurrentPageRegister = currentPathName === '/register';
  const authenticationButtonMessageId = isCurrentPageLogin ? 'header.sign_up' : 'header.sign_in';
  const authenticationLinkPath = isCurrentPageLogin ? '/register' : '/login';
  const authenticationContextMessageId = isCurrentPageLogin ? 'header.sign_up_context' : 'header.sign_in_context';

  const handleAccountDropdownClose = () => {
    setShowAccountDropdown(false);
    setShowLanguageOptionsList(false);

    return true;
  };

  const handleAvatarButtonClick = (e) => {
    e.preventDefault();
    setShowAccountDropdown(true);
  };

  const handleLogoutClick = () => {
    setShowMobileMenuSlider(false);
    setShowAccountDropdown(false);
    dispatch(logout())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while trying to logout.');

        return response;
      })
      .then(async (response) => {
        await dispatch(resetCheckoutData());
        await dispatch(resetDelivery());
        await dispatch(resetNotifications());
        history.push('/');

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  // Notifications
  const notifications = useSelector(selectNotifications);
  const unseenNotifications = useSelector(selectUnseenNotifications);

  const notificationsIcon = unseenNotifications && unseenNotifications.length ? notificationsUnreadIcon : notificationsReadIcon;

  const handleNotificationClick = (id) => {
    // To use in combination with the onClick event handler in Notification’s wrapper of PushNotifications, which has some padding
    dispatch(markNotificationAsSeen(id));
  };

  const handleNotificationsDropdownClose = () => {
    setShowNotificationsDropdown(false);
  };

  const handleNotificationsIconClick = () => {
    setShowNotificationsDropdown(true);
  };

  const handleNotificationsIconKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowNotificationsDropdown(true);
    }
  };

  const [showAddressModal, setShowAddressModal] = useState(false);

  const closeAddressModal = () => {
    setShowAddressModal(false);
  };

  const handleAccountDropdownAddAddressClick = () => {
    setShowAccountDropdown(false);
    openAddressModal();
  };

  const handleAddressModalSave = () => {
    dispatch(getUser())
      .then(((response) => {
        dispatch(setDefaultPickup());

        return response;
      }))
      .catch((error) => {
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });

    closeAddressModal();
  };

  const handleAddressModalClose = () => {
    closeAddressModal();
  };

  const handleNavbarChangeAddress = () => {
    setShowMobileMenuSlider(false);
    openAddressModal();
  };

  const handleNavbarChangeAddressKeyDown = (event) => {
    if (event.keyCode === 13) {
      openAddressModal();
    }
  };

  const openAddressModal = () => {
    setShowAddressModal(true);
  };

  // Menu dropdown
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const [horizontalPosition, setHorizontalPosition] = useState(typeof window !== 'undefined' && window.innerWidth < MOBILE_BREAKPOINT ?
    DROPDOWN_HORIZONTAL_POSITION_RIGHT :
    DROPDOWN_HORIZONTAL_POSITION_LEFT);
  const [showLanguageOptionsList, setShowLanguageOptionsList] = useState(false);
  const [showMenuDropdown, setShowMenuDropdown] = useState(false);
  const [showMobileMenuSlider, setShowMobileMenuSlider] = useState(false);
  const languageLocales = Object.values(locales);
  const languageLocalesFormat = languageLocales.map(language => ({
    value: language.path,
    text: language.locale
  }));
  const currentLocaleValue = useSelector(selectLocale);
  const [currentLanguage, setCurrentLanguage] = useState(languageLocalesFormat.find(item => item.value === currentLocaleValue));

  if (currentLanguage.value === 'en') {
    currentLanguage.icon = 'https://flag.pk/flags/4x3/us.svg';
  } else {
    currentLanguage.icon = `https://flag.pk/flags/4x3/${currentLanguage.value}.svg`;
  }

  const handleSetCurrentLanguage = (value, icon, text) => {
    setCurrentLanguage({
      value,
      icon,
      text
    });

    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(false);
    }, 0);
  };

  const handleShowLanguageOptionsList = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(true);
    }, 0);
  };

  const handleHideLanguageOptionsList = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(false);
    }, 0);
  };

  const handleDropdownClose = () => {
    setShowMenuDropdown(false);
  };

  const handleMenuTogglerClick = () => {
    setShowMenuDropdown(true);
  };

  const handleMenuTogglerKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowMenuDropdown(!showMenuDropdown);
    }
  };

  const handleMobileMenuClose = () => {
    setShowMobileMenuSlider(false);
    document.querySelector('body').style.overflowY = 'scroll';
  };

  const handleMobileMenuTogglerClick = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowMobileMenuSlider(true);
    }, 1);
    document.querySelector('body').style.overflowY = 'hidden';
  };

  const handleMobileMenuTogglerKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowMobileMenuSlider(!showMobileMenuSlider);
    }
  };

  const handlePageScroll = () => {
    if (typeof window !== 'undefined' && window.pageYOffset === 0) {
      setIsPageScrolled(false);
    } else if (!isPageScrolled) {
      setIsPageScrolled(true);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handlePageScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', handlePageScroll);
        window.removeEventListener('resize', handleResize);
      }
    };
  });

  const handleResize = () => {
    if (typeof window !== 'undefined' && window.innerWidth < MOBILE_BREAKPOINT) {
      setHorizontalPosition(DROPDOWN_HORIZONTAL_POSITION_RIGHT);
    } else {
      setHorizontalPosition(DROPDOWN_HORIZONTAL_POSITION_LEFT);
    }
  };

  return (
    <>
      <header className={`navbar${!user ? ' navbar--authentication' : ''}`}>
        <div className="container">
          <div className="navbar__content">
            <div className={`navbar__logo-wrapper${!isCurrentPageLogin && !isCurrentPageRegister ? ' navbar__logo-wrapper--center' : ''}`}>
              <Link
                className="navbar__logo-link"
                to="/"
              >
                <img
                  alt="logo"
                  className="navbar__logo"
                  src={logo}
                />
              </Link>
            </div>
            <div className={`navbar__items-container${!isCurrentPageLogin && !isCurrentPageRegister ? ' order-2 order-lg-3' : ' navbar__items-container--left-margin'}`}>
              <div className="navbar__icons-wrapper">
                {!user && (
                  <div className="navbar__language-selector d-none d-lg-block">
                    <LocaleSelect />
                  </div>
                )}
                {user && (
                  <>
                    <Link
                      className="navbar__orders-link"
                      to="/orders"
                    >
                      <div className="navbar__icon-wrapper">
                        <img
                          alt="Request Icon"
                          className="navbar__icon"
                          src={orderIcon}
                        />
                      </div>
                    </Link>
                    <div className="navbar__notifications">
                      <div
                        className="navbar__icon-wrapper"
                        role="button"
                        tabIndex={0}
                        onClick={handleNotificationsIconClick}
                        onKeyDown={handleNotificationsIconKeyDown}
                      >
                        <img
                          alt="Notifications Icon"
                          className="navbar__icon"
                          src={notificationsIcon}
                        />
                      </div>
                    </div>
                    <NotificationsDropdown
                      linkComponent={Link}
                      notifications={notifications}
                      show={showNotificationsDropdown}
                      onClose={handleNotificationsDropdownClose}
                      onNotificationClick={handleNotificationClick}
                    />
                  </>
                )}
              </div>
              {!!user && (
                <div className="navbar__account-button-wrapper d-none d-lg-block">
                  <AvatarButton
                    imageUrl={userImageUrl}
                    name={userName}
                    text={(
                      <FormattedMessage
                        id="header.greeting"
                        values={{ name: userName }}
                      />
                    )}
                    onClick={handleAvatarButtonClick}
                  />
                  <AccountDropdown
                    currentLanguage={currentLanguage}
                    hideLanguageOptionsList={handleHideLanguageOptionsList}
                    isLanguageMenuVisible={showLanguageOptionsList}
                    linkComponent={Link}
                    setCurrentLanguage={handleSetCurrentLanguage}
                    show={showAccountDropdown}
                    showLanguageOptionsList={handleShowLanguageOptionsList}
                    user={user}
                    onAddAddressClick={handleAccountDropdownAddAddressClick}
                    onClose={handleAccountDropdownClose}
                    onLogoutClick={handleLogoutClick}
                  />
                </div>
              )}
              {!user && isAuthenticated && (
                <Button
                  context="primary"
                  largeHorizontalPadding
                  noShadow
                  outline
                  smallVerticalPadding
                  onClick={handleLogoutClick}
                >
                  <FormattedMessage id="header.sign_out" />
                </Button>
              )}
              {!user && !isAuthenticated && (
                <>
                  {isCurrentPageRegister || isCurrentPageLogin ? (
                    <div className="navbar__authentication-options d-none d-lg-flex">
                      <div className="navbar__authentication-options-text">
                        <FormattedMessage id={authenticationContextMessageId} />
                      </div>
                      <Link to={authenticationLinkPath}>
                        <Button
                          context="primary"
                          largeHorizontalPadding
                          noShadow
                          outline
                          small
                        >
                          <FormattedMessage id={authenticationButtonMessageId} />
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div className="navbar__items-container d-none d-lg-block order-lg-3">
                      <Button
                        classNames="navbar__button"
                        linkComponent={Link}
                        linkProps={{ to: '/login' }}
                        noArrow
                        noBackground
                        noNewTab
                        small
                      >
                        <FormattedMessage id="header.sign_in" />
                      </Button>
                      <Button
                        classNames="navbar__button"
                        context="primary"
                        linkComponent={Link}
                        linkProps={{ to: '/register' }}
                        noArrow
                        noNewTab
                        small
                      >
                        <FormattedMessage id="header.sign_up" />
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="navbar__menu-container d-lg-none order-3">
              <div className="navbar__menu-wrapper">
                <div
                  className="navbar__menu-toggler"
                  role="button"
                  tabIndex={0}
                  onClick={handleMobileMenuTogglerClick}
                  onKeyDown={handleMobileMenuTogglerKeyDown}
                >
                  <img
                    alt="Menu"
                    className="navbar__menu-icon"
                    src={menuIcon}
                  />
                </div>
                <OffScreenSlider
                  show={showMobileMenuSlider}
                  side="right"
                  onClose={handleMobileMenuClose}
                >
                  {showLanguageOptionsList ? (
                    <ul className="navbar__menu-list">
                      {user && (
                        <li className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item__user">
                          {(!!userImageUrl || !!avatarImage) && (
                            <div className="navbar__mobile-menu-list-item__avatar">
                              <img
                                alt={userName}
                                className="navbar__mobile-menu-list-item__avatar-image"
                                src={userImageUrl || avatarImage}
                              />
                            </div>
                          )}
                          <div className="navbar__mobile-menu-list-item__user-info">
                            <div className="navbar__mobile-menu-list-item__user-greeting">
                              <FormattedMessage
                                id="header.greeting"
                                values={{ name: userName }}
                              />
                            </div>
                            {!FormattedMessage && (
                              <>
                                <span className="navbar__mobile-menu-list-item__user-greeting">
                                  Hello
                                  {`${userName ? ',\xa0' : ''}`}
                                </span>
                                {userName || null}
                              </>
                            )}
                            <div className="navbar__mobile-menu-list-item__user-email">
                              {userEmail}
                            </div>
                          </div>
                        </li>
                      )}
                      <li
                        className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-back-chevron"
                        onClick={handleHideLanguageOptionsList}
                      >
                        <FormattedMessage id="header.back" />
                      </li>
                      <LocaleSelect
                        setSelectedLanguage={handleSetCurrentLanguage}
                        showAsList
                      />
                    </ul>
                  ) : (
                    <ul className="navbar__menu-list">
                      {user ? (
                        <>
                          <li className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item__user">
                            {(!!userImageUrl || !!avatarImage) && (
                              <div className="navbar__mobile-menu-list-item__avatar">
                                <img
                                  alt={userName}
                                  className="navbar__mobile-menu-list-item__avatar-image"
                                  src={userImageUrl || avatarImage}
                                />
                              </div>
                            )}
                            <div className="navbar__mobile-menu-list-item__user-info">
                              <div className="navbar__mobile-menu-list-item__user-greeting">
                                <FormattedMessage
                                  id="header.greeting"
                                  values={{ name: userName }}
                                />
                              </div>
                              {!FormattedMessage && (
                                <>
                                  <span className="navbar__mobile-menu-list-item__user-greeting">
                                    Hello
                                    {`${userName ? ',\xa0' : ''}`}
                                  </span>
                                  {userName || null}
                                </>
                              )}
                              <div className="navbar__mobile-menu-list-item__user-email">
                                {userEmail}
                              </div>
                            </div>
                          </li>
                          <li
                            className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-chevron"
                            onClick={handleShowLanguageOptionsList}
                          >
                            <img
                              alt="Language Icon"
                              className="navbar__mobile-menu-list-item__language-icon"
                              src={currentLanguage?.icon}
                            />
                            <span>
                              {currentLanguage?.text}
                            </span>
                          </li>
                          <li
                            className="navbar__mobile-menu-list-item"
                            role="button"
                            /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
                            tabIndex={10}
                            onClick={handleNavbarChangeAddress}
                            onKeyDown={handleNavbarChangeAddressKeyDown}
                          >
                            {user?.default_address?.address?.streetAddress || <FormattedMessage id="header.add_default_address" />}
                          </li>
                          <a
                            className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-border"
                            href="/account"
                          >
                            <FormattedMessage id="header.edit_profile" />
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className="navbar__mobile-menu-list-item navbar__mobile-menu-list-link--orange-text"
                            href="/login"
                          >
                            <FormattedMessage id="header.sign_in" />
                          </a>
                          <a
                            className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--orange-background navbar__mobile-menu-list-link--white-text"
                            href="/register"
                          >
                            <FormattedMessage id="header.sign_up" />
                          </a>
                          <li
                            className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-chevron"
                            onClick={handleShowLanguageOptionsList}
                          >
                            <img
                              alt="Language Icon"
                              className="navbar__mobile-menu-list-item__language-icon"
                              src={currentLanguage?.icon}
                            />
                            <span>
                              {currentLanguage?.text}
                            </span>
                          </li>
                        </>
                      )}
                      <a
                        className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                        href={MARKETING_WEBSITE_URL}
                      >
                        <FormattedMessage id="header.navigation_links.go_to_website" />
                      </a>
                      {isAuthenticated && (
                        <li
                          className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item__logout"
                          onClick={handleLogoutClick}
                        >
                          <FormattedMessage id="header.sign_out" />
                        </li>
                      )}
                    </ul>
                  )}
                </OffScreenSlider>
              </div>
            </div>
            <div className="navbar__menu-container d-none d-lg-block order-lg-1">
              <div className="navbar__menu-wrapper">
                {!isCurrentPageRegister && !isCurrentPageLogin && (
                  <>
                    <div
                      className="navbar__menu-toggler"
                      role="button"
                      tabIndex={0}
                      onClick={handleMenuTogglerClick}
                      onKeyDown={handleMenuTogglerKeyDown}
                    >
                      <img
                        alt="Menu"
                        className="navbar__menu-icon"
                        src={menuIcon}
                      />
                    </div>
                    <Dropdown
                      autoWidth
                      className="navbar__menu-dropdown"
                      hasBackgroundOverlay
                      horizontalPosition={horizontalPosition}
                      show={showMenuDropdown}
                      onClose={handleDropdownClose}
                    >
                      <ul className="navbar__menu-list">
                        <li className="navbar__menu-list-item">
                          <a
                            className="navbar__menu-list-link"
                            href={MARKETING_WEBSITE_URL}
                          >
                            <FormattedMessage id="header.navigation_links.go_to_website" />
                          </a>
                        </li>
                      </ul>
                    </Dropdown>
                    {!!user && !isCurrentPageRegister && (
                      <div className="navbar__address d-none d-lg-flex">
                        <div className="navbar__icon-wrapper">
                          <img
                            alt="Address Icon"
                            className="navbar__icon"
                            src={addressIcon}
                          />
                        </div>
                        <div
                          className={`navbar__address-text${!address?.streetAddress ? ' navbar__address-text--underline' : ''}`}
                          role="button"
                          /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
                          tabIndex={10}
                          onClick={handleNavbarChangeAddress}
                          onKeyDown={handleNavbarChangeAddressKeyDown}
                        >
                          {address?.streetAddress || <FormattedMessage id="header.add_default_address" />}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      {showAddressModal && (
        <AddressModal
          currentAddress={userAddress}
          type="user"
          onClose={handleAddressModalClose}
          onSave={handleAddressModalSave}
        />
      )}
    </>
  );
};

export default Navbar;
