import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ShoppingApi from '@youship/api/shopping';

import formatPrice from '@youship/utils/format-price';
import paymentIcon from '@youship/utils/payment-icons';

import './styles.scss';

const OrderPayment = ({ payment, resume, onOrderPayment }) => {
  const paymentMethodCode = payment?.method?.code;
  const paymentMethodName = payment?.method?.name;
  const priceDetails = resume?.priceDetails;
  const [paymentConfirmation, setPaymentConfirmation] = useState(null);
  const ysToken = payment?.ysToken;

  useEffect(() => {
    if (ysToken) {
      setPaymentConfirmation({ message: 'Validating payment…' });

      // eslint-disable-next-line camelcase
      ShoppingApi.confirmPayment({ ys_token: ysToken })
        .then((response) => {
          setPaymentConfirmation(response);

          if (response.success) {
            onOrderPayment(paymentMethodCode);
          }

          return response;
        })
        .catch((error) => {
          setPaymentConfirmation({ message: error?.message || 'Could not confirm payment, please reload the page.' });
        });
    }
  }, [onOrderPayment, paymentMethodCode, ysToken]);

  const paymentConfirmationMessage = typeof paymentConfirmation?.message === 'string' ? paymentConfirmation.message : null;
  const paymentConfirmed = typeof payment?.isdone === 'boolean' ? payment?.isdone : null;

  return (
    <>
      {(!!paymentMethodName && !!priceDetails) && (
        <div className="order-payment">
          <h3 className="order-payment__title">
            <FormattedMessage id="order.payment" />
          </h3>
          <div className="order-payment__description">
            <FormattedMessage id="order.payment_method" />
          </div>
          <div className="order-payment__type">
            {paymentMethodCode ? (
              <img
                alt="Card Icon"
                className="order-payment__type-icon"
                src={paymentIcon(paymentMethodCode)}
              />
            ) : paymentMethodName}
          </div>
          <ul className="order-payment__details">
            {Array.isArray(priceDetails) && !!priceDetails.length && priceDetails.map((entry, index) => (
              <li
                key={index}
                className="order-payment__details-item"
              >
                <div className="order-payment__details-title">
                  {entry.text}
                </div>
                <div className="order-payment__details-value order-payment__details-value">
                  {formatPrice(entry.value)}
                </div>
              </li>
            ))}
          </ul>
          {!!paymentConfirmationMessage && (
            <div className={`order-payment__confirmation${
              paymentConfirmed === true ? ' order-payment__confirmation--success' : ''
            }${
              paymentConfirmed === false ? ' order-payment__confirmation--error' : ''
            }`}
            >
              {paymentConfirmationMessage}
            </div>
          )}
        </div>
      )}
    </>
  );
};

OrderPayment.propTypes = {
  onOrderPayment: PropTypes.func,
  payment: PropTypes.shape({
    paymentMethodCode: PropTypes.string,
    paymentMethodName: PropTypes.string,
    ysToken: PropTypes.string
  }),
  resume: PropTypes.shape({
    priceDetails: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    }))
  })
};

OrderPayment.defaultProps = {
  onOrderPayment: () => {},
  payment: null,
  resume: null
};

export default OrderPayment;
