import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';

import { submitMessage } from 'store/slices/messages';
import { getDeliveryDetails, sendPublicDeliveryMessage } from 'store/slices/deliveries';
import { getUser, selectUserProfileImageUrl } from 'store/slices/authentication';

import Button from '@youship/components/objects/button';
import OrderMessage from 'components/order/message';

import avatarImage from 'images/avatar.jpg';

import './styles.scss';

const OrderMessages = ({ orderId, messages, publicDeliveryMessage }) => {
  const dispatch = useDispatch();
  const userProfileImageUrlFromState = useSelector(selectUserProfileImageUrl);

  const [errorMessage, setErrorMessage] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [userProfileImageUrl, setUserProfileImageUrl] = useState(userProfileImageUrlFromState);
  const [publicMessageSuccess, setPublicMessageSuccess] = useState(false);

  useEffect(() => {
    if (!userProfileImageUrl && !publicDeliveryMessage) {
      dispatch(getUser())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while loading user data.');

          return response;
        })
        .then((response) => {
          const userImage = response.payload.photoUrl;

          setUserProfileImageUrl(userImage);

          return response;
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
  }, [dispatch, userProfileImageUrl, publicDeliveryMessage]);

  const handleMessageInputChange = (value) => {
    setUserMessage(value);
  };

  const handleMessageError = (value) => {
    setErrorMessage(value);
  };

  const handleMessageFormSubmit = (event) => {
    event.preventDefault();

    const action = publicDeliveryMessage ?
      dispatch(sendPublicDeliveryMessage({
        orderId,
        publicMessage: userMessage
      })) :
      dispatch(submitMessage({
        orderId,
        message: userMessage
      }));

    action
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while submitting your message.');

        return response;
      })
      .then((response) => {
        if (publicDeliveryMessage) {
          setPublicMessageSuccess(true);
          setTimeout(() => {
            setPublicMessageSuccess(false);
          }, 2000);
        } else {
          dispatch(getDeliveryDetails(orderId));
        }

        setUserMessage('');

        return response;
      })
      .catch((error) => {
        setErrorMessage(error?.message);
      });
  };

  const isMessageValid = !!userMessage;

  const sortedMessages = Array.isArray(messages) ?
    JSON.parse(JSON.stringify(messages)) :
    null;

  // Sort in reverse chronological order (most recent is sorted first)
  if (sortedMessages) {
    sortedMessages.sort((a, b) => {
      if (moment(b.dateSent).isBefore(a.dateSent)) return -1;
      if (moment(a.dateSent).isBefore(b.dateSent)) return 1;

      return 0;
    });
  }

  const intl = useIntl();

  return (
    <div className="order-messages">
      <h3 className="order-messages__title">
        <FormattedMessage id="order.messages" />
      </h3>
      <div className="order-messages__description">
        <FormattedMessage id="order.message.description" />
      </div>
      {!!errorMessage && (
        <div className="order-messages__errors">
          <p>
            {errorMessage}
          </p>
        </div>
      )}
      {!!publicMessageSuccess && (
        <div className="order-messages__success">
          <p>
            <FormattedMessage id="order.message.success" />
          </p>
        </div>
      )}
      <form
        className="order-messages__input-wrapper"
        onSubmit={handleMessageFormSubmit}
      >
        <img
          alt="Avatar"
          className="order-messages__avatar"
          src={userProfileImageUrl ?? avatarImage}
        />
        <input
          className="order-messages__input"
          placeholder={ intl.formatMessage({ id: 'order.message.placeholder' })}
          value={userMessage}
          onChange={event => handleMessageInputChange(event?.target?.value ?? '')}
        />
        <Button
          className="order-messages__button"
          disabled={!isMessageValid}
          type="submit"
        />
      </form>
      {!!sortedMessages && sortedMessages.map((message, index) => (
        <OrderMessage
          key={index}
          message={message}
          orderId={orderId}
          userPhotoUrl={userProfileImageUrl}
          onError={handleMessageError}
        />
      ))}
    </div>
  );
};

OrderMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()),
  orderId: PropTypes.string,
  publicDeliveryMessage: PropTypes.bool
};

OrderMessages.defaultProps = {
  messages: null,
  orderId: null,
  publicDeliveryMessage: false
};

export default OrderMessages;
