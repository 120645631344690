import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';
import Rating from '@youship/components/objects/rating';

import avatarImage from 'images/avatar.jpg';
import greenCheckIcon from 'images/icons/check-green.svg';
import closeIcon from 'images/icons/close.svg';

import './styles.scss';

const ReviewModal = ({ finalMessage, isLoading, name, onClose, onConfirm, onRate, photoUrl, rating }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'order.review.title' }, { name });

  return (
    <Modal
      small
      onClose={!isLoading ? onClose : () => {}}
    >
      <div className="review-modal">
        <img
          alt="Close Icon"
          className="review-modal__close-icon"
          src={closeIcon}
          onClick={!isLoading ? onClose : () => {}}
        />
        {finalMessage ? (
          <>
            <Notice
              classNames="review-modal__notice"
              description={finalMessage}
              fixed
              icon={greenCheckIcon}
            />
            <div className="review-modal__button-wrapper">
              <Button
                context="primary"
                disabled={isLoading}
                largeHorizontalPadding
                text={intl.formatMessage({ id: 'order.review.finish' })}
                onClick={!isLoading ? onClose : () => {}}
              />
            </div>
          </>
        ) : (
          <>
            <img
              alt="Transporter Avatar"
              className="review-modal__avatar"
              src={photoUrl || avatarImage}
            />
            <div className="review-modal__title">
              {title}
            </div>
            <div className="review-modal__description">
              <FormattedMessage id="order.review.description" />
            </div>
            <Rating
              clickable
              large
              value={rating}
              onChange={onRate}
            />
            <div className="review-modal__button-wrapper">
              <Button
                context="primary"
                disabled={isLoading}
                largeHorizontalPadding
                text={intl.formatMessage({ id: 'order.review.button' })}
                onClick={onConfirm}
              />
            </div>
            <div
              className="review-modal__dismiss"
              disabled={isLoading}
              onClick={!isLoading ? onClose : () => {}}
            >
              <FormattedMessage id="order.review.do_later" />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

ReviewModal.propTypes = {
  finalMessage: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onRate: PropTypes.func,
  rating: PropTypes.number
};

ReviewModal.defaultProps = {
  description: null,
  finalMessage: null,
  name: null,
  onClose: () => {},
  onConfirm: () => {},
  onRate: () => {},
  rating: 0
};

export default ReviewModal;
