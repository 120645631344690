import React, { useState } from 'react';
import PropTypes from 'prop-types';

import starIcon from '../../../assets/images/icons/star.svg';
import starSolidIcon from '../../../assets/images/icons/star-solid.svg';

import './styles.scss';

const STARS_TOTAL = 5;

const Rating = ({ value, large, clickable, onChange }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [selectedValue, setSelectedValue] = useState(0);

  const starClick = (newRatingValue) => {
    if (newRatingValue === selectedValue) {
      setSelectedValue(0);
    } else {
      setSelectedValue(newRatingValue);
    }

    onChange(newRatingValue);
  };

  const starsElements = [];

  for (let i = 1; i < STARS_TOTAL + 1; i += 1) {
    starsElements.push((
      <div
        key={i}
        className="rating__star"
      >
        <img
          alt="Star Icon"
          className="rating__star-image"
          src={value >= i || selectedValue >= i || currentValue >= i ? starSolidIcon : starIcon}
          onClick={() => clickable && starClick(i)}
          onMouseEnter={() => clickable && setCurrentValue(i)}
          onMouseLeave={() => clickable && setCurrentValue(0)}
        />
      </div>
    ));
  }

  return (
    <div className={`rating${large ? ' rating--large' : ''}${clickable ? ' rating--clickable' : ''}`}>
      {starsElements}
    </div>
  );
};

Rating.propTypes = {
  clickable: PropTypes.bool,
  large: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number
};

Rating.defaultProps = {
  clickable: false,
  large: false,
  onChange: () => {},
  value: 0
};

export default Rating;
