import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { markNotificationAsSeen, selectPushNotifications, unpushNotifications } from 'store/slices/notifications';

import Notification from '@youship/components/notification';

import './styles.scss';

const PushNotification = ({ durationInMiliseconds, max }) => {
  const dispatch = useDispatch();

  const notifications = useSelector(selectPushNotifications);

  useEffect(() => {
    if (notifications.length) {
      const slice = notifications.slice(0, max);
      const ids = slice.map(notification => notification.id);

      setTimeout(
        () => {
          dispatch(unpushNotifications(ids));
        },
        durationInMiliseconds
      );
    }
  }, [dispatch, durationInMiliseconds, max, notifications]);

  if (!notifications.length) return null;

  const slice = notifications.slice(0, max);

  const handlePushNotificationClick = (id) => {
    dispatch(markNotificationAsSeen(id));
  };

  return (
    <div className="push-notifications">
      {slice.map((notification, index) => (
        <div
          key={index}
          className="push-notifications__notification"
          onClick={() => handlePushNotificationClick(notification.id)}
        >
          <Notification
            {...notification}
            compact
            linkPath={null}
            showTimeDifference
          />
        </div>
      ))}
    </div>
  );
};

PushNotification.propTypes = {
  durationInMiliseconds: PropTypes.number,
  max: PropTypes.number
};

PushNotification.defaultProps = {
  durationInMiliseconds: 10000,
  max: 4
};

export default PushNotification;
