/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';

import mapIcon from 'images/icons/map.svg';

import './styles.scss';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDSq8lMU2FUvhWFPNPPCyn_viuDE5t8FPI';

const StaticMap = ({ classNames, location, markerIcon, name, instructions }) => (
  <div className={`static-map${classNames ? ` ${classNames}` : ''}`}>
    {location ? (
      <>
        <img
          className="static-map__image"
          src={
            `https://maps.googleapis.com/maps/api/staticmap?center=${location}
            &zoom=12&size=3000x2000&maptype=roadmap&key=${GOOGLE_MAPS_API_KEY}`
          }
          alt="Location"
        />
        <img
          className="static-map__marker"
          src={markerIcon}
          alt="Location Marker"
        />
        {name && (
          <div className="static-map__location-name">
            {name}
          </div>
        )}
      </>
    ) : (
      <div className="static-map__placeholder">
        <img
          className="static-map__placeholder-icon"
          src={mapIcon}
          alt="Map Icon"
        />
        {instructions}
      </div>
    )}
  </div>
);


StaticMap.propTypes = {
  classNames: PropTypes.string,
  instructions: PropTypes.string,
  location: PropTypes.string,
  markerIcon: PropTypes.string,
  name: PropTypes.string
};

StaticMap.defaultProps = {
  classNames: null,
  instructions: 'Please add an adress.',
  location: null,
  markerIcon: null,
  name: null
};

export default StaticMap;
