import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import OrdersList from 'components/sections/orders-list';

const Orders = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const redirectUrl = query.get('redirectUrl');

  // The goal of redirecting from the orders page is to use it for the exterior payment page so that the user lands on this route if going back from the payment
  // page (the redirectUrl is assigned when pushing to this route after order creation).
  // The redirect should only happen if history action is a push, to prevent the redirect to the exterior payment page when the user comes from it.
  if (redirectUrl && history.action === 'PUSH') window.location.assign(redirectUrl);

  return (
    <>
      <OrdersList />
    </>
  );
};

export default Orders;
