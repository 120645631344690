import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUser, selectIsAuthenticated, selectUser } from 'store/slices/authentication';
import {
  addDeliveryDropoff,
  addDeliveryPickup,
  addDeliveryShipment,
  addDeliveryShipmentItems,
  addDeliveryStop,
  addDeliveryStopShipment,
  createDelivery,
  getDeliveryQuote,
  removeDeliveryDropoff,
  removeDeliveryPickup,
  removeDeliveryShipment,
  removeDeliveryShipmentItems,
  removeDeliveryStop,
  resetDelivery,
  selectAutoAddShipmentItems,
  selectDeliveryDistance,
  selectDeliveryDuration,
  selectDeliveryIsLoading,
  selectDeliveryQuote,
  selectDeliveryQuoteIsLoading,
  selectDestinationSchedule,
  selectDropoffs,
  selectHasReturn,
  selectOriginSchedule,
  selectPaymentTypeId,
  selectPickups,
  selectRates,
  selectServiceCode,
  selectShipmentOptions,
  selectShipments,
  selectShipType,
  selectStops,
  setAddressForDropoff,
  setAddressForPickup,
  setAddressForStop,
  setContactForDropoff,
  setContactForPickup,
  setContactForStop,
  setDeliveryDropoffs,
  setDeliveryHasReturn,
  setDeliveryPickups,
  setDeliveryStops,
  setDestinationScheduleDate,
  setDestinationScheduleTimeSlotValue,
  setNotesForDropoff,
  setNotesForPickup,
  setNotesForStop,
  setOriginScheduleDate,
  setOriginScheduleTimeSlotValue,
  setPaymentTypeId,
  setServiceCode,
  setShipmentForDropoff,
  setShipmentForPickup,
  setShipmentForStop,
  setShipmentOptions,
  setShipType,
  updateDeliveryShipmentItem
} from 'store/slices/new-delivery';

import { DELIVERY_QUOTE_DISTANCE_ERROR_CODE } from '@youship/api/delivery';

import ActionModal from 'components/order/action-modal';
import Button from '@youship/components/objects/button';
import Input from '@youship/components/objects/input';
import DateSelect from 'components/date-select';
import Dropdown from '@youship/components/objects/dropdown';
import HourSelect from '@youship/components/objects/hour-select';
import Modal from '@youship/components/objects/modal';
import Notice from '@youship/components/objects/notice';
import Quantity from '@youship/components/objects/quantity';
import Radio from '@youship/components/objects/radio';
import Select from '@youship/components/objects/select';
import Textarea from '@youship/components/objects/textarea';
import Tooltip from '@youship/components/objects/tooltip';

import AddressModal from '@youship/components/address-modal';
import ShipmentOptionsNotesModal from 'components/new-order/shipment-options/information-modal';
import ShipmentOptionsOption from 'components/new-order/shipment-options/option';
import ShipmentOptionsShipmentDetails from 'components/new-order/shipment-options/shipment-details';

import chevronDownIcon from 'images/icons/chevron-down.svg';
import checkOrangeIcon from 'images/icons/check-orange.svg';
import infoGrayIcon from 'images/icons/info-gray.svg';

import locationIcon from 'images/icons/location.svg';
import userIcon from 'images/icons/user.svg';
import noticeIcon from 'images/icons/notice.svg';

import chevronLeftIcon from 'images/icons/chevron-left.svg';
import truckIcon from 'images/icons/truck.svg';
import boxIcon from 'images/icons/box.svg';

import paymentIcon from '@youship/utils/payment-icons';

import mapIcon from 'images/icons/map.svg';
import clockIcon from 'images/icons/clock.svg';
import couponIcon from 'images/icons/coupon.svg';
// Not used for now
// import searchIcon from 'images/icons/search.svg';
import fileIcon from 'images/icons/file.svg';

import deleteIcon from 'images/icons/close.svg';
import trashIcon from 'images/icons/trash.svg';
import trashRedIcon from 'images/icons/trash--red.svg';

import normalShipmentMethodIcon from 'images/icons/shipment-methods/normal.svg';
import multipleDropoffsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-dropoffs.svg';
import multiplePickupsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-pickups.svg';
import multipleStopsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-stops.svg';

import './styles.scss';

const validDiscountCodes = [
  'YOU12'
];

// The order types were discarded in the latest design iteration
// This array can be removed if we don't find any use for it
/* const orderTypes = [
  {
    value: 'fast',
    name: 'Fast (courier)',
    icon: truckIcon
  },
  {
    value: 'express',
    name: 'Express',
    icon: truckIcon
  },
  {
    value: 'takeaway',
    name: 'Takeaway',
    icon: bagIcon
  },
  {
    value: 'proposal',
    name: 'Order proposal',
    icon: proposalIcon
  }
]; */

const shipmentMethods = [
  {
    value: 'normal',
    name: 'new-order.shipment_methods.m1.name',
    description: 'new-order.shipment_methods.m1.description',
    icon: normalShipmentMethodIcon
  },
  {
    value: 'multiple_dropoffs',
    name: 'new-order.shipment_methods.m2.name',
    description: 'new-order.shipment_methods.m2.description',
    icon: multipleDropoffsShipmentMethodIcon,
    disabled: true
  },
  {
    value: 'multiple_pickups',
    name: 'new-order.shipment_methods.m3.name',
    description: 'new-order.shipment_methods.m3.description',
    icon: multiplePickupsShipmentMethodIcon,
    disabled: true
  },
  {
    value: 'multiple_stops',
    name: 'new-order.shipment_methods.m4.name',
    description: 'new-order.shipment_methods.m4.description',
    icon: multipleStopsShipmentMethodIcon,
    disabled: true
  }
];

const formatShipSubtitle = shipment => `${
  shipment.specifications.weight
} Kg · ${
  shipment.specifications.length
}×${
  shipment.specifications.width
}×${
  shipment.specifications.height
} cm`;

const NewOrderShipmentOptions = ({ onRouteChange, onDeliveryError, onStartLocationChange, onEndLocationChange, width }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const autoAddShipment = useSelector(selectAutoAddShipmentItems);
  const deliveryDistance = useSelector(selectDeliveryDistance);
  const deliveryDuration = useSelector(selectDeliveryDuration);
  const deliveryQuote = useSelector(selectDeliveryQuote);
  const deliveryQuoteIsLoading = useSelector(selectDeliveryQuoteIsLoading);
  const deliveryHasReturn = useSelector(selectHasReturn);
  const dropoffSchedule = useSelector(selectDestinationSchedule);
  const pickupSchedule = useSelector(selectOriginSchedule);
  const orderIsLoading = useSelector(selectDeliveryIsLoading);
  const paymentTypeId = useSelector(selectPaymentTypeId);
  const shipType = useSelector(selectShipType);
  const dropoffs = useSelector(selectDropoffs);
  const pickups = useSelector(selectPickups);
  const stops = useSelector(selectStops);
  const serviceCode = useSelector(selectServiceCode);
  const shipmentsOptions = useSelector(selectShipmentOptions);
  const shipmentsItems = useSelector(selectShipments);
  const shipmentsRates = useSelector(selectRates);
  const user = useSelector(selectUser);

  const pickupScheduleDate = pickupSchedule?.pickupDate || null;
  const pickupScheduleTimeSlotValue = pickupScheduleDate ? pickupSchedule?.pickupTimeSlotValue || null : null;
  const paymentMessage = deliveryQuote?.paymentMessage || null;
  const paymentTypes = deliveryQuote?.paymentTypes || null;
  const priceDetails = deliveryQuote?.priceDetails || null;
  const priceTotal = deliveryQuote?.total || null;
  const schedulePickupIntervals = deliveryQuote?.schedulePickupIntervals || null;
  const allowedPickupDates = schedulePickupIntervals ? Object.entries(schedulePickupIntervals).map(date => date?.[1].pickupDate) : null;
  const getDeliveryQuoteTimeout = useRef();

  const [deliveryDistanceErrorValue, setDeliveryDistanceErrorValue] = useState(null);
  const [deliveryDurationErrorValue, setDeliveryDurationErrorValue] = useState(null);
  const [deliveryError, setDeliveryError] = useState(false);
  const [pickupHourSelectInstructions, setPickupHourSelectInstructions] = useState('');
  const [pickupTimeSlots, setPickupTimeSlots] = useState([]);

  const disabled = !deliveryQuote || deliveryQuoteIsLoading || orderIsLoading;

  // Default Values
  const addressDefault = {
    apartmentAddress: '',
    city: '',
    formattedAddress: '',
    numberAddress: '',
    postalCode: '',
    streetAddress: ''
  };

  const searchDefault = {
    search: ''
  };

  const contactDefault = {
    name: '',
    company: '',
    countryCallingCode: '351',
    phone: ''
  };

  const defaultShipmentOptionsValues = {
    reference: '',
    comments: '',
    shipper: '',
    fragile: false,
    return: false,
    handDelivery: false,
    marketing: false,
    chargeValue: 0,
    chargeInfo: '',
    statedValue: 0,
    insuredValue: 0
  };

  const notesDefault = '';

  // Pickups, Dropoffs and Stops
  const [pickupIndexSelected, setPickupIndexSelected] = useState(null);
  const [dropoffIndexSelected, setDropoffIndexSelected] = useState(null);
  const [stopIndexSelected, setStopIndexSelected] = useState(null);

  const isPickupAddressFilled = index => !!pickups[index]?.address?.streetAddress && !!pickups[index]?.address?.postalCode;

  // Pickup Contact
  const [showPickupContactModal, setShowPickupContactModal] = useState(false);
  const [isPickupContactValid, setIsPickupContactValid] = useState(false);
  const [modalPickupContact, setModalPickupContact] = useState({
    name: pickups[pickupIndexSelected]?.contact?.name ?? '',
    company: pickups[pickupIndexSelected]?.contact?.company ?? '',
    countryCallingCode: pickups[pickupIndexSelected]?.contact?.countryCallingCode && pickups[pickupIndexSelected].contact.countryCallingCode !== 'null' ? pickups[pickupIndexSelected].contact.countryCallingCode : '351',
    phone: pickups[pickupIndexSelected]?.contact?.phone ?? ''
  });

  const isPickupContactFilled = index => !!pickups[index]?.contact?.name && !!pickups[index]?.contact?.phone;
  const arePickupNotesFilled = index => !!pickups[index]?.notes;

  // Dropoff Contact
  const [showDropoffContactModal, setShowDropoffContactModal] = useState(false);
  const [isDropoffContactValid, setIsDropoffContactValid] = useState(false);
  const [modalDropoffContact, setModalDropoffContact] = useState({
    name: dropoffs[dropoffIndexSelected]?.contact?.name ?? '',
    company: dropoffs[dropoffIndexSelected]?.contact?.company ?? '',
    countryCallingCode: dropoffs[dropoffIndexSelected]?.contact?.countryCallingCode && dropoffs[dropoffIndexSelected].contact.countryCallingCode !== 'null' ? dropoffs[dropoffIndexSelected].contact.countryCallingCode : '351',
    phone: dropoffs[dropoffIndexSelected]?.contact?.phone ?? ''
  });

  const isDropoffContactFilled = index => !!dropoffs[index]?.contact?.name && !!dropoffs[index]?.contact?.phone;
  const areDropoffNotesFilled = index => !!dropoffs[index]?.notes;

  const handleAddContactButtonClick = (type, index) => {
    if (type === 'pickup') {
      setPickupIndexSelected(index);
      openPickupContactModal(index);
    } else if (type === 'dropoff') {
      setDropoffIndexSelected(index);
      openDropoffContactModal(index);
    } else if (type === 'stop') {
      setStopIndexSelected(index);
      openStopContactModal(index);
    }
  };

  const handlePickupContactModalClose = () => {
    closePickupContactModal();
  };

  const handleContactModalSave = (type) => {
    if (type === 'pickup') {
      dispatch(setContactForPickup({ modalPickupContact, pickupIndexSelected }));

      closePickupContactModal();
    } else if (type === 'dropoff') {
      dispatch(setContactForDropoff({ modalDropoffContact, dropoffIndexSelected }));

      closeDropoffContactModal();
    } else if (type === 'stop') {
      dispatch(setContactForStop({ modalStopContact, stopIndexSelected }));

      closeStopContactModal();
    }
  };

  const openPickupContactModal = (index) => {
    setModalPickupContact(pickups[index].contact ?
      { ...pickups[index].contact } :
      {
        company: '',
        countryCallingCode: '351',
        name: '',
        phone: ''
      });
    setShowPickupContactModal(true);
  };

  const closePickupContactModal = () => {
    setShowPickupContactModal(false);
    setModalPickupContact(null);
  };

  const handlePickupContactEdit = (index) => {
    setPickupIndexSelected(index);
    openPickupContactModal(index);
  };

  useEffect(() => {
    setIsPickupContactValid(!!modalPickupContact?.name && typeof modalPickupContact.phone === 'string' && modalPickupContact?.phone?.length > 6);
  }, [modalPickupContact]);

  useEffect(() => {
    if (dropoffs[dropoffIndexSelected]) {
      dispatch(setDeliveryDropoffs(dropoffs));
    }
  }, [dispatch, dropoffIndexSelected, dropoffs]);

  useEffect(() => {
    if (pickups[pickupIndexSelected]) {
      dispatch(setDeliveryPickups(pickups));
    }
  }, [dispatch, pickupIndexSelected, pickups]);

  useEffect(() => {
    if (stops[stopIndexSelected]) {
      dispatch(setDeliveryStops(stops));
    }
  }, [dispatch, stopIndexSelected, stops]);

  useEffect(() => {
    if (!dropoffs.length) {
      dispatch(addDeliveryDropoff({
        address: { ...addressDefault },
        contact: { ...contactDefault },
        details: { ...defaultShipmentOptionsValues },
        search: { ...searchDefault },
        notes: notesDefault
      }));
      dispatch(removeDeliveryShipmentItems());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dropoffs.length, searchDefault, user]);

  useEffect(() => {
    if (!user?.defaultAddress) {
      dispatch(addDeliveryPickup({
        address: { ...addressDefault },
        contact: { ...contactDefault },
        details: { ...defaultShipmentOptionsValues },
        search: { ...searchDefault },
        notes: notesDefault
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Shipment
  const orderType = shipmentsItems && shipmentsItems[0] && shipmentsItems[0][0] ? shipmentsItems[0][0].specifications.serviceCode : null;

  const filteredShipmentOptions = orderType ?
  shipmentsRates?.filter(option => option.service?.code === orderType) :
    shipmentsRates;

  const [showShipmentModal, setShowShipmentModal] = useState(false);
  const [selectedShipmentOption, setSelectedShipmentOption] = useState({
    description: '',
    icon: '',
    id: '',
    name: '',
    code: ''
  });

  const selectedShipmentSpecificationsDefault = {
    autoAdd: false,
    code: '',
    quantity: 1,
    quantities: null,
    description: '',
    weight: null,
    weights: null,
    length: null,
    lengths: null,
    width: null,
    widths: null,
    height: null,
    heights: null
  };

  const [
    selectedShipmentSpecifications,
    setSelectedShipmentSpecifications
  ] = useState(selectedShipmentSpecificationsDefault);
  const [isShipmentEdit, setIsShipmentEdit] = useState(false);
  const [shipmentEditIndex, setShipmentEditIndex] = useState(null);
  const [shipmentCurrentStep, setShipmentCurrentStep] = useState(1);
  const [shipmentIndexSelected, setShipmentIndexSelected] = useState(null);

  const [shipmentMethod, setShipmentMethod] = useState({
    value: 'normal',
    name: 'new-order.shipment_methods.m1.name',
    description: 'new-order.shipment_methods.m1.description',
    icon: normalShipmentMethodIcon
  });

  const intl = useIntl();

  useEffect(() => {
    if (shipmentMethod?.value) {
      dispatch(setShipType(shipmentMethod?.value));
    }
  }, [dispatch, shipmentMethod]);

  useEffect(() => {
    if (shipType) {
      const type = shipType;

      switch (type) {
        case 'multiple_dropoffs':
          setShipmentMethod({
            value: 'multiple_dropoffs',
            name: 'Multiple Dropoffs',
            description: '1 Pickup with multiple dropoffs',
            icon: multipleDropoffsShipmentMethodIcon
          });
          break;
        case 'multiple_pickups':
          setShipmentMethod({
            value: 'multiple_pickups',
            name: 'Multiple Pickups',
            description: 'Multiple pickups and 1 dropoff',
            icon: multiplePickupsShipmentMethodIcon
          });
          break;
        case 'multiple_stops':
          setShipmentMethod({
            value: 'multiple_stops',
            name: 'Multiple Stops',
            description: '1 Dropoff and 1 pickup with multiple Stops',
            icon: multipleStopsShipmentMethodIcon
          });
          break;
        default:
          setShipmentMethod({
            value: 'normal',
            name: 'new-order.shipment_methods.m1.name',
            description: 'new-order.shipment_methods.m1.description',
            icon: normalShipmentMethodIcon
          });
      }
    }
  }, [shipType]);

  const isShipmentModalContinueButtonValid = () => shipmentCurrentStep !== 2 || isSelectedShipmentSpecificationsValid;

  const isSelectedShipmentSpecificationsValid = selectedShipmentSpecifications.quantity &&
    selectedShipmentSpecifications.weight &&
    selectedShipmentSpecifications.length &&
    selectedShipmentSpecifications.width &&
    selectedShipmentSpecifications.height &&
    selectedShipmentSpecifications.description;

  const isShipmentFilled = index => shipmentsItems[index]?.length;

  const isShipmentDetailsFilled = (index) => {
    let isShipmentValid = false;

    if ((dropoffs[index]?.details || pickups[index]?.details) && shipmentsItems[index]?.length > 0) {
      if (hasReturn || shipmentMethod.value === 'multiple_pickups') {
        if (shipmentsOptions.hasReference && shipmentsOptions.hasShipper) {
          isShipmentValid = !!pickups[index].details.reference &&
            !!pickups[index].details.shipper &&
            !!dropoffs[index].details.reference &&
            !!dropoffs[index].details.shipper;
        } else if (shipmentsOptions.hasReference) {
          isShipmentValid = !!pickups[index].details.reference &&
            !!dropoffs[index].details.reference;
        } else if (shipmentsOptions.hasShipper) {
          isShipmentValid = !!pickups[index].details.shipper &&
            !!dropoffs[index].details.shipper;
        }
      } else if (shipmentsOptions.hasReference && shipmentsOptions.hasShipper) {
        isShipmentValid = !!dropoffs[index].details.reference && !!dropoffs[index].details.shipper;
      } else if (shipmentsOptions.hasReference) {
        isShipmentValid = !!dropoffs[index].details.reference;
      } else if (shipmentsOptions.hasShipper) {
        isShipmentValid = !!dropoffs[index].details.shipper;
      } else {
        isShipmentValid = true;
      }
    }

    return isShipmentValid;
  };

  const handleSelectedShipmentOption = (value, optionItem) => {
    setSelectedShipmentOption({
      description: optionItem.description,
      icon: optionItem.iconUrl,
      id: value,
      name: optionItem.name,
      code: optionItem.code,
      serviceCode: optionItem.serviceCode
    });

    setSelectedShipmentSpecifications({
      autoAdd: optionItem.autoAdd,
      code: optionItem.code,
      description: optionItem.autoAdd ? optionItem.name : '',
      quantities: optionItem.quantities,
      quantity: 1,
      weight: optionItem.formattedWeight.value,
      weights: optionItem.formattedWeights,
      length: optionItem.formattedLength.value,
      lengths: optionItem.formattedLengths,
      width: optionItem.formattedWidth.value,
      widths: optionItem.formattedWidths,
      height: optionItem.formattedHeight.value,
      heights: optionItem.formattedHeights,
      serviceCode: optionItem.serviceCode
    });
  };

  const handleShipmentModalBackButtonClick = () => {
    // This is needed as workaround since the button on the modal immediately changes
    // And is recognized as a click outside the modal and the modal closes
    setTimeout(() => {
      setShipmentCurrentStep(prevStep => prevStep - 1);
    }, 0);
  };

  const handleShipmentModalContinueButtonClick = () => {
    if (shipmentCurrentStep === 1 && (!selectedShipmentSpecifications.autoAdd || isShipmentEdit)) {
      setShipmentCurrentStep(prevStep => prevStep + 1);
    } else {
      if (isShipmentEdit) {
        dispatch(updateDeliveryShipmentItem({ shipmentEditIndex, shipmentIndexSelected, selectedShipmentOption, selectedShipmentSpecifications }));
      } else {
        dispatch(addDeliveryShipmentItems({
          shipmentIndexSelected,
          selectedShipmentOption,
          selectedShipmentSpecifications
        }));
        dispatch(setServiceCode(selectedShipmentSpecifications.serviceCode));
      }
      dispatchRequestDeliveryQuote();
      closeShipmentModal();
    }
  };

  const handleShipmentModalClose = () => {
    closeShipmentModal();
    setShipmentCurrentStep(1);
  };

  const closeShipmentModal = () => {
    setShowShipmentModal(false);
    setSelectedShipmentOption({
      description: '',
      icon: '',
      id: '',
      name: '',
      code: ''
    });
    setSelectedShipmentSpecifications(selectedShipmentSpecificationsDefault);
    setShipmentCurrentStep(1);
    setIsShipmentEdit(false);
  };

  const handleAddShipmentButtonClick = (index) => {
    setShipmentIndexSelected(index);

    if (autoAddShipment) {
      setSelectedShipmentOption({
        description: autoAddShipment[index].description,
        icon: autoAddShipment[index].iconUrl,
        id: autoAddShipment[index].id,
        name: autoAddShipment[index].name,
        code: autoAddShipment[index].code,
        serviceCode: autoAddShipment[index].serviceCode
      });

      setSelectedShipmentSpecifications({
        autoAdd: autoAddShipment[index].autoAdd,
        code: autoAddShipment[index].code,
        description: autoAddShipment[index].name,
        quantities: autoAddShipment[index].quantities,
        quantity: 1,
        weight: autoAddShipment[index].formattedWeight.value,
        weights: autoAddShipment[index].formattedWeights,
        length: autoAddShipment[index].formattedLength.value,
        lengths: autoAddShipment[index].formattedLengths,
        width: autoAddShipment[index].formattedWidth.value,
        widths: autoAddShipment[index].formattedWidths,
        height: autoAddShipment[index].formattedHeight.value,
        heights: autoAddShipment[index].formattedHeights,
        serviceCode: autoAddShipment[index].serviceCode
      });
    }

    openShipmentModal();
  };

  const openShipmentModal = () => {
    setShowShipmentModal(true);
  };

  const handleShipmentOptionEdit = (index, shipmentIndex) => {
    setIsShipmentEdit(true);
    setShipmentIndexSelected(index);
    setShipmentEditIndex(shipmentIndex);
    setSelectedShipmentOption({
      description: shipmentsItems[index][shipmentIndex].info.description,
      icon: shipmentsItems[index][shipmentIndex].info.icon,
      id: shipmentsItems[index][shipmentIndex].info.id,
      name: shipmentsItems[index][shipmentIndex].info.name,
      code: shipmentsItems[index][shipmentIndex].info.code,
      serviceCode: shipmentsItems[index][shipmentIndex].info.serviceCode
    });

    setSelectedShipmentSpecifications({
      autoAdd: shipmentsItems[index][shipmentIndex].specifications.autoAdd,
      code: shipmentsItems[index][shipmentIndex].specifications.code,
      quantity: shipmentsItems[index][shipmentIndex].specifications.quantity,
      quantities: shipmentsItems[index][shipmentIndex].specifications.quantities,
      description: shipmentsItems[index][shipmentIndex].specifications.description,
      weight: shipmentsItems[index][shipmentIndex].specifications.weight,
      weights: shipmentsItems[index][shipmentIndex].specifications.weights,
      length: shipmentsItems[index][shipmentIndex].specifications.length,
      lengths: shipmentsItems[index][shipmentIndex].specifications.lengths,
      width: shipmentsItems[index][shipmentIndex].specifications.width,
      widths: shipmentsItems[index][shipmentIndex].specifications.widths,
      height: shipmentsItems[index][shipmentIndex].specifications.height,
      heights: shipmentsItems[index][shipmentIndex].specifications.heights,
      serviceCode: shipmentsItems[index][shipmentIndex].specifications.serviceCode
    });
    openShipmentModal();
  };

  const handleShipmentOptionDelete = (index, shipmentIndex) => {
    dispatch(removeDeliveryShipmentItems({ index, shipmentIndex }));
    dispatchRequestDeliveryQuote();
  };

  const dropoffScheduleDate = dropoffSchedule?.dropoffDate || null;
  const dropoffScheduleTimeSlotValue = dropoffScheduleDate ? dropoffSchedule?.dropoffTimeSlotValue || null : null;
  const [dropoffHourSelectInstructions, setDropoffHourSelectInstructions] = useState('');
  const [dropoffTimeSlots, setDropoffTimeSlots] = useState([]);
  const scheduleDropoffIntervals = deliveryQuote?.scheduleDropoffIntervals || null;
  const allowedDropoffDates = scheduleDropoffIntervals ? Object.entries(scheduleDropoffIntervals).map(date => date?.[1].dropoffDate) : null;

  const isDropoffAddressFilled = index => !!dropoffs[index]?.address?.streetAddress && !!dropoffs[index]?.address?.postalCode;

  const handleDropoffContactModalClose = () => {
    closeDropoffContactModal();
  };

  const openDropoffContactModal = (index) => {
    setModalDropoffContact(dropoffs[index].contact ?
      { ...dropoffs[index].contact } :
      {
        company: '',
        countryCallingCode: '351',
        name: '',
        phone: ''
      });
    setShowDropoffContactModal(true);
  };

  const closeDropoffContactModal = () => {
    setShowDropoffContactModal(false);
    setModalDropoffContact(null);
  };

  const handleDropoffContactEdit = (index) => {
    setDropoffIndexSelected(index);
    openDropoffContactModal(index);
  };

  useEffect(() => {
    setIsDropoffContactValid(!!modalDropoffContact?.name && typeof modalDropoffContact.phone === 'string' && modalDropoffContact.phone.length > 6);
  }, [modalDropoffContact]);

  // Stop

  const isStopAddressFilled = index => !!stops[index]?.address?.streetAddress && !!stops[index]?.address?.postalCode;

  // Stop Contact

  const [showStopContactModal, setShowStopContactModal] = useState(false);
  const [isStopContactValid, setIsStopContactValid] = useState(false);
  const [modalStopContact, setModalStopContact] = useState(null);

  const isStopContactFilled = index => !!stops[index]?.contact?.name && !!stops[index]?.contact?.phone;
  const areStopNotesFilled = index => !!stops[index]?.notes;

  const handleStopContactModalClose = () => {
    closeStopContactModal();
  };

  const openStopContactModal = (index) => {
    const contact = stops[index]?.contact || {};

    setModalStopContact({ ...contact });
    setShowStopContactModal(true);
  };

  const closeStopContactModal = () => {
    setShowStopContactModal(false);
    setModalStopContact(null);
  };

  const handleStopContactEdit = (index) => {
    setStopIndexSelected(index);
    openStopContactModal(index);
  };

  useEffect(() => {
    setIsStopContactValid(modalStopContact?.name && typeof modalStopContact.phone === 'string' && modalStopContact.phone.length > 6);
  }, [modalStopContact]);

  // Return
  const [hasReturn, setHasReturn] = useState(false);

  useEffect(() => {
    setHasReturn(deliveryHasReturn);
  }, [deliveryHasReturn]);

  const handleAddReturnButtonClick = () => {
    dispatch(setDeliveryHasReturn({ hasReturn: true, shipmentsItems }));
    dispatchRequestDeliveryQuote();
    setHasReturn(true);
  };

  const handleReturnDeleteButtonClick = () => {
    dispatch(setDeliveryHasReturn({ hasReturn: false, shipmentsItems }));
    dispatchRequestDeliveryQuote();
    setHasReturn(false);
  };

  // Shipment Details

  const handleShipmentDetailsChange = (type, values, index) => {
    clearTimeout(getDeliveryQuoteTimeout.current);

    if (type === 'pickup') {
      setPickupIndexSelected(index);
      dispatch(setShipmentForPickup({ values, index }));
    } else if (type === 'dropoff') {
      setDropoffIndexSelected(index);
      dispatch(setShipmentForDropoff({ values, index }));
    } else if (type === 'stop') {
      setStopIndexSelected(index);
      dispatch(setShipmentForStop({ values, index }));
    }

    getDeliveryQuoteTimeout.current = setTimeout(() => {
      dispatchRequestDeliveryQuote();
    }, 1000);
  };

  // Payment
  const handleSelectedPaymentOption = (selected) => {
    dispatch(setPaymentTypeId(selected));
  };

  // Discount
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [isDiscountCodeValid, setIsDiscountCodeValid] = useState(true);
  const [discountCode, setDiscountCode] = useState('');
  const [modalDiscountCode, setModalDiscountCode] = useState('');

  const handleDiscountModalClose = () => {
    closeDiscountModal();
  };

  const checkDiscountCode = () => validDiscountCodes.includes(modalDiscountCode);

  const handleDiscountCodeInputChange = (value) => {
    setModalDiscountCode(value);
  };

  const handleDiscountCodeButtonClick = () => {
    if (checkDiscountCode()) {
      setIsDiscountCodeValid(true);
      closeDiscountModal();
      setDiscountCode(modalDiscountCode);
    } else {
      setIsDiscountCodeValid(false);
    }
  };

  const handleDeleteDiscountCodeClick = () => {
    setTimeout(() => {
      setDiscountCode('');
      setModalDiscountCode('');
    }, 0);
  };

  const openDiscountModal = () => {
    setShowDiscountModal(true);
  };

  const closeDiscountModal = () => {
    setShowDiscountModal(false);
  };

  const isInfoValid = serviceCode &&
    pickups.every((pickup, index) => isPickupAddressFilled(index) &&
    isPickupContactFilled(index)) &&
    dropoffs.every((dropoff, index) => isDropoffAddressFilled(index) &&
    isDropoffContactFilled(index)) &&
    shipmentsItems.every((shipmentItem, index) => isShipmentFilled(index));

  const isShipmentDetailsInfoValid = serviceCode &&
    pickups.every((pickup, index) => isPickupAddressFilled(index) &&
    isPickupContactFilled(index) &&
    isShipmentDetailsFilled(index)) &&
    dropoffs.every((dropoff, index) => isDropoffAddressFilled(index) &&
    isDropoffContactFilled(index) &&
    isShipmentDetailsFilled(index)) &&
    shipmentsItems.every((shipmentItem, index) => isShipmentFilled(index));

  const isOrderValid = isInfoValid && paymentTypeId && isShipmentDetailsInfoValid;

  // Payment Details

  const handleCreateDeliveryButtonClick = () => {
    dispatch(createDelivery())
      .then((response) => {
        if (response.payload.success) return response.payload;

        throw response.payload;
      })
      .then((response) => {
        const { orderCode, paymentUrl } = response;

        if (orderCode) {
          if (paymentUrl) {
            history.replace(`/order/${orderCode}`);
            window.location = `${paymentUrl}&ys_return_url=${window.location}`;
          } else {
            history.push(`/order/${orderCode}`);
          }
          dispatch(resetDelivery());
        }

        return response;
      })
      .catch((error) => {
        onDeliveryError(error);
        setDeliveryError(true);
      });
  };

  const handleAddAnotherPickupButtonClick = () => {
    addAnotherPickup();
  };

  const addAnotherPickup = () => {
    dispatch(addDeliveryPickup({
      address: { ...addressDefault },
      contact: { ...contactDefault },
      search: { ...searchDefault },
      notes: notesDefault
    }));
    dispatch(addDeliveryShipment());
    dispatchRequestDeliveryQuote();
  };

  const handleAddAnotherStopButtonClick = () => {
    addAnotherStop();
  };

  const addAnotherStop = () => {
    dispatch(addDeliveryStop({
      address: { ...addressDefault },
      contact: { ...contactDefault },
      search: { ...searchDefault },
      notes: notesDefault
    }));
    dispatch(addDeliveryStopShipment());
    dispatchRequestDeliveryQuote();
  };

  const handleAddAnotherDropoffButtonClick = () => {
    addAnotherDropoff();
  };

  const addAnotherDropoff = () => {
    dispatch(addDeliveryDropoff({
      address: { ...addressDefault },
      contact: { ...contactDefault },
      details: { ...defaultShipmentOptionsValues },
      search: { ...searchDefault },
      notes: notesDefault
    }));
    dispatch(addDeliveryShipment());
    dispatchRequestDeliveryQuote();
  };

  const [showShipmentMethodDropdown, setShowShipmentMethodDropdown] = useState(false);

  const handleShipmentMethodDropdownClose = () => {
    closeShipmentMethodDropdown();
  };

  const handleOrderTypeTogglerClick = () => {
    openShipmentMethodDropdown();
  };

  const handleShipmentMethodClick = (method) => {
    setShipmentMethod({ ...method });
    closeShipmentMethodDropdown();
  };

  const openShipmentMethodDropdown = () => {
    setShowShipmentMethodDropdown(true);
  };

  const closeShipmentMethodDropdown = () => {
    setShowShipmentMethodDropdown(false);
  };

  // Tooltip
  const [showTotalInfoTooltip, setShowTotalInfoTooltip] = useState(false);

  const handleTotalInfoMouseEnter = () => {
    setShowTotalInfoTooltip(true);
  };

  const handleTotalInfoTooltipClose = () => {
    closeTotalInfoTooltipClose();
  };

  const closeTotalInfoTooltipClose = () => {
    setShowTotalInfoTooltip(false);
  };

  const mapPickups = pickups.map(pickup => ({
    city: pickup?.address?.city,
    lat: pickup?.address?.lat,
    lng: pickup?.address?.lng,
    postalCode: pickup?.address?.postalCode,
    streetAddress: pickup?.address?.streetAddress
  }));

  const mapStops = stops.map(stop => ({
    city: stop.address?.city,
    lat: stop.address?.lat,
    lng: stop.address?.lng,
    postalCode: stop.address?.postalCode,
    streetAddress: stop.address?.streetAddress
  }));

  const mapDropoffs = dropoffs.map(dropoff => ({
    city: dropoff?.address?.city,
    lat: dropoff?.address?.lat,
    lng: dropoff?.address?.lng,
    postalCode: dropoff?.address?.postalCode,
    streetAddress: dropoff?.address?.streetAddress
  }));

  useEffect(() => {
    onRouteChange({
      pickups: mapPickups,
      stops: mapStops,
      dropoffs: mapDropoffs
    });
  }, [mapPickups, mapStops, mapDropoffs, onRouteChange]);

  const [notesModalNotes, setNotesModalNotes] = useState('');
  const [notesTypeSelected, setNotesTypeSelected] = useState(null);
  const [showNotesModal, setShowNotesModal] = useState(false);

  const handleAddNotesButtonClick = (type, index) => {
    setNotesTypeSelected(type);
    setPickupIndexSelected(index);
    setDropoffIndexSelected(index);
    setStopIndexSelected(index);
    openNotesModal(type, index);
  };

  const openNotesModal = (type, index) => {
    let notes = '';

    if (type === 'pickup') {
      notes = pickups[index].notes;
    } else if (type === 'stop') {
      notes = stops[index].notes;
    } else if (type === 'dropoff') {
      notes = dropoffs[index].notes;
    }

    setNotesModalNotes(notes);
    setShowNotesModal(true);
  };

  const handleNotesModalSave = (notes) => {
    if (notesTypeSelected === 'pickup') {
      dispatch(setNotesForPickup({ notes, pickupIndexSelected }));
    } else if (notesTypeSelected === 'stop') {
      dispatch(setNotesForStop({ notes, stopIndexSelected }));
    } else if (notesTypeSelected === 'dropoff') {
      dispatch(setNotesForDropoff({ notes, dropoffIndexSelected }));
    }

    closeNotesModal();
  };

  const handleNotesModalClose = () => {
    closeNotesModal();
  };

  const closeNotesModal = () => {
    setShowNotesModal(false);
    setNotesTypeSelected(null);
    setNotesModalNotes('');
  };

  const handlePickupDeleteButtonClick = (index) => {
    dispatch(removeDeliveryPickup(index));
    dispatch(removeDeliveryShipment(index));
    dispatchRequestDeliveryQuote();
  };

  const handleStopDeleteButtonClick = (index) => {
    dispatch(removeDeliveryStop(index));
    dispatch(removeDeliveryShipment(index));
    dispatchRequestDeliveryQuote();
  };

  const handleDropoffDeleteButtonClick = (index) => {
    dispatch(removeDeliveryDropoff(index));
    dispatch(removeDeliveryShipment(index));
    dispatchRequestDeliveryQuote();
  };

  const [addressModalAddress, setAddressModalAddress] = useState({
    apartmentAddress: '',
    city: '',
    formattedAddress: '',
    numberAddress: '',
    postalCode: '',
    streetAddress: ''
  });

  const [searchModalAddress, setSearchModalAddress] = useState({ search: '' });
  const [addressTypeSelected, setAddressTypeSelected] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showAddressModalConfirmation, setShowAddressModalConfirmation] = useState(false);

  const handleAddAddressButtonClick = (type, index) => {
    setAddressTypeSelected(type);
    setPickupIndexSelected(index);
    setDropoffIndexSelected(index);
    setStopIndexSelected(index);
    openAddressModal(type, index);
  };

  const openAddressModal = (type, index) => {
    let address = '';
    let search = '';

    if (type === 'pickup') {
      address = pickups[index].address;
      search = pickups[index].search;
    } else if (type === 'stop') {
      address = stops[index].address;
      search = stops[index].search;
    } else if (type === 'dropoff') {
      address = dropoffs[index].address;
      search = dropoffs[index].search;
    }

    setAddressModalAddress(address);
    setSearchModalAddress(search);
    setShowAddressModal(true);
  };

  const openAddressModalConfirmation = (address, contact, search) => {
    setShowAddressModalConfirmation(true);

    if (addressTypeSelected === 'pickup') {
      setAddressModalAddress(address);
      setModalPickupContact(contact);
      setSearchModalAddress(search);
    } else if (addressTypeSelected === 'stop') {
      setAddressModalAddress(address);
      setModalStopContact(contact);
      setSearchModalAddress(search);
    } else if (addressTypeSelected === 'dropoff') {
      setAddressModalAddress(address);
      setModalDropoffContact(contact);
      setSearchModalAddress(search);
    }
  };

  const handleAddressModalConfirmation = () => {
    dispatch(removeDeliveryShipmentItems());
    dispatchRequestDeliveryQuote();
    handleAddressModalSave(addressModalAddress, searchModalAddress);
  };

  const handleAddressModalSave = (address, contact, search) => {
    if (addressTypeSelected === 'pickup') {
      onStartLocationChange(address);
      dispatch(setAddressForPickup({ pickupIndexSelected, address, search }));
      if (contact) dispatch(setContactForPickup({ contact, pickupIndexSelected }));
    } else if (addressTypeSelected === 'stop') {
      onEndLocationChange(address);
      dispatch(setAddressForStop({ stopIndexSelected, address, search }));
      if (contact) dispatch(setContactForStop({ contact, stopIndexSelected }));
    } else if (addressTypeSelected === 'dropoff') {
      onEndLocationChange(address);
      dispatch(setAddressForDropoff({ dropoffIndexSelected, address, search }));
      if (contact) dispatch(setContactForDropoff({ contact, dropoffIndexSelected }));
    }

    closeAddressModal();
    closeAddressModalConfirmation();
  };

  const handleAddressModalSelect = (address, contact, search) => {
    if (isInfoValid) {
      openAddressModalConfirmation(address, contact, search);
    } else {
      setShowAddressModalConfirmation(false);
      handleAddressModalSave(address, contact, search);
    }
  };

  const handleAddressModalClose = () => {
    closeAddressModal();
  };

  const closeAddressModal = () => {
    setShowAddressModal(false);
    setAddressTypeSelected(null);
    setAddressModalAddress({});
  };

  const closeAddressModalConfirmation = () => {
    setShowAddressModalConfirmation(false);
  };

  const formatAddressDescription = (address) => {
    const addressOptionsArray = [
      address?.numberAddress || null,
      address?.apartmentAddress || null,
      address?.postalCode || null,
      address?.city || null
    ];

    const formattedAddress = addressOptionsArray.filter(Boolean).join(' • ');

    return formattedAddress;
  };

  const handleDropoffDateSelectChange = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');
    const isBeforePickupDate = moment(formattedDate).isBefore(dropoffScheduleDate);

    dispatch(setDestinationScheduleDate(formattedDate));
    if (isBeforePickupDate) dispatch(setOriginScheduleDate(formattedDate));
    dispatchRequestDeliveryQuote();
  };

  const handleDropoffHourSelectChange = (value) => {
    dispatch(setDestinationScheduleTimeSlotValue(value));
    dispatchRequestDeliveryQuote();
  };

  const handlePickupDateSelectChange = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');
    const isAfterDropoffDate = moment(formattedDate).isAfter(dropoffScheduleDate);

    dispatch(setOriginScheduleDate(formattedDate));
    if (isAfterDropoffDate) dispatch(setDestinationScheduleDate(formattedDate));
    dispatchRequestDeliveryQuote();
  };

  const handlePickupHourSelectChange = (value) => {
    dispatch(setOriginScheduleTimeSlotValue(value));
    dispatchRequestDeliveryQuote();
  };

  const handleResetDeliveryButtonClick = () => {
    dispatch(resetDelivery());
    dispatch(getUser())
      .then((response) => {
        const { defaultAddress } = response.payload;

        if (!defaultAddress) {
          dispatch(addDeliveryPickup({
            address: { ...addressDefault },
            contact: { ...contactDefault },
            details: { ...defaultShipmentOptionsValues },
            search: { ...searchDefault },
            notes: notesDefault
          }));
        }

        return response;
      })
      .catch((error) => {
        // NOTE: add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (schedulePickupIntervals) {
      if (pickupScheduleDate) {
        const schedulePickupInterval = schedulePickupIntervals.find(interval => interval.pickupDate === pickupScheduleDate);

        if (schedulePickupInterval) {
          setPickupTimeSlots(schedulePickupInterval.pickupTimeSlots);
          setPickupHourSelectInstructions('Select one');
        } else {
          setPickupTimeSlots([]);
          setPickupHourSelectInstructions('No time slots for the chosen date');
        }
      } else {
        setPickupTimeSlots([]);
        setPickupHourSelectInstructions('Choose a date');
      }
    }
  }, [pickupScheduleDate, schedulePickupIntervals]);

  useEffect(() => {
    if (scheduleDropoffIntervals) {
      if (dropoffScheduleDate) {
        const scheduleDropoffInterval = scheduleDropoffIntervals.find(interval => interval.dropoffDate === dropoffScheduleDate);

        if (scheduleDropoffInterval) {
          setDropoffTimeSlots(scheduleDropoffInterval.dropoffTimeSlots);
          setDropoffHourSelectInstructions('Select one');
        } else {
          setDropoffTimeSlots([]);
          setDropoffHourSelectInstructions('No time slots for the chosen date');
        }
      } else {
        setDropoffTimeSlots([]);
        setDropoffHourSelectInstructions('Choose a date');
      }
    }
  }, [dropoffScheduleDate, scheduleDropoffIntervals]);

  const handleDeliveryQuoteError = (error) => {
    if (error?.errorcode === DELIVERY_QUOTE_DISTANCE_ERROR_CODE) {
      setDeliveryError(true);
      setDeliveryDistanceErrorValue(error.resume?.distance);
      setDeliveryDurationErrorValue(error.resume?.duration);
    }
  };

  const dispatchRequestDeliveryQuote = () => {
    onDeliveryError(null);
    setDeliveryDistanceErrorValue(false);
    setDeliveryDurationErrorValue(false);
    setDeliveryError(false);

    dispatch(getDeliveryQuote())
      .then((response) => {
        if (response?.error) {
          if (response.payload) {
            handleDeliveryQuoteError(response.payload);
            onDeliveryError(response.payload);
            throw new Error(response.payload.message || 'Something went wrong while requesting a quote for your delivery.');
          } else {
            onDeliveryError(response.error);
            setDeliveryError(false);
            throw new Error(response.message || 'Something went wrong while requesting a quote for your delivery.');
          }
        }

        return response;
      })
      .catch((error) => {
        // TODO: Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (isInfoValid) {
      dispatch(getDeliveryQuote());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoAddShipment && Array.isArray(shipmentsItems[0]) && shipmentsItems[0].length < 1) {
      dispatch(setServiceCode(autoAddShipment.serviceCode));
    }
  }, [autoAddShipment, dispatch, shipmentsItems]);

  useEffect(() => {
    if (shipmentsRates &&
      Array.isArray(shipmentsItems[0]) &&
      shipmentsItems[0].length
    ) {
      const rates = shipmentsRates.find(rate => rate.service.code === shipmentsItems[0][0].info?.serviceCode);

      dispatch(setShipmentOptions(rates?.service?.options));
    }
  }, [dispatch, shipmentsItems, shipmentsRates]);

  return (
    <div
      className={`new-order-shipment-options ${!isAuthenticated ? 'new-order-shipment-options--disabled' : ''}`}
      style={{ width: !isAuthenticated ? `${width}px` : 'auto' }}
    >
      <div className="new-order-shipment-options__header">
        <h1 className="new-order-shipment-options__header-title">
          <FormattedMessage id="new-order.new" />
        </h1>
        <div className="new-order-shipment-options__header-buttons">
          {isDropoffAddressFilled(0) && (
            <div className="new-order-shipment-options__reset-delivery">
              <Button
                context="primary"
                noBackground
                noPadding
                noShadow
                text={intl.formatMessage({ id: 'new-order.reset' })}
                onClick={handleResetDeliveryButtonClick}
              />
            </div>
          )}
          <div className="new-order-shipment-options__shipment-method">
            <div
              className={`new-order-shipment-options__shipment-method-toggler${showShipmentMethodDropdown ? ' new-order-shipment-options__shipment-method-toggler--open' : ''}${shipmentsItems?.length > 1 || shipmentsItems[0]?.length ? ' new-order-shipment-options__shipment-method-toggler--disabled' : ''}`}
              onClick={handleOrderTypeTogglerClick}
            >
              <div className="new-order-shipment-options__shipment-method-icon-wrapper">
                <img
                  alt="Order Type Icon"
                  src={shipmentMethod.icon}
                />
              </div>
              <div className="new-order-shipment-options__shipment-method-text">
                <FormattedMessage id={shipmentMethod.name} />
              </div>
              <div className="new-order-shipment-options__shipment-method-chevron">
                <img
                  alt="Toggler Icon"
                  src={chevronDownIcon}
                />
              </div>
            </div>
            <Dropdown
              autoWidth
              className="shipment-method-dropdown"
              show={showShipmentMethodDropdown}
              onClose={handleShipmentMethodDropdownClose}
            >
              <ul className="shipment-method-dropdown__list">
                {shipmentMethods.map((method, index) => (
                  <li
                    key={index}
                    className={`shipment-method-dropdown__list-item${method.value === shipmentMethod.value ? ' shipment-method-dropdown__list-item--selected' : ''}${method.disabled ? ' shipment-method-dropdown__list-item--disabled' : ''}`}
                    onClick={() => (!method.disabled ? handleShipmentMethodClick(method) : () => {})}
                  >
                    <img
                      alt="Order Type icon"
                      className="shipment-method-dropdown__list-icon"
                      src={method.icon}
                    />
                    <div className="shipment-method-dropdown__text">
                      <FormattedMessage id={method.name} />
                      <div className="shipment-method-dropdown__description">
                        <FormattedMessage id={method.description} />
                      </div>
                    </div>
                    {method.value === shipmentMethod.value && (
                      <img
                        alt="Selected Type"
                        className="shipment-method-dropdown__selected-icon"
                        src={checkOrangeIcon}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="new-order-shipment-options__route">
        {pickups.map((pickup, index) => (
          <div
            key={index}
            className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--start"
          >
            <h3 className="new-order-shipment-options__title">
              <FormattedMessage id="new-order.pickup.title" />
              {index !== 0 && ` #${index + 1}`}
              {' '}
              *
              {shipmentMethod.value === 'multiple_pickups' && index !== 0 && (
                <img
                  alt="Delete"
                  className="new-order-shipment-options__delete-icon"
                  src={trashIcon}
                  onClick={() => handlePickupDeleteButtonClick(index)}
                  onMouseOut={(event) => {
                    event.currentTarget.src = trashIcon;
                  }}
                  onMouseOver={(event) => {
                    event.currentTarget.src = trashRedIcon;
                  }}
                />
              )}
            </h3>
            <div className="new-order-shipment-options__description">
              <FormattedMessage id="new-order.pickup.description" />
            </div>
            <div className="new-order-shipment-options__action">
              {!isPickupAddressFilled(index) ? (
                <Button
                  block
                  highlightedBorder={!isPickupAddressFilled(0)}
                  icon={truckIcon}
                  light
                  lightBorder
                  outline
                  text={intl.formatMessage({ id: 'new-order.pickup.add_address' })}
                  onClick={() => handleAddAddressButtonClick('pickup', index)}
                />
              ) : (
                <div>
                  <ShipmentOptionsOption
                    description={formatAddressDescription(pickup.address)}
                    hasError={deliveryError}
                    icon={locationIcon}
                    title={pickup.address.streetAddress}
                    onEdit={() => handleAddAddressButtonClick('pickup', index)}
                  />
                </div>
              )}
            </div>
            {isPickupAddressFilled(index) && (
              <div className="new-order-shipment-options__action">
                {!isPickupContactFilled(index) ? (
                  <Button
                    block
                    highlightedBorder={!isPickupContactFilled(0)}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.pickup.add_contact' })}
                    onClick={() => handleAddContactButtonClick('pickup', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={`+${pickup.contact.countryCallingCode} ${pickup.contact.phone}`}
                    icon={userIcon}
                    title={pickup.contact.name}
                    onEdit={() => handlePickupContactEdit(index)}
                  />
                )}
              </div>
            )}
            {isPickupContactFilled(index) && (
              <div className="new-order-shipment-options__action">
                {!arePickupNotesFilled(index) ? (
                  <Button
                    block
                    icon={fileIcon}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.pickup.add_notes' })}
                    onClick={() => handleAddNotesButtonClick('pickup', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={pickup?.notes || ''}
                    icon={fileIcon}
                    title={intl.formatMessage({ id: 'new-order.pickup.notes' })}
                    onEdit={() => handleAddNotesButtonClick('pickup', index)}
                  />
                )}
              </div>
            )}
            {shipmentMethod.value === 'multiple_pickups' && (
              <div className="new-order-shipment-options__shipment-wrapper">
                <h3 className="new-order-shipment-options__title">
                  <FormattedMessage id="new-order.shipment.title" />
                </h3>
                <div className="new-order-shipment-options__description">
                  <FormattedMessage id="new-order.shipment.description" />
                </div>
                {Array.isArray(shipmentsItems[index]) && shipmentsItems[index].map((shipment, shipmentIndex) => (
                  <div
                    key={shipmentIndex}
                    className="new-order-shipment-options__action"
                  >
                    <ShipmentOptionsOption
                      deletable
                      description={formatShipSubtitle(shipment)}
                      icon={shipment.info?.icon}
                      title={shipment.specifications?.description || shipment.info?.name}
                      onDelete={() => handleShipmentOptionDelete(index, shipmentIndex)}
                      onEdit={() => handleShipmentOptionEdit(index, shipmentIndex)}
                    />
                  </div>
                ))}
                <div className="new-order-shipment-options__action">
                  <div className="row">
                    <div className="col col-12">
                      <Button
                        block
                        highlightedBorder={!isShipmentFilled(0)}
                        icon={boxIcon}
                        light
                        lightBorder
                        outline
                        // text={`Add Shipment${!shipmentsItems[index]?.length ? ' *' : ''}`}
                        text={shipmentsItems[index]?.length ?
                          intl.formatMessage({ id: 'new-order.shipment.add_shipment_more' }) :
                          intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                        onClick={() => handleAddShipmentButtonClick(index)}
                      />
                    </div>
                    {/* Hidden for now */}
                    {/* <div className="col col-6">
                      <Input
                        backgroundImage={searchIcon}
                        block
                        inputId="search-reference"
                        placeholder="Search by reference"
                      />
                    </div> */}
                  </div>
                </div>
                {Array.isArray(shipmentsItems[index]) && !!shipmentsItems[index].length && (
                  <ShipmentOptionsShipmentDetails
                    details={shipmentsOptions || {}}
                    values={pickup?.details || {}}
                    onChange={values => handleShipmentDetailsChange('pickup', values, index)}
                  />
                )}
              </div>
            )}
          </div>
        ))}
        {shipmentMethod.value === 'multiple_pickups' && (
          <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
            <div
              className="new-order-shipment-options__add-option"
              onClick={() => handleAddAnotherPickupButtonClick()}
            >
              <FormattedMessage id="new-order.stop.add_pickup" />
            </div>
          </div>
        )}
        {stops.map((stop, index) => (
          <div
            key={index}
            className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--middle"
          >
            <h3 className="new-order-shipment-options__title">
              <FormattedMessage id="new-order.stop.title" />
              {index !== 0 && ` #${index + 1}`}
              {' '}
              *
              {shipmentMethod.value === 'multiple_stops' && index !== 0 && (
                <img
                  alt="Delete"
                  className="new-order-shipment-options__delete-icon"
                  src={trashIcon}
                  onClick={() => handleStopDeleteButtonClick(index)}
                  onMouseOut={(event) => {
                    event.currentTarget.src = trashIcon;
                  }}
                  onMouseOver={(event) => {
                    event.currentTarget.src = trashRedIcon;
                  }}
                />
              )}
            </h3>
            <div className="new-order-shipment-options__description">
              <FormattedMessage id="new-order.stop.description" />
            </div>
            <div className="new-order-shipment-options__action">
              {!isStopAddressFilled(index) ? (
                <Button
                  block
                  icon={truckIcon}
                  light
                  lightBorder
                  outline
                  text={intl.formatMessage({ id: 'new-order.stop.add_address' })}
                  onClick={() => handleAddAddressButtonClick('stop', index)}
                />
              ) : (
                <div>
                  <ShipmentOptionsOption
                    description={formatAddressDescription(stop.address)}
                    icon={locationIcon}
                    title={stop.address.streetAddress}
                    onEdit={() => handleAddAddressButtonClick('stop', index)}
                  />
                </div>
              )}
            </div>
            {isStopAddressFilled(index) && (
              <div className="new-order-shipment-options__action">
                {!isStopContactFilled(index) ? (
                  <Button
                    block
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.stop.add_contact' })}
                    onClick={() => handleAddContactButtonClick('stop', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={`+${stop.contact.countryCallingCode} ${stop.contact.phone}`}
                    icon={userIcon}
                    title={stop.contact.name}
                    onEdit={() => handleStopContactEdit(index)}
                  />
                )}
              </div>
            )}
            {isStopContactFilled(index) && (
              <div className="new-order-shipment-options__action">
                {!areStopNotesFilled(index) ? (
                  <Button
                    block
                    icon={fileIcon}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.stop.add_notes' })}
                    onClick={() => handleAddNotesButtonClick('stop', index)}
                  />
                ) : (
                  <ShipmentOptionsOption
                    description={stop?.notes || ''}
                    icon={fileIcon}
                    title={intl.formatMessage({ id: 'new-order.stop.notes' })}
                    onEdit={() => handleAddNotesButtonClick('stop', index)}
                  />
                )}
              </div>
            )}
            <div className="new-order-shipment-options__shipment-wrapper">
              <h3 className="new-order-shipment-options__title">
                <FormattedMessage id="new-order.shipment.title" />
              </h3>
              <div className="new-order-shipment-options__description">
                <FormattedMessage id="new-order.shipment.description" />
              </div>
              {shipmentsItems[index].map((shipment, shipmentIndex) => (
                <div
                  key={shipmentIndex}
                  className="new-order-shipment-options__action"
                >
                  <ShipmentOptionsOption
                    deletable
                    description={formatShipSubtitle(shipment)}
                    icon={shipment.info?.icon}
                    title={shipment.specifications?.description || shipment.info?.name}
                    onDelete={() => handleShipmentOptionDelete(index, shipmentIndex)}
                    onEdit={() => handleShipmentOptionEdit(index, shipmentIndex)}
                  />
                </div>
              ))}
            </div>
            <div className="new-order-shipment-options__action">
              <div className="row">
                <div className="col col-12">
                  <Button
                    block
                    highlightedBorder={!isShipmentFilled(0)}
                    icon={boxIcon}
                    light
                    lightBorder
                    outline
                    // text={`Add Shipment${!shipmentsItems[index]?.length ? ' *' : ''}`}
                    text={shipmentsItems[index]?.length ?
                      intl.formatMessage({ id: 'new-order.shipment.add_shipment_more' }) :
                      intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                    onClick={() => handleAddShipmentButtonClick(index)}
                  />
                </div>
                {/* Hidden for now */}
                {/* <div className="col col-6">
                  <Input
                    backgroundImage={searchIcon}
                    block
                    inputId="search-reference"
                    placeholder="Search by reference"
                  />
                </div> */}
              </div>
            </div>
            {Array.isArray(shipmentsItems[index]) && !!shipmentsItems[index].length && (
              <ShipmentOptionsShipmentDetails
                details={shipmentsOptions || {}}
                values={stop?.details || {}}
                onChange={values => handleShipmentDetailsChange('stop', values, index)}
              />
            )}
          </div>
        ))}
        {shipmentMethod.value === 'multiple_stops' && (
          <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
            <div
              className="new-order-shipment-options__add-option"
              onClick={() => handleAddAnotherStopButtonClick()}
            >
              <FormattedMessage id="new-order.stop.add_stop" />
            </div>
          </div>
        )}
        {dropoffs.map((dropoff, index) => {
          const dropoffShipmentItem = shipmentsItems[shipmentMethod.value === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index];

          return (
            <div
              key={index}
              className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--end"
            >
              <h3 className="new-order-shipment-options__title">
                <FormattedMessage id="new-order.dropoff.title" />
                {index !== 0 && ` #${index + 1}`}
                {' '}
                *
                {shipmentMethod.value === 'multiple_dropoffs' && index !== 0 && (
                  <img
                    alt="Delete"
                    className="new-order-shipment-options__delete-icon"
                    src={trashIcon}
                    onClick={() => handleDropoffDeleteButtonClick(index)}
                    onMouseOut={(event) => {
                      event.currentTarget.src = trashIcon;
                    }}
                    onMouseOver={(event) => {
                      event.currentTarget.src = trashRedIcon;
                    }}
                  />
                )}
              </h3>
              <div className="new-order-shipment-options__description">
                <FormattedMessage id="new-order.dropoff.description" />
              </div>
              <div className="new-order-shipment-options__action">
                {!isDropoffAddressFilled(index) ? (
                  <Button
                    block
                    highlightedBorder={!isDropoffAddressFilled(0)}
                    icon={truckIcon}
                    light
                    lightBorder
                    outline
                    text={intl.formatMessage({ id: 'new-order.dropoff.add_address' })}
                    onClick={() => handleAddAddressButtonClick('dropoff', index)}
                  />
                ) : (
                  <div>
                    <ShipmentOptionsOption
                      description={formatAddressDescription(dropoff.address)}
                      hasError={deliveryError}
                      icon={locationIcon}
                      title={dropoff.address.streetAddress}
                      onEdit={() => handleAddAddressButtonClick('dropoff', index)}
                    />
                  </div>
                )}
              </div>
              {isDropoffAddressFilled(index) && (
                <div className="new-order-shipment-options__action">
                  {!isDropoffContactFilled(index) ? (
                    <Button
                      block
                      highlightedBorder={!isDropoffContactFilled(0)}
                      light
                      lightBorder
                      outline
                      text={intl.formatMessage({ id: 'new-order.dropoff.add_contact' })}
                      onClick={() => handleAddContactButtonClick('dropoff', index)}
                    />
                  ) : (
                    <ShipmentOptionsOption
                      description={`+${dropoff.contact.countryCallingCode} ${dropoff.contact.phone}`}
                      icon={userIcon}
                      title={dropoff.contact.name}
                      onEdit={() => handleDropoffContactEdit(index)}
                    />
                  )}
                </div>
              )}
              {isDropoffContactFilled(index) && (
                <div className="new-order-shipment-options__action">
                  {!areDropoffNotesFilled(index) ? (
                    <Button
                      block
                      icon={fileIcon}
                      light
                      lightBorder
                      outline
                      text={intl.formatMessage({ id: 'new-order.dropoff.add_notes' })}
                      onClick={() => handleAddNotesButtonClick('dropoff', index)}
                    />
                  ) : (
                    <ShipmentOptionsOption
                      description={dropoff?.notes || ''}
                      icon={fileIcon}
                      title={intl.formatMessage({ id: 'new-order.dropoff.notes' })}
                      onEdit={() => handleAddNotesButtonClick('dropoff', index)}
                    />
                  )}
                </div>
              )}
              {shipmentMethod.value !== 'multiple_pickups' && (
                <div className={`${
                  index === dropoffs.length - 1 && !hasReturn ? ' new-order-shipment-options__route-filler' : ''}${
                  !isPickupAddressFilled(0) ||
                  !isDropoffAddressFilled(0) ?
                    ' new-order-shipment-options__route-filler--disabled' :
                    ''}
                `}
                >
                  <div className="new-order-shipment-options__shipment-wrapper">
                    <h3 className="new-order-shipment-options__title">
                      <FormattedMessage id="new-order.shipment.title" />
                    </h3>
                    <div className="new-order-shipment-options__description">
                      <FormattedMessage id="new-order.shipment.description" />
                    </div>
                    {Array.isArray(dropoffShipmentItem) && dropoffShipmentItem.map((shipment, shipmentIndex) => (
                      <div
                        key={shipmentIndex}
                        className="new-order-shipment-options__action"
                      >
                        <ShipmentOptionsOption
                          deletable
                          // description={shipment.info?.description}
                          description={formatShipSubtitle(shipment)}
                          icon={shipment.info?.icon}
                          title={shipment.specifications?.description || shipment.info?.name}
                          onDelete={() => handleShipmentOptionDelete(shipmentMethod.value === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index, shipmentIndex)}
                          onEdit={() => handleShipmentOptionEdit(shipmentMethod.value === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index, shipmentIndex)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="new-order-shipment-options__action">
                    <div className="row">
                      <div className="col col-12">
                        <Button
                          block
                          highlightedBorder={!isShipmentFilled(0)}
                          icon={boxIcon}
                          light
                          lightBorder
                          outline
                          // text={`Add Shipment${!shipmentsItems[index]?.length ? ' *' : ''}`}
                          text={shipmentsItems[index]?.length ?
                            intl.formatMessage({ id: 'new-order.shipment.add_shipment_more' }) :
                            intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                          onClick={() => handleAddShipmentButtonClick(shipmentMethod.value === 'multiple_stops' && stops.length ? shipmentsItems.length - 1 : index)}
                        />
                      </div>
                      {/* Hidden for now */}
                      {/* <div className="col col-6">
                        <Input
                          backgroundImage={searchIcon}
                            block
                          inputId="search-reference"
                          placeholder="Search by reference"
                        />
                      </div> */}
                    </div>
                  </div>
                  {Array.isArray(shipmentsItems[index]) && !!shipmentsItems[index].length && (
                    <ShipmentOptionsShipmentDetails
                      details={shipmentsOptions || {}}
                      values={dropoff?.details || {}}
                      onChange={values => handleShipmentDetailsChange('dropoff', values, index)}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
        {hasReturn && Array.isArray(shipmentsItems) && (
          <div className="new-order-shipment-options__option new-order-shipment-options__route-circle new-order-shipment-options__route-circle--return">
            <h3 className="new-order-shipment-options__title">
              <FormattedMessage id="new-order.return.title" />
              <img
                alt="Delete"
                className="new-order-shipment-options__delete-icon"
                src={trashIcon}
                onClick={() => handleReturnDeleteButtonClick()}
                onMouseOut={(event) => {
                  event.currentTarget.src = trashIcon;
                }}
                onMouseOver={(event) => {
                  event.currentTarget.src = trashRedIcon;
                }}
              />
            </h3>
            <div className="new-order-shipment-options__action">
              <ShipmentOptionsOption
                description={formatAddressDescription(pickups[0]?.address)}
                edit={false}
                icon={locationIcon}
                title={pickups[0]?.address?.streetAddress}
              />
            </div>
            <div className="new-order-shipment-options__route-filler">
              <div className="new-order-shipment-options__shipment-wrapper">
                <h3 className="new-order-shipment-options__title">
                  <FormattedMessage id="new-order.shipment.title" />
                </h3>
                <div className="new-order-shipment-options__description">
                  <FormattedMessage id="new-order.shipment.description" />
                </div>
                {Array.isArray(shipmentsItems[shipmentsItems.length - 1]) && shipmentsItems[shipmentsItems.length - 1].map((shipment, shipmentIndex) => (
                  <div
                    key={shipmentIndex}
                    className="new-order-shipment-options__action"
                  >
                    <ShipmentOptionsOption
                      deletable
                      description={formatShipSubtitle(shipment)}
                      icon={shipment.info?.icon}
                      title={shipment.specifications?.description || shipment.info?.name}
                      onDelete={() => handleShipmentOptionDelete(shipmentsItems.length - 1, shipmentIndex)}
                      onEdit={() => handleShipmentOptionEdit(shipmentsItems.length - 1, shipmentIndex)}
                    />
                  </div>
                ))}
              </div>
              <div className="new-order-shipment-options__action">
                <div className="row">
                  <div className="col col-12">
                    <Button
                      block
                      highlightedBorder={!isShipmentFilled(0)}
                      icon={boxIcon}
                      light
                      lightBorder
                      outline
                      text={intl.formatMessage({ id: 'new-order.shipment.add_shipment' })}
                      onClick={() => handleAddShipmentButtonClick(shipmentsItems.length - 1)}
                    />
                  </div>
                  {/* Hidden for now */}
                  {/* <div className="col col-6">
                    <Input
                      backgroundImage={searchIcon}
                      block
                      inputId="search-reference"
                      placeholder="Search by reference"
                    />
                  </div> */}
                </div>
              </div>
              {Array.isArray(shipmentsItems[1]) && !!shipmentsItems[1].length && (
                <ShipmentOptionsShipmentDetails
                  details={shipmentsOptions || {}}
                  values={pickups[0]?.details || {}}
                  onChange={values => handleShipmentDetailsChange('pickup', values, 0)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
        {shipmentMethod.value === 'normal' && !hasReturn && !!isDropoffAddressFilled(0) && (
          <div className="new-order-shipment-options__action new-order-shipment-options__action--large-margin">
            <div
              className={`new-order-shipment-options__add-option${!isPickupAddressFilled(0) || !isPickupContactFilled(0) ? ' new-order-shipment-options__add-option--disabled' : ''}`}
              onClick={isPickupAddressFilled(0) && isPickupContactFilled(0) ? handleAddReturnButtonClick : () => {}}
            >
              <FormattedMessage id="new-order.stop.add_return" />
            </div>
          </div>
        )}
        {shipmentMethod.value === 'multiple_dropoffs' && (
          <div
            className="new-order-shipment-options__add-option"
            onClick={() => handleAddAnotherDropoffButtonClick()}
          >
            <FormattedMessage id="new-order.stop.add_dropoff" />
          </div>
        )}
      </div>
      {(!isInfoValid || !isShipmentDetailsInfoValid) && (
        <div className="new-order-shipment-options__action">
          <div className="row">
            <div className="col col-12">
              <div className="new-order-shipment-options__mandatory-field-messages">
                <Notice
                  description={intl.formatMessage({ id: 'new-order.fill_fields' })}
                  fixed
                  icon={noticeIcon}
                  noBottomMargin
                  noTopMargin
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`new-order-shipment-options__gray-section${!isInfoValid || !isShipmentDetailsInfoValid ? ' new-order-shipment-options__gray-section--disabled' : ''}`}>
        <h3 className="new-order-shipment-options__title new-order-shipment-options__title--margin-top">
          <FormattedMessage id="new-order.schedule.title" />
        </h3>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.schedule.description" />
        </div>
        <Notice
          description={intl.formatMessage({ id: 'new-order.schedule.alert' })}
          fixed
          icon={noticeIcon}
        />
        <div className="new-order-shipment-options__subtitle">
          <FormattedMessage id="new-order.schedule.pickup.title" />
        </div>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.schedule.pickup.description" />
        </div>
        <div className="new-order-shipment-options__inputs-wrapper">
          <div className="row">
            <div className="col col-12 col-md-6">
              <DateSelect
                allowedDates={allowedPickupDates}
                id="initial"
                left
                startDate={pickupScheduleDate}
                onChange={handlePickupDateSelectChange}
              />
            </div>
            <div className="col col-12 col-md-6">
              <HourSelect
                instructions={pickupHourSelectInstructions}
                options={pickupTimeSlots}
                selected={pickupScheduleTimeSlotValue}
                onChange={handlePickupHourSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="new-order-shipment-options__subtitle">
          <FormattedMessage id="new-order.schedule.dropoff.title" />
        </div>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.schedule.dropoff.description" />
        </div>
        <div className="new-order-shipment-options__inputs-wrapper">
          <div className="row">
            <div className="col col-12 col-md-6">
              <DateSelect
                allowedDates={allowedDropoffDates}
                id="final"
                left
                startDate={dropoffScheduleDate}
                onChange={handleDropoffDateSelectChange}
              />
            </div>
            <div className="col col-12 col-md-6">
              <HourSelect
                instructions={dropoffHourSelectInstructions}
                options={dropoffTimeSlots}
                selected={dropoffScheduleTimeSlotValue}
                onChange={handleDropoffHourSelectChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`new-order-shipment-options__gray-section${!isInfoValid || !isShipmentDetailsInfoValid ? ' new-order-shipment-options__gray-section--disabled' : ''}`}>
        <h3 className="new-order-shipment-options__title new-order-shipment-options__title--margin-top">
          <FormattedMessage id="new-order.payment.title" />
        </h3>
        <div className="new-order-shipment-options__description">
          <FormattedMessage id="new-order.payment.description" />
        </div>
        {!!paymentMessage && (
          <Notice
            description={paymentMessage}
            fixed
            icon={noticeIcon}
          />
        )}
        <div className="new-order-shipment-options__input-group">
          {paymentTypes?.map(paymentType => (
            <Radio
              key={paymentType.id}
              alignTop
              description={paymentType.description}
              disabled={disabled}
              icon={paymentIcon(paymentType.id)}
              iconRight
              label={paymentType.name}
              light
              selected={paymentTypeId}
              value={paymentType.id}
              onChange={() => handleSelectedPaymentOption(paymentType.id)}
            />
          ))}
        </div>
        <div className="new-order-shipment-options__required-info">
          <FormattedMessage id="new-order.required_fields" />
        </div>
      </div>
      {isAuthenticated && (
        <div className="new-order-shipment-options__footer">
          <div className="new-order-shipment-options__footer-left">
            <ul className="new-order-shipment-options__footer-list">
              <li className="new-order-shipment-options__footer-list-item">
                <img
                  alt="Address Icon"
                  className="new-order-shipment-options__footer-icon"
                  src={mapIcon}
                />
                <div className="new-order-shipment-options__footer-value">
                  {deliveryDistanceErrorValue || deliveryDistance}
                </div>
              </li>
              <li className="new-order-shipment-options__footer-list-item">
                <img
                  alt="Clock Icon"
                  className="new-order-shipment-options__footer-icon"
                  src={clockIcon}
                />
                <div className="new-order-shipment-options__footer-value">
                  {deliveryDurationErrorValue || deliveryDuration}
                </div>
              </li>
              {priceTotal?.svalue && (
                <li className="new-order-shipment-options__footer-list-item">
                  <img
                    alt="Coupon Icon"
                    className="new-order-shipment-options__footer-icon"
                    src={couponIcon}
                  />
                  <div
                    className={`new-order-shipment-options__footer-value${!discountCode ? ' new-order-shipment-options__footer-value--highlight' : ' new-order-shipment-options__footer-value--discount'}`}
                    onClick={() => openDiscountModal()}
                  >
                    {discountCode ? '-0.00€' : intl.formatMessage({ id: 'new-order.discount_code.title' }) }
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="new-order-shipment-options__footer-right">
            <div className="new-order-shipment-options__footer-total">
              {/* LEONEL: price is already formatted */}
              {priceTotal?.svalue}
              {Array.isArray(priceDetails) && !!priceDetails.length && (
                <div
                  className="new-order-shipment-options__footer-total-info"
                  onMouseEnter={handleTotalInfoMouseEnter}
                >
                  <img
                    alt="Total Info"
                    src={infoGrayIcon}
                  />
                  <Tooltip
                    className="total-info-tooltip"
                    horizontalPosition="center"
                    show={showTotalInfoTooltip}
                    verticalPosition="top"
                    onClose={handleTotalInfoTooltipClose}
                  >
                    <ul className="total-info-tooltip__list">
                      {priceDetails.map((price, index) => {
                        // Template string is breaking eslint
                        const priceClassName = `total-info-tooltip__list-item-value${price === 'Total' ? ' total-info-tooltip__list-item-value--total' : ''}`;

                        return (
                          <li
                            key={index}
                            className="total-info-tooltip__list-item"
                          >
                            <span className="total-info-tooltip__list-item-name">
                              {price?.text}
                            </span>
                            <span className={priceClassName}>
                              {/* LEONEL: price is already formatted */}
                              {price?.svalue}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </Tooltip>
                </div>
              )}
            </div>
            <Button
              context="primary"
              disabled={!isOrderValid}
              largeHorizontalPadding
              text={intl.formatMessage({ id: 'new-order.submit' })}
              onClick={handleCreateDeliveryButtonClick}
            />
          </div>
        </div>
      )}
      {showPickupContactModal && (
        <Modal
          footer={
            <Button
              context="primary"
              disabled={!isPickupContactValid}
              largeHorizontalPadding
              text={intl.formatMessage({ id: 'new-order.contact.save' })}
              onClick={() => handleContactModalSave('pickup')}
            />
          }
          footerAlignRight
          subtitle={intl.formatMessage({ id: 'new-order.contact.pickup.subtitle' })}
          title={intl.formatMessage({ id: 'new-order.contact.pickup.title' })}
          onClose={handlePickupContactModalClose}
          onEnter={isPickupContactValid ? () => handleContactModalSave('pickup') : () => {}}
        >
          <div className="new-order-shipment-options-modal">
            <div className="new-order-shipment-options-modal__input-group">
              <Input
                block
                inputId="pickup-name"
                label={intl.formatMessage({ id: 'new-order.contact.name.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.name.placeholder' })}
                value={modalPickupContact?.name || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalPickupContact(prevState => ({ ...prevState, name: value }));
                }}
              />
              <Input
                block
                inputId="pickup-company"
                label={intl.formatMessage({ id: 'new-order.contact.company.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.company.placeholder' })}
                value={modalPickupContact.company}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalPickupContact(prevState => ({ ...prevState, company: value }));
                }}
              />
              <Input
                block
                countryCallingCode={modalPickupContact.countryCallingCode}
                inputId="pickup-phone"
                label={intl.formatMessage({ id: 'new-order.contact.phone.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.phone.placeholder' })}
                type="phone"
                value={modalPickupContact?.phone || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalPickupContact(prevState => ({ ...prevState, phone: value }));
                }}
                onCountryCallingCodeChange={
                  newCountryCallingCode => setModalPickupContact(prevState => ({ ...prevState, countryCallingCode: newCountryCallingCode }))
                }
              />
            </div>
          </div>
        </Modal>
      )}
      {showShipmentModal && (
        <Modal
          classNames={`new-order-shipment-options-modal ${shipmentCurrentStep === 2 ? 'new-order-shipment-options-modal--show-dropdown-top' : ''}`}
          footer={
            <div className="new-order-shipment-options-modal__footer">
              {shipmentCurrentStep !== 1 && (
                <div
                  className="new-order-shipment-options-modal__footer-go-back"
                  onClick={handleShipmentModalBackButtonClick}
                >
                  <img
                    alt="Go Back"
                    className="new-order-shipment-options-modal__footer-go-back-icon"
                    src={chevronLeftIcon}
                  />
                  <FormattedMessage id="new-order.shipment.back" />
                </div>
              )}
              <div className="new-order-shipment-options-modal__button-wrapper">
                <Button
                  context="primary"
                  disabled={!isShipmentModalContinueButtonValid()}
                  largeHorizontalPadding
                  text={shipmentCurrentStep === 2 ?
                    intl.formatMessage({ id: 'new-order.shipment.add' }) :
                    intl.formatMessage({ id: 'new-order.shipment.continue' })}
                  onClick={handleShipmentModalContinueButtonClick}
                />
              </div>
            </div>
          }
          footerAlignRight
          noScroll
          title={intl.formatMessage({ id: 'new-order.shipment.add_title' })}
          onClose={handleShipmentModalClose}
        >
          <div className="new-order-shipment-options-modal">
            {shipmentCurrentStep === 1 && (
              <>
                {filteredShipmentOptions?.map((item, index) => (
                  <div
                    key={index}
                    className="new-order-shipment-options-modal__input-group"
                  >
                    <div>
                      <div className="new-order-shipment-options-modal__input-group-title">
                        {item?.service?.name}
                      </div>
                      <div className="new-order-shipment-options-modal__input-group-description">
                        {item?.service?.description}
                      </div>
                    </div>
                    {item.items?.map((optionItem, optionItemIndex) => (
                      <Radio
                        key={optionItemIndex}
                        description={optionItem.description}
                        icon={optionItem.iconUrl}
                        label={optionItem.name}
                        note={optionItem.price}
                        selected={selectedShipmentOption.id}
                        value={optionItem.id}
                        onChange={value => handleSelectedShipmentOption(value, optionItem)}
                      />
                    ))}
                  </div>
                ))}
              </>
            )}
            {shipmentCurrentStep === 2 && (
              <>
                <Notice
                  description={intl.formatMessage({ id: 'new-order.shipment.alert' })}
                  fixed
                  icon={noticeIcon}
                  noTopMargin
                />
                <div className="row">
                  <div className="col col-sm-4">
                    <div className="new-order-shipment-options-modal__input-group">
                      <Quantity
                        block
                        label={intl.formatMessage({ id: 'new-order.shipment.item.quantity.label' })}
                        value={selectedShipmentSpecifications.quantity || autoAddShipment.quantity}
                        onChange={quantity => setSelectedShipmentSpecifications(prevState => ({ ...prevState, quantity }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="new-order-shipment-options-modal__input-group">
                  <Textarea
                    label={intl.formatMessage({ id: 'new-order.shipment.item.description.label' })}
                    resizeVertical
                    singleRow
                    value={selectedShipmentSpecifications.description}
                    onChange={description => setSelectedShipmentSpecifications(prevState => ({ ...prevState, description }))}
                  />
                </div>
                <div className="row">
                  <div className="col col-sm-4">
                    <div className="new-order-shipment-options-modal__input-group">
                      <Select
                        instructions={intl.formatMessage({ id: 'new-order.shipment.item.weight.placeholder' })}
                        label={intl.formatMessage({ id: 'new-order.shipment.item.weight.label' })}
                        options={selectedShipmentSpecifications.weights || autoAddShipment.formattedWeights}
                        value={selectedShipmentSpecifications.weight}
                        onChange={value => setSelectedShipmentSpecifications(prevState => ({ ...prevState, weight: value }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-4 new-order-shipment-options-modal__input-group--stacked-mobile">
                    <Select
                      instructions={intl.formatMessage({ id: 'new-order.shipment.item.length.placeholder' })}
                      label={intl.formatMessage({ id: 'new-order.shipment.item.length.label' })}
                      options={selectedShipmentSpecifications.lengths || autoAddShipment.formattedLengths}
                      value={selectedShipmentSpecifications.length}
                      onChange={value => setSelectedShipmentSpecifications(prevState => ({ ...prevState, length: value }))}
                    />
                  </div>
                  <div className="col-12 col-sm-4 new-order-shipment-options-modal__input-group--stacked-mobile">
                    <Select
                      instructions={intl.formatMessage({ id: 'new-order.shipment.item.width.placeholder' })}
                      label={intl.formatMessage({ id: 'new-order.shipment.item.width.label' })}
                      options={selectedShipmentSpecifications.widths || autoAddShipment.formattedWidths}
                      value={selectedShipmentSpecifications.width}
                      onChange={value => setSelectedShipmentSpecifications(prevState => ({ ...prevState, width: value }))}
                    />
                  </div>
                  <div className="col-12 col-sm-4 new-order-shipment-options-modal__input-group--stacked-mobile">
                    <Select
                      instructions={intl.formatMessage({ id: 'new-order.shipment.item.height.placeholder' })}
                      label={intl.formatMessage({ id: 'new-order.shipment.item.height.label' })}
                      options={selectedShipmentSpecifications.heights || autoAddShipment.formattedHeights}
                      value={selectedShipmentSpecifications.height}
                      onChange={value => setSelectedShipmentSpecifications(prevState => ({ ...prevState, height: value }))}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
      {showDropoffContactModal && (
        <Modal
          footer={
            <Button
              context="primary"
              disabled={!isDropoffContactValid}
              largeHorizontalPadding
              text={intl.formatMessage({ id: 'new-order.shipment.save' })}
              onClick={() => handleContactModalSave('dropoff')}
            />
          }
          footerAlignRight
          subtitle={intl.formatMessage({ id: 'new-order.contact.dropoff.subtitle' })}
          title={intl.formatMessage({ id: 'new-order.contact.dropoff.title' })}
          onClose={handleDropoffContactModalClose}
          onEnter={isDropoffContactValid ? () => handleContactModalSave('dropoff') : () => {}}
        >
          <div className="new-order-shipment-options-modal">
            <div className="new-order-shipment-options-modal__input-group">
              <Input
                block
                inputId="dropoff-name"
                label={intl.formatMessage({ id: 'new-order.contact.name.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.name.placeholder' })}
                value={modalDropoffContact?.name || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalDropoffContact(prevState => ({ ...prevState, name: value }));
                }}
              />
              <Input
                block
                inputId="dropoff-company"
                label={intl.formatMessage({ id: 'new-order.contact.company.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.company.placeholder' })}
                value={modalDropoffContact?.company || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalDropoffContact(prevState => ({ ...prevState, company: value }));
                }}
              />
              <Input
                block
                countryCallingCode={modalDropoffContact.countryCallingCode}
                inputId="dropoff-phone"
                label={intl.formatMessage({ id: 'new-order.contact.phone.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.phone.placeholder' })}
                type="phone"
                value={modalDropoffContact?.phone || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalDropoffContact(prevState => ({ ...prevState, phone: value }));
                }}
                onCountryCallingCodeChange={((countryCallingCode) => {
                  setModalDropoffContact(prevState => ({
                    ...prevState,
                    countryCallingCode
                  }));
                })}
              />
            </div>
          </div>
        </Modal>
      )}
      {showStopContactModal && (
        <Modal
          footer={
            <Button
              context="primary"
              disabled={!isStopContactValid}
              largeHorizontalPadding
              text={intl.formatMessage({ id: 'new-order.contact.save' })}
              onClick={() => handleContactModalSave('stop')}
            />
          }
          footerAlignRight
          subtitle={intl.formatMessage({ id: 'new-order.contact.stop.subtitle' })}
          title={intl.formatMessage({ id: 'new-order.contact.stop.title' })}
          onClose={handleStopContactModalClose}
          onEnter={isStopContactValid ? () => handleContactModalSave('stop') : () => {}}
        >
          <div className="new-order-shipment-options-modal">
            <div className="new-order-shipment-options-modal__input-group">
              <Input
                block
                inputId="stop-name"
                label={intl.formatMessage({ id: 'new-order.contact.name.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.name.placeholder' })}
                value={modalStopContact?.name || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalStopContact(prevState => ({ ...prevState, name: value }));
                }}
              />
              <Input
                block
                inputId="stop-company"
                label={intl.formatMessage({ id: 'new-order.contact.company.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.company.placeholder' })}
                value={modalStopContact?.company || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalStopContact(prevState => ({ ...prevState, company: value }));
                }}
              />
              <Input
                block
                countryCallingCode={modalStopContact.countryCallingCode}
                inputId="stop-phone"
                label={intl.formatMessage({ id: 'new-order.contact.phone.label' })}
                placeholder={intl.formatMessage({ id: 'new-order.contact.phone.placeholder' })}
                type="phone"
                value={modalStopContact?.phone || ''}
                onChange={(event) => {
                  const value = event?.target?.value || '';

                  setModalStopContact(prevState => ({ ...prevState, phone: value }));
                }}
                onCountryCallingCodeChange={((newCountryCallingCode) => {
                  setModalStopContact(prevState => ({
                    ...prevState,
                    newCountryCallingCode
                  }));
                })}
              />
            </div>
          </div>
        </Modal>
      )}
      {showDiscountModal && (
        <Modal
          footer={
            <Button
              context="primary"
              disabled={!modalDiscountCode}
              text={discountCode ? intl.formatMessage({ id: 'new-order.discount_code.close' }) : intl.formatMessage({ id: 'new-order.discount_code.apply' })}
              onClick={() => handleDiscountCodeButtonClick()}
            />
          }
          footerAlignRight
          title={intl.formatMessage({ id: 'new-order.discount_code.title' })}
          onClose={() => handleDiscountModalClose()}
        >
          <div className="new-order-shipment-options-modal">
            {!discountCode ? (
              <Input
                backgroundImage={couponIcon}
                block
                inputId="discount-code"
                message={!isDiscountCodeValid && modalDiscountCode ?
                  intl.formatMessage({ id: 'new-order.discount_code.invalid' }) :
                  null}
                placeholder={intl.formatMessage({ id: 'new-order.discount_code.placeholder' })}
                status={!isDiscountCodeValid && modalDiscountCode ? 'error' : 'default'}
                value={modalDiscountCode ?? ''}
                onChange={event => handleDiscountCodeInputChange(event?.target?.value ?? '')}
              />
            ) : (
              <div className="new-order-shipment-options-modal__discount-code">
                {discountCode}
                <img
                  alt="Delete Coupon Code"
                  className="new-order-shipment-options-modal__delete-icon"
                  src={deleteIcon}
                  onClick={handleDeleteDiscountCodeClick}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      {showNotesModal && (
        <ShipmentOptionsNotesModal
          notes={notesModalNotes ?? ''}
          onClose={handleNotesModalClose}
          onSave={handleNotesModalSave}
        />
      )}
      {showAddressModal && (
        <AddressModal
          currentAddress={addressModalAddress}
          search={searchModalAddress}
          type={addressTypeSelected}
          onClose={handleAddressModalClose}
          onSave={handleAddressModalSelect}
        />
      )}
      {showAddressModalConfirmation && (
        <ActionModal
          notice={intl.formatMessage({ id: 'new-order.address_changed.subtitle' })}
          title={intl.formatMessage({ id: 'new-order.address_changed.title' })}
          onClose={closeAddressModalConfirmation}
          onConfirm={handleAddressModalConfirmation}
        />
      )}
    </div>
  );
};

NewOrderShipmentOptions.propTypes = {
  onDeliveryError: PropTypes.func,
  onEndLocationChange: PropTypes.func,
  onRouteChange: PropTypes.func,
  onStartLocationChange: PropTypes.func
};

NewOrderShipmentOptions.defaultProps = {
  onDeliveryError: () => {},
  onEndLocationChange: () => {},
  onRouteChange: () => {},
  onStartLocationChange: () => {}
};

export default NewOrderShipmentOptions;
