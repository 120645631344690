/* eslint-disable camelcase */
import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsAuthenticated } from 'store/slices/authentication';
import { selectShopId, selectShoppingCartItems, setCustomizationProduct, setShopId } from 'store/slices/shopping-cart';
import { selectShopById } from 'store/slices/shops';

import ConfirmationModal from '@youship/components/confirmation-modal';
import ShoppingCart from 'components/shopping-cart';
import ShopDetailsModal from 'components/shop-details-modal';
import ShopProductCard from 'components/shop-product-card';

import locationIcon from 'images/icons/location.svg';
import starIcon from 'images/icons/star-solid.svg';

import './styles.scss';

const ShopDetails = ({
  address,
  alertColor,
  alertMessage,
  averagePrice,
  categories,
  fulfillment,
  id,
  isLoading,
  name,
  rating,
  schedule
}) => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const shoppingCartItems = useSelector(selectShoppingCartItems);
  const shoppingCartShopId = useSelector(selectShopId);
  const shoppingCartShop = useSelector(state => selectShopById(state, shoppingCartShopId));

  const [isDetectingScroll, setIsDetectingScroll] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [pendingCustomizationProduct, setPendingCustomizationProduct] = useState(null);
  const [showMoreDetailsModal, setShowMoreDetailsModal] = useState(false);
  const [showOrderResetConfirmationModal, setShowOrderResetConfirmationModal] = useState(false);

  // Categories
  const refs = (Array.isArray(categories) ? categories : []).reduce((acc, category) => {
    acc[category.id] = createRef();

    return acc;
  }, {});

  useEffect(() => {
    if (id !== shoppingCartShopId && !shoppingCartItems?.length) {
      dispatch(setShopId(id));
    }
  }, [dispatch, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(categories)) {
      setSelectedCategory(categories[0]?.id);
    }
  }, [categories]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCategoryClick = (category) => {
    setIsDetectingScroll(false);

    if (category !== selectedCategory) {
      setSelectedCategory(category);
      refs[category].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    // This timeout is important to ensure that scrollIntoView() doesn't trigger the 'scroll' eventListener
    // If the eventListener is triggered the categories will jump to the active state
    // one after another until scrollIntoView() is completed
    setTimeout(() => {
      setIsDetectingScroll(true);
    }, 1000);
  };

  const handleMoreInfoClick = () => {
    const disableScrollEvent = new Event('disable-scroll');

    window.dispatchEvent(disableScrollEvent);

    setShowMoreDetailsModal(true);
  };

  const handleMoreInfoModalClose = () => {
    setShowMoreDetailsModal(false);
    const enableScrollEvent = new Event('enable-scroll');

    window.dispatchEvent(enableScrollEvent);
  };

  const handleOrderResetConfirmationModalClose = () => {
    setShowOrderResetConfirmationModal(false);
  };

  const handleOrderResetConfirmationModalConfirm = () => {
    dispatch(setShopId(id));
    dispatch(setCustomizationProduct(pendingCustomizationProduct));
    setPendingCustomizationProduct(null);
    setShowOrderResetConfirmationModal(false);
  };

  const handleShopProductCardClick = (product) => {
    if (id !== shoppingCartShopId && shoppingCartItems?.length) {
      setPendingCustomizationProduct(product);
      setShowOrderResetConfirmationModal(true);
    } else {
      dispatch(setCustomizationProduct(product));
    }
  };

  // Detect which category is in view
  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();

    return (
      rect.top <= 174 &&
      rect.top > 0
    );
  };

  useEffect(() => {
    if (isDetectingScroll) {
      document.addEventListener('scroll', () => {
        if (Array.isArray(categories)) {
          categories.every((category) => {
            if (category.id !== selectedCategory &&
              refs[category.id] &&
              refs[category.id].current &&
              isInViewport(refs[category.id].current)
            ) {
              setSelectedCategory(category.id);

              return false;
            }

            return true;
          });
        }
      }, {
        passive: false
      });
    }
  }, [categories, isDetectingScroll, refs, selectedCategory]);


  let title = name;
  const formattedAddress = address?.formattedAddress;
  let moreDetails = null;

  if (!title) title = isLoading ? 'Loading…' : 'Error';

  if (address?.coordinates || schedule) {
    moreDetails = { name };

    if (address?.coordinates) moreDetails.coordinates = address.coordinates;
    if (schedule) moreDetails.schedule = schedule;
  }

  return (
    <div className="shop-details">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-lg-8">
            <div className="shop-details__header">
              <div className="shop-details__header-top">
                <h1 className="shop-details__title">
                  {title}
                </h1>
                <div className="shop-details__rating">
                  <img
                    alt="Rating"
                    className="shop-details__rating-icon"
                    src={starIcon}
                  />
                  {(!!rating || typeof rating === 'number') && (
                    <div className="shop-details__rating-text">
                      {rating}
                    </div>
                  )}
                </div>
              </div>
              <div className="shop-details__info">
                {!!averagePrice && `Average price of ${averagePrice}`}
                {!!averagePrice && !!fulfillment && ' · '}
                {!!fulfillment && fulfillment}
              </div>
              <div className="shop-details__location">
                {(!!formattedAddress || !!moreDetails) && (
                  <img
                    alt="Location Icon"
                    className="shop-details__location-icon"
                    src={locationIcon}
                  />
                )}
                {!!formattedAddress && (
                  <div className="shop-details__location-text">
                    {formattedAddress}
                  </div>
                )}
                {!!formattedAddress && !!moreDetails && (
                  <div className="shop-details__location-spacer">
                    {'\xa0\xa0·\xa0\xa0'}
                  </div>
                )}
                {!!moreDetails && (
                  <div
                    className="shop-details__location-more-info"
                    onClick={handleMoreInfoClick}
                  >
                    More Information
                  </div>
                )}
              </div>
              {!!alertMessage && (
                <div className="shop-details__alerts">
                  <div
                    className="shop-details__alert"
                    style={{ color: alertColor, borderColor: alertColor }}
                  >
                    {alertMessage}
                  </div>
                </div>
              )}
            </div>
            {Array.isArray(categories) && !!categories.length && (
              <>
                <ul className={`shop-details-categories-nav${isAuthenticated ? ' shop-details-categories-nav--is-authenticated' : ''}`}>
                  {categories.map((category, index) => (
                    <li
                      key={index}
                      className={`shop-details-categories-nav__item${selectedCategory === category.id ? ' shop-details-categories-nav__item--active' : ''}`}
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      {category.name}
                    </li>
                  ))}
                </ul>
                {categories.map((category, index) => (
                  <div
                    key={index}
                    ref={refs[category.id]}
                    className="shop-details__items"
                    id={`items-${category.id}`}
                  >
                    <div className="shop-details__items-title">
                      {category.name}
                    </div>
                    {category.products.length ? (
                      <div className="row">
                        {category.products.map((product, productIndex) => (
                          <div
                            key={productIndex}
                            className="col col-12 col-md-6 shop-details__card-col"
                          >
                            <div className="shop-details__card-wrapper">
                              <ShopProductCard
                                {...product}
                                description={product.smallDescription || product.description}
                                disabled={product.soldOut}
                                price={product.price?.value}
                                onClick={() => handleShopProductCardClick(product)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="shop-details__items-empty-disclaimer">
                        There are currently no products for this category.
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="col col-12 col-lg-4">
            <ShoppingCart
              hasScrollBehavior
              shopId={id}
            />
          </div>
        </div>
      </div>
      {!!moreDetails && (
        <ShopDetailsModal
          {...moreDetails}
          show={showMoreDetailsModal}
          title="Location and Schedule"
          onClose={handleMoreInfoModalClose}
        />
      )}
      {showOrderResetConfirmationModal && (
        <ConfirmationModal
          description={`If you proceed you will lose your current shopping cart for ${shoppingCartShop?.name ? `${shoppingCartShop.name}` : 'another venue'}.`}
          title="Warning"
          onClose={handleOrderResetConfirmationModalClose}
          onConfirm={handleOrderResetConfirmationModalConfirm}
        />
      )}
    </div>
  );
};

ShopDetails.propTypes = {
  address: PropTypes.shape({
    coordinates: PropTypes.shape({
      latitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      longitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    }),
    formattedAddress: PropTypes.string
  }),
  averagePrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })),
  fulfillment: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  rating: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  schedule: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string,
    intervals: PropTypes.arrayOf(PropTypes.string)
  }))
};

ShopDetails.defaultProps = {
  address: null,
  averagePrice: null,
  categories: null,
  fulfillment: null,
  id: null,
  isLoading: false,
  name: null,
  rating: null,
  schedule: null
};

export default ShopDetails;
