import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '@youship/components/objects/loader';

import markerPickupIcon from 'images/icons/marker-blue.svg';
import markerStopIcon from 'images/icons/marker-green.svg';
import markerDropoffIcon from 'images/icons/marker.svg';

import './styles.scss';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDSq8lMU2FUvhWFPNPPCyn_viuDE5t8FPI';

const Map = ({ id, route, onMapLoaded }) => {
  const [isMapCreated, setIsMapCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMapErrorMessage, setShowMapErrorMessage] = useState(false);

  const totalPickups = Array.isArray(route) ? route.filter(location => location.type === 'pickup').length : 0;
  const totalDropoffs = Array.isArray(route) ? route.filter(location => location.type === 'dropoff').length : 0;

  const markers = [];
  let geocoder = {};

  const loadMap = useCallback(() => {
    if (totalPickups && totalDropoffs) {
      const map = new window.google.maps.Map(
        document.getElementById(id),
        {
          disableDefaultUI: true,
          zoom: 11
        }
      );

      // NOTE: This should be fixed in the future
      // eslint-disable-next-line react-hooks/exhaustive-deps
      geocoder = new window.google.maps.Geocoder();

      setIsMapCreated(true);

      addMarkers(map).then((response) => {
        const calcRoutesPromises = [];

        let routes = [];

        if (totalDropoffs > 1) {
          const pickup = route.find(location => location.type === 'pickup');

          route.filter(location => location.type === 'dropoff').forEach((location) => {
            routes.push([
              pickup,
              location
            ]);
          });
        } else if (totalPickups > 1) {
          const dropoff = route.find(location => location.type === 'dropoff');

          route.filter(location => location.type === 'pickup').forEach((location) => {
            routes.push([
              location,
              dropoff
            ]);
          });
        } else {
          routes = [route];
        }

        routes.forEach((individualRoute) => {
          calcRoutesPromises.push(calcRoute(individualRoute, map));
        });

        // NOTE: eslint-disable-next-line promise/no-nesting is not working
        // eslint-disable-next-line
        Promise.all(calcRoutesPromises).then((responses) => {
          setIsLoading(false);
          onMapLoaded();

          return responses;
        })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
            setIsLoading(false);
            setShowMapErrorMessage(true);
          });

        return response;
      })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          setIsLoading(false);
          setShowMapErrorMessage(true);
        });
    }
  }, [route]);

  const getCoordinates = location => new Promise((resolve, reject) => {
    geocoder.geocode({ location }, (results, status) => {
      if (
        status === 'OK' &&
        results &&
        results[0] &&
        results[0].geometry &&
        results[0].geometry.location
      ) {
        const coordinates = results[0].geometry.location;

        return resolve(coordinates);
      }

      return reject(new Error('Location not found'));
    });
  });

  const calcRoute = (individualRoute, map) => new Promise((resolve, reject) => {
    const startLocation = individualRoute[0].location;
    const endLocation = individualRoute[individualRoute.length - 1].location;

    const directionsService = new window.google.maps.DirectionsService();

    const rendererOptions = {
      map,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: '#E69635',
        strokeWeight: 4
      },
      preserveViewport: true
    };

    const wayPoints = [];

    const requestRouteOptions = {
      origin: startLocation,
      destination: endLocation,
      travelMode: 'DRIVING'
    };

    if (individualRoute.length > 2) {
      const stops = route.filter(location => location.type === 'stop');
      const promises = [];

      stops.forEach((stop) => {
        promises.push(getCoordinates(stop.location));
      });

      Promise.all(promises).then((data) => {
        data.forEach((coordinates) => {
          wayPoints.push({
            location: coordinates,
            stopover: true
          });

          requestRouteOptions.waypoints = wayPoints;

          // NOTE: eslint-disable-next-line promise/no-nesting is not working
          // eslint-disable-next-line
          return setRoute(directionsService, map, requestRouteOptions, rendererOptions).then(() => resolve());
        });

        return data;
      })
        .catch(error => reject(new Error(error)));
    } else {
      return setRoute(directionsService, map, requestRouteOptions, rendererOptions).then(() => resolve());
    }

    return reject();
  });

  const setRoute = (directionsService, map, requestRouteOptions, rendererOptions) => new Promise((resolve, reject) => {
    directionsService.route(requestRouteOptions, (resultRoute, status) => {
      const directionsRenderer = new window.google.maps.DirectionsRenderer(rendererOptions);

      if (status === 'OK' &&
        resultRoute &&
        resultRoute.routes.length &&
        resultRoute.routes[0] &&
        resultRoute.routes[0].legs.length &&
        resultRoute.routes[0].legs[0] &&
        resultRoute.routes[0].legs[0].steps
      ) {
        directionsRenderer.setDirections(resultRoute);

        const { bounds } = directionsRenderer.getDirections().routes[0];

        map.fitBounds(bounds, {
          top: 16,
          left: 16,
          rigth: 16,
          bottom: 182
        });

        return resolve();
      }

      return reject(new Error('Route not found'));
    });
  });

  const addMarkers = map => new Promise((resolve, reject) => {
    const promises = [];

    route.forEach((location) => {
      promises.push(setMarker(map, location.location, location.type));
    });

    Promise.all(promises).then(() => resolve())
      .catch(error => reject(new Error(error)));
  });

  const setMarker = (map, location, type) => new Promise((resolve, reject) => {
    geocoder.geocode({ location }, (results, status) => {
      if (
        status === 'OK' &&
        results &&
        results[0] &&
        results[0].geometry &&
        results[0].geometry.location
      ) {
        const coordinates = results[0].geometry.location;

        let markerIcon = markerPickupIcon;

        if (type === 'stop') {
          markerIcon = markerStopIcon;
        } else if (type === 'dropoff') {
          markerIcon = markerDropoffIcon;
        }

        markers.push(new window.google.maps.Marker({
          position: coordinates,
          map,
          icon: {
            url: markerIcon,
            scaledSize: new window.google.maps.Size(50, 50),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(25, 25)
          }
        }));

        map.setCenter(results[0].geometry.location);

        return resolve();
      }

      return reject(new Error(status));
    });
  });

  useEffect(() => {
    if (!document.getElementById('google-maps-react')) {
      const googleMapsScript = document.createElement('script');

      googleMapsScript.setAttribute('id', 'google-maps-react');

      googleMapsScript.type = 'text/javascript';
      googleMapsScript.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;

      const googleMapsScriptTag = document.getElementsByTagName('script')[0];

      googleMapsScriptTag.parentNode.insertBefore(googleMapsScript, googleMapsScriptTag);
    }
  });

  useEffect(() => {
    if (!window.google) {
      document.getElementById('google-maps-react').addEventListener('load', () => {
        loadMap();
      });
    } else if (!isMapCreated) {
      loadMap();
    }
  });

  useEffect(() => {
    if (window.google && totalPickups && totalDropoffs) {
      loadMap();
    }
  }, [loadMap, route, totalDropoffs, totalPickups]);

  return (
    <div className="map__container">
      <div
        className="map__wrapper"
        id={id}
      />
      {isLoading && (
        <div className="map__loader">
          <Loader />
        </div>
      )}
      {showMapErrorMessage && (
        <div className="map__error">
          Something went wrong, try again later.
        </div>
      )}
    </div>
  );
};

Map.propTypes = {
  id: PropTypes.string.isRequired,
  onMapLoaded: PropTypes.func,
  route: PropTypes.arrayOf(PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    type: PropTypes.string
  }))
};

Map.defaultProps = {
  onMapLoaded: () => {},
  route: []
};

export default Map;
