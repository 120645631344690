import { configureStore } from '@reduxjs/toolkit';

import authentication from './slices/authentication';
import categories from './slices/categories';
import dashboard from './slices/dashboard';
import deliveries from './slices/deliveries';
import locales from './slices/locales';
import newDelivery from './slices/new-delivery';
import notifications from './slices/notifications';
import shoppingCart from './slices/shopping-cart';
import shops from './slices/shops';
import userAddresses from './slices/user-addresses';

const store = configureStore({
  reducer: {
    authentication,
    categories,
    dashboard,
    deliveries,
    locales,
    newDelivery,
    notifications,
    shoppingCart,
    shops,
    userAddresses
  }
});

export default store;
