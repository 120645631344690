import BaseApi from '..';
import {
  UPLOAD_DOCUMENT
} from '../endpoints';

const DOCUMENT_NAME_DRIVING_LICENSE = 'doc_drivinglicense';
const DOCUMENT_NAME_PERSON_IDENTIFICATION = 'doc_person_id';

export {
  DOCUMENT_NAME_DRIVING_LICENSE,
  DOCUMENT_NAME_PERSON_IDENTIFICATION
};

export default class UploadApi extends BaseApi {
  /* eslint-disable camelcase */
  static uploadTransporterDocuments = fileForm => this.post(UPLOAD_DOCUMENT, fileForm, null, {
    'Content-Type': 'multipart/form-data'
  })
  /* eslint-enable camelcase */
}
