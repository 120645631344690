import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


import { fetchNotifications } from 'store/slices/notifications';
import { acceptBid, getDeliveryDetails, refuseBid } from 'store/slices/deliveries';

import formatPrice from '@youship/utils/format-price';

import BidModal from 'components/order/bid-modal';
import Tooltip from '@youship/components/objects/tooltip';
import Rating from '@youship/components/objects/rating';

import infoGrayIcon from 'images/icons/info-gray.svg';
import avatarImage from 'images/avatar.jpg';

import './styles.scss';

const OrderProposals = ({ bids, id, name }) => {
  const dispatch = useDispatch();
  const [showBidModal, setShowBidModal] = useState(false);

  /*
  const [showTotalInfoTooltip, setShowTotalInfoTooltip] = useState(false);

  const closeTotalInfoTooltipClose = () => {
    setShowTotalInfoTooltip(false);
  };

  const handleTotalInfoMouseEnter = () => {
    setShowTotalInfoTooltip(true);
  };

  const handleTotalInfoTooltipClose = () => {
    closeTotalInfoTooltipClose();
  };
  */

  // showTotalInfoTooltip has the bid code to show the tooltip
  const [showTotalInfoTooltip, setShowTotalInfoTooltip] = useState(0);

  const closeTotalInfoTooltipClose = (index) => {
    setShowTotalInfoTooltip(0);
  };

  const handleTotalInfoMouseEnter = (index) => {
    setShowTotalInfoTooltip(index);
  };

  const handleTotalInfoTooltipClose = (index) => {
    closeTotalInfoTooltipClose(index);
  };

  const handleBidAction = (action, payment, bidCode) => {
    let actionType = null;
    const paymentUrl = payment?.paymentUrl;

    if (action === 'accept') actionType = dispatch(acceptBid(bidCode));
    else if (action === 'refuse') actionType = dispatch(refuseBid(bidCode));

    if (action === 'pay' && paymentUrl) {
      const paymentUrlWithReturn = `${paymentUrl}&ys_return_url=${origin}/order/${id}`;

      window.location.replace(paymentUrlWithReturn);
    } else {
      actionType
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

          return response;
        })
        .then((response) => {
          dispatch(fetchNotifications());
          dispatch(getDeliveryDetails(id));
          setShowBidModal(false);

          return response;
        })
        .catch((error) => {
          setShowBidModal(false);
          // NOTE: Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  const handleBidModalClose = (bidCode) => {
    setTimeout(() => {
      setShowBidModal(bidCode);
    }, 0);
  };

  const handleBidModalOpen = (bidCode) => {
    setShowBidModal(bidCode);
  };

  return (
    <div
      className="order-proposals"
    >
      <h3 className="order-proposals__title">
        <FormattedMessage id="order.bids" />
      </h3>
      <div className="order-proposals__bids">
        {(!Array.isArray(bids) || !bids.length) && (
          <div>
            <FormattedMessage id="order.bids_empty" />
          </div>
        )}
        {Array.isArray(bids) && bids.map(bid => (
          <div
            key={bid.bidCode}
            className="order-proposals__info"
            onClick={() => handleBidModalOpen(bid.bidCode)}
          >
            <div className="order-proposals__left-content">
              <div className="order-proposals__name">
                <img
                  alt="Transporter"
                  className="order-proposals__transporter-image"
                  src={bid.transporter?.photoUrl || avatarImage}
                />
                <div>
                  {bid.transporter?.name}
                  <Rating value={bid.transporter?.rating} />
                </div>
              </div>
              {!!bid.schedule && (
                <div className="order-proposals__date">
                  {bid.schedule?.ready}
                  {' '}
                  &sdot;
                  {' '}
                  {bid.schedule?.deadline}
                </div>
              )}
            </div>
            <div className="order-proposals__right-content">
              {Array.isArray(bid.priceDetails) && !!bid.priceDetails.length && (
                <div
                  className="order-proposals__price"
                  onMouseEnter={() => handleTotalInfoMouseEnter(bid.bidCode)}
                >
                  {formatPrice(bid.value ?? 0)}
                  <img
                    alt="Total Info"
                    className="order-proposals__tooltip-icon"
                    src={infoGrayIcon}
                  />
                  <Tooltip
                    className="order-proposals__tooltip"
                    horizontalPosition="center"
                    // show={showTotalInfoTooltip}
                    show={showTotalInfoTooltip === bid.bidCode}
                    verticalPosition="top"
                    onClose={() => handleTotalInfoTooltipClose(bid.bidCode)}
                  >
                    <ul className="order-proposals__tooltip-list">
                      {bid.priceDetails.map((price, index) => {
                        // Template string is breaking eslint
                        const priceClassName = `order-proposals__tooltip-list-item-value${price === 'Total' ? ' order-proposals__tooltip-list-item-value--total' : ''}`;

                        return (
                          <li
                            key={index}
                            className="order-proposals__tooltip-list-item"
                          >
                            <span className="order-proposals__tooltip-list-item-name">
                              {price?.text}
                            </span>
                            <span className={priceClassName}>
                              {formatPrice(price?.value)}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </Tooltip>
                </div>
              )}
              {!!bid.status?.statusName && (
                <div
                  className="order-proposals__status-name"
                  style={bid.status.statusColor ? { border: `1px solid ${bid.status.statusColor}`, color: bid.status.statusColor } : {}}
                >
                  {bid.status.statusName}
                </div>
              )}
            </div>
            { showBidModal === bid.bidCode && (
              <BidModal
                {...bid}
                onActionButtonClick={action => handleBidAction(action, bid.payment, bid.bidCode)}
                onClose={handleBidModalClose}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

OrderProposals.propTypes = {
  bids: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string
};

OrderProposals.defaultProps = {
  bids: [],
  name: null
};

export default OrderProposals;
