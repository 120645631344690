import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/objects/button';

import './styles.scss';

const HighlightCard = ({ actionName, actionUrl, classNames, description, imageUrl, imgComponent, linkComponent, name, noMargin }) => {
  const Img = imgComponent;

  return (
    <div className={`highlight-card${noMargin ? ' highlight-card--no-margin' : ''}${classNames ? ` ${classNames}` : ''}`}>
      <div className="highlight-card__image-wrapper">
        {!!imageUrl && (
          <>
            {Img ? (
              <>
                <Img
                  alt={name}
                  classNames="highlight-card__image"
                  src={imageUrl}
                />
                <div classNames="highlight-card__image highlight-card__image--overlay" />
              </>
            ) : (
              <>
                <img
                  alt={name}
                  className="highlight-card__image"
                  src={imageUrl}
                />
                <div className="highlight-card__image highlight-card__image--overlay" />
              </>
            )}
          </>
        )}
      </div>
      <div className="highlight-card__content">
        {(!!name || !!description) && (
          <div className="highlight-card__content-text">
            {!!name && (
              <div className="highlight-card__title">
                {name}
              </div>
            )}
            {!!description && (
              <div className="highlight-card__description">
                {description}
              </div>
            )}
          </div>
        )}
        {!!actionUrl && !!linkComponent && (
          <div className="highlight-card__content-button">
            <Button
              largeHorizontalPadding
              light
              noArrow
              to={actionUrl}
            >
              {actionName}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

HighlightCard.propTypes = {
  actionName: PropTypes.string,
  actionUrl: PropTypes.string,
  classNames: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  imgComponent: PropTypes.func,
  linkComponent: PropTypes.shape({
    render: PropTypes.func
  }),
  name: PropTypes.string,
  noMargin: PropTypes.bool
};

HighlightCard.defaultProps = {
  actionName: 'See more',
  actionUrl: null,
  classNames: null,
  description: null,
  imageUrl: null,
  imgComponent: null,
  linkComponent: null,
  name: null,
  noMargin: false
};

export default HighlightCard;
