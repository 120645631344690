import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../dropdown';

import clockIcon from '../../../assets/images/icons/clock.svg';

import './styles.scss';

const HourSelect = ({ disabled, hasSoonOption, instructions, onChange, options, selected }) => {
  const [value, setValue] = useState(selected || '');
  const [isOpen, setIsOpen] = useState(false);

  let hoursOptions = [];

  if (hasSoonOption) {
    hoursOptions.push({
      label: 'As soon as possible',
      value: '00:00h'
    });
  }

  if (Array.isArray(options)) {
    hoursOptions = options;
  } else {
    for (let index = 0; index < 24; index += 1) {
      hoursOptions.push({
        label: `${index}:00h`,
        value: `${index}:00h`
      }, {
        label: `${index}:30h`,
        value: `${index}:30h`
      });
    }
  }

  const label = hoursOptions.find(option => option.value === selected)?.label || value;

  useEffect(() => {
    setValue(selected || '');
  }, [selected]);

  const handleHourSelectClick = () => {
    if (hoursOptions.length && !disabled) setIsOpen(true);
  };

  const handleHourOptionClick = (event, hourOption) => {
    event.preventDefault();
    event.stopPropagation();

    if (!disabled) {
      setIsOpen(false);
      onChange(hourOption);
    }
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`hour-select${disabled ? ' hour-select--disabled' : ''}`}
      onClick={() => handleHourSelectClick()}
    >
      <div className="hour-select__input-wrapper">
        <div className="hour-select__icon-wrapper">
          <img
            className="hour-select__icon"
            src={clockIcon}
            alt="Calendar Icon"
          />
        </div>
        <input
          className={`hour-select__input${isOpen ? ' hour-select__input--is-open' : ''}`}
          value={label || instructions}
          readOnly
        />
      </div>
      <Dropdown
        show={isOpen}
        onClose={handleDropdownClose}
        block
        className="hour-select__dropdown"
      >
        <ul className="hour-select__list">
          {hoursOptions.map((option, index) => (
            <li
              key={index}
              className={`hour-select__list-item${option.value === value ? ' hour-select__list-item--selected' : ''}`}
              onClick={event => handleHourOptionClick(event, option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </Dropdown>
    </div>
  );
};

HourSelect.propTypes = {
  disabled: PropTypes.bool,
  hasSoonOption: PropTypes.bool,
  instructions: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })),
  selected: PropTypes.string
};

HourSelect.defaultProps = {
  disabled: false,
  hasSoonOption: false,
  instructions: 'Select one',
  onChange: () => {},
  options: null,
  selected: '00:00h'
};

export default HourSelect;
