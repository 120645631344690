import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Pagination = ({ selected, length, onChange }) => {
  const MAXIMUM_NUMBER_OF_PAGINATION_PAGES = 8;

  const showInitialFiller = length > MAXIMUM_NUMBER_OF_PAGINATION_PAGES && selected > 4;
  const showFinalFiller = length > MAXIMUM_NUMBER_OF_PAGINATION_PAGES && selected < length - 3;

  const navigateToNextPage = () => {
    if (selected < length) {
      onChange(selected + 1);
    }
  };

  const navigateToPreviousPage = () => {
    if (selected > 1) {
      onChange(selected - 1);
    }
  };

  const middlePagesNumbers = () => {
    const middlePages = [];

    if (showInitialFiller && showFinalFiller) {
      for (let i = selected - 1; i < selected + (MAXIMUM_NUMBER_OF_PAGINATION_PAGES - 5); i += 1) {
        middlePages.push(i);
      }
    } else if (!showInitialFiller && !showFinalFiller) {
      for (let i = 2; i < Math.min(MAXIMUM_NUMBER_OF_PAGINATION_PAGES - 1, length); i += 1) {
        middlePages.push(i);
      }
    } else if (!showInitialFiller) {
      for (let i = 2; i < MAXIMUM_NUMBER_OF_PAGINATION_PAGES - 1; i += 1) {
        middlePages.push(i);
      }
    } else if (!showFinalFiller) {
      for (let i = length - 4; i < length; i += 1) {
        middlePages.push(i);
      }
    }

    return middlePages;
  };

  const onPageNumberClick = (pageNumber) => {
    onChange(pageNumber);
  };

  return (
    <ul className="pagination">
      <li
        className={`pagination__item${selected === 1 ? ' pagination__item--disabled' : ''}`}
        onClick={navigateToPreviousPage}
      >
        &lt;
      </li>
      <li
        className={`pagination__item${selected === 1 ? ' pagination__item--selected' : ''}`}
        onClick={() => onPageNumberClick(1)}
      >
        1
      </li>
      {showInitialFiller && (
        <li className="pagination__item pagination__item--filler">
          …
        </li>
      )}
      {middlePagesNumbers().map((pageNumber, index) => (
        <li
          key={index}
          className={`pagination__item${selected === pageNumber ? ' pagination__item--selected' : ''}`}
          onClick={() => onPageNumberClick(pageNumber)}
        >
          {pageNumber}
        </li>
      ))}
      {showFinalFiller && (
        <li className="pagination__item pagination__item--filler">
          …
        </li>
      )}
      <li
        className={`pagination__item${selected === length ? ' pagination__item--selected' : ''}`}
        onClick={() => onPageNumberClick(length)}
      >
        {length}
      </li>
      <li
        className={`pagination__item${selected === length ? ' pagination__item--disabled' : ''}`}
        onClick={navigateToNextPage}
      >
        &gt;
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  selected: PropTypes.number,
  length: PropTypes.number
};

Pagination.defaultProps = {
  selected: 1,
  length: 10
};

export default Pagination;
