import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import normalShipmentMethodIcon from 'images/icons/shipment-methods/normal.svg';
import multipleDropoffsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-dropoffs.svg';
import multiplePickupsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-pickups.svg';
import multipleStopsShipmentMethodIcon from 'images/icons/shipment-methods/multiple-stops.svg';

import './styles.scss';

const shipmentMethods = [
  {
    value: 'normal',
    name: 'new-order.shipment_methods.m1.name',
    description: 'new-order.shipment_methods.m1.description',
    icon: normalShipmentMethodIcon
  },
  {
    value: 'multiple_dropoffs',
    name: 'new-order.shipment_methods.m2.name',
    description: 'new-order.shipment_methods.m2.description',
    icon: multipleDropoffsShipmentMethodIcon
  },
  {
    value: 'multiple_pickups',
    name: 'new-order.shipment_methods.m3.name',
    description: 'new-order.shipment_methods.m3.description',
    icon: multiplePickupsShipmentMethodIcon
  },
  {
    value: 'multiple_stops',
    name: 'new-order.shipment_methods.m4.name',
    description: 'new-order.shipment_methods.m4.description',
    icon: multipleStopsShipmentMethodIcon
  }
];

const NewOrderPreview = forwardRef((props, ref) => (
  <div
    ref={ref}
    className="new-order-preview"
  >
    <h3>
      <FormattedMessage id="new-order.shipment_methods.title" />
    </h3>
    <ul className="new-order-preview__options">
      {shipmentMethods.map((method, index) => (
        <li
          key={index}
          className="new-order-preview__options-item"
        >
          <img
            alt="Order Type icon"
            className="new-order-preview__options-icon"
            src={method.icon}
          />
          <div className="new-order-preview__options-text">
            <FormattedMessage id={method.name} />
            <div className="new-order-preview__options-description">
              <FormattedMessage id={method.description} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
));

export default NewOrderPreview;
