import BaseApi from '..';
import {
  NOTIFICATION_LIST,
  NOTIFICATION_SEEN
} from '../endpoints';


export default class NotificationsApi extends BaseApi {
  /* eslint-disable camelcase */

  static allNotifications = () => this.post(NOTIFICATION_LIST);

  static notificationSeen = ({
    notification_code
  }) => this.post(NOTIFICATION_SEEN, {
    notification_code
  });

  /* eslint-enable camelcase */
}
