import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUser, selectIsSigningUpAsTransporter, selectIsUploadingTransporterDocuments, signUpAsTransporter, uploadTransporterDocuments } from 'store/slices/authentication';

import {
  DOCUMENT_NAME_DRIVING_LICENSE,
  DOCUMENT_NAME_PERSON_IDENTIFICATION
} from '@youship/api/upload';

import {
  CARGO_DIMENSIONS,
  NUMBERS_ONLY_PATTERN,
  POSTAL_CODE_PATTERN,
  VAT_MINIMUM_LENGTH
} from '@youship/utils/form-validation';

import Button from '@youship/components/objects/button';
import Checkbox from '@youship/components/objects/checkbox';
import Input from '@youship/components/objects/input';
import Loader from '@youship/components/objects/loader';
import Notice from '@youship/components/objects/notice';
import Select from '@youship/components/objects/select';

import greenCheckIcon from 'images/icons/check-green.svg';

import './styles.scss';

const MARKETING_WEBSITE_URL = process.env.REACT_APP_MARKETING_WEBSITE_URL;

const USER_TYPE = [
  {
    id: 0,
    text: 'r_transporter.user_type.singular'
  },
  {
    id: 1,
    text: 'r_transporter.user_type.company'
  }
];

const PROFESSIONAL_STATUS = [
  {
    id: 0,
    text: 'r_transporter.worktype.no_activity'
  },
  {
    id: 1,
    text: 'r_transporter.worktype.private'
  },
  {
    id: 2,
    text: 'r_transporter.worktype.person_company'
  },
  {
    id: 3,
    text: 'r_transporter.worktype.company'
  }
];

const INSURANCE_TYPE = [
  {
    id: 0,
    text: 'r_transporter.insurance.no_insurance'
  },
  {
    id: 1,
    text: 'r_transporter.insurance.work'
  },
  {
    id: 2,
    text: 'r_transporter.insurance.liability'
  },
  {
    id: 3,
    text: 'r_transporter.insurance.parcel'
  }
];

const TransporterForm = () => {
  const { control, errors, handleSubmit, register, trigger, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      about: '',
      address: '',
      bankAccountIban: null,
      bankAccountName: '',
      cargoSpace: '',
      city: '',
      companyName: '',
      companyCode: null,
      driverLicenseFile: null,
      driverLicenseNumber: null,
      insuranceCoverage: '',
      insuranceName: '',
      insuranceNumber: null,
      insuranceType: '',
      postalCode: null,
      professionalStatus: '',
      schedule: '',
      transportLocation: '',
      transportPermit: '',
      userIdentificationFile: null,
      userType: '',
      vat: null,
      vehicleType: ''
    }
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const isSigningUpAsTransporter = useSelector(selectIsSigningUpAsTransporter);
  const isUploadingTransporterDocuments = useSelector(selectIsUploadingTransporterDocuments);

  // NOTE: This variables are always false because the client wants to always show the form
  const userIsAwaitingTransporterApproval = false;
  const userIsTransporter = false;

  const [errorMessage, setErrorMessage] = useState('Sorry, we were unable to process your request at this time. Please try again later.');
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isSingular, setIsSingular] = useState(false);
  const [userIdentificationFileUploadName, setUserIdentificationFileUploadName] = useState(null);
  const [userDrivingLicenseFileUploadName, setUserDrivingLicenseFileUploadName] = useState(null);

  const requiredInputs = [
    'about',
    'address',
    'cargoSpace',
    'city',
    'driverLicenseNumber',
    'insuranceType',
    'postalCode',
    'schedule',
    'transportLocation',
    'userType',
    'vat',
    'vehicleType'
  ];

  if (isSingular) {
    requiredInputs.push('professionalStatus', 'driverLicenseFile', 'userIdentificationFile');
  } else if (isCompany) {
    requiredInputs.push('companyName', 'companyCode');
  }

  const isFormValid = requiredInputs.every(input => (!!watch(input) || typeof watch(input) === 'number') && !errors[input]) && termsAgreed;

  const isFormDisabled = isSigningUpAsTransporter || isUploadingTransporterDocuments || userIsAwaitingTransporterApproval || userIsTransporter;

  const isFormSubmitDisabled = isFormDisabled || !isFormValid;

  const intl = useIntl();

  useEffect(() => {
    register({ name: 'about' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.about.required' }),
      minLength: {
        value: 5,
        message: intl.formatMessage({ id: 'r_transporter.form.about.pattern' })
      }
    });

    register({ name: 'address' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.address.required' })
    });

    register({ name: 'bankAccountIban' });

    register({ name: 'bankAccountName' });

    register({ name: 'cargoSpace' }, {
      pattern: {
        value: CARGO_DIMENSIONS,
        message: intl.formatMessage({ id: 'r_transporter.form.vehiclecapacity.pattern' })
      },
      required: intl.formatMessage({ id: 'r_transporter.form.vehiclecapacity.required' })
    });

    register({ name: 'city' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.city.required' })
    });

    if (isCompany) {
      register({ name: 'companyName' }, {
        required: intl.formatMessage({ id: 'r_transporter.form.company.required' })
      });

      register({ name: 'companyCode' }, {
        required: intl.formatMessage({ id: 'r_transporter.form.company_code.required' })
      });
    }

    if (isSingular) {
      register({ name: 'driverLicenseFile' }, {
        required: intl.formatMessage({ id: 'r_transporter.form.driver_license_file.required' })
      });
    }

    register({ name: 'driverLicenseNumber' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.driver_license.required' })
    });

    register({ name: 'insuranceCoverage' });

    register({ name: 'insuranceName' });

    register({ name: 'insuranceNumber' });

    register({ name: 'insuranceType' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.insurance_type.required' })
    });

    register({ name: 'postalCode' }, {
      pattern: {
        value: POSTAL_CODE_PATTERN,
        message: intl.formatMessage({ id: 'r_transporter.form.postalcode.pattern' })
      },
      required: intl.formatMessage({ id: 'r_transporter.form.postalcode.required' })
    });

    if (isSingular) {
      register({ name: 'professionalStatus' }, {
        required: intl.formatMessage({ id: 'r_transporter.form.worktype.required' })
      });
    }

    register({ name: 'schedule' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.availability.required' })
    });

    register({ name: 'transportLocation' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.maincity.required' })
    });

    register({ name: 'transportPermit' });

    if (isSingular) {
      register({ name: 'userIdentificationFile' }, {
        required: intl.formatMessage({ id: 'r_transporter.form.user_identification_file.required' })
      });
    }

    register({ name: 'userType' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.user_type.required' })
    });

    register({ name: 'vat' }, {
      pattern: {
        value: NUMBERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'r_transporter.form.vat.pattern' })
      },
      minLength: {
        value: VAT_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'r_transporter.form.vat.minlength' },
          { VAT_MINIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'r_transporter.form.vat.required' })
    });

    register({ name: 'vehicleType' }, {
      required: intl.formatMessage({ id: 'r_transporter.form.vehicletype.required' })
    });
  });

  const getInputMessage = (name) => {
    if (errors[name]) {
      return errors[name].message;
    }

    return '';
  };

  const getInputStatus = (name) => {
    if (errors[name]) {
      return 'error';
    }

    return 'default';
  };

  const handleInputChange = (value, props) => {
    const { name } = props;

    if (name === 'userType') {
      setIsSingular(value === '0');
      setIsCompany(value === '1');
    }

    if (name === 'userIdentificationFile' || name === 'driverLicenseFile') {
      const file = value[0] || null;

      if (file) {
        if (name === 'userIdentificationFile') {
          setUserIdentificationFileUploadName(file.name);
        } else if (name === 'driverLicenseFile') {
          setUserDrivingLicenseFileUploadName(file.name);
        }
        try {
          // NOTE: We have to create a fake formData because its mandatory to submit multipart/form-data request
          const fileForm = new FormData();
          const fileName = name === 'userIdentificationFile' ? DOCUMENT_NAME_PERSON_IDENTIFICATION : DOCUMENT_NAME_DRIVING_LICENSE;

          fileForm.append(fileName, file);
          dispatch(uploadTransporterDocuments(fileForm))
            .then((response) => {
              dispatch(getUser());

              return response;
            })
            .catch((error) => {
              throw error;
            });
        } catch (error) {
          // TODO: add proper error handling
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    }

    props.onChange(value);

    if (errors[name]) {
      trigger(name);
    }
  };

  const listUserTypes = USER_TYPE.map(item => ({
    value: item.id.toString(),
    text: intl.formatMessage({ id: item.text })
  }));

  const listProfessionalStatus = PROFESSIONAL_STATUS.map(item => ({
    value: item.id.toString(),
    text: intl.formatMessage({ id: item.text })
  }));

  const listInsuranceType = INSURANCE_TYPE.map(item => ({
    value: item.id.toString(),
    text: intl.formatMessage({ id: item.text })
  }));

  const handleFormSubmit = (data) => {
    const formData = {
      ...data
    };

    dispatch(signUpAsTransporter(formData))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while submitting the form.');

        return response;
      })
      .then((response) => {
        setSuccessMessage(intl.formatMessage({ id: 'r_transporter.success' }));
        dispatch(getUser());

        setTimeout(() => {
          history.push('/account');
        }, 6000);

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        setFailed(true);
        setErrorMessage(error.message);
      });
  };

  return (
    <>
      {userIsTransporter && (
        <div className="transporter-form__notice">
          <FormattedMessage id="r_transporter.already_signup" />
        </div>
      )}
      {!userIsTransporter && !successMessage && userIsAwaitingTransporterApproval && (
        <div className="transporter-form__notice">
          <FormattedMessage id="r_transporter.already_awaiting" />
        </div>
      )}
      {!successMessage ? (
        <form
          className="transporter-form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          {isSigningUpAsTransporter && (
            <div className="transporter-form__loader-wrapper">
              <Loader />
            </div>
          )}
          <div className="transporter-form__input-group">
            <Controller
              control={control}
              name="userType"
              render={props => (
                <Select
                  disabled={isFormDisabled}
                  instructions={intl.formatMessage({ id: 'r_transporter.form.user_type.placeholder' })}
                  label={intl.formatMessage({ id: 'r_transporter.form.user_type.label' })}
                  message={getInputMessage(props.name)}
                  options={listUserTypes}
                  status={getInputStatus(props.name)}
                  value={props.value}
                  onBlur={props.onBlur}
                  onChange={option => handleInputChange(option, props)}
                />
              )}
            />
            {!!isCompany && (
              <>
                <Controller
                  control={control}
                  name="companyName"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.company.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.company.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="companyCode"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.companycode.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.companycode.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="transportPermit"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.charter.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.charter.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
              </>
            )}
            {!!isSingular && (
              <Controller
                control={control}
                name="professionalStatus"
                render={props => (
                  <Select
                    instructions={intl.formatMessage({ id: 'r_transporter.form.worktype.placeholder' })}
                    label={intl.formatMessage({ id: 'r_transporter.form.worktype.label' })}
                    message={getInputMessage(props.name)}
                    options={listProfessionalStatus}
                    status={getInputStatus(props.name)}
                    value={props.value}
                    onBlur={props.onBlur}
                    onChange={option => handleInputChange(option, props)}
                  />
                )}
              />
            )}
            {(!!isSingular || !!isCompany) && (
              <>
                <Controller
                  control={control}
                  name="insuranceType"
                  render={props => (
                    <Select
                      instructions={intl.formatMessage({ id: 'r_transporter.form.insurance_type.placeholder' })}
                      label={intl.formatMessage({ id: 'r_transporter.form.insurance_type.label' })}
                      message={getInputMessage(props.name)}
                      options={listInsuranceType}
                      status={getInputStatus(props.name)}
                      value={props.value}
                      onBlur={props.onBlur}
                      onChange={option => handleInputChange(option, props)}
                    />
                  )}
                />
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Controller
                      control={control}
                      name="insuranceName"
                      render={props => (
                        <Input
                          block
                          label={intl.formatMessage({ id: 'r_transporter.form.insurance.label' })}
                          message={getInputMessage(props.name)}
                          placeholder={intl.formatMessage({ id: 'r_transporter.form.insurance.placeholder' })}
                          status={getInputStatus(props.name)}
                          onBlur={props.onBlur}
                          onChange={e => handleInputChange(e.target.value, props)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Controller
                      control={control}
                      name="insuranceNumber"
                      render={props => (
                        <Input
                          block
                          label={intl.formatMessage({ id: 'r_transporter.form.policy.label' })}
                          message={getInputMessage(props.name)}
                          placeholder={intl.formatMessage({ id: 'r_transporter.form.policy.placeholder' })}
                          status={getInputStatus(props.name)}
                          onBlur={props.onBlur}
                          onChange={e => handleInputChange(e.target.value, props)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Controller
                      control={control}
                      name="insuranceCoverage"
                      render={props => (
                        <Input
                          block
                          label={intl.formatMessage({ id: 'r_transporter.form.coverage.label' })}
                          message={getInputMessage(props.name)}
                          placeholder={intl.formatMessage({ id: 'r_transporter.form.coverage.placeholder' })}
                          status={getInputStatus(props.name)}
                          onBlur={props.onBlur}
                          onChange={e => handleInputChange(e.target.value, props)}
                        />
                      )}
                    />
                  </div>
                </div>
                <hr className="transporter-form__separator" />
                <Controller
                  control={control}
                  name="address"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.address.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.address.placeholder' })}
                      status={getInputStatus(props.name)}
                      type="textarea"
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <div className="row">
                  <div className="col-12 col-md-4">
                    <Controller
                      control={control}
                      name="postalCode"
                      render={props => (
                        <Input
                          block
                          label={intl.formatMessage({ id: 'r_transporter.form.postalcode.label' })}
                          message={getInputMessage(props.name)}
                          placeholder={intl.formatMessage({ id: 'r_transporter.form.postalcode.placeholder' })}
                          status={getInputStatus(props.name)}
                          onBlur={props.onBlur}
                          onChange={e => handleInputChange(e.target.value, props)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-8">
                    <Controller
                      control={control}
                      name="city"
                      render={props => (
                        <Input
                          block
                          label={intl.formatMessage({ id: 'r_transporter.form.city.label' })}
                          message={getInputMessage(props.name)}
                          placeholder={intl.formatMessage({ id: 'r_transporter.form.city.placeholder' })}
                          status={getInputStatus(props.name)}
                          onBlur={props.onBlur}
                          onChange={e => handleInputChange(e.target.value, props)}
                        />
                      )}
                    />
                  </div>
                </div>
                <hr className="transporter-form__separator" />
                <Controller
                  control={control}
                  name="vat"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.vat.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.vat.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="driverLicenseNumber"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.driver_license.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.driver_license.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <hr className="transporter-form__separator" />
                <Controller
                  control={control}
                  name="vehicleType"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.vehicletype.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.vehicletype.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="cargoSpace"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.vehiclecapacity.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.vehiclecapacity.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="schedule"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.availability.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.availability.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="transportLocation"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.maincity.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.maincity.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="about"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.about.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.about.placeholder' })}
                      status={getInputStatus(props.name)}
                      type="textarea"
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                {!!isSingular && (
                  <>
                    <hr className="transporter-form__separator" />
                    <p className="transporter-form__subtitle">
                      <FormattedMessage id="r_transporter.form.documents.title" />
                    </p>
                    <div className="transporter-form__instructions-content">
                      <p className="transporter-form__instruction">
                        <FormattedMessage id="r_transporter.form.documents.instruction_1" />
                      </p>
                      <p className="transporter-form__instruction">
                        <FormattedMessage id="r_transporter.form.documents.instruction_2" />
                      </p>
                    </div>
                    <Controller
                      control={control}
                      name="userIdentificationFile"
                      render={props => (
                        <Input
                          block
                          fileName={userIdentificationFileUploadName || 'Choose File'}
                          inputId="upload-identification-file"
                          label={intl.formatMessage({ id: 'r_transporter.form.user_identification_file.label' })}
                          message={getInputMessage(props.name)}
                          status={getInputStatus(props.name)}
                          type="file"
                          onBlur={props.onBlur}
                          onChange={e => handleInputChange(e.target.files, props)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="driverLicenseFile"
                      render={props => (
                        <Input
                          block
                          fileName={userDrivingLicenseFileUploadName || 'Choose File'}
                          inputId="upload-driver-license"
                          label={intl.formatMessage({ id: 'r_transporter.form.driver_license_file.label' })}
                          message={getInputMessage(props.name)}
                          status={getInputStatus(props.name)}
                          type="file"
                          onBlur={props.onBlur}
                          onChange={e => handleInputChange(e.target.files, props)}
                        />
                      )}
                    />
                  </>
                )}
                <hr className="transporter-form__separator" />
                <p className="transporter-form__subtitle">
                  <FormattedMessage id="r_transporter.form.bank.title" />
                </p>
                <Controller
                  control={control}
                  name="bankAccountName"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.bank_name.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.bank_name.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="bankAccountIban"
                  render={props => (
                    <Input
                      block
                      label={intl.formatMessage({ id: 'r_transporter.form.bank_account.label' })}
                      message={getInputMessage(props.name)}
                      placeholder={intl.formatMessage({ id: 'r_transporter.form.bank_account.placeholder' })}
                      status={getInputStatus(props.name)}
                      onBlur={props.onBlur}
                      onChange={e => handleInputChange(e.target.value, props)}
                    />
                  )}
                />
                <hr className="transporter-form__separator" />
                <Checkbox
                  alignTop
                  checked={termsAgreed}
                  label={(
                    <div className="transporter-form__info">
                      <FormattedMessage id="register.form.terms_agree" />
                      {' '}
                      <a
                        className="transporter-form__link"
                        href={`${MARKETING_WEBSITE_URL}/terms-and-conditions`}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={event => event.stopPropagation()}
                      >
                        <FormattedMessage id="register.form.terms_link" />
                      </a>
                      {' '}
                      <FormattedMessage id="register.form.terms_text" />
                      {' '}
                      <a
                        className="transporter-form__link"
                        href={`${MARKETING_WEBSITE_URL}/privacy`}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={event => event.stopPropagation()}
                      >
                        <FormattedMessage id="register.form.privacy_link" />
                      </a>
                    </div>
                  )}
                  noBorder
                  smallMargin
                  onChange={value => setTermsAgreed(value)}
                />
              </>
            )}
          </div>
          {(!!isSingular || !!isCompany) && (
            <>
              <div className="transporter-form__footer">
                <Button
                  context="primary"
                  disabled={isFormSubmitDisabled}
                  largeHorizontalPadding
                  smallVerticalPadding
                  text={intl.formatMessage({ id: 'r_transporter.submit' })}
                  type="submit"
                />
              </div>
              {failed && (
                <div className="transporter-form__error">
                  {errorMessage}
                </div>
              )}
            </>
          )}
        </form>
      ) : (
        <div className="transporter-form__feedback">
          <Notice
            description={successMessage}
            fixed
            icon={greenCheckIcon}
          />
        </div>
      )}
    </>
  );
};

export default TransporterForm;
