import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Notification from '@youship/components/notification';

import './styles.scss';

const NotificationsGroup = ({ notifications, onNotificationClick, showTimeDifference, title }) => (
  <div className="notifications-group">
    <div className="notifications-group__title">
      {title}
    </div>
    {Array.isArray(notifications) && notifications.map(notification => (
      <Notification
        key={notification.id}
        {...notification}
        hoverable
        linkComponent={Link}
        showSeenStatus
        showTimeDifference={showTimeDifference}
        onClick={() => onNotificationClick(notification.id)}
      />
    ))}
  </div>
);

NotificationsGroup.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  })),
  onNotificationClick: PropTypes.func,
  showTimeDifference: PropTypes.bool,
  title: PropTypes.string
};

NotificationsGroup.defaultProps = {
  notifications: null,
  onNotificationClick: () => {},
  showTimeDifference: false,
  title: 'Notifications'
};

export default NotificationsGroup;
