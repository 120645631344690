import React, { useEffect } from 'react';
import { Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Cookies from 'universal-cookie';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { getUser, initialize as initializeAuthentication, selectInitialized, selectIsAuthenticated, selectUserHomePage, selectUserIsTransporter } from 'store/slices/authentication';
import { selectLocale } from 'store/slices/locales';
import { fetchNotifications } from 'store/slices/notifications';
import { initialize as initializeShoppingCart } from 'store/slices/shopping-cart';
import { initialize as initializeNewDelivery } from 'store/slices/new-delivery';
import SessionStorageManager from '@youship/utils/SessionStorageManager';

import en from '@youship/i18n/web-app/en';
import pt from '@youship/i18n/web-app/pt';
import flattenMessages from '@youship/utils/flatten-messages';

import Route from 'utils/Route';

import Navbar from 'components/navbar';

import Account from 'views/account';
import AccountConfirmation from 'views/account-confirmation';
import Categories from 'views/categories';
import Category from 'views/category';
import Dashboard from 'views/dashboard';
import EmailSent from 'views/email-sent';
import ForgotPassword from 'views/forgot-password';
import LoginFromSession from 'views/login-from-session';
import Login from 'views/login';
import Logout from 'views/logout';
import Checkout from 'views/checkout';
import NewOrder from 'views/new-order';
import NotFound from 'views/not-found';
import Notifications from 'views/notifications';
import Order from 'views/order';
import Orders from 'views/orders';
import Register from 'views/register';
import Venue from 'views/venue';

import PushNotifications from 'components/push-notifications';

import ScrollToTopOnHistoryChange from 'utils/scrollToTopOnHistoryChange';

const cookies = new Cookies();

const API_BASE_URL = process.env.NODE_ENV === 'development' ?
  process.env.REACT_APP_API_URL_DEVELOPMENT :
  process.env.REACT_APP_API_URL_PRODUCTION;

const messages = {
  en: flattenMessages(en),
  pt: flattenMessages(pt)
};

const HOME_PAGES = {
  shopping: {
    component: <Dashboard />,
    path: '/shopping',
    requiresAuthentication: false
  },
  orders: {
    component: <Orders />,
    path: '/orders',
    requiresAuthentication: true
  }
};

const App = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);

  // useLocation() hook can't be used in App.js
  const currentPathname = window.location.pathname;

  const authenticationInitialized = useSelector(selectInitialized);
  const userIsTransporter = useSelector(selectUserIsTransporter);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userHomePage = useSelector(selectUserHomePage);

  const authTokenCookie = cookies.get('AUTH_TOKEN');
  const userRequiresPhoneActivationCookie = cookies.get('USER_REQUIRES_PHONE_ACTIVATION');

  const isAuthentication = currentPathname === '/login' || currentPathname === '/forgot-password' || currentPathname === '/email-sent' || currentPathname === '/register';
  const showPushNotifications = currentPathname !== 'notifications' && !isAuthentication;

  useEffect(() => {
    dispatch(initializeAuthentication());
    dispatch(initializeShoppingCart());
    dispatch(initializeNewDelivery());
  }, [dispatch]);

  useEffect(
    () => {
      SessionStorageManager.setApiBaseUrl(API_BASE_URL);

      if (authTokenCookie) {
        SessionStorageManager.setAuthenticationToken(authTokenCookie);
        SessionStorageManager.setAuthenticationRequirePhoneActivation(!(userRequiresPhoneActivationCookie === 'false'));
        dispatch(getUser());
      }
    },
    // We just need this hook to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchNotifications());

      let notificationsRefreshTimeout = setTimeout(
        function refresh () {
          dispatch(fetchNotifications());

          notificationsRefreshTimeout = setTimeout(refresh, 30000);
        },
        120000
      );

      return () => clearTimeout(notificationsRefreshTimeout);
    }

    return () => {};
  }, [dispatch, isAuthenticated]);

  const handleDisableScroll = () => {
    const { body } = document;
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');

    body.classList.add('no-scroll');
    body.style.top = `-${scrollY}`;
  };

  const handleEnableScroll = () => {
    const { body } = document;
    const scrollY = body.style.top;

    body.classList.remove('no-scroll');
    body.style.position = '';
    body.style.top = '';

    if (window) {
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  };

  const handleScroll = () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('disable-scroll', handleDisableScroll);
      window.addEventListener('enable-scroll', handleEnableScroll);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (window) {
        window.removeEventListener('disable-scroll', handleDisableScroll);
        window.removeEventListener('enable-scroll', handleEnableScroll);
        window.removeEventListener('enable-scroll', handleEnableScroll);
      }
    };
  });

  // This is necessary to prevent unnecessary dashboard requests. Before authentication is initalized we cannot tell if user is transporter.
  if (!authenticationInitialized) return null;

  const homePage = HOME_PAGES[userHomePage] || (userIsTransporter ? HOME_PAGES.orders : HOME_PAGES.shopping);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
    >
      <Router>
        <>
          <ScrollToTopOnHistoryChange />
          <div className="app">
            <Navbar />
            <main className="app-main">
              <Switch>
                <Route
                  exact
                  path="/"
                >
                  {homePage.component}
                </Route>
                <Route
                  path={homePage.path}
                  render={routeProps => authenticationInitialized && <Redirect to={{ ...routeProps.location, pathname: '/' }} />}
                  requiresAuthentication={!!homePage.requiresAuthentication}
                />
                <Route
                  path="/orders"
                  requiresAuthentication
                >
                  <Orders />
                </Route>
                {!userIsTransporter && (
                  <Route path="/shopping">
                    <Dashboard />
                  </Route>
                )}
                {!userIsTransporter && (
                  <Route path="/categories">
                    <Categories />
                  </Route>
                )}
                {!userIsTransporter && (
                  <Route
                    component={Category}
                    path="/category/:orderTypeId"
                  />
                )}
                <Route
                  path="/account"
                  requiresAuthentication
                >
                  <Account />
                </Route>
                <Route
                  path="/account-confirmation"
                  requiresAuthentication
                >
                  <AccountConfirmation />
                </Route>
                {!userIsTransporter && (
                  <Route
                    component={Venue}
                    path="/venue/:shopId"
                  />
                )}
                <Route
                  component={Order}
                  path="/order/:orderId"
                />
                <Route path="/new-order">
                  <NewOrder />
                </Route>
                <Route
                  path="/checkout"
                  requiresAuthentication
                >
                  <Checkout />
                </Route>
                <Route path="/notifications">
                  <Notifications />
                </Route>
                <Route path="/register">
                  <Register />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route path="/email-sent">
                  <EmailSent />
                </Route>
                <Route path="/logout">
                  <Logout />
                </Route>
                <Route path="/goto">
                  <LoginFromSession />
                </Route>
                <Route path="/404">
                  <NotFound />
                </Route>
                <Redirect to="/404" />
              </Switch>
            </main>
            {showPushNotifications && <PushNotifications />}
          </div>
        </>
      </Router>
    </IntlProvider>
  );
};

export default App;
