import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import Disclaimer from 'components/disclaimer';
import NewOrderCard from 'components/new-order-card';
import OrderTypeCard from 'components/order-type-card';

import './styles.scss';

const OrderTypesSlider = ({ searchTerm, items }) => {
  if (!Array.isArray(items)) return null;

  const filteredItems = searchTerm ?
    items.filter(orderType => orderType.name && orderType.name.toLowerCase().includes(searchTerm.toLowerCase())) :
    items;

  return (
    <Swiper
      breakpoints={{
        576: {
          slidesPerView: 2
        },
        992: {
          slidesPerView: 4
        }
      }}
      className="order-types-slider"
      slidesPerView="1"
      spaceBetween={32}
    >
      <SwiperSlide>
        <NewOrderCard />
      </SwiperSlide>
      {filteredItems.length ?
        filteredItems.map((item, index) => (
          <SwiperSlide
            key={index}
            className="order-types-slider__slide"
          >
            <OrderTypeCard
              {...item}
              linkProps={{ to: `/category/${item.orderType}` }}
            />
          </SwiperSlide>
        )) : (
          <div className="col col-12">
            <Disclaimer />
          </div>
        )}
    </Swiper>
  );
};

OrderTypesSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    orderType: PropTypes.string
  })),
  searchTerm: PropTypes.string
};

OrderTypesSlider.defaultProps = {
  items: null,
  searchTerm: null
};

export default OrderTypesSlider;
