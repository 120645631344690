import React from 'react';

import CheckoutDetails from 'components/sections/checkout-details';

const Checkout = () => (
  <>
    <CheckoutDetails />
  </>
);

export default Checkout;
