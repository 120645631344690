import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import ShoppingApi from '@youship/api/shopping';

const shopsAdapter = createEntityAdapter();

const initialState = shopsAdapter.getInitialState({
  isLoading: false
});

// Thunks:

export const fetchShop = createAsyncThunk(
  'shops/fetchShop',
  ({ shopId, complete }) => {
    const shopDetailsRequest = complete ? ShoppingApi.shopDetailsComplete : ShoppingApi.shopDetails;

    // eslint-disable-next-line camelcase
    return shopDetailsRequest({ shop_code: shopId })
      .then((response) => {
        if (response?.shop) {
          const { shop } = response;

          const restructuredShop = {
            ...shop,
            alertColor: shop.alert_color,
            alertMessage: shop.alert_message,
            averagePrice: shop.avg_price,
            closedMessage: shop.closed_message,
            fulfillment: typeof shop.fulfillment === 'string' ? shop.fulfillment.replace(/-|–/g, '–') : shop.fulfillment,
            id: shop.shop_code,
            imageUrl: shop.photo_url,
            schedule: Array.isArray(shop.hours) ?
              shop.hours.map(({ day, intervals }) => {
                const timeSlot = {};

                if (day?.title) {
                  timeSlot.day = day.title;
                }

                if (Array.isArray(intervals)) {
                  timeSlot.intervals = intervals.map(interval => (typeof interval?.title === 'string' ? interval.title.replace(/-|–/g, '–') : interval?.title));
                }

                return timeSlot;
              }) :
              null
          };

          if (shop.pickup?.address) {
            const { address } = shop.pickup;

            restructuredShop.address = {
              ...address,
              coordinates: {
                latitude: address.lat,
                longitude: address.lng
              },
              countryId: address.idcountry,
              formattedAddress: address.formatted_address,
              lineAddress: address.line_address,
              number: address.number_address,
              postalCode: address.postalcode,
              strAddress: address.str_address,
              streetAddress: address.street_address
            };
          }

          if (Array.isArray(shop.categories)) {
            restructuredShop.categories = shop.categories.map(category => ({
              ...category,
              id: category.category_code,
              products: Array.isArray(category.products) ?
                category.products.map(product => ({
                  ...product,
                  id: product.product_code,
                  imageUrl: product.photo_url,
                  options: Array.isArray(product.options) ?
                    product.options.map(option => ({
                      ...option,
                      extras: Array.isArray(option.extras) ?
                        option.extras.map(extra => ({
                          ...extra,
                          id: extra.product_code,
                          smallDescription: extra.small_description,
                          soldOut: extra.sold_out
                        })) :
                        [],
                      id: option.category_code
                    })) :
                    [],
                  smallDescription: product.small_description,
                  soldOut: product.sold_out
                })) :
                []
            }));
          }

          // If shop_types is not in the response, do not include in payload (to prevent overwrite)
          if (Array.isArray(shop.shop_types)) restructuredShop.shopTypes = shop.shop_types;

          return restructuredShop;
        }

        return null;
      });
  }
);

export const fetchShops = createAsyncThunk(
  'shops/fetchShops',
  // eslint-disable-next-line camelcase
  orderTypeId => ShoppingApi.listShops({ order_type: orderTypeId })
    .then((response) => {
      if (response && Array.isArray(response.rows)) {
        return response.rows.map(shop => ({
          ...shop,
          alertColor: shop.alert_color,
          alertMessage: shop.alert_message,
          averagePrice: shop.avg_price,
          closedMessage: shop.closed_message,
          fulfillment: typeof shop.fulfillment === 'string' ? shop.fulfillment.replace(/-|–/g, '–') : shop.fulfillment,
          id: shop.shop_code,
          imageUrl: shop.photo_url,
          orderTypeIds: [orderTypeId],
          schedule: shop.hours,
          shopTypes: Array.isArray(shop.shop_types) ? shop.shop_types : []
        }));
      }

      return null;
    })
);

export const fetchMostPopularShops = createAsyncThunk(
  'shops/fetchMostPopularShops',
  // eslint-disable-next-line camelcase
  () => ShoppingApi.listShops({ order_type: 'food' }) // NOTE: Using 'food' shops while there is no most popular shops endpoint
    .then((response) => {
      if (response && Array.isArray(response.rows)) {
        const shops = response.rows.map(shop => ({
          ...shop,
          alertColor: shop.alert_color,
          alertMessage: shop.alert_message,
          averagePrice: shop.avg_price,
          closedMessage: shop.closed_message,
          fulfillment: typeof shop.fulfillment === 'string' ? shop.fulfillment.replace(/-|–/g, '–') : shop.fulfillment,
          id: shop.shop_code,
          imageUrl: shop.photo_url,
          isMostPopular: true,
          schedule: shop.hours,
          shopTypes: Array.isArray(shop.shop_types) ? shop.shop_types : []
        }));
        // Double shop.id used for mocked data
        const moreShops = shops.map(shop => ({ ...shop, id: `${shop.id}${shop.id}` }));

        return [...shops, ...moreShops];
      }

      return null;
    })
);

// Slice:

const shopsSlice = createSlice({
  name: 'shops',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchShop.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchShop.fulfilled, (state, action) => {
        const shop = action.payload;

        if (shop) shopsAdapter.upsertOne(state, shop);

        state.isLoading = false;
      })
      .addCase(fetchShop.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchShops.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchShops.fulfilled, (state, action) => {
        const shops = action.payload;

        if (Array.isArray(shops)) {
          shops.forEach((shop) => {
            const { id } = shop;
            const [orderTypeIdFromThisRequest] = shop.orderTypeIds;

            const entity = state.entities[id];

            if (entity) {
              if (Array.isArray(entity.orderTypeIds)) {
                const orderTypeIds = entity.orderTypeIds.slice();
                const index = orderTypeIds.indexOf(orderTypeIdFromThisRequest);

                if (index !== -1) orderTypeIds.splice(index, 1);

                orderTypeIds.push(orderTypeIdFromThisRequest);
              }
            }

            shopsAdapter.upsertOne(state, shop);
          });
        }

        state.isLoading = false;
      })
      .addCase(fetchShops.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchMostPopularShops.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMostPopularShops.fulfilled, (state, action) => {
        const shops = action.payload;

        if (Array.isArray(shops)) {
          shops.forEach((shop) => {
            shopsAdapter.upsertOne(state, shop);
          });
        }

        state.isLoading = false;
      })
      .addCase(fetchMostPopularShops.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export default shopsSlice.reducer;

// Selectors:

export const {
  selectAll: selectShops,
  selectById: selectShopById
} = shopsAdapter.getSelectors(state => state.shops);

export const selectIsLoading = state => state.shops.isLoading;

export const selectShopsByOrderTypeId = (state, payload) => {
  const shops = [];
  const { ids, entities } = state.shops;

  ids.forEach((id) => {
    if (Array.isArray(entities[id].orderTypeIds) && entities[id].orderTypeIds.includes(payload)) shops.push(entities[id]);
  });

  return shops;
};

export const selectShopsByMostPopular = (state) => {
  const shops = [];
  const { ids, entities } = state.shops;

  ids.forEach((id) => {
    if (entities[id].isMostPopular === true) shops.push(entities[id]);
  });

  return shops;
};
