import React from 'react';

import NewOrderDetails from 'components/sections/new-order-details';

const NewOrder = () => (
  <>
    <NewOrderDetails />
  </>
);

export default NewOrder;
