/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOrderTypes, fetchShopTypes, selectOrderTypeById, selectShopTypesByOrderTypeId } from 'store/slices/categories';
import { fetchShops, selectShopsByOrderTypeId } from 'store/slices/shops';

import CategoryHeader from 'components/category-header';
import NotFoundShops from 'components/sections/not-found-shops';
import ShopList from 'components/shop-list';
import ShopTypeList from 'components/shop-type-list';
import Search from 'components/search';

const OrderType = ({ match }) => {
  const { orderTypeId } = match.params;

  const dispatch = useDispatch();
  const orderType = useSelector(state => selectOrderTypeById(state, orderTypeId));
  const shopTypes = useSelector(state => selectShopTypesByOrderTypeId(state, orderTypeId));
  const shops = useSelector(state => selectShopsByOrderTypeId(state, orderTypeId));

  const [searchValue, setSearchValue] = useState('');
  const [selectedShopTypeId, setSelectedShopTypeId] = useState(null);

  const name = orderType?.name || '\xa0'; // non-breaking space char to preserve height
  const searchPlaceholder = `Search${orderType?.name ? ` in ${name.toLowerCase()}` : ''}`;

  useEffect(() => {
    if (!orderType) dispatch(fetchOrderTypes());
  }, [dispatch, orderType]);

  useEffect(() => {
    if (!Array.isArray(shopTypes)) dispatch(fetchShopTypes(orderTypeId));
  }, [dispatch, orderTypeId, shopTypes]);

  useEffect(() => {
    dispatch(fetchShops(orderTypeId))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while fetching shops for this category.');

        return response;
      })
      .catch((error) => {
        // TODO: add proper error handling
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const shopTypeList = Array.isArray(shopTypes) ? shopTypes : [];

  const filteredShops = searchValue || selectedShopTypeId ?
    shops.filter(shop => (
      searchValue && shop.name && shop.name.toLowerCase().includes(searchValue.toLowerCase())
    ) || (
      selectedShopTypeId && Array.isArray(shop.shopTypes) && shop.shopTypes.map(({ code }) => code).includes(selectedShopTypeId)
    )) :
    shops;

  const handleSearchChange = (event) => {
    setSearchValue(event?.target?.value ?? '');
  };

  const handleShopTypeClick = (shopTypeId) => {
    if (shopTypeId !== selectedShopTypeId) {
      setSelectedShopTypeId(shopTypeId);
    } else {
      setSelectedShopTypeId(null);
    }
  };

  return (
    <div className="container">
      <CategoryHeader category={name} />
      {Array.isArray(shops) && shops.length > 0 ? (
        <>
          <ShopTypeList
            items={shopTypeList}
            selected={selectedShopTypeId}
            onItemClick={handleShopTypeClick}
          />
          <Search
            largeMargin
            placeholder={searchPlaceholder}
            value={searchValue}
            onChange={handleSearchChange}
          />
          <ShopList items={filteredShops} />
        </>
      ) : (
        <NotFoundShops
          buttonLink="/partners"
          buttonText="dashboard.not_found_shops.button"
          text="dashboard.not_found_shops.text"
          title="dashboard.not_found_shops.title"
        />
      )}
    </div>
  );
};


export default OrderType;
