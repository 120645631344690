/* Regex patterns */
const CARGO_DIMENSIONS = /^\d+x\d+x\d+/;
const COMPANY_CODE_PATTERN = /^\d{4}-\d{4}-\d{4}$/;
const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const LETTERS_ONLY_PATTERN = /[A-Za-z]/;
const NUMBERS_ONLY_PATTERN = /^[0-9]+$/i;
const PASSWORD_PATTERN = /(?=.*[A-Za-z])((?=.*[0-9]))/;
const POSTAL_CODE_PATTERN = /^\d{4}(-\d{3})?$/;

/* Minimum and max lengths */
const PASSWORD_MINIMUM_LENGTH = 6;
const PHONE_MAXIMUM_LENGTH = 15;
const PHONE_MINIMUM_LENGTH = 9;
const VAT_MINIMUM_LENGTH = 8;

export {
  CARGO_DIMENSIONS,
  COMPANY_CODE_PATTERN,
  EMAIL_PATTERN,
  LETTERS_ONLY_PATTERN,
  NUMBERS_ONLY_PATTERN,
  PASSWORD_MINIMUM_LENGTH,
  PASSWORD_PATTERN,
  PHONE_MAXIMUM_LENGTH,
  PHONE_MINIMUM_LENGTH,
  POSTAL_CODE_PATTERN,
  VAT_MINIMUM_LENGTH
};
