import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { getDeliveryDetails, getDeliveryProof, performDeliveryAction, rescheduleDelivery, selectDeliveryProof, selectIsLoadingDeliveryProof, selectIsPerformingAction } from 'store/slices/deliveries';
import { fetchNotifications } from 'store/slices/notifications';

import ActionModal from 'components/order/action-modal';
import DeliveryProofModal from 'components/order/delivery-proof-modal';
import RescheduleModal from 'components/order/reschedule-modal';

import './styles.scss';

const OrderActions = ({ actions, dropoffSchedule, goBackUrl, id, pickupSchedule }) => {
  const dispatch = useDispatch();

  const [actionType, setActionType] = useState(null);
  const [actionTitle, setActionTitle] = useState(null);
  const [deliveryProofError, setDeliveryProofError] = useState(null);
  const [showActionModal, setShowActionModal] = useState(false);
  const [showDeliveryProofModal, setShowDeliveryProofModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [removeOrderSuccessMessage, setRemoveOrderSuccessMessage] = useState(null);
  const [rescheduleErrorMessage, setRescheduleErrorMessage] = useState(null);

  const deliveryProof = useSelector(selectDeliveryProof);
  const isLoadingDeliveryProof = useSelector(selectIsLoadingDeliveryProof);
  const isPerformingAction = useSelector(selectIsPerformingAction);

  const orderId = id;
  const schedule = {
    dropoffSchedule,
    pickupSchedule
  };

  const fetchDeliveryProof = (action) => {
    setDeliveryProofError(null);
    dispatch(getDeliveryProof({ orderId, proofType: action }))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

        return response;
      })
      .catch((error) => {
        setDeliveryProofError(error?.message);
      });
  };

  const handleActionModalCloseButtonClick = () => {
    // We can hide both modals because there is only one open at the time
    setShowActionModal(false);
    setShowDeliveryProofModal(false);
    setShowRescheduleModal(false);
  };

  const handleActionModalConfirmButtonClick = (event) => {
    event.preventDefault();

    dispatch(performDeliveryAction({ orderId, actionType }))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

        return response;
      })
      .then((response) => {
        dispatch(fetchNotifications());
        dispatch(getDeliveryDetails(orderId));

        if (actionType === 'remove') {
          setRemoveOrderSuccessMessage('Order successfully removed.');
        } else {
          setShowActionModal(false);
        }

        return response;
      })
      .catch((error) => {
        // NOTE: Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleRescheduleDeliveryConfirmation = (areDatesValid, dropoffDate, pickupDate) => {
    if (!areDatesValid) {
      setRescheduleErrorMessage('Make sure your date is in the future and has a correct format.');
    } else {
      setRescheduleErrorMessage(null);
      dispatch(rescheduleDelivery({ orderId, dropoffDate, pickupDate }))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Something went wrong while performing this action.');

          return response;
        })
        .then((response) => {
          dispatch(fetchNotifications());
          dispatch(getDeliveryDetails(orderId));

          setShowRescheduleModal(false);

          return response;
        })
        .catch((error) => {
          setShowRescheduleModal(false);
          // NOTE: Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  const handleDeliveryActionClick = (action, direct, title) => {
    setActionType(action);
    setActionTitle(title);

    if (direct) {
      setShowActionModal(true);
      setShowDeliveryProofModal(false);
      setShowRescheduleModal(false);
    } else if (action === 'proof_collection' || action === 'proof_delivery' || action === 'proof_return') {
      setShowActionModal(false);
      setShowDeliveryProofModal(true);
      setShowRescheduleModal(false);
      fetchDeliveryProof(action);
    } else if (action === 'reschedule') {
      setShowActionModal(false);
      setShowDeliveryProofModal(false);
      setShowRescheduleModal(true);
    }
  };

  const intl = useIntl();

  return (
    <div className="order-actions">
      <h3 className="order-proposals__title">
        <FormattedMessage id="order.actions" />
      </h3>
      <ul className="order-actions__list">
        {Array.isArray(actions) && actions.map((item, index) => (
          <li
            key={index}
            className="order-actions__item"
          >
            {item.actionUrl ? (
              <a
                className="order-actions__item-text"
                href={item.actionUrl.startsWith('/') ? item.actionUrl : `${item.actionUrl}`}
                rel="noreferrer noopener"
                style={item.color ? { color: `${item.color}` } : {}}
                target="_blank"
              >
                {item.text}
              </a>
            ) : (
              <div
                className="order-actions__item-text"
                style={item.color ? { color: `${item.color}` } : {}}
                onClick={() => handleDeliveryActionClick(item.action, item.direct, item.title || item.text)}
              >
                {item.text}
              </div>
            )}
          </li>
        ))}
      </ul>
      {showActionModal && (
        <ActionModal
          finalMessage={actionType === 'remove' ? removeOrderSuccessMessage : null}
          finishPath={actionType === 'remove' ? goBackUrl : null}
          finishText={actionType === 'remove' ? intl.formatMessage({ id: 'order.action.back' }) : intl.formatMessage({ id: 'order.action.finish' })}
          isLoading={isPerformingAction}
          title={actionTitle}
          onClose={handleActionModalCloseButtonClick}
          onConfirm={handleActionModalConfirmButtonClick}
        />
      )}
      {showDeliveryProofModal && (
        <DeliveryProofModal
          {...deliveryProof}
          error={deliveryProofError}
          isLoading={isLoadingDeliveryProof}
          title={actionTitle}
          onClose={handleActionModalCloseButtonClick}
        />
      )}
      {showRescheduleModal && (
        <RescheduleModal
          errorMessage={rescheduleErrorMessage}
          isLoading={isPerformingAction}
          schedule={schedule}
          title={actionTitle}
          onClose={handleActionModalCloseButtonClick}
          onSave={handleRescheduleDeliveryConfirmation}
        />
      )}
    </div>
  );
};

OrderActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    actionUrl: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
  })),
  dropoffSchedule: PropTypes.string,
  goBackUrl: PropTypes.string,
  id: PropTypes.string,
  pickupSchedule: PropTypes.string
};

OrderActions.defaultProps = {
  actions: null,
  dropoffSchedule: null,
  goBackUrl: null,
  id: null,
  pickupSchedule: null
};

export default OrderActions;
