import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { addAddress, getAddresses } from 'store/slices/user-addresses';
import { getUser } from 'store/slices/authentication';

import AddressForm from '@youship/components/address-form';
import MapPoint from '@youship/components/map-point';

import mapIcon from '@youship/assets/images/icons/map.svg';
import markerPickupIcon from '@youship/assets/images/icons/marker-blue.svg';

import './styles.scss';

const AddressInformationStep = forwardRef((componentProps, ref) => {
  const { classNames, formClassName, onSubmitting, onValidation, onSuccess } = componentProps;
  const form = useRef(null);
  const dispatch = useDispatch();

  const [addressChangeIsLoading, setAddressChangeIsLoading] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({
    apartmentAddress: '',
    city: '',
    countryCode: '',
    formattedAddress: '',
    lat: '',
    lng: '',
    numberAddress: '',
    streetAddress: '',
    postalCode: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mapIsLoading, setMapIsLoading] = useState(false);
  const [searchAddress, setSearchAddress] = useState('');
  const [showMap, setShowMap] = useState(false);

  const isAddressValid = !!currentAddress.streetAddress && !!currentAddress.postalCode && !mapIsLoading && !isSubmitting;

  useImperativeHandle(ref, () => ({
    submitForm () {
      handleFormSubmit(currentAddress);
    }
  }));

  const handleFormChange = (formAddress) => {
    setCurrentAddress(prevState => ({
      ...prevState,
      ...formAddress
    }));

    if (formAddress?.streetAddress) setSearchAddress(formAddress.streetAddress);
  };

  const handleFormSearchChange = (formSearchAddress) => {
    setSearchAddress(formSearchAddress);
  };

  const handleFormSubmit = (newAddress) => {
    setIsSubmitting(true);
    onSubmitting();
    dispatch(addAddress({
      address: {
        apartmentAddress: newAddress.apartmentAddress,
        city: newAddress.city,
        formattedAddress: newAddress.formattedAddress,
        lat: newAddress.lat,
        lng: newAddress.lng,
        numberAddress: newAddress.numberAddress,
        postalCode: newAddress.postalCode,
        streetAddress: newAddress.streetAddress,
        countryCode: newAddress.countryCode
      },
      isDefault: true
    }))
      .then(async (response) => {
        setCurrentAddress(newAddress);

        await Promise.all([dispatch(getUser()), dispatch(getAddresses)]);

        onSuccess();
        setIsSubmitting(false);

        return response;
      })
      .catch((error) => {
        setIsSubmitting(false);
        // Add proper error handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(
    () => {
      dispatch(getAddresses({
        pages: {
          current: 0,
          resultsbypage: 20
        }
      }))
        .catch((error) => {
          // Add proper error handling
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    setShowMap(currentAddress && currentAddress.formattedAddress !== '');
  }, [currentAddress, isSubmitting, mapIsLoading]);

  useEffect(() => {
    setShowMap(currentAddress && currentAddress.formattedAddress !== '');
  }, [currentAddress]);

  useEffect(() => {
    onValidation(isAddressValid);
  }, [isAddressValid, onValidation]);

  return (
    <div className="address-information-step">
      <div className="address-information-step__map">
        {showMap ? (
          <MapPoint
            address={currentAddress?.formattedAddress}
            coordinates={{ lat: currentAddress.lat, lng: currentAddress.lng }}
            fullHeight
            id="modalMap"
            location={currentAddress?.formattedAddress}
            markerIcon={markerPickupIcon}
            onAddressChange={handleFormChange}
            onLoadingAddressChange={(value) => {
              setAddressChangeIsLoading(value);
            }}
            onLoadingStateChange={(value) => {
              setMapIsLoading(value);
            }}
          />
        ) : (
          <div className="address-information-step__map-placeholder">
            <img
              alt="Map Icon"
              className="address-information-step__map-placeholder-icon"
              src={mapIcon}
            />
            <FormattedMessage id="map.choose_location" />
          </div>
        )}
      </div>
      <AddressForm
        ref={form}
        address={currentAddress}
        addressIsChanging={addressChangeIsLoading}
        classNames={`${formClassName ? formClassName : 'register-wizard'}__step${classNames ? ` ${classNames}` : ''}`}
        fullWidth
        mapIsLoading={mapIsLoading}
        searchAddress={searchAddress}
        showCountryInput
        type="user"
        onChange={handleFormChange}
        onSearchInputChange={handleFormSearchChange}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
});

AddressInformationStep.propTypes = {
  componentProps: PropTypes.shape({
    classNames: PropTypes.string,
    formClassName: PropTypes.string,
    onSubmitting: PropTypes.func,
    onValidation: PropTypes.func,
    onSuccess: PropTypes.func
  })
};

AddressInformationStep.defaultProps = {
  componentProps: null
};

export default AddressInformationStep;
