import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SharedOrderTypeCard from '@youship/components/order-type-card';

const OrderTypeCard = ({ id, ...props }) => (
  id || typeof id === 'number' ? (
    <SharedOrderTypeCard
      {...props}
      linkComponent={Link}
      linkProps={{ to: `/category/${id}` }}
    />
  ) : (
    <SharedOrderTypeCard {...props} />
  )
);


OrderTypeCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

OrderTypeCard.defaultProps = {
  id: null
};

export default OrderTypeCard;
