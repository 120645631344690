import BaseApi from '..';
import {
  ADDRESS_ADD,
  ADDRESS_DETAILS,
  ADDRESS_EDIT,
  ADDRESS_LIST,
  ADDRESS_REMOVE
} from '../endpoints';


export default class UserAddressesApi extends BaseApi {
  /* eslint-disable camelcase */

  static addressList = ({
    pages = { // optional
      current: 0,
      resultsbypage: 0
    }
  }) => this.post(ADDRESS_LIST, {
    pages
  });

  static addressDetails = ({
    address_code
  }) => this.post(ADDRESS_DETAILS, {
    address_code
  });

  static newAddress = ({
    address = {
      lat: '', // optional
      lng: '', // optional
      street_address: '',
      number_address: '', // optional
      apartment_address: '', // optional
      state_address: '', // optional
      postalcode: '',
      city: '',
      countrycode: '', // optional
      idcountry: '', // optional
      formatted_address: ''
    },
    contact = {
      name: '',
      company: '', // optional
      phone: '',
      phonecode: '', // optional
      idphonecode: 0, // optional
      phonelist: '', // optional
      email: '', // optional
      notes: '' // optional
    },
    isdefault, // optional
    type // optional
  }) => {
    const data = {
      address,
      contact
    };

    if (isdefault) data.isdefault = isdefault;
    if (type) data.type = type;

    return this.post(ADDRESS_ADD, data);
  };

  static editAddress = ({
    address = {
      lat: '', // optional
      lng: '', // optional
      street_address: '',
      number_address: '', // optional
      apartment_address: '', // optional
      state_address: '', // optional
      postalcode: '',
      city: '',
      countrycode: '', // optional
      idcountry: '', // optional
      formatted_address: ''
    },
    contact = {
      name: '',
      company: '', // optional
      phone: '',
      phonecode: '', // optional
      idphonecode: 0, // optional
      phonelist: '', // optional
      email: '', // optional
      notes: '' // optional
    },
    address_code,
    isdefault, // optional
    type // optional
  }) => {
    const data = {
      address,
      contact,
      address_code
    };

    if (isdefault) data.isdefault = isdefault;
    if (type) data.type = type;

    return this.post(ADDRESS_EDIT, data);
  };

  static removeAddress = ({
    address_code
  }) => this.post(ADDRESS_REMOVE, {
    address_code
  });

  /* eslint-enable camelcase */
}
