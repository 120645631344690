import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import NotificationsApi from '@youship/api/notifications';

const notificationsAdapter = createEntityAdapter();

const initialState = notificationsAdapter.getInitialState({
  cleanSlate: true,

  isLoading: false
});

// Thunks:

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  () => NotificationsApi.allNotifications()
    .then((response) => {
      if (response && Array.isArray(response.rows)) {
        const notifications = response.rows
          .filter(notification => notification.payload)
          .map((notification) => {
            const { notification_code: id, notification_date: date, seen } = notification;
            const { body, click_action: clickAction, data: { order_code: orderId }, title } = notification.payload;

            let linkPath = null;

            if (NOTIFICATION_CLICK_ACTIONS.includes(clickAction)) {
              // TODO: add missing click actions handling
              if (orderId) {
                linkPath = `/order/${orderId}`;
              }
            }

            const notificationDate = typeof date === 'string' ? moment(date) : date;

            const difference = moment().diff(notificationDate, 'minutes');
            let timeDifferenceText = '';

            if (difference < 5) {
              timeDifferenceText = 'now';
            } else if (difference < 60) {
              timeDifferenceText = `${difference}m`;
            } else if (difference < 1440) {
              timeDifferenceText = `${Math.round(difference / 60)}h`;
            } else {
              timeDifferenceText = `${Math.round(difference / 1440)}d`;
            }

            return {
              ...notification,
              id,
              date,
              linkPath,
              seen,
              text: body,
              title,
              timeDifference: timeDifferenceText
            };
          });

        return notifications;
      }

      return null;
    })
);

export const markNotificationAsSeen = createAsyncThunk(
  'notifications/markNotificationAsSeen',
  // eslint-disable-next-line camelcase
  notificationId => NotificationsApi.notificationSeen({ notification_code: notificationId })
    .then(() => notificationId)
);

// Slice:

const notificationsSlice = createSlice({
  name: 'shops',

  initialState,

  reducers: {
    resetNotifications (state) {
      notificationsAdapter.setAll(state, []);
      state.cleanSlate = true;
    },

    unpushNotification (state, action) {
      const id = action.payload;

      if (id || typeof id === 'number') {
        notificationsAdapter.updateOne(state, { id, changes: { push: false } });
      }
    },

    unpushNotifications (state, action) {
      const ids = action.payload;

      if (Array.isArray(ids)) {
        const updates = [];

        ids.forEach((id) => {
          if (id || typeof id === 'number') {
            updates.push({ id, changes: { push: false } });
          }
        });

        notificationsAdapter.updateMany(state, updates);
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        const { cleanSlate, ids } = state;
        const notifications = action.payload;

        if (Array.isArray(notifications)) {
          if (cleanSlate) {
            // Push unseen notifications from the last two minutes
            const threshold = moment().subtract(2, 'minutes');

            notificationsAdapter.setAll(state, notifications.map(notification => ({
              ...notification,
              push: !notification.seen && moment(notification.date).isAfter(threshold)
            })));
            state.cleanSlate = false;
          } else {
            notifications.forEach((notification) => {
              const { id, seen } = notification;

              if (!ids.includes(id)) notificationsAdapter.addOne(state, { ...notification, push: !seen });
            });
          }
        }

        state.isLoading = false;
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(markNotificationAsSeen.fulfilled, (state, action) => {
        const { ids } = state;
        const notificationId = action.payload;

        if (ids.includes(notificationId)) {
          notificationsAdapter.upsertOne(state, { id: notificationId, push: false, seen: true });
        }
      });
  }
});

export default notificationsSlice.reducer;

// Actions

export const { resetNotifications, unpushNotification, unpushNotifications } = notificationsSlice.actions;

// Selectors:

export const {
  selectAll: selectNotifications,
  selectById: selectNotificationById
} = notificationsAdapter.getSelectors(state => state.notifications);

export const selectIsLoading = state => state.notifications.isLoading;

export const selectUnseenNotifications = createSelector(
  selectNotifications,
  notifications => notifications.filter(notification => !notification.seen)
);

export const selectPushNotifications = createSelector(
  selectNotifications,
  notifications => notifications.filter(notification => notification.push)
);

export const NOTIFICATION_CLICK_ACTION_OPEN_BID_DETAILS = 'OPEN_BID_DETAILS';
export const NOTIFICATION_CLICK_ACTION_OPEN_MESSAGE_DETAILS = 'OPEN_MESSAGE_DETAILS';
export const NOTIFICATION_CLICK_ACTION_OPEN_ORDER_DETAILS = 'OPEN_ORDER_DETAILS';

export const NOTIFICATION_CLICK_ACTIONS = [
  NOTIFICATION_CLICK_ACTION_OPEN_BID_DETAILS,
  NOTIFICATION_CLICK_ACTION_OPEN_MESSAGE_DETAILS,
  NOTIFICATION_CLICK_ACTION_OPEN_ORDER_DETAILS
];
