import BaseApi from '..';
import {
  TRACK_DETAILS,
  TRACK_LIVE_TRACKING,
  TRACK_SEND_MESSAGE
} from '../endpoints';

export default class TrackApi extends BaseApi {
  /* eslint-disable camelcase */

  static orderTracking = ({
    code
  }) => this.post(TRACK_DETAILS, {
    code
  });

  static orderLiveTracking = ({
    code
  }) => this.post(TRACK_LIVE_TRACKING, {
    code
  });

  static sendMessage = ({
    code,
    msg
  }) => this.post(TRACK_SEND_MESSAGE, {
    code,
    msg
  });

  /* eslint-enable camelcase */
}
