import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const InputPlaceholder = ({
  children,
  items,
  loading
}) => (
  <div className={`input-placeholder${loading ? ' input-placeholder--loading' : ''}`}>
    {children}
    <div className="input-placeholder__items">
      {items.map((item, index) => (
        <div
          key={index}
          className="input-placeholder__item"
          style={{ width: `${item}%` }}
        />
      ))}
    </div>
  </div>
);

InputPlaceholder.propTypes = {
  children: PropTypes.element,
  items: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool
};

InputPlaceholder.defaultProps = {
  children: null,
  items: [{ width: '100%' }],
  loading: null
};

export default InputPlaceholder;
