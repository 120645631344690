/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const NOTE_CONTEXT_DANGER = 'danger';
const NOTE_CONTEXT_DEFAULT = 'default';
const NOTE_CONTEXT_SUCCESS = 'success';

const NOTE_CONTEXTS = [
  NOTE_CONTEXT_DANGER,
  NOTE_CONTEXT_DEFAULT,
  NOTE_CONTEXT_SUCCESS
];

const CustomQuantity = ({ description, disabled, label, max, min, note, noteContext, value, onChange }) => {
  const preventAddition = typeof max === 'number' && value >= max;
  const preventSubtraction = typeof min === 'number' && value <= min;

  const handleMinusClick = () => {
    if (!disabled && !preventSubtraction) onChange(value - 1);
  };

  const handlePlusClick = () => {
    if (!disabled && !preventAddition) onChange(value + 1);
  };

  return (
    <div className={`custom-quantity${disabled ? ' custom-quantity--disabled' : ''}${value === 0 ? ' custom-quantity--zero' : ''}`}>
      <div className="custom-quantity__input-container">
        <div className="custom-quantity__input">
          <div
            className={`custom-quantity__button custom-quantity__button--minus${preventSubtraction ? ' custom-quantity__button--disabled' : ''}`}
            onClick={handleMinusClick}
          />
          <div className="custom-quantity__value">
            {value}
          </div>
          <div
            className={`custom-quantity__button custom-quantity__button--plus${preventAddition ? ' custom-quantity__button--disabled' : ''}`}
            onClick={handlePlusClick}
          />
        </div>
      </div>
      <div className="custom-quantity__content">
        <div className="custom-quantity__content-header">
          <div className="custom-quantity__label">
            {label}
          </div>
        </div>
        {!!description && (
          <div className="custom-quantity__description">
            {description}
          </div>
        )}
      </div>
      {!!note && (
        <div className={`custom-quantity__note${NOTE_CONTEXTS.includes(noteContext) && noteContext !== NOTE_CONTEXT_DEFAULT ? ` radio__note--${noteContext}` : ''}`}>
          {note}
        </div>
      )}
    </div>
  );
};

CustomQuantity.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  note: PropTypes.string,
  noteContext: PropTypes.oneOf(NOTE_CONTEXTS),
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func
};

CustomQuantity.defaultProps = {
  description: null,
  disabled: false,
  label: '',
  max: null,
  min: 0,
  note: null,
  noteContext: NOTE_CONTEXT_DEFAULT,
  onChange: () => {}
};

export default CustomQuantity;
